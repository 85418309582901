// stateless react component that renders an object
// Takes in the object in the props

import React, { Component } from 'react';

export default class PrettySize extends Component {

    render() {

        var prettyvalue;

        if(this.props.value < 2000)
        {
            prettyvalue = this.props.value;
        }
        if(this.props.value < 1200000)
        {
            prettyvalue = "" + this.props.value / 1000 + "k";
        }
        if(this.props.value < 1200000000)
        {
            prettyvalue = "" + this.props.value / 1000000 + " mb";
        }
        return (<div>{prettyvalue}</div>);
    }
}
