import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dialog from '../dialog';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';
import Events from '../../../../common/events';
import globalconfig from '../../../../common/config';

export default class PerformRavenAction extends Component {

    constructor(props) {
        super(props);

        // Store the raven to use in the state.  If it doesn't exist, then offer the
        // raven selector
        this.state = {
            historyModalIsOpen: false,
            selectedAction: null,
            suspend_activity: true,
            changed: false,
            attempts: 0
        }

        this.ravenActions = [
          // { value: 'PING', label: 'Ping' },
          { value: 'LOGREQUEST', label: 'Request Logs' },
          { value: 'REBOOT', label: 'Reboot Raven' },
        ];

        this.componentWillReceiveProps(props);

        // How many seconds from now before tombstones are considered stale
        this.tombstoneStaleSeconds = 18000;

        this.localState = {}

    }


    componentDidMount() {

        if(this.props.raven && this.props.raven.Id)
        {
            this.localState.ravenid = this.props.raven.Id;
            this.queryTombstoneData();
        }
    }

    componentDidUpdate() {

        if(this.props.raven && this.props.raven.Id)
        {   
            if(this.props.raven.Id !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.raven.Id;

                this.queryTombstoneData();
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.ravenData.geojson && nextProps.ravenData.geojson.objects)
        {
            this.ravenList = nextProps.ravenData.geojson.objects.map(obj => {
                return { value : '' + obj['Id']
                        + ' ' + obj['Account']['Email']
                        + ' (' + obj['Raven']['Unit Id'] + ')',
                    label: '' + obj['Raven']['Unit Id']
                        + ' ' + obj['Account']['Email'],
                    id: obj['Id'],
                    obj: obj
                };

            });

        }
        else
        {
            this.ravenList = [];
        }

    }



    queryTombstoneData()
    {

        if(this.tombstoneTimeout) {
            clearTimeout(this.tombstoneTimeout);
        }

        // Reset the number of attempts if the state changed in a big way (different Raven).
        this.setState({tombstoneMessageType: 'error', tombstoneMessage: "Querying for log messages...", tombstones: [], attempts: 0});

        // Queue it up for 2 seconds from now.  It'll refresh the timeout handler itself
        this.tombstoneTimeout = setTimeout( this.queryTombstoneTimeout, 2000);

    }

    queryTombstoneTimeout = () =>
    {

        // Clear the tombstone data in case we switch Ravens
        this.setState({tombstoneMessageType: 'error', tombstoneMessage: "Querying for log messages...", tombstones: []});


        var curraven = this.props.raven;
        if(!curraven)
        {
            this.setState({tombstoneMessageType: 'error', tombstoneMessage: "Not a valid Raven. Not querying for tombstones", tombstones: []});
            return;
        }
        var ravenid = curraven['Id'];
        var uuid = curraven['Raven']['Raven UUID'];
        var version = curraven['Build']['OS Version'];

        var me = this;

        var prefix1 = process.env.REACT_APP_KLOUD_API_BASE_URL + 'query/';


        // perform ajax calls to get it the next state
        var url = "" + prefix1 + "logs"
                + "?stage=" + this.props.stage
                + "&type=tombstones"
                + "&raven=" + ravenid
                + "&uuid=" + uuid
                + "&version=" + version;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                return [];
            }
        }).then((json) => {


            var tmessage = this.checkTombstoneData(json);

            this.setState({tombstoneMessageType: tmessage[0], tombstoneMessage: tmessage[1], tombstones: json});


            // Poll.  Check again in 30 seconds
            this.tombstoneTimeout = setTimeout( this.queryTombstoneTimeout, 30000);

        }).catch( (error) => {

            this.setState({tombstoneMessage: "Error querying for log messages.  Not able to reboot Raven.", tombstones: []});

            // Poll.  Check again in 30 seconds
            this.tombstoneTimeout = setTimeout( this.queryTombstoneTimeout, 30000);

        });

    }

    checkTombstoneData(tombstones)
    {

        if(!tombstones)
        {
            return ['error', "No log data found yet.  Please wait for log query to return"];
        }
        if(!tombstones.length)
        {
            return ['error', "No log data.  Please request logs and try again"];
        }
        var i;

        var mostrecent;

        for(i = 0; i < tombstones.length; i++)
        {

            var tombstone = tombstones[i];
            if(!tombstone['name'])
            {
                continue;
            }
            if(tombstone['name'].includes('logd'))
            {
                if(!tombstone['LastModified'])
                {
                    continue;
                }
                var ttime = moment.utc(tombstone['LastModified']);

                if(moment().diff(ttime, 'seconds') < this.tombstoneStaleSeconds )
                {
                    mostrecent = ttime.local().format(globalconfig.display.timeFormat) + " (" + ttime.fromNow() + ")" ;
                    return ['success', "Most recent logs is "+mostrecent+"."];
                }
                else
                {
                    if(!mostrecent)
                    {

                        mostrecent = ttime.local().format(globalconfig.display.timeFormat) + " (" + ttime.fromNow() + ")" ;

                    }
                }
            }
        }

        if(!mostrecent)
        {
            return ['error', "No recent logs.  Please send log request and wait for them to upload prior to rebooting."];
        }
        else
        {
            return ['error', "Most recent logs is "+mostrecent+".  Waiting for newer logs."];
        }
    }


    onRavenActionChange= (val) => {
        this.setState({selectedAction: val, attempts: 0 });
    }

    onPerformAction = (e) => {

        e.preventDefault();

        if(this.state.selectedAction)
        {
            if(this.state.selectedAction.value === 'REBOOT')
            {
                // Check for tombstone data
                var reasonarray = this.checkTombstoneData(this.state.tombstones);

                var reason = reasonarray[0];

                // This is an easter egg.  It was requested that there be an ability to force reboot a Raven even if the logs aren't uploaded.
                // However, we don't want ot advertise that fact with a checkbox or a dialog as people will end up using it all the time.
                // This being undocumented and unsupported means that we can change or remove it at any time... in theory.

                if(reason === 'success')
                {
                    // good to go
                    this.performRavenAction();
                }
                else if(this.state.attempts > 5)
                {
                    // override
                    this.performRavenAction(true);
                }
                else
                {

                    var numattempts = this.state.attempts + 1;

                    this.setState({message: "Error:" + reasonarray[1], messageType: 'error', changed: true, attempts: numattempts});

                }
            }
            else
            {
                this.performRavenAction();
            }
        }
        else
        {
            console.log("Go clicked with nothing set");
        }
    }

    performRavenAction = (override = false) => {

        console.log("Perform Raven Action.");

        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE,
                { detail: { loadingMessage: 'Performing Raven Action' } }));

        var prefix = this.props.dataStore.prefix2;

        let reason;

        switch (this.state.selectedAction.value) {
            case "LOGREQUEST":
            case "REBOOT":
            default:
                reason = "Deployed from tools section. No reason input available at this time.";
                break;
        }

        /* FIXME: Determine if token is expired and refresh prior to calling */
        var url = prefix + "performravenaction"
            + "?stage=" + this.props.stage
            + "&ravenunit=" + this.props.raven['Raven']['Raven UUID']
            + "&action=" + this.state.selectedAction.value
            + "&reason=" + encodeURIComponent(reason);

        if(override)
        {
            this.setState({message: "Override Enforced.  Action Performed", messageType: 'error', changed: true, attempts: 0});
        }
        else
        {
            this.setState({message: null, messageType: 'error', changed: true, attempts: 0});
        }


        // console.log("Calling into", url);

        return fetch(url,{
            method: 'POST',
            headers: { Authorization: CognitoUtil.getCurrentUserToken() }
          })
          .then((response) => {
            return response.json();
          }).then((json) => {

              // console.log("Received", json);
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));

              if(json['status'] === 'SUCCESS')
              {
                  if(this.state.selectedAction.value === 'LOGREQUEST')
                  {
                      // For loqrequest action, give a better success message
                      this.setState({message: "Success.  Please wait a few minutes for the logs to upload before attempting to reboot.", messageType: 'success', attempts: 0});

                  }
                  else
                  {
                      this.setState({message: "Success", messageType: 'success', attempts: 0});
                  }
              }
              else if(json['message'])
              {
                  this.setState({message: "Error:" + json['message'], messageType: 'error', attempts: 0});
              }
              else
              {
                  this.setState({message: "Error: Unknown Error:" + json['status'], messageType: 'error', attempts: 0});
              }

          }).catch((error) => {
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
              this.setState({message: "Error: " + error, messageType: 'error', attempts: 0});
              console.error("Status Change Error", error);
          });

    }


    onSelectRavenSearch = (e) => {

        var uuid = e.obj['Raven']['Raven UUID'];

        this.props.routeProps.history.push('/tool/' + this.props.stage + '/perform-raven-action/' + uuid);

    }


    render() {
        var content = "Select Raven";

        var errorMessage;

        if(this.state.messageType === 'success')
        {
            errorMessage = this.state.message ?
                <p className="success-message"><i className="material-icons">check_circle_outline</i>{this.state.message}</p> :
                null;
        } 
        else
        {
            errorMessage = this.state.message ?
                <p className="error-message"><i className="material-icons">error_outline</i>{this.state.message}</p> :
                null;
        } 

        var tombstoneMessage = null;

        if(this.state.tombstoneMessage)
        {
            if(this.state.tombstoneMessageType === 'success')
            {
                tombstoneMessage = <p className="success-message"><i className="material-icons">check_circle_outline</i>{this.state.tombstoneMessage}</p>;
            }
            else
            {
                tombstoneMessage = <p className="error-message"><i className="material-icons">error_outline</i>{this.state.tombstoneMessage}</p>;
            }
        }

        var title;

        if(this.props.ravenUuid)
        {
            // Raven UUID set, that means that we are probably still loading
            content = "Loading...";
        }

        if(this.props.raven)
        {
            title="Perform Action For "+this.props.raven['Raven']['Enclosure Serial No.'];

            var lastseen =  this.props.raven['Last Update']?
                moment.unix(this.props.raven['Last Update']).format(globalconfig.display.timeFormat) + " (" + moment.unix(this.props.raven['Last Update']).fromNow() + ")" :
                "Never";


            content = (
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">Raven ID:</div>
                        <div className="col-6">{this.props.raven['Raven']['Enclosure Serial No.']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Email:</div>
                        <div className="col-6">{this.props.raven['Account']['Email']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Owner Name:</div>
                        <div className="col-6">{this.props.raven['Raven']['Owner Name']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Seen:</div>
                        <div className="col-6">{lastseen}</div>

                    </div>
                    <div className="row">
                        <div className="col-6">Account Type:</div>
                        <div className="col-6">{this.props.raven['Account']['Account Type']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Action to Perform: 
                        </div>
                        <div className="col-6">
                            <Select id="ravenSearch" 
                            placeholder="Action..."
                            searchable={true} 
                            value={this.state.selectedAction}
                            options={this.ravenActions}  
                            name="ravenAction" 
                            onChange={this.onRavenActionChange} 
                            clearable={false} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {this.state.selectedAction ?
                                <button onClick={this.onPerformAction}>Commit</button> :
                                <button onClick={this.onPerformAction} disabled>Select an Option</button>
                            }
                        </div>
                        <div className="col-6">
                            {errorMessage}
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-6">
                        </div>
                        <div className="col-6">
                            {tombstoneMessage}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Dialog
                size="small"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-2">Raven:</div>
                            <div className="col-10">
                                <Select id="ravenSearch" 
                                    placeholder="Search..."
                                    searchable={true} 
                                    options={this.ravenList}
                                    noResultsText="Loading..."
                                    name="ravenSearch" 
                                    filterOption={(option, filter) => {

                                        // Search: ravenid, raven unit id, email, raven enclosure id 
                                        if(option.obj['Id'].toString().includes(filter))
                                            return true;
                                        if('Serial No.' in option.obj['Raven'] && option.obj['Raven']['Serial No.'])
                                        {
                                            if(option.obj['Raven']['Serial No.'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Email' in option.obj['Account'] && option.obj['Account']['Email'])
                                        {
                                            if(option.obj['Account']['Email'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Enclosure Serial No.' in option.obj['Raven'] &&
                                            option.obj['Raven']['Enclosure Serial No.'])
                                        {
                                            if(option.obj['Raven']['Enclosure Serial No.'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Unit Id' in option.obj['Raven'] && option.obj['Raven']['Unit Id'])
                                        {
                                            if(option.obj['Raven']['Unit Id'].toString().includes(filter))
                                                return true;
                                        }
                                        if('Raven UUID' in option.obj['Raven'] && option.obj['Raven']['Raven UUID'])
                                        {
                                            if(option.obj['Raven']['Raven UUID'].toString().includes(filter))
                                                return true;
                                        }

                                        return false;
                                    }}
                                    onChange={this.onSelectRavenSearch} 
                                    clearable={false} />
                            </div>
                        </div>
                        <div className="row">
                            {content}
                        </div>
                    </div>
            </Dialog>
        );
    }
}
