import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import globalconfig from '../../common/config';
import FeatureSheet from './panels/featureSheet';
import FeatureListing from './panels/featureListing';
import Header from './panels/header';
import Events from '../../common/events';
import MapboxMap from '../../mapbox/mapboxMap';
import CognitoUtil from '../../aws/cognito/cognitoUtil';
import ZendeskContactWebWidget from './widgets/ZendeskContactWebWidget';
import RavenDataStore from '../../common/ravenDataStore';

import 'bootstrap/dist/css/bootstrap.min.css';

/*
import TombstoneAllHistory from '../modals/logs/tombstoneAll';
import ThirdpartyStatus from '../modals/3rdparty/3rdpartyStatusaaa';
import NewAccountCreate from '../modals/user/newAccountCreate';
*/

import { tsvParse } from  "d3-dsv";
import { timeParse, timeFormat } from "d3-time-format";

const parseDate = timeParse("%Y-%m-%d");
const formatEpoch = timeFormat("%Y%m%d");

var letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
var numbers = "0123456789";

function isalpha(l) {
    return (letters.indexOf(l) != -1);
}

function isdigit(l) {
    return (numbers.indexOf(l) != -1);
}

function isalnum(l) {
    return (isalpha(l) || isdigit(l));
}

export default class MainPage extends Component {

    constructor(props) {

        super(props);

        this.state = {
            displayVariable: {},
            ravenData: {
                ravens: [],
                geojson: undefined
            },
            selectedFeatureAccountId: undefined,
            selectedFeatureAccountExternalId: undefined,
            ravenDataFiltered: undefined, // filtered for selectedFeature or 
            ravenDataTimestamp: Date.now(),
            supportTicketModal: null
        };

        this.localState = {}

        this.dataStore = new RavenDataStore(this.dataChangeCallback);
        
    }

    filterRavenDataForSelectedFeature (ravenData, selectedRavenUuidOwnerAccountId = this.state.selectedFeatureAccountId, selectedRavenUuidOwnerAccountName = this.state.selectedFeatureAccountExternalId) {

        /*  For automatically filtering on any subcomponent raven select event:
            However, intial test showed this was problematic due to data loss (account id) and beyond scope of sprint

        const selectedRavenUuid = this.props.match.params.ravenUuid; // <-- from feature click or directly via URL

        if (!selectedRavenUuid) {
            return undefined;
        }

        let selectedRavenUuidOwnerAccountId;
        let selectedRavenUuidOwnerAccountName;
        // get the account id for the selected raven
        if (!this.state.selectedFeatureAccountId || !this.state.selectedFeatureAccountExternalId) { // if user is following a direct link, this will be undefined
            for (let i = 0; i < ravenData.ravens.length; i++) {
                if (ravenData.ravens[i].hasOwnProperty("item") &&
                    ravenData.ravens[i].item.hasOwnProperty("Account") &&
                    ravenData.ravens[i].item.hasOwnProperty("Raven") &&
                    ravenData.ravens[i].item.Raven.hasOwnProperty("Raven UUID")) {

                    if (ravenData.ravens[i].item.Raven["Raven UUID"] === selectedRavenUuid) {
                        if (ravenData.ravens[i].item.Account["Account Id"]) {
                            selectedRavenUuidOwnerAccountId = ravenData.ravens[i].item.Account["Account Id"];
                        }
                        if (ravenData.ravens[i].item.Account["Account Name"]) {
                            selectedRavenUuidOwnerAccountName = ravenData.ravens[i].item.Account["Account Name"];
                        }
                        if (selectedRavenUuidOwnerAccountId && selectedRavenUuidOwnerAccountName) {
                            break; // stop the 'for loop'
                        }
                    }
                }
            }
            this.setState({
                selectedFeatureAccountId: selectedRavenUuidOwnerAccountId,
                selectedFeatureAccountExternalId: selectedRavenUuidOwnerAccountName
            })
        } else {
            selectedRavenUuidOwnerAccountId = this.state.selectedFeatureAccountId;
            selectedRavenUuidOwnerAccountName = this.state.selectedFeatureAccountExternalId;
        }

        if (!selectedRavenUuidOwnerAccountId && !selectedRavenUuidOwnerAccountName) {
            console.error("ravenPage filterRavenDataForSelectedFeature could not find account ids for selected raven");
            return undefined;
        } //End of "For automatically filtering ..." comment block */

        if (!selectedRavenUuidOwnerAccountId && !selectedRavenUuidOwnerAccountName) {
            return undefined;
        }

        let ravenDataFiltered = {
            ravens: [],
            geojson: undefined
        }

        let ravensFound = false;
        if (ravenData.ravens) {
            ravenDataFiltered.ravens = ravenData.ravens.filter((raven) => {

                if (raven.hasOwnProperty("item") && raven.item.hasOwnProperty("Account")) {

                    if ((raven.item.Account["Account Id"] === selectedRavenUuidOwnerAccountId) ||
                        (raven.item.Account["Account Name"] === selectedRavenUuidOwnerAccountName)) {

                        ravensFound = true;
                        return true;
                    }
                }
                return false;
            });
        }

        if (!ravensFound) {
            console.error("ravenPage filterRavenDataForSelectedFeature missing ravens for selectedRavenUuidOwnerAccountId.");
            return undefined;
        }

        if (!ravenData.geojson ||
            !ravenData.geojson.data ||
            !ravenData.geojson.data.features) {
            console.error("ravenPage filterRavenDataForSelectedFeature missing or invalid geojson property.");
            return undefined;
        }

        let geojsonFeaturesFound = false;
        ravenDataFiltered.geojson = {
            type: "geojson",
            objects: ravenDataFiltered.ravens.map((raven) => {return raven.item}),
            meta: ravenData.geojson.meta // TODO 'meta' seems like it need to be adjusted to reflect filtered data, but I didn't find an issue yet
        }
        ravenDataFiltered.geojson.data = {
            type: "FeatureCollection",
            features: ravenData.geojson.data.features.filter((feature) => {

                if (feature.hasOwnProperty("properties")) {
                    if ((feature.properties.account_id === selectedRavenUuidOwnerAccountId) ||
                        (feature.properties.account_external_id === selectedRavenUuidOwnerAccountName)) {

                        geojsonFeaturesFound = true;
                        return true;
                    }
                }
                return false;
            })
        }

        ravenDataFiltered.geojson.data.features.forEach( (feature, index) => {
            if (ravenDataFiltered.geojson.objects.length > index) {

                if (ravenDataFiltered.geojson.objects[index].Id === feature.properties.raven_id) {
                    feature.properties.index = index;
                    return;
                }
            }
            let featurePropertiesIndexFound = false;
            console.warn("ravenPage filterRavenDataForSelectedFeature geojson.objects index mismatch, searching ...");
            for (let j = 0; j < ravenDataFiltered.geojson.objects.length; j++) {

                if (ravenDataFiltered.geojson.objects[j].Id === feature.properties.raven_id) {
                    console.warn("ravenPage filterRavenDataForSelectedFeature geojson.objects index found!");
                    feature.properties.index = j;
                    featurePropertiesIndexFound = true;
                    break;
                }
            }
            if (!featurePropertiesIndexFound) {
                console.error("ravenPage filterRavenDataForSelectedFeature geojson.objects missing feature's raven");
            }
            return;
        });
        
        if (!geojsonFeaturesFound) {
            console.error("ravenPage filterRavenDataForSelectedFeature missing geojson features for selectedRavenUuidOwnerAccountId.");
            return undefined;
        }
        // optimization: store meta data in the ravenDataFiltered to prevent unnecessary refreshes (ravenPage renders ~6 times on load alone)
        return ravenDataFiltered;
    }

    dataChangeCallback = (ravenData) => {

        document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
        this.setState({
            ravenData: ravenData,
            ravenDataFiltered: this.filterRavenDataForSelectedFeature(ravenData),
            ravenDataTimestamp: Date.now()
        });
    }

    focusSearch = (e) => {
        if(e.ctrlKey || e.altKey || e.metaKey)
        {
            // Don't do anything if it isn't a normal key press
            return;
        }
        if(e.target) {
            switch (e.target.tagName) {
                case "INPUT":
                case "TEXTAREA":
                    // Don't do anything if the focus is already on an input field
                    return;
                default:
                    break;
            }
        }
        
        if(isalnum(e.key))
        {
            document.getElementById("ravenSearch").focus();
        }
    }

    componentDidMount() {
        // Focus the search bar if someone types alphanumeric characters somewhere in the main page
        document.addEventListener("keydown", this.focusSearch);
    }

    componentWillUnmount() {
         document.removeEventListener("keydown", this.focusSearch);
    }

    componentDidUpdate() {

        if(this.props.match.params.stage)
        {
            if(this.props.match.params.stage !== this.localState.stage ||
                this.state.displayType !== this.localState.type ||
                this.state.displayVariable.value !== this.localState.value ||
                this.state.displayVariable.label !== this.localState.label)
            {
                this.localState.stage = this.props.match.params.stage;
                this.localState.type = this.state.displayType;
                this.localState.value = this.state.displayVariable.value;
                this.localState.label = this.state.displayVariable.label;

                document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE,
                        { detail: { loadingMessage: 'Loading Ravens' } }));

                this.dataStore.refreshRavenList(this.localState.stage, 
                        this.localState.type, this.localState.value, 
                        this.localState.label);

            }
        }
    }

    onSupportTicketClick = (featureItem, itemOtabuilds) => {

        const dismissModal = () => {
            this.setState({supportTicketModal: null});
        };

        const user = window.user; // CognitoUtil.getUser(); provides an unauthenticated user (∴ temporarily applying window.user in CognitoUtil.refreshLogin())

        this.setState({
            supportTicketModal: <ZendeskContactWebWidget
                onClose={dismissModal}
                user={user}
                //order={order}
                item={featureItem}
            />
        });
    }

    onFeatureClick = (raven) => {

        if(!raven || !raven.item)
        {
            return;
        }

        if(raven.item["Raven"]["Raven UUID"])
        {
            this.props.history.push("/raven/" + this.props.match.params.stage + "/" + raven.item["Raven"]["Raven UUID"]);
        }
        else if(raven.item["Raven"]["Enclosure Serial No."])
        {
            this.props.history.push("/raven/" + this.props.match.params.stage + "/" + raven.item["Raven"]["Enclosure Serial No."]);
        }
        else
        {
            this.props.history.push("/raven/" + this.props.match.params.stage);
        }
    }

    applyFilterRavenId = (selectedRavenId) => {

        if (!selectedRavenId) {
            console.error("ravenPage applyFilterRavenId ravenid detail missing.");
            return;
        }

        var selectedRaven = this.state.ravenData.ravens.find( (raven) => {
            return raven.item ? raven.item.Id === selectedRavenId : false;
        });

        if (!selectedRaven) {
            console.error("ravenPage applyFilterRavenId selectedRaven not found in state ravenData for selectedRavenId.");
            return;
        }

        if (selectedRaven.item.hasOwnProperty("Account")) {
            // to be safe, mimic the two checks in the history.pushes below
            if (selectedRaven.item["Raven"]["Raven UUID"] || selectedRaven.item["Raven"]["Enclosure Serial No."]) {
                const selectedFeatureAccountId = selectedRaven.item.Account["Account Id"];
                const selectedFeatureAccountExternalId = selectedRaven.item.Account["Account Name"];
                this.setState({
                    selectedFeatureAccountId: selectedFeatureAccountId,
                    selectedFeatureAccountExternalId: selectedFeatureAccountExternalId,
                    ravenDataFiltered: this.filterRavenDataForSelectedFeature(this.state.ravenData, selectedFeatureAccountId, selectedFeatureAccountExternalId)
                });
                // check for missing data and attempt recovery
                if (!selectedFeatureAccountId || !selectedFeatureAccountExternalId) {
                    console.warn("ravenPage applyFilterRavenId selectedRavenitem missing account id or external id. Refreshing list.", selectedRaven);
                    // This issue seems to occur during queryRaven results merge (the result is missing account id, for example)
                    this.dataStore.refreshRavenList(
                        this.localState.stage, 
                        this.localState.type,
                        this.localState.value, 
                        this.localState.label);

                }
            }
        }
    }

    onRavenStageChanged = (val, type, prop) => {

        console.log("OnRavenStageChanged");
        this.setState({
            selectedFeatureAccountId: undefined,
            selectedFeatureAccountExternalId: undefined,
            ravenDataFiltered: undefined
        });
        this.props.history.push("/raven/" + val);

    }

    onRavenDisplayTypeChanged = (type, prop) => {

        this.setState({displayType: type, displayVariable: prop});

    }

    zoomToExtents = () => {
        document.dispatchEvent(new CustomEvent(Events.ZOOM_TO_EXTENTS));
    }

    onRavenDisplayVariableChanged = (type, prop) => {

        this.setState({displayType: type, displayVariable: prop});

    }

    renderFeatureSheet = (props) => {
        var curFeature;

        const ravenData = this.state.ravenData; // this.filterRavenDataForSelectedFeature(this.state.ravenData);
        if(props.match.params.ravenUuid)
        {
            curFeature = ravenData.ravens.find((elem) => { 
                    return elem.item['Raven']['Raven UUID'] === props.match.params.ravenUuid; 
                });

            if(!curFeature)
            {
                curFeature = ravenData.ravens.find((elem) => {
                    return elem.item['Raven']['Enclosure Serial No.'] === props.match.params.ravenUuid;
                });
            };

            return ( <FeatureSheet 
                    stage= { this.props.match.params.stage }
                    dataStore = {this.dataStore}
                    feature={ curFeature } 
                    featureId={ props.match.params.ravenUuid } 
                    onFeatureClick = { this.onFeatureClick } 
                    onSupportTicketClick = { this.onSupportTicketClick }
                />);
        }
        else
        {
            return <div>No Raven Selected</div>;
        }
    } 

    renderMap = (props) => {
        var curFeature;

        const ravenData = this.state.ravenDataFiltered ? this.state.ravenDataFiltered : this.state.ravenData;

        if(props.match.params.ravenUuid)
        {
            curFeature = ravenData.ravens.find((elem) => { 
                    return elem.item['Raven']['Raven UUID'] === props.match.params.ravenUuid; 
                });

            if(!curFeature)
            {
                curFeature = ravenData.ravens.find((elem) => {
                    return elem.item['Raven']['Enclosure Serial No.'] === props.match.params.ravenUuid;
                });
            };

            return ( <MapboxMap 
                    stage= { this.props.match.params.stage }
                    feature={ curFeature } 
                    displayVariable={this.state.displayVariable.value} 
                    displayVariableLabel={this.state.displayVariable.label} 
                    geojson={ravenData.geojson}
                    unfilteredGeojson={this.state.ravenData.geojson} // for RAVEN_SELECT_EVENT event handler (header.js displays unfiltered ravenData)
                    geojsonTimestamp={this.state.ravenDataTimestamp}
                    onFeatureClick = { this.onFeatureClick } 
                    onFeaturesVisible={this.onFeaturesVisible} 
                />);
        }
        else
        {
            return ( <MapboxMap 
                    stage= { this.props.match.params.stage }
                    feature={ null } 
                    displayVariable={this.state.displayVariable.value} 
                    displayVariableLabel={this.state.displayVariable.label} 
                    geojson={ravenData.geojson}
                    unfilteredGeojson={this.state.ravenData.geojson} // for RAVEN_SELECT_EVENT event handler (header.js displays unfiltered ravenData)
                    geojsonTimestamp={this.state.ravenDataTimestamp}
                    onFeatureClick = { this.onFeatureClick } 
                    onFeaturesVisible={this.onFeaturesVisible} 
                />);
        }
    } 
 
    render() {

        const FeatureListingRavenData = this.state.ravenDataFiltered ? this.state.ravenDataFiltered : this.state.ravenData;
        let filteredAccountId = this.state.selectedFeatureAccountId;
        if (globalconfig.features.ravens.vendorDetailsPreferred) { // OEM/Vendor
            filteredAccountId = this.state.selectedFeatureAccountExternalId;
        }

        return (
            <div id="mainPage" className="main-page">
                <div className="container-fluid">
                    <Header
                        stage={this.props.match.params.stage}
                        ravenStages={ this.props.stages }
                        ravenList={(this.state.ravenData.geojson && this.state.ravenData.geojson.objects) ? this.state.ravenData.geojson.objects : []}
                        ravenStageLoader={this.getRavenStages}
                        ravenDisplayTypeLoader={this.getRavenDisplayTypes}
                        ravenDisplayVariableLoader={this.getRavenDisplayVariables}
                        onRavenStageChange={this.onRavenStageChanged}
                        onRavenDisplayTypeChange={this.onRavenDisplayTypeChanged}
                        onRavenDisplayVariableChange={this.onRavenDisplayVariableChanged}
                        applyFilterRavenId={this.applyFilterRavenId}
                    />
                </div>

                <div className="main-content container-fluid">
                    <div className="row mapcontent">
                        <div className="listing" id='feature-listing'>
                            {filteredAccountId ? 
                                <div className="feature-list-filter">
                                    <span>Showing ravens for account</span>
                                    <span>{filteredAccountId}</span>
                                    <span><a href={"/raven/" + this.props.match.params.stage}>List All Ravens</a></span>
                                </div>
                            :
                                null
                            }
                            <FeatureListing
                                stage= { this.props.match.params.stage }
                                featureList={ [ ...FeatureListingRavenData.ravens] } 
                                timestamp={this.state.ravenDataTimestamp}
                                displayVariable={this.state.displayVariable} 
                                onFeatureClick={ this.onFeatureClick } 
                            />
                            <a href="#!" onClick={this.zoomToExtents}>
                                <img src="/images/ic_zoom_out_map_white_24dp_2x.png" alt="Zoom to Extents"/>
                            </a>
                        </div>
                        <div className="map">
                            <Route path="/raven/:stage/:ravenUuid?" render={this.renderMap} />
                        </div>
                        <div className="sheet" id='feature-sheet'>
                            <Route path="/raven/:stage/:ravenUuid?" render={this.renderFeatureSheet} />
                       </div>
                    </div>
                </div>
                {this.state.supportTicketModal}
            </div>
        );
    }
}
