
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Dialog from '../dialog';

import moment from 'moment';

import PropTypes from "prop-types";
import JSONView from '../../../../widgets/jsonView';

import { Sparklines, SparklinesLine } from 'react-sparklines';

import { set } from "d3-collection";
import { scaleOrdinal, scaleTime } from  "d3-scale";
import { schemeCategory10 } from  "d3-scale-chromatic";
import { timeFormat } from "d3-time-format";
import { format } from "d3-format";

import { ChartCanvas, Chart } from "react-stockcharts";
import { StackedBarSeries, LineSeries } from "react-stockcharts/lib/series";

import { Label } from "react-stockcharts/lib/annotation";

// import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { HoverTooltip } from "react-stockcharts/lib/tooltip";

import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { fitWidth } from "react-stockcharts/lib/helper";
import { EdgeIndicator, CrossHairCursor, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";


var dateFormat = timeFormat("%a %Y-%m-%d");
var numberFormat = format(",.0f");
var numberFormatFraction = format(",.1f");

function drawDataTotal(val, total) {
    return "" + numberFormat(val) + " (" + 
            numberFormatFraction((100 * val)/total) + "%)";
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function barWidthForTimeScale(props, moreProps) {

    const { widthRatio } = props;
    const { xScale } = moreProps;

    const [l, r] = xScale.range();

    const totalWidth = Math.abs(r - l);
    if (xScale.invert != null) {
        const [dl, dr] = xScale.domain();
        const width = totalWidth / dateDiffInDays(dl, dr);
        return width * widthRatio;
    } else {
        const [dr, dl] = xScale.domain();
        const width = totalWidth / dateDiffInDays(dl, dr);
        return width * widthRatio;
    }
}


function tooltipContent(calculators) {
    return ({ currentItem, xAccessor }) => {
        var retval = {
            x: dateFormat(xAccessor(currentItem)),
            y: [
                { label: "Streaming Tx", value: drawDataTotal(currentItem.tx_streaming, currentItem.tx_total + currentItem.rx_total) },
                { label: "Hotspot Tx", value: drawDataTotal(currentItem.tx_hotspot, currentItem.tx_total + currentItem.rx_total) },
                { label: "System Tx", value: drawDataTotal(currentItem.tx_system, currentItem.tx_total + currentItem.rx_total) },
                { label: "Other Tx", value: drawDataTotal(currentItem.tx_other, currentItem.tx_total + currentItem.rx_total) },
                { label: "Unknown Tx", value: drawDataTotal(currentItem.tx_unknown, currentItem.tx_total + currentItem.rx_total) },
                { label: "Rx", value: drawDataTotal(
                    currentItem.rx_total, 
                    currentItem.tx_total + currentItem.rx_total) },
                { label: "Mode", value: "Drive:" 
                        + numberFormatFraction(currentItem.driving_seconds / 3600) + "\nParked:" 
                        + numberFormatFraction(currentItem.parked_seconds / 3600) + "\nOffline:" 
                        + numberFormatFraction(currentItem.offline_seconds / 3600) + ' hours'
                },
                { label: "Mode", value: "Drive:" 
                        + numberFormatFraction(100 * currentItem.driving_fraction) + "% \nParked:" 
                        + numberFormatFraction(100 * currentItem.parked_fraction) + "% \nOffline:" 
                        + numberFormatFraction(100 * currentItem.offline_fraction) + '%'
                },
            ]
            .filter(line => line.value)
        };

        return retval;
    };
}


class DataBinsStackedBarChart extends React.Component {
	render() {
		var { data, type, width, height, ratio } = this.props;


        if(!data || data.length === 0)
        {
            return (
                <div>Loading...</div>
            );
        }
        else if(typeof data === "string")
        {
            return (
                <div>{data}</div>
            );

        }

/*
        var annotationProps = {
            fontFamily: "Glyphicons Halflings",
            fontSize: 20,
            fill: "#060F8F",
            opacity: 0.8,
            // text: "\ue182",
            text: "\ue094",
            y: ({ yScale, datum }) => yScale(datum.high) - 20,
            // y: ({ yScale }) => yScale.range()[0],
            onClick: console.log.bind(console),
            tooltip: d => dateFormat(d.date) + " " + d.label,
            // onMouseOver: console.log.bind(console),
        };
*/

        const f = scaleOrdinal(schemeCategory10);

        const fill = (d, i) => f(i);
        const fill2 = (d, i) => f(i + 5);

        var margin = { left: 80, right: 80, top: 10, bottom: 10 };

        // var [yAxisLabelX, yAxisLabelY] = [width -margin.left - 40, margin.top + (height - margin.top - margin.bottom) / 2]
        var [yAxisLabelX, yAxisLabelY] = [ -((margin.left/2) + 10), margin.top + (height - margin.top - margin.bottom) / 2]
        var [yAxisLabelXRight, yAxisLabelYRight] = [ width - margin.left - 10, margin.top + (height - margin.top - margin.bottom) / 2]

		return (
			<ChartCanvas ratio={ratio} width={width} height={height}
					margin={margin} type={type}
					seriesName="Property"
					data={data} 
                    onLoadMore={this.props.onLoadMore}
					xAccessor={d => d ? d.date : null} xScale={scaleTime()}>
                <Chart id={1} height={height - 50}
                        padding={{ top: 10, bottom: 60}}
                        yExtents={[0, d => d.total_bytes]}>

					<XAxis axisAt="bottom" orient="bottom"
                        stroke="#ffffff" tickStroke="#ffffff"
                    />
					<YAxis axisAt="right" orient="right" ticks={5}
                        stroke="#ffffff" tickStroke="#ffffff"
                    />

					<MouseCoordinateX
						rectWidth={60}
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(",.0f")} />

                    <StackedBarSeries yAccessor={[
                            d => d.tx_streaming, 
                            d => d.tx_hotspot, 
                            d => d.tx_system,
                            d => d.tx_other,
                            d => d.tx_unknown,
                            d => d.rx_streaming +
                                d.rx_hotspot +
                                d.rx_system +
                                d.rx_other +
                                d.rx_unknown
                        ]}
                        width={barWidthForTimeScale}
                        fill={fill} />

					<EdgeIndicator itemType="last" orient="right" edgeAt="right"
						yAccessor={d => d.total_bytes} 
                        fill="#6BA583" displayFormat={format(",.0f")} />
                    {
                        /*
                            <EdgeIndicator itemType="first" orient="left" edgeAt="left"
                                yAccessor={d => d.total_bytes} 
                                fill="#6BA583" displayFormat={format(",.0f")} />
                        */
                    }

                    <Label x={yAxisLabelXRight} y={yAxisLabelYRight}
                        rotate={-90} fontSize={12} text="Bytes"
                        fill="#ffffff"
                    />

                    <HoverTooltip
                        chartId={1}
                        tooltipContent={tooltipContent([])}
                        fontSize={15} />
				</Chart>
                <Chart id={2} height={50}
                        padding={{ top:10, bottom: 10}}
                        origin={(w, h) => [0, h - 80]}
                        yExtents={[0, 1]}>

                    <YAxis axisAt="left" orient="left" ticks={3}
                        stroke="#ffffff" tickStroke="#ffffff"
                    />

                    <MouseCoordinateX
                        rectWidth={60}
                        at="bottom"
                        orient="bottom"
                        displayFormat={timeFormat("%Y-%m-%d")} />
                    <MouseCoordinateY
                        at="right"
                        orient="right"
                        displayFormat={format(",.2f")} />

                    <StackedBarSeries yAccessor={[d => d.driving_fraction, d => d.parked_fraction, d => d.offline_fraction]}
                            width={barWidthForTimeScale}
                            fill={fill2} />

                    <Label x={yAxisLabelX} y={yAxisLabelY + 150}
                        rotate={-90} fontSize={12} text="Mode Fraction"
                        fill="#ffffff"
                    />

                </Chart>

				<CrossHairCursor />
			</ChartCanvas>
		);
	}
}

DataBinsStackedBarChart.propTypes = {
	// data: PropTypes.array.isRequired,
	// width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

DataBinsStackedBarChart.defaultProps = {
	type: "hybrid",
};
DataBinsStackedBarChart = fitWidth(DataBinsStackedBarChart);

export class DataBinsModal extends Component {

    componentDidMount() 
    {
        if(!this.props.raven.databins)
        {
            this.onLoadMore(moment().subtract(14,'d'), moment());
        }
    }

    onLoadMore = (start, end) => 
    {
        this.props.dataStore.queryDataBins(this.props.raven.item, start, end);
    }

    render() {

        return (
            <Dialog
                size="medium"
                className="test"
                label={ this.props.title }
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                    <DataBinsStackedBarChart
                        data={this.props.raven.databins }
                        // width={this.chart_width}
                        height={450}
                        ratio={1}
                        onLoadMore={this.onLoadMore}
                        type="hybrid"
                        seriesName="Usage" />

                    </div>
            </Dialog>
        );
    }

}

export class DataBins extends Component {

    componentWillUpdate() {
        if(!this.props.data || Object.keys(this.props.data).length === 0)
        {
            this.totalusage = [];
        }
        else if(typeof this.props.data === "string")
        {
            this.totalusage = [];
        }
        else
        {
            this.totalusage = this.props.data.map((obj) => { return parseInt(obj.total_bytes/100000, 10) });
        }
    }

    render()
    {

        console.log("Data Usage:", this.props);

        if(!this.props.data || Object.keys(this.props.data).length === 0)
            return null;

        if(typeof this.props.data === "string")
        {
            return ( 
                <div className="data-usage">
                    <h3>Data Usage</h3>
                        <JSONView json={this.props.data} /> 
                </div>
            );
        }

        if(this.props.data)
        {
            if(this.totalusage && this.totalusage.length === 0)
            {
                this.componentWillUpdate();
            }


            return ( 
                <div className="data-usage">
                    <h3>Data Usage</h3>
                    <Link to={"/raven/" + this.props.stage +"/"+ this.props.raven['Raven']['Raven UUID'] + "/databins"} >
                            <Sparklines data={this.totalusage} limit={21} >
                                    <g>
                                      <text x={ "5%" } y={ "90%" } fontSize={10} fill="gray">
                                         Total Usage 
                                      </text>
                                    </g>

                                    <SparklinesLine />
                            </Sparklines>
                    </Link>
                </div>
            );
        }
        else
        {
            return ( <div className="data-usage">
                <h3>Data Usage</h3>
                <JSONView json={this.props.data} />
                </div>
            );
        }

    }
}


