import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dialog from '../dialog';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';
import globalconfig from '../../../../common/config';

import "react-table/react-table.css";

export class RavenStatusHistoryView extends Component {

    render() {

        var columns = [
            {
                Header: 'Time',
                accessor: d => moment.utc(d.ts).unix() * 1000,
                id: 'ts',
                Cell: props => <span title={moment.utc(props.original.ts).unix()}>{ moment(props.value).format(globalconfig.display.timeFormat) } ( { moment(props.value).fromNow() } ) </span> ,
            },
            {
                Header: 'User',
                accessor: 'user',
            },
            {
                Header: 'Raven Id',
                accessor: 'raven_id',
                maxWidth: 100,
            },
            {
                Header: 'Raven Unit Id',
                accessor: 'raven_unit_id',
                maxWidth: 100,
            },
            {
                Header: 'From',
                accessor: 'from',
                maxWidth: 100,
            },
            {
                Header: 'To',
                accessor: 'to',
            },
        ];

        var datasize = this.props.data ? this.props.data.length : 0;
        var paginate = this.props.data ? datasize > this.props.pageSize : false;

        var pagesize = paginate ? this.props.pageSize : datasize;


        return (
            <div id="tombstone-listing" className="tombstone-list">
                    <ReactTable className="-striped" data={this.props.data}
                        filterable={true}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value) }
                        showPagination={paginate}
                        showPageSizeOptions={false}
                        showPageJump={false}
                        style = {{ height: "65vh"}}
                        pageSize={pagesize}
                        noDataText="No Status"
                        defaultSorted={[{ id: 'ts', desc: true }]}
                        columns={columns} />
            </div>
        );
    }
}


RavenStatusHistoryView.defaultProps = {
    pageSize: 100,
};

RavenStatusHistoryView.propTypes = {
    pageSize: PropTypes.number,
}

export default class RavenViewStatusModal extends Component {

    constructor() {
    
        super();

        this.localState = {};

    }

    queryRavenStatusHistoryData()
    {

        this.localState.ravenid = this.props.raven.item.Id;

        this.props.dataStore.getRavenHistoryStatus(this.props.raven);

    }

    componentDidMount() {
        this.queryRavenStatusHistoryData();
    }

    componentDidUpdate() {

        if(this.props.raven && this.props.raven.item && this.props.raven.item.Id)
        {
            if(this.props.raven.item.Id !== this.localState.ravenid)
            {
                this.queryRavenStatusHistoryData();
            }
        }
    }

    render() {

        return (
            <Dialog
                size="medium"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.props.raven.ravenstatus ?
                                        this.props.raven.ravenstatus.length !== 0 ?  
                                            <RavenStatusHistoryView 
                                                data={this.props.raven.ravenstatus} 
                                                /> : 
                                            <p>No Status History Data...</p> :
                                        <p>Loading...</p>
                                }
                            </div>
                        </div>
                    
                    </div>
            </Dialog>
        );
    }

}
