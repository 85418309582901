import React, { Component } from 'react';
import Dialog from '../dialog';

import ReactTable from 'react-table';
import "react-table/react-table.css";
import PropTypes from 'prop-types';

import globalconfig from '../../../../common/config';

import moment from 'moment';

export default class OTAHistoryModal extends Component {

    render() {

        var title = "OTA History for Raven Unit Id " + this.props.raven['Raven']['Unit Id'] + " (" + this.props.raven.Account.Email + ")";
        if (globalconfig.features.ravens.vendorDetailsPreferred) {
            const ravenEnclosureSN = this.props.raven.Raven['Enclosure Serial No.'];
            const accountExternalId = this.props.raven.Account['Account Name'];
            if (ravenEnclosureSN && accountExternalId) {
                title = "OTA History for Raven " + ravenEnclosureSN + " (" + accountExternalId + ")";
            }
        }

        // var title = "OTA History for FIXME";

        return (
            <Dialog
                size="medium"
                className="test"
                label={title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.props.data && Array.isArray(this.props.data) ?
                                        <OTAHistoryView 
                                            data={this.props.data} 
                                            /> : 
                                        <p>No OTA History...</p>
                                }
                            </div>
                        </div>
                    
                    </div>
            </Dialog>
        );
    }

}

class OTAPreview extends Component {

    render () {

        var columns = [
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'OS Version',
                accessor: 'raven_os_version',
            },
            {
                Header: 'MCU Version',
                accessor: 'raven_mcu_version',
            },
            {
                Header: 'WNC Version',
                accessor: 'raven_wnc_version',
            },
            {
                Header: 'Kernel Version',
                accessor: 'kernel_version',
            },
        ];

        var formatteddata = [
            { type: "From",
                raven_os_version : this.props.data.from_os_version,
             raven_mcu_version : this.props.data.from_mcu_version,
             raven_wnc_version : this.props.data.from_wnc_version,
             kernel_version :  this.props.data.from_kernel_version 
            },
            { type: "To",
            raven_os_version : this.props.data.to_os_version,
             raven_mcu_version : this.props.data.to_mcu_version,
             raven_wnc_version : this.props.data.to_wnc_version,
             kernel_version :  this.props.data.to_kernel_version 
            },
        ];


        return ( 
                    <ReactTable data={formatteddata}
                        filterable={false}
                        showPagination={false}
                        showPageSizeOptions={false}
                        showPageJump={false}
                        noDataText="No Data"
                        pageSize={2}
                        columns={columns} />
            )

    }

}

class OTAHistoryView extends Component {

    render() {

        var columns = [
            {
                Header: 'Time',
                accessor: d => moment.utc(d.ts).unix() * 1000,
                id: 'ts',
                Cell: props => <span title={moment.utc(props.original.ts).unix()} >{ moment(props.value).format(globalconfig.display.timeFormat) } ( { moment(props.value).fromNow() } ) </span> ,
            },
            {
                Header: 'Type',
                accessor: 'type',
                maxWidth: 100,
            },
            {
                Header: 'OS Version',
                accessor: 'to_os_version',
            },
        ];

        var datasize = this.props.data ? this.props.data.length : 0;
        var paginate = this.props.data ? datasize > this.props.pageSize : false;

        var pagesize = paginate ? this.props.pageSize : datasize;


        return (
            <div id="tombstone-listing" className="tombstone-list">
                    <ReactTable className="-striped" data={this.props.data}
                        filterable={true}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value) }
                        showPagination={paginate}
                        showPageSizeOptions={false}
                        showPageJump={false}
                        style={{ height: "65vh" }}
                        pageSize={pagesize}
                        noDataText="No OTAs"
                        defaultSorted={[{ id: 'ts', desc: true }]}
                        SubComponent = { row => {
                            return ( <OTAPreview data={row.original} /> );
                        } }
                        columns={columns} />
            </div>
        );
    }
}


OTAHistoryView.defaultProps = {
    pageSize: 100,
};

OTAHistoryView.propTypes = {
    pageSize: PropTypes.number,
}
