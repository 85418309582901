"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";

import GenericChartComponent from "react-stockcharts/lib/GenericChartComponent";
import { first, last } from "react-stockcharts/lib/utils";

class EventAnnotate extends Component {
	constructor(props) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
	}
	render() {
		return <GenericChartComponent
			svgDraw={this.renderSVG}
			drawOn={["pan"]}
			/>;
	}
	renderSVG(moreProps) {
		var { xAccessor } = moreProps;
		var { xScale, chartConfig: { yScale }, plotData } = moreProps;

		var { className, usingProps, with: Annotation } = this.props;
		var data = helper(this.props, this.props.data);

		return (
			<g className={`react-stockcharts-enable-interaction ${className}`}>
				{data.map((d, idx) => <Annotation key={idx}
						{...usingProps}
						xScale={xScale}
						yScale={yScale}
						xAccessor={xAccessor}
						plotData={this.props.data}
						datum={d} />)}
			</g>
		);
	}
}

EventAnnotate.propTypes = {
	className: PropTypes.string.isRequired,
	with: PropTypes.func,
	when: PropTypes.func,
	usingProps: PropTypes.object,
};

EventAnnotate.defaultProps = {
	className: "react-stockcharts-annotate react-stockcharts-default-cursor",
};

function helper({ when }, plotData) {
	return plotData.filter(when);
}

export default EventAnnotate;
