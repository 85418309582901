import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Link } from 'react-router-dom';

import globalconfig from '../../common/config';
import RavenDataStore from '../../common/ravenDataStore';

import Header from './panels/header';
import { ModalContainer, ConfirmationDialog } from '../../modals/Modal2';
import { ThirdpartyStatusModal } from './modals/3rdparty/3rdpartyStatus';
import { NewAccountCreateModal } from './modals/user/newAccountCreate';
import { NewUserCreateModal } from './modals/user/newUserCreate';
import { UpdateUserModal } from './modals/user/updateUser';
import RavenChangeStatusModal from './modals/status/ravenChangeStatus';
import RavenChangeReprovisionModal from './modals/reprovision/ravenChangeReprovision';
import CouponLookupModal from './modals/coupon/couponLookup';
import NewCustomInventoryTable from './../orders/panels/NewCustomInventoryTable/NewCustomInventoryTable';
import OrderForm from './../orders/OrderForm';
import UpdateWarrantyModal from './modals/warranty/updateWarranty';
import PerformRavenAction from './modals/ravenaction/performRavenAction';
import { AddUserToAccountModal } from './modals/user/addUserToAccount';

export default class ToolPage extends Component {
    constructor(props) {
        super(props);

        this.ordersFulfillmentEnabled = false; //TODO this is a substitute for something like ordersManagementEnabled (RAV-2241)
        if (globalconfig.features) {
            if (globalconfig.features.orders) {
                this.ordersFulfillmentEnabled = globalconfig.features.orders.fulfillmentEnabled;
            }
        }

        this.primaryAddNewOrderActionName = "Add New Order";
        this.primaryAddNewOrderActionPath = "add-new-order";
        if (!this.ordersFulfillmentEnabled) {
            this.primaryAddNewOrderActionName = "Add New Account";
            this.primaryAddNewOrderActionPath = "add-new-account";
        }

        this.state = {
            ravenData: {},
            confirmDialog: null
        };

        this.localState = {};

        this.dataStore = new RavenDataStore(this.dataChangeCallback);


    }

    componentDidMount()
    {
        this.dataStore.queryFleetPlans(this.props.match.params.stage);
        this.dataStore.queryChannels(this.props.match.params.stage);
        this.dataStore.getRavenUnits(this.props.match.params.stage);

        this.localState.stage = this.props.match.params.stage;
    }

    componentDidUpdate()
    {
        if(this.props.match.params.stage !== this.localState.stage)
        {
            this.localState.stage = this.props.match.params.stage;
            this.dataStore.queryAccounts(this.props.match.params.stage);
            this.dataStore.queryFleetPlans(this.props.match.params.stage);
            this.dataStore.queryChannels(this.props.match.params.stage);
            this.dataStore.getRavenUnits(this.props.match.params.stage);
        }
    }


    onRavenStageChanged = (stage) => {
        this.props.history.push("/tool/" + stage);
    }


    dataChangeCallback = (ravenData) => {
        this.setState({ravenData: ravenData});
    }

    renderToolPage = (props) => {

        var stage = props.match.params.stage;

        return (
            <section className="tools-page">
                <header>
                    Tools
                </header>
                <nav>
                    <Link to={"/tool/" + stage +"/3rdparty"}>3rd Party Status</Link>
                    <Link to={"/tool/" + stage + "/new-fleet-account"}>New Fleet Account</Link>
                    <Link to={"/tool/" + stage + "/new-fleet-user"}>New Fleet User</Link>
                    <Link to={"/tool/" + stage + "/update-fleet-user"}>Update Fleet User</Link>
                    <Link to={"/tool/" + stage + "/add-user-to-fleet"}>Add User to Fleet</Link>
                    {this.ordersFulfillmentEnabled !== true ?
                        <Link to={"/tool/" + stage + "/" + this.primaryAddNewOrderActionPath}>{this.primaryAddNewOrderActionName}</Link>
                    :
                        null
                    }
                    {/*<Link to={"/tool/" + stage + "/add-new-custom-inventory"}>New Custom Inventory</Link>*/}
                    <Link to={"/tool/" + stage + "/change-status"}>Change Raven Status</Link>
                    <Link to={"/tool/" + stage + "/change-reprovision"}>Change Raven Auto Reprovisioning</Link>
                    <Link to={"/tool/" + stage + "/coupon-lookup"}>Lookup Coupon Code</Link>
                    <Link to={"/tool/" + stage + "/update-warranty"}>Update Warranty</Link>
                    <Link to={"/tool/" + stage + "/perform-raven-action"}>Raven Actions</Link>
                    <Link to={"/tool/" + stage + "/signout"}>Sign Out</Link>
                </nav>
            </section>
        );
    }

    render3rdParty = (props) => {
        var title = "3rdparty Status";

        return (
            <ThirdpartyStatusModal title={title} isOpen={true}
                    stage={this.props.match.params.stage}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );

    }

    renderNewFleetAccount = (props) => {
        var title = "New Fleet Account";

        return (
            <NewAccountCreateModal title={title} isOpen={true}
                    dataStore={this.dataStore}
                    stage={this.props.match.params.stage}
                    plans={this.state.ravenData.plans ? this.state.ravenData.plans : []}
                    channels={this.state.ravenData.channels ? this.state.ravenData.channels : []}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );
    }

    renderNewFleetUser = (props) => {
        var title = "New User Account";

        return (
            <NewUserCreateModal title={title} isOpen={true}
                    dataStore={this.dataStore}
                    stage={this.props.match.params.stage}
                    plans={this.state.ravenData.plans ? this.state.ravenData.plans : []}
                    channels={this.state.ravenData.channels ? this.state.ravenData.channels : []}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );
    }

    renderAddUserToFleet = (props) => {
        var title = "Add User To Account";

        return (
            <AddUserToAccountModal title={title} isOpen={true}
                    dataStore={this.dataStore}
                    stage={this.props.match.params.stage}
                    channels={this.state.ravenData.channels ? this.state.ravenData.channels : []}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );
    }

    renderUpdateFleetUser = (props) => {
        var title = "Update User Account";

        return (
            <UpdateUserModal title={title} isOpen={true}
                    dataStore={this.dataStore}
                    stage={this.props.match.params.stage}
                    plans={this.state.ravenData.plans ? this.state.ravenData.plans : []}
                    channels={this.state.ravenData.channels ? this.state.ravenData.channels : []}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );
    }

    renderAddInventory = (props) => {

        const title = "New Custom Inventory";

        const dismissModal = () => {
            this.props.history.push('/tool/' + props.match.params.stage);
        };

        const cancelAndClearForm = () => {

            const onClickConfirm = () => {
                this.setState({
                    confirmDialog: null
                })
                dismissModal();
            }
            const onClickBack = () => {
                this.setState({
                    confirmDialog: null
                })            
            }
    
            this.setState({
                confirmDialog: <ConfirmationDialog
                        title="Clear Inventory Form?"
                        message="Any changes in this new inventory form will be lost."
                        cancelButtonAction={onClickBack}
                        cancelButtonTitle="Go Back"
                        submitButtonAction={onClickConfirm}
                        submitButtonTitle="Clear Form"
                    />
            });
        };

        return (
            <ModalContainer title={title} onClickClose={cancelAndClearForm}>
                <NewCustomInventoryTable
                    stage={this.props.match.params.stage}
                    onCancel={cancelAndClearForm}
                    onFormSubmit={dismissModal}
                />
            </ModalContainer>
        )
    }

    renderAddOrder = (props) => {

        const title = this.primaryAddNewOrderActionName;

        const dismissModal = () => {
            this.props.history.push('/tool/' + props.match.params.stage);
        };

        const cancelAndClearForm = () => {

            const onClickConfirm = () => {
                this.setState({
                    confirmDialog: null
                })
                dismissModal();
            }
            const onClickBack = () => {
                this.setState({
                    confirmDialog: null
                })            
            }
    
            this.setState({
                confirmDialog: <ConfirmationDialog
                        title="Clear Form?"
                        message="Any changes in this form will be lost."
                        cancelButtonAction={onClickBack}
                        cancelButtonTitle="Go Back"
                        submitButtonAction={onClickConfirm}
                        submitButtonTitle="Discard Any Unsaved Changes"
                    />
            });
        };

        return (
            <ModalContainer title={title} onClickClose={cancelAndClearForm}>
                <OrderForm
                    stage={this.props.match.params.stage}
                    onCancel={cancelAndClearForm} 
                    onSuccess={dismissModal}
                />
            </ModalContainer>
        )
    }

    renderChangeStatus = (props) => {
        var title = "Change Status";

        var raven;

        if(props.match.params.enclosureid && this.state.ravenData.geojson && this.state.ravenData.geojson.objects)
        {
            raven = this.state.ravenData.geojson.objects.find((elem) => {
                return elem['Raven']['Enclosure Serial No.'] === props.match.params.enclosureid;
            });

        }

        if(this.state.ravenData.geojson)
            console.log("Render Change Status Dialog with Raven", props.match.params.enclosureid, raven, this.state.ravenData);

        return (
            <RavenChangeStatusModal title={title} isOpen={true}
                    stage={this.props.match.params.stage}
                    enclosureId={props.match.params.enclosureid}
                    raven={raven}
                    ravenData={this.state.ravenData}
                    dataStore={this.dataStore}
                    routeProps={props}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );
    }

    renderChangeReprovision = (props) => {
        var title = "Change Auto Reprovisioning";

        var raven;

        if(props.match.params.enclosureid && this.state.ravenData.geojson && this.state.ravenData.geojson.objects)
        {
            raven = this.state.ravenData.geojson.objects.find((elem) => {
                return elem['Raven']['Enclosure Serial No.'] === props.match.params.enclosureid;
            });

        }

        return (
            <RavenChangeReprovisionModal title={title} isOpen={true}
                    stage={this.props.match.params.stage}
                    raven={raven}
                    ravenData={this.state.ravenData}
                    dataStore={this.dataStore}
                    routeProps={props}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );
    }

    renderCouponLookup = (props) => {
        var title = "Coupon Lookup";

        return (
            <CouponLookupModal title={title} isOpen={true}
                    stage={this.props.match.params.stage}
                    ravenData={this.state.ravenData}
                    dataStore={this.dataStore}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );
    }

    renderUpdateWarranty = (props) => {
        var title = "Update Warranty";

        var raven;

        if(props.match.params.uuid && this.state.ravenData.geojson && this.state.ravenData.geojson.objects)
        {
            raven = this.state.ravenData.ravens.find((elem) => {
                return elem.item['Raven']['Raven UUID'] === props.match.params.uuid;
            });
            if(raven)
            {
                raven = raven.item;
            }
            else
            {
                raven = this.state.ravenData.geojson.objects.find((elem) => {
                    return elem['Raven']['Raven UUID'] === props.match.params.uuid;
                });
            }

        }

        return (
            <UpdateWarrantyModal title={title} isOpen={true}
                    stage={this.props.match.params.stage}
                    raven={raven}
                    ravenData={this.state.ravenData}
                    dataStore={this.dataStore}
                    routeProps={props}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );
    }

    renderSignOut = (props) => {
        window.localStorage.clear()
        window.location.href = window.location.origin;
        return null;
    }

    renderPerformRavenAction = (props) => {
        var title = "Perform Raven Action";

        var raven;

        if(props.match.params.uuid && this.state.ravenData.geojson && this.state.ravenData.geojson.objects)
        {
            raven = this.state.ravenData.ravens.find((elem) => {
                return elem.item['Raven']['Raven UUID'] === props.match.params.uuid;
            });
            if(raven)
            {
                raven = raven.item;
            }
            else
            {
                raven = this.state.ravenData.geojson.objects.find((elem) => {
                    return elem['Raven']['Raven UUID'] === props.match.params.uuid;
                });
            }

        }

        return (
            <PerformRavenAction title={title} isOpen={true}
                    stage={this.props.match.params.stage}
                    raven={raven}
                    ravenUuid={props.match.params.uuid}
                    ravenData={this.state.ravenData}
                    dataStore={this.dataStore}
                    routeProps={props}
                    onClose={() => {this.props.history.push('/tool/' + props.match.params.stage);}}
            />
        );
    }

    render () {

        return (

            <div className="tools-page">
                <div className="container-fluid">
                    <Header
                        ravenStages={ this.props.stages }
                        stage={this.props.match.params.stage}
                        ravenList={(this.state.ravenData.geojson && this.state.ravenData.geojson.objects) ? this.state.ravenData.geojson.objects : []}
                        ravenStageLoader={this.getRavenStages}
                        onRavenStageChange={this.onRavenStageChanged}
                    />
                </div>

                <div className="main-content container-fluid">
                     <Route path="/tool/:stage" render={this.renderToolPage} />
                     <Route path="/tool/:stage/3rdparty" render={this.render3rdParty} />
                     <Route path="/tool/:stage/new-fleet-account" render={this.renderNewFleetAccount} />
                     <Route path="/tool/:stage/new-fleet-user" render={this.renderNewFleetUser} />
                     <Route path="/tool/:stage/update-fleet-user" render={this.renderUpdateFleetUser} />
                     <Route path="/tool/:stage/add-user-to-fleet" render={this.renderAddUserToFleet} />
                     <Route path="/tool/:stage/add-new-custom-inventory" render={this.renderAddInventory} />
                     <Route path="/tool/:stage/add-new-order" render={this.renderAddOrder} />
                     <Route path="/tool/:stage/add-new-account" render={this.renderAddOrder} />
                     <Route path="/tool/:stage/change-status/:enclosureid?" render={this.renderChangeStatus} />
                     <Route path="/tool/:stage/change-reprovision/:enclosureid?" render={this.renderChangeReprovision} />
                     <Route path="/tool/:stage/coupon-lookup" render={this.renderCouponLookup} />
                     <Route path="/tool/:stage/update-warranty/:uuid?" render={this.renderUpdateWarranty} />
                     <Route path="/tool/:stage/perform-raven-action/:uuid?" render={this.renderPerformRavenAction} />
                     <Route path="/tool/:stage/signout" render={this.renderSignOut} />
                </div>
                {this.state.confirmDialog}
            </div>
        );

    }
}
