import React, { Component } from 'react';
import Modal from 'react-modal';

export default class Dialog extends Component {

    constructor() {
        super();

        this.state = {
            isOpen: false
        }
    }

    openModal = (e) => {
        this.setState({isOpen: true});
    }

    closeModal = (e) => {
        this.setState({isOpen: false});
    }

    styleHelper(size) {
        if(size === 'small')
        {
            return {
              content : {
                    top        : '15%',
                    left       : '25%',
                    right      : '0',
                    bottom     : '0',
                    width     : '50%',
                    height     : '50%',
              },
            };
        }
        else if(size === 'medium')
        {
            return {
              content : {
                    top        : '10%',
                    left       : '10%',
                    right      : '0',
                    bottom     : '0',
                    width     : '80%',
                    height     : '80%',
              },
            };
        }
        else if(size === 'large')
        {
            return {
              content : {
                top         : '5%',
                left        : '5%',
                right       : '0',
                bottom      : '0',
                width      : '90%',
                height      : '90%',
              }
            };
        }
        else
        {
            return null;
        }
    }
    

    render() {

        return (
            <Modal 
                appElement= {document.root }
                className="raven-modal-content"
                overlayClassName="raven-modal"
                isOpen={this.props.isOpen}
                contentLabel={this.props.label}
                style={this.styleHelper(this.props.size)}
                onRequestClose={this.props.onClose}
                onAfterOpen={this.props.onAfterOpen}
                role="dialog"
            >
                <div className="container-fluid">
                    <header className="row">
                        <div className="col-11">
                            <div className="raven-modal-title"><h3>{this.props.label}</h3></div>
                        </div>
                        <div className="col-1 raven-modal-close">
                            <a href="#!" onClick={this.props.onClose}><i className="material-icons white">clear</i></a>
                        </div>
                    </header>
                    <div className="row raven-modal-inner-content">
                        <div className="col-12">
                            { this.props.children }
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}
