
export default class Events {
    static SHOW_LOADING_PAGE = 'SHOW_LOADING_PAGE';
    static HIDE_LOADING_PAGE = 'HIDE_LOADING_PAGE';
    
    static LOGIN_SUCCESS = 'LOGIN_SUCCESS';
    static LOGIN_FAILED = 'LOGIN_FAILED';
    static LOGIN_REFRESH = 'LOGIN_REFRESH';
    static LOGIN_NEW_PASSWORD_REQUIRED = 'LOGIN_NEW_PASSWORD_REQUIRED';
    static LOGIN_PASSWORD_RESET_REQUIRED = 'LOGIN_PASSWORD_RESET_REQUIRED';
    static LOGIN_MFA_REQUIRED = 'LOGIN_MFA_REQUIRED';

    static CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
    static CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
    
    static GET_RAVENS_SUCCESS = 'GET_RAVENS_SUCCESS';
    static GET_RAVENS_ERROR = 'GET_RAVENS_ERROR';

    static MQTT_CONNECTION_CHANGED = 'MQTT_CONNECTION_CHANGED';
    static RAVEN_MQTT_NOTIFICATION = 'RAVEN_MQTT_NOTIFICATION';
    static RAVEN_MQTT_BROKER_ROUTE_UPDATE = 'RAVEN_MQTT_BROKER_ROUTE_UPDATE';

    static SELECT_EVENT = 'SELECT_EVENT';

    static RAVEN_SELECT_EVENT = 'RAVEN_SELECT_EVENT';
    static RAVEN_HIGHLIGHT_EVENT = 'RAVEN_HIGHLIGHT_EVENT';

    static ZOOM_TO_EXTENTS = 'ZOOM_TO_EXTENTS';
}
