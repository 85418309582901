import React from 'react';
import PropTypes from 'prop-types';

export class ModalContainer extends React.PureComponent {

    static propTypes = {
        smallDialogMode: PropTypes.bool, // default false (1000px max width), if true 550px max width
        onClickClose: PropTypes.func,
        title: PropTypes.string.isRequired
    }

    render() {

        if (!this.props.children) return null;

        let modalClassName = "modal2";
        if (this.props.smallDialogMode) modalClassName += " small-format";

        let closeButton = null;
        if (this.props.onClickClose) {
            closeButton = (<button>
                    <i className="material-icons white" onClick={this.props.onClickClose}>clear</i>
                </button>)
        }

        return (
            <div className="modal2-container">
                <div className={modalClassName}>
                    <header>
                        <span>
                            {this.props.title}
                        </span>
                        {closeButton}
                    </header>
                    <section className="modal2-inner-content">
                        {this.props.children}
                    </section>
                </div>
            </div>
        )
    }
}

export class ConfirmationDialog extends React.PureComponent {

    constructor(props) {
        super(props);
    }
    static propTypes = {
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        cancelButtonAction: PropTypes.func.isRequired,
        cancelButtonTitle: PropTypes.string.isRequired,
        hideHeaderCloseButton: PropTypes.bool, // set to true when you want to force user to make a choice
        submitButtonAction: PropTypes.func.isRequired,
        submitButtonTitle: PropTypes.string.isRequired
    }

    render () {
        return (
            <ModalContainer title={this.props.title} onClickClose={this.props.hideHeaderCloseButton ? null : this.props.cancelButtonAction} smallDialogMode={true} >
                <div className="message">
                    {this.props.message}
                </div>
                <div className="actions">
                    <button className="destructive" onClick={this.props.cancelButtonAction} >
                        {this.props.cancelButtonTitle}
                    </button>
                    <button onClick={this.props.submitButtonAction} >
                        {this.props.submitButtonTitle}
                    </button>
                </div>
            </ModalContainer>
        )
    }
}

export class MessageDialog extends React.PureComponent {

    constructor(props) {
        super(props);
    }
    static propTypes = {
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        closeButtonAction: PropTypes.func.isRequired,
        closeButtonTitle: PropTypes.string, // default "Okay"
        messageType: PropTypes.string // error | warning | info | success (default)
    }

    getClassName () {
        const messageType = this.props.messageType || "success";

        switch (messageType.toLowerCase()) {
            case "error":
                return "text-danger";
            case "warning":
                return "text-warning";
            case "info":
                return "text-info";
            case "success":
                return "text-primary";
            default:
                return "";
        }
    }

    render () {
        const closeButtonTitle = this.props.closeButtonTitle || "Okay";
        const className = this.getClassName();

        return (
            <ModalContainer title={this.props.title} onClickClose={this.props.closeButtonAction} smallDialogMode={true} >
                <div className="message">
                    <div className={className}>{this.props.message}</div>
                </div>
                <div className="actions">
                    <button onClick={this.props.closeButtonAction} >
                        {closeButtonTitle}
                    </button>
                </div>
            </ModalContainer>
        )
    }
}