import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import "react-table/react-table.css";
import moment from 'moment';
import globalconfig from '../../../../common/config';
import { format } from "d3-format";

var numberFormat = format(",.2f");

export default class BillingView extends Component {

    render() {

        var columns = [
            {
                Header: 'Start Date',
                accessor: d => moment.unix(d.start),
                id: 'start',
                Cell: props => <span>{ moment(props.value).format(globalconfig.display.timeFormat) } ( { moment(props.value).fromNow() } ) </span> ,
            },
            {
                Header: 'End Date',
                accessor: d => moment.unix(d.end),
                id: 'end',
                Cell: props => <span>{ moment(props.value).format(globalconfig.display.timeFormat) } ( { moment(props.value).fromNow() } ) </span> ,
            },
            {
                Header: 'Plan',
                accessor: 'plan',
                maxWidth: 150,
            },
        ];

        var datasize = this.props.data ? this.props.data.length : 0;
        var paginate = this.props.data ? datasize > this.props.pageSize : false;

        var pagesize = paginate ? this.props.pageSize : datasize;

        return (
            <div id="tombstone-listing" className="tombstone-list">
                    <ReactTable className="-striped" data={this.props.data}
                        filterable={true}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value) }
                        showPagination={paginate}
                        showPageSizeOptions={false}
                        showPageJump={false}
                        style = {{ height: "65vh"}}
                        pageSize={pagesize}
                        noDataText="No Billing History"
                        defaultSorted={[{ id: 'start', desc: true }]}
                        SubComponent = { row => {

                            var streamData = "None";
                            if(row.original.streams)
                            {
                                var entries = row.original.streams.split(';');
                                var streamData = entries.map(x => { 
                                    var vals = x.split(','); 
                                    return (
                                            <div id={vals[0]} className="row">
                                                <div className="col-5">{ moment.unix(vals[0]).format(globalconfig.display.timeFormat) } ( { moment.unix(vals[0]).fromNow() } )</div><div className="col-2">{vals[1]} s</div>
                                            </div>
                                    );
                                } );
                            }

                            return ( 
                                <div className="container-fluid">
                                    <div className="col-12">
                                        <h4>Monthly ({row.original.days} days)</h4>
                                        <div className="row">
                                            <div className="col-2"><strong>Upload:</strong></div><div className="col-2">{numberFormat(row.original.upload)} MB</div>
                                            <div className="col-2"><strong>Streaming:</strong></div><div className="col-2">{row.original.streaming} s</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2"><strong>Download:</strong></div><div className="col-2">{numberFormat(row.original.download)} MB</div>
                                            <div className="col-2"><strong>Streaming Warning:</strong></div><div className="col-2">{row.original.streaming_warning ? "Yes" : "No"}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2"><strong>Usage:</strong></div><div className="col-2">{numberFormat(row.original.total)} MB</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2"><strong>Exclude:</strong></div><div className="col-2">{numberFormat(row.original.exclude)} MB</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2"><strong>Total:</strong></div><div className="col-2">{numberFormat(row.original.total - row.original.exclude)} MB</div>
                                        </div>
                                        <h4>Daily</h4>
                                        <div className="row">
                                            <div className="col-2"><strong>Upload:</strong></div><div className="col-2">{numberFormat(row.original.daily_upload)} MB</div>
                                            <div className="col-2"><strong>Streaming:</strong></div><div className="col-2">{row.original.daily_streaming} s</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2"><strong>Download:</strong></div><div className="col-2">{numberFormat(row.original.daily_download)} MB</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2"><strong>Total:</strong></div><div className="col-2">{numberFormat(row.original.daily_average)} MB</div>
                                        </div>
                                        <h4>Streams</h4>
                                        {streamData}
                                    </div>
                                </div> 
                            );
                        } }

                        columns={columns} />
            </div>
        );
    }
}


BillingView.defaultProps = {
    pageSize: 100,
};

BillingView.propTypes = {
    pageSize: PropTypes.number,
}
