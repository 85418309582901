import React, { Component } from 'react';

import CognitoUtil from '../../aws/cognito/cognitoUtil';
import Events from '../../common/events';

class ResetPasswordModal extends Component {

    constructor(props) {

        super(props);

        console.log(this.props);

        this.state = {
            errorMessage: '',
            password: '',
            passwordValid: true,
            mfainput: '',
        };
    }

    handlePasswordChange = (e) => {
        e.preventDefault();

        var inpass = e.target.value;

        this.setState({
            password: inpass
        });
    }

    handleMFAChange = (e) => {
        e.preventDefault();

        var inpass = e.target.value;

        this.setState({
            mfainput: inpass
        });
    }

    onSubmit = (e) => {
        e.preventDefault();

        CognitoUtil.resetPassword(this.state.mfainput, this.state.password, (error) => {
            if(error)
            {
                this.setState({errorMessage: error});
            }
            else
            {
                // We have logged in, but we don't have proper state, refresh
                this.setState({errorMessage: "Password Reset.  Reloading Page."}, () => {
                    setTimeout( () => {
                        document.location.reload();
                    }, 2000);
                });
            }
        });

    }

    render() {

        return (
            <form id="resetPasswordForm">
                <h1>Password reset required</h1>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="password">Enter Code</label>
                        <input ref={(pass) => { this.pass = pass; }} id="mfa" type="text" name="mfa" onChange={this.handleMFAChange} onBlur={(e) => {this.handleMFAChange(e, true)}} />
                    </div>

                    <div className="col-12">
                        <label htmlFor="password">Password</label>
                        <input ref={(password) => { this.password = password; }} id="password" type="password" name="password" className={this.state.passwordValid ? '' : 'invalid'} onChange={this.handlePasswordChange} onBlur={(e) => {this.handlePasswordChange(e, true)}} />
                        {this.state.passwordValid ? <p className="error-message">&nbsp;</p> : <p className="error-message">Invalid Password</p>}
                    </div>
                    <div className="col-12">
                        <input className="btn" type="submit" onClick={this.onSubmit} value="Reset Password" />
                    </div>
                    {(this.state.errorMessage) ? (<div className="col-12"><p className="error-message"><i className="material-icons">error</i><span>{this.state.errorMessage}</span></p></div>) : null}
                </div>
            </form>
        );
    }
}

export default ResetPasswordModal;
