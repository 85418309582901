// Stateless react component to render a list of links to be clicked on
// Passes in the title, list and the callback as props

import React, { Component } from 'react';
import ReactTable from 'react-table';
import Events from '../../../common/events';
import "react-table/react-table.css";

import globalconfig from '../../../common/config';
export default class FeatureListing extends Component {

    onItemClick = (e, item) => {
        e.preventDefault();

        // Don't need to click the feature here as dispatching the event below will trigger
        // this.props.onFeatureClick(item.props.original);

        document.dispatchEvent(new CustomEvent(Events.RAVEN_SELECT_EVENT,
            { detail: {
                message: 'Selecting Raven',
                ravenid: item.props.original.item['Id'],
                ravenunitid: item.props.original.item['Raven']['Unit Id'],
                ravenuuid: item.props.original.item['Raven']['Raven UUID'],
                geometry: item.props.original.geometry
            } })
        );

    }

    onItemMouseOver = (e, item) => {
        e.preventDefault();

        document.dispatchEvent(new CustomEvent(Events.RAVEN_HIGHLIGHT_EVENT,
            { detail: {
                message: 'Highlight Raven',
                ravenid: item.props.original.item['Id'],
                ravenunitid: item.props.original.item['Raven']['Unit Id'],
                ravenuuid: item.props.original.item['Raven']['Raven UUID'],
                geometry: item.props.original.geometry
            } })
        );
    }

    render() {

        var col3accessor = 'item.';
        col3accessor += this.props.displayVariable.section ?  this.props.displayVariable.section + '.' : '';
        col3accessor += this.props.displayVariable.name;

        var col3cell = props => { return <a href="#!" 
            onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} 
            onClick={(e) => { this.onItemClick(e, {props})}}>{this.props.displayVariable.renderMethod ? this.props.displayVariable.renderMethod(props.value) : props.value }</a> };

        var columns = [];

        if (globalconfig.features.ravens.vendorDetailsPreferred) { // OEM/Vendor 
            columns.push({
                Header: 'ID',
                accessor: (d) => { // "NOTE If your data has a field/key with a dot (.) you will need to supply a custom accessor." (https://www.npmjs.com/package/react-table-v6#columns)
                    if (d.item.Raven['Raven External Id']) {//d.item.Raven.hasOwnProperty('Raven External Id')) {
                        return d.item.Raven['Raven External Id'];
                    }
                    if (d.item.Raven.hasOwnProperty('Enclosure Serial No.')) { // graceful fallback (no error message, supports other vendors who do not have subscriber ids)
                        return d.item.Raven['Enclosure Serial No.'];
                    }
                    return d.item.Raven['Unit Id'];
                }, //['item','Raven','Enclosure Serial No.'],
                id: 'Id',
                Cell: props => <a href="#!" 
                    onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} 
                    onClick={(e) => { this.onItemClick(e, {props})}}>{props.value}</a>
            });
        } else {
            columns.push({
                Header: 'ID',
                accessor: 'item.Raven.Unit Id',
                Cell: props => <a href="#!" 
                    onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} 
                    onClick={(e) => { this.onItemClick(e, {props})}}>{props.value}</a>
            });
        }

        if (globalconfig.features.ravens.vendorDetailsPreferred) { // OEM/Vendor 
            columns.push({
                Header: 'Account',
                accessor: (d) => { // "NOTE If your data has a field/key with a dot (.) you will need to supply a custom accessor." (https://www.npmjs.com/package/react-table-v6#columns)
                    if (d.item.Account.hasOwnProperty('Account Name')) {
                        return d.item.Account['Account Name'];
                    }
                    return d.item.Raven['Unit Id'];
                }, //['item','Raven','Enclosure Serial No.'],
                id: 'Account',
                Cell: props => <a title={props.value} href="#!" 
                    onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} 
                    onClick={(e) => { this.onItemClick(e, {props})}}>{props.value}</a>
            });
        } else {    
            columns.push({
                Header: 'Email',
                accessor: 'item.Account.Email',
                Cell: props => <a title={props.value} href="#!" 
                    onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} 
                    onClick={(e) => { this.onItemClick(e, {props})}}>{props.value}</a>
            });
        }
        columns.push({
            Header: this.props.displayVariable.label,
            accessor: col3accessor,
            id: 'default-sort-column-id', // https://spectrum.chat/react-table/general/default-multiple-sorting~665cf8be-5054-4126-ac49-1f44a1895b01
            Cell: col3cell,
                sortMethod: this.props.displayVariable.sortMethod
        });
        var pagesize = 100;

        var paginate = this.props.featureList.length > pagesize;

        let sortDesc = false; // see also displayVariableTypes' current sortMethod

        if (col3accessor === 'item.Last Update') {
            sortDesc = true; // effecively ASCENDING for this data set and displayVariableTypes' current sortMethod
        }

        return (
            <div id="featureListing" className="blank-page">
                    <ReactTable
                        className="-striped"
                        data={this.props.featureList}
                        showPagination={paginate}
                        showPageSizeOptions={false}
                        showPageJump={false}
                        pageSize={paginate ? pagesize : this.props.featureList.length }
                        defaultSorted={[
                            { id: 'default-sort-column-id', desc: sortDesc }
                        ]}
                        style={{ height: "70vh" }}
                        noDataText="No Ravens in View"
                        columns={columns} />
            </div>
        );
    }
}
