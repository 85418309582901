import React, { Component } from 'react';
import Events from '../common/events';
import mapboxgl from 'mapbox-gl';
import chroma from 'chroma-js';
import moment from 'moment';
import globalconfig from '../common/config';
import displayVariableTypes from '../common/displayVariableTypes';
import 'mapbox-gl/dist/mapbox-gl.css';
import supercluster from 'supercluster';
import turf from 'turf';
import {Chart} from 'chart.js';

mapboxgl.accessToken = globalconfig.mapbox.key;

export default class MapboxMap extends Component {

    constructor()
    {
        super();

        this.state = {
            loaded: false,
            legend: null,
        };

        this.geojsonseen = false;
        this.clusterRadius = 30;

    }

    componentDidMount() {
        this.loadMap();
        window.addEventListener('resize', this.resize);

        document.addEventListener(Events.ZOOM_TO_EXTENTS, (e) => this.queueZoomToExtents (e) );
        document.addEventListener(Events.RAVEN_SELECT_EVENT, (e) => this.findRaven (e) );
        document.addEventListener(Events.RAVEN_HIGHLIGHT_EVENT, (e) => this.highlightRaven (e) );

    }

    componentDidUpdate() {
        this.loadMap();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
        document.removeEventListener(Events.ZOOM_TO_EXTENTS, (e) => this.queueZoomToExtents (e) );
        document.removeEventListener(Events.RAVEN_SELECT_EVENT, (e) => this.findRaven);
        document.removeEventListener(Events.RAVEN_HIGHLIGHT_EVENT, (e) => this.highlightRaven);
    }

    queueZoomToExtents = () => {
        this.geojsonseen = false;
        setTimeout(this.zoomToExtentsTimeout, 500);
    }

    zoomToExtentsTimeout = () => {
        var me = this;

        me.zoomToExtents();
    }

    zoomToExtents() {

        if(this.props.geojson)
        {
            var bounds = new mapboxgl.LngLatBounds();

            this.props.geojson.data.features.forEach(function(feature) {
                if(feature.geometry)
                    bounds.extend(feature.geometry.coordinates);
            });

            if(!bounds.isEmpty())
            {
                this.map.fitBounds(bounds, { padding: 50 });
                this.geojsonseen = true;
            }
        }

    }

    loadMap() {
        if(this.map === null || this.map === undefined) {
            this.initMap();
            return;
        } 
        if(!this.geojsonseen && this.props.geojson && this.props.geojson.data)
        {
            // This is the first time we saw some geojson data,
            // move the map to the data

            this.zoomToExtents();

        }

        if(this.state.loaded && this.props.geojson && this.props.geojson.data)
        {
            if(this.oldtimestamp !== this.props.geojsonTimestamp ||
                this.oldDisplayVariable !== this.props.displayVariable)
            {

                // Remove the popup opon loading more data
                this.smallpopup.remove();

                this.map.getSource('allravens').setData(this.props.geojson.data);
                // Clear the highlighted item... will get re-instated later
                this.map.getSource('highlight').setData({"type": "FeatureCollection", "features": []});
                this.oldfeature = null;

                this.setRavenColor(this.props.displayVariable);

                this.pieChartColorGroup = null;
                this.getSuperCluster(this.props.displayVariable);
                this.updateClusters();

                this.oldDisplayVariable = this.props.displayVariable;
                this.oldtimestamp = this.props.geojsonTimestamp;
            }

            if(this.props.feature && this.oldfeature !== this.props.feature)
            {
                var geojsondata = {
                    "type": "FeatureCollection",
                    "features": [ {
                        "type": "Feature",
                        "geometry": this.props.feature.geometry
                    } ]
                };

                this.map.getSource('highlight').setData(geojsondata);

                this.oldfeature = this.props.feature;
            }
        }
    }

    initMap() {
        this.currentZoom = 12
        this.map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/dark-v9', //stylesheet location
            center: [-75.6972, 45.4215], // starting position
            zoom: this.currentZoom // starting zoom
        });

        this.smallpopup = new mapboxgl.Popup();
        this.smallpopupquery = null;

        var me = this

        this.map.on('load', (e) => me.onMapLoaded(e));
        //this.map.on('click', 'stops-cluster', (e) => me.onMapClick(e));
        this.map.on('click', 'stops-unclustered', (e) => me.onMapClick(e));
        //this.map.on('dblclick', 'stops-cluster', (e) => me.onMapDblClick(e));
        //this.map.on('mousemove', 'stops-cluster', (e) => me.onMapClusterMouseMove(e));
        //this.map.on('mouseleave', 'stops-cluster', (e) => me.onMapClusterMouseLeave(e));
        this.map.on('mousemove', 'stops-unclustered', (e) => me.onMapRavenMouseMove(e));
        this.map.on('mouseleave', 'stops-unclustered', (e) => me.onMapRavenMouseLeave(e));
        this.map.on('zoom', (e) => me.onMapRavenZoom(e));
    }

    onMapLoaded = () => {

        var me = this;

        this.map.loadImage('/images/map_marker_car_black_icon.png', function(error, image) {
            if (error) throw error;
            me.map.addImage('driving', image, { sdf: true} );
        });
        this.map.loadImage('/images/car-delay.png', function(error, image) {
            if (error) throw error;
            me.map.addImage('delayed', image, { sdf: true} );
        });
        this.map.loadImage('/images/car-svf.png', function(error, image) {
            if (error) throw error;
            me.map.addImage('parked', image, { sdf: true} );
        });
        this.map.loadImage('/images/harbor-svf.png', function(error, image) {
            if (error) throw error;
            me.map.addImage('stale', image, { sdf: true} );
        });

        this.initMapLayers();

        this.resize();

        this.setState({ loaded: true });

    }

    onMapClick = (e) => {
        if(this.props && this.props.geojson && e && e.features)
        {
            this.props.onFeatureClick({ 
                    item: this.props.geojson.objects[e.features[0].properties.index],
                    geometry: e.features[0].geometry,
                });
        }
    }

    onMapDblClick = (e) => {

        this.map.easeTo({
            center: [ e.lngLat.lng, e.lngLat.lat],
            zoom: this.map.getZoom() + 2,
        });
    }

    onMapClusterMouseMove = (e) => {
        this.map.getCanvas().style.cursor = 'pointer';

        // FIXME - add a cache to see if we have already done the spatial
        // search, so we don't have to do it again

        var feature = e.features[0];

        var innerTxt = '';

        var features = this.getClusteredFeatures(feature);
        if(features)
        {
            features.forEach((value, index) => {
                innerTxt += this.getRavenShortInfo(value, false) + '<br>\n';
            });

            if(features.length < feature.properties.point_count)
            {
                innerTxt += "+" + (feature.properties.point_count - features.length) + " Raven<br>\n";
            }
        }
        else
        {
            innerTxt = 'Zoom in to see Ravens';
        }

        // Populate the popup and set its coordinates based on the feature.
        this.smallpopup.setLngLat(feature.geometry.coordinates)
            .setHTML(innerTxt)
            .addTo(this.map);

    }

    onMapClusterMouseLeave = (e) => {
        this.map.getCanvas().style.cursor = '';
        this.smallpopup.remove();
    }

    onMapRavenMouseMove = (e) => {
        // Change the cursor style as a UI indicator.
        this.map.getCanvas().style.cursor = 'pointer';

        // Populate the popup and set its coordinates based on the feature.
        var feature = e.features[0];
        this.smallpopup.setLngLat(feature.geometry.coordinates)
            .setHTML(this.getRavenShortInfo(feature, true))
            .addTo(this.map);

    }

    onMapRavenMouseLeave = (e) => {
        this.map.getCanvas().style.cursor = '';
        this.smallpopup.remove();
    }

    onMapRavenZoom = (e) => {
        var newZoom = this.map.getZoom();
        /// this.getSuperCluster(this.props.displayVariable);

        if (Math.floor(this.currentZoom) === 0) {
            this.currentZoom = 1
        };

        if (Math.floor(newZoom) !== Math.floor(this.currentZoom)) {
            this.currentZoom = newZoom
            this.updateClusters();
            var mapSource = this.map.getSource('allravens');
            if (mapSource){
                mapSource.setData(this.props.geojson.data);
            }
        }
    }

    drawPie = (c) => {
        if (!c.properties.cluster) {
            return;
        }
        // create a DOM element for the marker
        var el = document.createElement('div');
        el.className = 'marker';
        el.style.height = '6em';
        el.style.width = '10em';

        var canvas = document.createElement('canvas');
        el.appendChild(canvas);

        var total = 0;
        var groups = this.pieChartColorGroup;
        var legendInfo = this.pieChartlegendInfo;
        var i;
        var chartData = [];
        for (i in groups){
            chartData.push(c.properties[groups[i][0]]);
            total += c.properties[groups[i][0]];
        }

        var labelData = [];
        if (this.pieChartLabels){
            labelData = this.pieChartLabels;
        }
        else if (legendInfo){
            for (i in legendInfo){
                var str = legendInfo[i][0];
                labelData.push(str.slice(0, str.search(" \\(")));
            }
        } else {
            for (i in groups){
                labelData.push('<'+String(groups[i][0]));
            }
        }

        var labels = [];
        var colorScale = [];
        var pieData = [];

        for (i in chartData){
            if (chartData[i] > 0){
                pieData.push(chartData[i]);
                colorScale.push(groups[i][1]);
                labels.push(labelData[i]);
            }
        }

        new Chart(canvas, {
            type: 'pie',
            data: {
                datasets: [{
                    data: pieData,
                    backgroundColor: colorScale,
                    hoverBackgroundColor: colorScale,
                    borderWidth: 0
                }],
                labels: labels
            },
            options: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                axes: {
                    display: false
                },
                animation: {
                    easing: "easeOutQuart",
                    onComplete: function () {
                        var ctx = canvas.getContext("2d");
                        ctx.font = "2em Arial";
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = '#000';
                        ctx.fillText(String(total), (canvas.width/2), canvas.height/2);
                    }
                }
            }
        });

        // add marker to map
        var m = new mapboxgl.Marker(el)
            .setLngLat(c.geometry.coordinates)
            .addTo(this.map);
        this.customMarkers.push(m);
    };

    getSuperCluster(propertyName){
        var groups, legendInfo, i, labels;
        [groups, legendInfo, i, labels] = this.getGroupLegendIfPossible(propertyName);
        this.pieChartColorGroup = groups;
        this.pieChartlegendInfo = legendInfo;
        this.pieChartLabels = labels;
        var categorical = false;

        if(this.props.geojson.meta[propertyName])
        {
            if(this.props.geojson.meta[propertyName]['values']){
                categorical = true;
            }
        }

        this.sCluster = supercluster({
            radius: 50,
            maxZoom: 16,
            colorGroups: groups,
            initial() {
                var item = {}
                for (i in groups){
                    item[groups[i][0]] = 0;
                };
                return item;
            },
            map(properties) {
                var item = {}
                for (i in groups){
                    if(categorical)
                    {
                        if (properties[propertyName] === groups[i][0]){
                            item[groups[i][0]] = 1;
                            break;
                        }
                    } else {
                        if (Number(properties[propertyName]) <= Number(groups[i][0])){
                            item[groups[i][0]] = 1;
                            break;
                        }
                        if (Number(i) === groups.length-1){
                            item[groups[i][0]] = 1;
                        }
                    }
                }
                return item;
            },
            reduce(accumulated, properties) {
                for (i in properties){
                    accumulated[i] += properties[i];
                }
            }
        })
        
        this.sCluster.load(this.props.geojson.data.features);
    }

    updateClusters = () => {
        for (var m in this.customMarkers){
            this.customMarkers[m].remove();
        }
        this.customMarkers = [];
        this.currentZoom = this.map.getZoom();
        if (this.sCluster != null){
            this.sClusterData = turf.featureCollection(this.sCluster.getClusters([-180.0000, -90.0000, 180.0000, 90.0000], Math.floor(this.currentZoom)));
            this.sClusterData.features.forEach(this.drawPie);
        }
    }

    getRavenShortInfo(feature, extended) {
        if(!this.props.geojson)
        {
            return null;
        }
        var props = this.props.geojson.objects[feature.properties.index];

        if(!props)
        {
            return null;
        }

        var text = "";
        if (globalconfig.features.ravens.vendorDetailsPreferred) {
            text = props['Raven']['Enclosure Serial No.'] + ' (' + props['Account']['Account Name'] + ')';
        } else {
            text = props['Raven']['Unit Id'] + ' (' + props['Account']['Email'] + ')';
        }

        if(extended)
        {
            var etext = '<div>';
            if(this.props.displayVariable)
            {   
                etext += '<span><b>ID</b>: ' + text + '</span><br>';

                etext += '<span><b>' + this.props.geojson.meta[this.props.displayVariable]['name'] + '</b>: ';
                etext += this.getItemPropertyValue(props, this.props.displayVariable);
                if(this.props.geojson.meta[this.props.displayVariable]['units'])
                {
                    etext += ' ' + this.props.geojson.meta[this.props.displayVariable]['units'];
                }
                etext += '</span>';
            }
            else
            {   
                etext = text;
            }
            etext += '</div>';
            return etext;
        }

        return text;
    }

    getUniqueFeatures(array, comparatorProperty) {
        var existingFeatureKeys = {};
        // Because features come from tiled vector data, feature geometries may be split
        // or duplicated across tile boundaries and, as a result, features may appear
        // multiple times in query results.
        var uniqueFeatures = array.filter(function(el) {
            if (existingFeatureKeys[el.properties[comparatorProperty]]) {
                return false;
            } else {
                existingFeatureKeys[el.properties[comparatorProperty]] = true;
                return true;
            }
        });

        return uniqueFeatures;
    }

    getClusteredFeatures(feature)
    {
        if(feature.properties.point_count > 40)
        {
            return [];
        }

        var clusterRadiusSquared = this.clusterRadius * this.clusterRadius;


        var featurepoint = this.map.project(feature.geometry.coordinates);

        if(this.props.geojson)
        {
            var sourcedata = this.props.geojson.data;

            if(!sourcedata)
                return;

            var mapbox = this.map;

            var features = sourcedata.features.filter(function(f) {

                    var pointPixels = mapbox.project(f.geometry.coordinates)
                      var pixelDistance = (
                        Math.pow(featurepoint.x - pointPixels.x, 2) +
                        Math.pow(featurepoint.y - pointPixels.y, 2)
                    );
                    return Math.abs(pixelDistance) <= clusterRadiusSquared;
            });

            return features;
        }

        return [];
    }

    initMapLayers() {

        var geojsondata = {
                "type": "FeatureCollection",
                "features": []
            };

        // Source
        this.map.addSource('allravens', {
            "type": "geojson",
            "data": geojsondata,
            cluster: true,
            clusterMaxZoom: 16, // Max zoom to cluster points on
            clusterRadius: 50 // Use small cluster radius for the heatmap look
        });

        this.map.addSource('highlight', {
            "type": "geojson",
            "data": geojsondata
        });

        // Layers
        this.map.addLayer({
            "id": "highlight",
            "type": "circle",
            "source": 'highlight',
            "paint": {
                "circle-color": '#2AAED3',
                "circle-radius": 20
            },
            "filter": [">=", ["zoom"], 6]
        });
        this.map.addLayer({
            "id": "stops-unclustered",
            // "type": "circle",
            "type": "symbol",
            "source": 'allravens',
            "layout": {
                "icon-image" : {
                    "type": "identity",
                    "property": "icon"
                },

                "icon-rotate": {
                    "type": "identity",
                    "property": "heading"
                },
                "icon-size": 0.5,
            },
            "paint": {
                "icon-color": 'white'
            },

            "filter": ["!=", "cluster", true]
        });


    }

    findRaven = (e) => {

        var curFeature;

        var curRaven = this.props.unfilteredGeojson.objects.find(x => x['Id'] === e.detail.ravenid);
        if(curRaven)
        {
            // First look for the Raven Id, then if that fails, the Raven Unit Id

            if(curRaven["Id"])
                curFeature = this.props.unfilteredGeojson.data.features.find(x => x.properties.raven_id === curRaven["Id"]);
            if(!curFeature)
                curFeature = this.props.unfilteredGeojson.data.features.find(x => x.properties.raven_unit_id === curRaven["Raven"]["Unit Id"]);

            var curGeometry = null;
            if(curFeature)
            {
                var zoomlevel = 14;
                if(this.map.getZoom() > zoomlevel)
                {
                    zoomlevel = this.map.getZoom();
                }

                this.map.flyTo({
                    center: curFeature.geometry.coordinates,
                    zoom: zoomlevel
                });

                curGeometry = curFeature.geometry;
            }

            this.props.onFeatureClick({
                    item: curRaven,
                    geometry: curGeometry
                });
        }
    }

    highlightRaven = (e) => {

        if(!this.props.geojson)
        {
            return;
        }

        var curRaven = this.props.geojson.objects.find(x => x['Id'] === e.detail.ravenid);

        if(curRaven)
        {
            var curFeature = this.props.geojson.data.features.find(x => x.properties.raven_id === curRaven["Raven"]["Unit Id"]);

            if(curFeature)
            {
                // Populate the popup and set its coordinates based on the feature.
                this.smallpopup.setLngLat(curFeature.geometry.coordinates)
                    .setHTML(this.getRavenShortInfo(curFeature, true))
                    .addTo(this.map);
            }
        }

    }

    resize = () => {
        this.map.resize();
    }

    getItemPropertyValue(item, variable)
    {

        var i;

        for (i = 0; i < displayVariableTypes.length; i++) 
        { 
            if(displayVariableTypes[i].value === variable)
            {
                var value;
                if('section' in displayVariableTypes[i])
                {
                    value = item[displayVariableTypes[i].section][displayVariableTypes[i].name];
                }
                else
                {
                    value = item[displayVariableTypes[i].name];
                }

                if('type' in displayVariableTypes[i])
                {
                    if(displayVariableTypes[i].type === 'date')
                    {
                        return "" + moment.unix(value).format(globalconfig.display.timeFormat) + " (" + moment.unix(value).fromNow() + ')';
                    }
                }
                else
                {
                    return value;
                }
            }
        }
        
        return "";
    }

    render() {

        return (
            <div className="mapbox-container">
                <div id='map' className="mapbox-map mapboxgl-ctrl">
                    { this.state.legend }
                </div>
            </div>
        );

    }

    hasCustomColor(propertyName) {
        var found = false;
        displayVariableTypes.forEach( function(item) { 
            if (item["value"] === propertyName && "colors" in item){
                found = true;
            }
        });
        return found;
    }

    getGroupLegendIfPossible(propertyName) {
        // var clusterColor = 'red';
        var circleColor = {
                'type': 'exponential',
                "property": propertyName,
            };

        // Initial bogus color scheme
        var groups = [ [ 0, 'white' ] ];
        var legendInfo;
        var labels;

        // Load from displayVariableTypes
        var group, color, legend;

        if(propertyName === 'ts')
        {
            // Now
            var secondsnow = moment().format('X');

            groups = [
                [secondsnow-(3600*24*30), '#80104C'],
                [secondsnow-(3600*24*7)-1, '#80104C'], // Hard switchover from red to blue
                [secondsnow-(3600*24*7), '#ff0000'],
                [secondsnow-(3600*12), '#ffa932'],
                [secondsnow-(60*20)-1, '#f7e800'],
                [secondsnow-(60*20), '#e6e6fa'],
                [+secondsnow, '#e6e6fa'], // string to integer
            ];
            legendInfo = [
                ['< 20 Minutes', '#e6e6fa'],
                ['12 Hours', '#f7e800'],
                ['Last week', '#ffa932'],
                ['Last month', '#ff0000'],
                ['Older', '#80104C'],
            ];
            labels = [
                'Older+',
                'Older',
                'Last month',
                'Last week',
                '12 Hours',
                '< 20 Minutes',
                '< 20 Minutes'
            ]
        }
    /*
        else if(propertyName === 'gps_accuracy')
        {
            groups = [
                [0, 'red'],
                [100, 'orange'],
                [200, 'yellow'],
                [500, 'white'],
            ];
        }
    */
       else if(propertyName === 'state')
        {
            circleColor['type'] = 'categorical';
            legendInfo = groups = [
                ['Parked', 'DodgerBlue'],
                ['Driving', 'LawnGreen'],
            ];
        }
        else if(this.hasCustomColor(propertyName))
        {
            displayVariableTypes.forEach( function(item) { 
                if (item["value"] === propertyName) {
                    group = item["groups"];
                    color = item["colors"];
                    legend = item["legend"];
                }
            });
            if (group && color){
                groups = group.map((value, index) => {
                    return [value, color[index]]
                });
            }
            if (legend && color){
                legendInfo = group.map((value, index) => {
                    return [value, color[index]]
                });
            }
        }
        else
        {
            if(this.props.geojson.meta && this.props.geojson.meta[propertyName])
            {
                if(this.props.geojson.meta[propertyName]['values'])
                {
                    // Categorical
                    var colorscale = ['DeepSkyBlue','LimeGreen','Yellow','Orange','Red'];
                    var values = this.props.geojson.meta[propertyName]['values'];
                    var colors = chroma.scale(colorscale).colors(Object.keys(values).length);
                    circleColor['type'] = 'categorical';
                    legendInfo = [];
                    groups = [];
                    Object.keys(values).forEach(function (key, index) {
                        var txt = key + " (" + values[key] + ")";
                        legendInfo.push([txt, colors[index]]);
                        groups.push([key, colors[index]]);
                    });
                }
                else
                {
                    var color_code;
                    if (propertyName === 'obd_battery_voltage' || propertyName === 'raven_battery_voltage'){
                        color_code = ['Red', 'Orange', 'Yellow', 'Lime'];
                    } else {
                        color_code = ['LightSkyBlue','Yellow','Orange','Red'];
                    }
                    var min = this.props.geojson.meta[propertyName]['min'];
                    var max = this.props.geojson.meta[propertyName]['max'];
                    groups = this.generateColorStops(4, 
                        color_code, 
                        min, max);
                }
            }
        }
        return [groups, legendInfo, circleColor, labels]
    }

    setRavenColor(propertyName) {

        if (!this.props.geojson ||
            !this.props.geojson.meta ||
            !this.props.geojson.meta.hasOwnProperty(propertyName)) {
            return;
        }
        // var clusterColor = 'red';
        var circleColor;

        // Initial bogus color scheme
        var groups = [ [ 0, 'white' ] ];
        var legendInfo;

        [groups, legendInfo, circleColor] = this.getGroupLegendIfPossible(propertyName);

        circleColor['stops'] = groups;

        if(groups.length > 1)
        {

            var label = this.props.geojson.meta[propertyName]['name'];
            if(this.props.geojson.meta[propertyName]['units'])
            {
                label += " (" + this.props.geojson.meta[propertyName]['units'] + ")";
            }

            var legend = (
                <div className='legend map-overlay'>
                    <div><span>{label}</span></div>
                    {
                        legendInfo ?
                            legendInfo.map(function(value,index) { return (
                                <div key={index}>
                                    <span className='legend-key' style={{backgroundColor: value[1]}} ></span>
                                    <span className='legend-value'>{value[0]}</span>
                                </div>
                            )}) :
                            // Generate the legend from the group info
                            groups.map(function(value,index) { return (
                                <div key={index}>
                                    <span className='legend-key' style={{backgroundColor: value[1]}} ></span>
                                    <span className='legend-value'> {
                                        index === 0 ? "<=" + value[0] : groups[index - 1][0] + ' - ' + value[0]
                                    } </span>
                                </div>
                            )})
                    }
                </div>
            );

            this.setState({legend: legend});
        }
        else
        {
            this.setState({legend: null});
        }

        // this.map.setPaintProperty('stops-cluster', 'circle-color', clusterColor);
        // this.map.setPaintProperty('stops-unclustered', 'circle-color', circleColor);
        this.map.setPaintProperty('stops-unclustered', 'icon-color', circleColor);
    }

    generateColorStops(numstops, colors, min, max)
    {
        if(numstops < 2)
        {
            numstops = 2;
        }
        colors =chroma.scale(colors).colors(numstops);

        var colorStops = [];

        var colorIncrement = 2;
        var colorRange = 0;
        if (typeof min !== 'undefined' &&
            typeof max !== 'undefined')
        {
            if(min === 1)
            {
                min = 0;
            }
            colorRange = (max - min);
            colorIncrement = Math.floor(colorRange/(numstops-1));
            if(colorIncrement <= 0)
            {
                colorIncrement = 1;
            }
        }

        // See if we are going to do linear or log scale
        if(colorRange < 100)
        {
            // console.log("range " + colorRange + " increment " + colorIncrement + " length " + colors.length);
            for (var i = 0; i < colors.length; i++) {
                colorStops.push(
                       [min + (colorIncrement * i), colors[i]]);
            }
        }
        else
        {
            // Use logarithmic increment
            if(min === 0)
            {
                min = 1;
            }
            var logstops = chroma.limits([min, min+colorRange], 'l', numstops-1);
            colorStops.push([min, colors[0]]);
            for (i = 1; i < colors.length; i++) {
                colorStops.push([Math.floor(logstops[i]), colors[i]]);
            }
        }

        return colorStops;
    }
}

