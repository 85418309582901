var globalconfig = {
    aws: {
        region: process.env.REACT_APP_AWS_REGION,
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        RoleId: process.env.REACT_APP_LEVEL1_ROLE_ID
    },
    mapbox: {
        key: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
    },
    recurly: {
        key: process.env.REACT_APP_RECURLY_PUBLIC_KEY
    },
    display: {
        timeFormat: 'MMM D YYYY HH:mm:ss'
    }
}

globalconfig.features = {};

globalconfig.features.ravens = {};
const reactAppFeatureRavensVendorDetailsPreferred = process.env.REACT_APP_FEATURE_RAVENS_VENDOR_DETAILS_PREFERRED || "false";
globalconfig.features.ravens.vendorDetailsPreferred = reactAppFeatureRavensVendorDetailsPreferred.toLowerCase() === 'true';
const reactAppFeatureRavensDiagnosticsEnabled = process.env.REACT_APP_FEATURE_RAVENS_DIAGNOSTICS_ENABLED || "false";
globalconfig.features.ravens.diagnosticsEnabled = reactAppFeatureRavensDiagnosticsEnabled.toLowerCase() === 'true';
globalconfig.features.ravens.simManagementExternalUrl = process.env.REACT_APP_FEATURE_RAVENS_SIM_MANAGEMENT_EXTERNAL_URL;
globalconfig.features.ravens.simManagementExternalUrlOptionalTitle = process.env.REACT_APP_FEATURE_RAVENS_SIM_MANAGEMENT_EXTERNAL_URL_OPTIONAL_TITLE;
globalconfig.features.ravens.externalIdOptionalTitle = process.env.REACT_APP_FEATURE_RAVENS_EXTERNAL_ID_OPTIONAL_TITLE;

globalconfig.features.orders = {};
const reactAppFeatureOrdersFulfillmentEnabled = process.env.REACT_APP_FEATURE_ORDERS_FULFILLMENT_ENABLED || "false";
globalconfig.features.orders.fulfillmentEnabled = reactAppFeatureOrdersFulfillmentEnabled.toLowerCase() === 'true';


globalconfig.features.accounts = {};
const reactAppFeatureAccountsManagementEnabled = process.env.REACT_APP_FEATURE_ACCOUNTS_MANAGEMENT_ENABLED || "false";
globalconfig.features.accounts.managementEnabled = reactAppFeatureAccountsManagementEnabled.toLowerCase() === 'true';
globalconfig.features.accounts.externalSectionOptionalTitle = process.env.REACT_APP_FEATURE_ACCOUNTS_EXTERNAL_SECTION_OPTIONAL_TITLE;
globalconfig.features.accounts.externalIdOptionalTitle = process.env.REACT_APP_FEATURE_ACCOUNTS_EXTERNAL_ID_OPTIONAL_TITLE;


globalconfig.features.tools = {};
const reactAppFeatureToolsEnabled = process.env.REACT_APP_FEATURE_TOOLS_ENABLED || "true";
globalconfig.features.tools.enabled = reactAppFeatureToolsEnabled.toLowerCase() === 'true';

const reactAppFeatureAccountsCreationEnabled = process.env.REACT_APP_FEATURE_ACCOUNTS_CREATION_ENABLED || "false";
globalconfig.features.accounts.creationEnabled = reactAppFeatureAccountsCreationEnabled.toLowerCase() === 'true';

const reactAppFeatureAccountsSendWelcomeEmailOptionAvailable = process.env.REACT_APP_FEATURE_ACCOUNTS_SEND_WELCOME_EMAIL_OPTION_AVAILABLE || "false";
globalconfig.features.accounts.sendWelcomeEmailOptionAvailable = reactAppFeatureAccountsSendWelcomeEmailOptionAvailable.toLowerCase() === 'true';


globalconfig.features.support = {};
const reactAppFeatureIssueEscalationFormVendorName = process.env.REACT_APP_FEATURE_ISSUE_ESCALATION_FORM_VENDOR_NAME; // or undefined
globalconfig.features.support.escalationFormVendorName = reactAppFeatureIssueEscalationFormVendorName;


export default globalconfig;
