import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dialog from '../dialog';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';
import { RavenStatusHistoryView } from '../../../raven/modals/status/ravenStatusView';
import Events from '../../../../common/events';
import globalconfig from '../../../../common/config';

class RavenViewStatusModal extends Component {

    constructor() {
    
        super();

        this.state = {
            isOpen: false,
            history: [],
            actionReasonValue: '',
        };

        this.localState = {};

    }

    queryRavenStatusHistoryData()
    {
        // console.log("Query History:", this.props, this.state);

        var me = this;

        var ruid = this.props.raven['Raven']['Unit Id'];

        // CognitoUtil.refreshLogin();

        var prefix = this.props.dataStore.prefix1;

        // perform ajax calls to get it the next state
        var url = prefix + "ajax"
                + "?stage=" + this.props.stage
                + "&type=ravenstatus"
                + "&raven=" + ruid;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
          .then((response) => {
            if(response.ok)
            {
                return response.json();
            }
            else
            {
                return [];
            }
        }).then((json) => {

            // console.log("Setting history to:", json);

            me.setState({history: json});
        });

    }

    openModal = (e) => {
        this.setState({isOpen: true});
    }

    closeModal = (e) => {
        this.setState({isOpen: false});
    }

    componentDidUpdate() {

        if(this.props.raven && this.props.raven.Id)
        {
            if(this.props.raven.Id !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.raven.Id;

                this.queryRavenStatusHistoryData();
            }
        }
    }

    render() {

        // console.log("Render History:", this.props, this.state);

        return (
            <Dialog
                size="medium"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.state.history.length !== 0 ?  
                                        <RavenStatusHistoryView 
                                            data={this.state.history} 
                                            /> : 
                                        <p>No Status History Data...</p>
                                }
                            </div>
                        </div>
                    
                    </div>
            </Dialog>
        );
    }

}

export default class RavenStatusModal extends Component {

    constructor(props) {
        super(props);

        // Store the raven to use in the state.  If it doesn't exist, then offer the
        // raven selector
        this.state = {
            historyModalIsOpen: false,
            curState: null,
            suspend_activity: true,
            serialno: '',
        }

        this.ravenStates = [
          { value: 'RETURNED', label: 'Raven Returned' },
          { value: 'RMA', label: 'Raven RMA' },
          { value: 'LOST/STOLEN', label: 'Raven Lost and/or Stolen' },
          { value: 'RAVENLESS', label: 'Remove Raven from account' },
          { value: 'SUSPENDED', label: 'Seasonal Suspend' },
          { value: 'UNSUSPEND', label: 'Unsuspend' },
        ];

        this.componentWillReceiveProps(props);

    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.ravenData.geojson && nextProps.ravenData.geojson.objects)
        {
            this.ravenList = nextProps.ravenData.geojson.objects.map(obj => {
                return { value : '' + obj['Id']
                        + ' ' + obj['Account']['Email']
                        + ' (' + obj['Raven']['Unit Id'] + ')',
                    label: '' + obj['Raven']['Unit Id']
                        + ' ' + obj['Account']['Email'],
                    id: obj['Id'],
                    obj: obj
                };

            });

        }
        else
        {
            this.ravenList = [];
        }

        if(this.state.serialNo === '' && nextProps.raven['Raven']['Replacement Raven Serial No'])
        {
            this.setState({serialNo: nextProps.raven['Raven']['Replacement Raven Serial No']});
        }

    }

    onRavenStatusChange= (val) => {
        this.setState({curState: val });
    }

    onRavenSerialChange = (val) => {

        // Check the database

        var len = this.ravenList.length;
        var message;
        var mtype;

        if(!val)
        {
            this.setState({serialno: val, message: null, messageType: 'error' }); 

            return;
            
        }

        var needle = val.toUpperCase();
        needle = needle.trim();

        var ravenindex = null;

        for(var i = 0; i < len; i++)
        {
            if(this.ravenList[i].obj['Raven']['Enclosure Serial No.'] === needle)
            {
                ravenindex = i;            
                break;
            }
        }

        if(ravenindex === null)
        {
            message = "Raven " + needle + " not found in database";
            mtype = 'error';
        }
        else
        {
            if(this.ravenList[ravenindex].obj.Account['Account Id'] !== null)
            {
                let lastseen = this.ravenList[ravenindex].obj['Last Update'] > 0 ? 
                    moment(this.ravenList[ravenindex].obj['Last Update'] * 1000).format(globalconfig.display.timeFormat) : 'Never';

                message = "Raven " + needle + " is already on account " + this.ravenList[ravenindex].obj.Account.Email + " last seen " + lastseen;
                mtype = 'error';
            }
            else
            {
                message = "Raven " + needle + " is in the database and not already on an account";
                mtype = 'success';
            }
        }

        this.setState({serialno: val, message: message, messageType: mtype }); 
    }

    onRavenSuspendChange = () => {
        this.setState({ suspend_activity: !this.state.suspend_activity } );
    }

    onReasonChange = (e) => {
        this.setState({actionReasonValue: e.target.value});
    }

    onMakeChange = () => {
        if(this.state.curState)
        {
            this.performStatusChange();
        }
        else
        {
            console.log("Go clicked with nothing set");
        }
    }

    performStatusChange = () => {
    
        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE,
                { detail: { loadingMessage: 'Changing Raven Status' } }));

        var prefix = this.props.dataStore.prefix2;

        var cleaned_serialno = this.state.serialno.toUpperCase();
        cleaned_serialno = cleaned_serialno.trim();

        var url = prefix + "modifystatus"
            + "?stage=" + this.props.stage
            + "&ravenunit=" + this.props.raven['Raven']['Raven UUID']
            + "&status=" + this.state.curState.value
            + "&serialno=" + cleaned_serialno
            + "&suspend=" + (this.state.suspend_activity ? "true" : "false")
            + "&notes=" + encodeURIComponent(this.state.actionReasonValue);

        this.setState({message: null, messageType: 'error'});

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
          .then((response) => {
            return response.json();
          }).then((json) => {

              // console.log("Received", json);
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));

              this.setState({message: "Success", messageType: 'success'});

          }).catch((error) => {
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
              this.setState({message: "Error: " + error, messageType: 'error'});
              console.error("Status Change Error", error);
          });

    }


    onSelectRavenSearch = (e) => {

        var enclosureid = e.obj['Raven']['Enclosure Serial No.'];

        this.props.routeProps.history.push('/tool/' + this.props.stage + '/change-status/' + enclosureid);

    }

    openHistoryModal = (e) => {
        e.preventDefault();

        this.setState({historyModalIsOpen: true});
    }


    render() {
        var content = "Select Raven";

        var errorMessage;

        if(this.props.enclosureId)
        {
            content = "Loading " + this.props.enclosureId;
        }

        if(this.state.messageType === 'success')
        {
            errorMessage = this.state.message ?
                <p className="success-message"><i className="material-icons">check_circle_outline</i>{this.state.message}</p> :
                null;
        } 
        else
        {
            errorMessage = this.state.message ?
                <p className="error-message"><i className="material-icons">error_outline</i>{this.state.message}</p> :
                null;
        } 

        var title;

        // console.log("raven: ", this.props.raven);

        if(this.props.raven)
        {
            title="History For "+this.props.raven['Raven']['Enclosure Serial No.'];

            var lastseen =  this.props.raven['Last Update']?
                moment.unix(this.props.raven['Last Update']).format(globalconfig.display.timeFormat) + " (" + moment.unix(this.props.raven['Last Update']).fromNow() + ")" :
                "Never";

            content = (
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">Raven ID:</div>
                        <div className="col-6">{this.props.raven['Raven']['Enclosure Serial No.']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Email:</div>
                        <div className="col-6">{this.props.raven['Account']['Email']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Owner Name:</div>
                        <div className="col-6">{this.props.raven['Raven']['Owner Name']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Seen:</div>
                        <div className="col-6">{lastseen}</div>

                    </div>
                    <div className="row">
                        <div className="col-6">Account Type:</div>
                        <div className="col-6">{this.props.raven['Account']['Account Type']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Current Status is:</div>
                        <div className="col-6">{this.props.raven["Raven"]["Raven Unit Status"]} </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Change Status to: 
                        </div>
                        <div className="col-6">
                            <Select id="ravenSearch" 
                            placeholder="Change..."
                            searchable={true} 
                            value={this.state.curState}
                            options={this.ravenStates}  
                            name="ravenStatusChange" 
                            onChange={this.onRavenStatusChange} 
                            clearable={false} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Suspend Activity:<br />
                            <small>(Raven at Klashwerks)</small>
                        </div>
                        <div className="col-6">
                            <input type="checkbox" 
                                defaultChecked={this.state.suspend_activity} 
                                onChange={this.onRavenSuspendChange} 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Replacement Raven Serial No:
                        </div>
                        <div className="col-6">
                            <input type="text" 
                                name="serialno"
                                onChange={(e) => { this.onRavenSerialChange(e.target.value) }} 
                                value={this.state.serialno}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-6'>
                            Reason for Change:
                        </div>
                        <textarea
                            name='actions-reason'
                            value={this.state.actionReasonValue ? this.state.actionReasonValue : ""}
                            onChange={this.onReasonChange}
                        />
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {this.state.curState ?
                                <button onClick={this.onMakeChange}>Commit</button> :
                                <button onClick={this.onMakeChange} disabled>Select an Option</button>
                            }
                        </div>
                        <div className="col-6">
                            <button onClick={this.openHistoryModal}>View History</button>
                            {errorMessage}
                        </div>
                    </div>
                    <RavenViewStatusModal title={title} isOpen={this.state.historyModalIsOpen} 
                            onClose={() => {this.setState({historyModalIsOpen: false})}} 
                            raven={this.props.raven} 
                            stage={this.props.stage} 
                            dataStore={this.props.dataStore}
                    />
                </div>
            );
        }

        return (
            <Dialog
                size="small"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-2">Raven:</div>
                            <div className="col-10">
                                <Select id="ravenSearch" 
                                    placeholder="Search..."
                                    searchable={true} 
                                    options={this.ravenList}
                                    noResultsText="Loading..."
                                    name="ravenSearch" 
                                    filterOption={(option, filter) => {

                                        // Search: ravenid, raven unit id, email, raven enclosure id 
                                        if(option.obj['Id'].toString().includes(filter))
                                            return true;
                                        if('Serial No.' in option.obj['Raven'] && option.obj['Raven']['Serial No.'])
                                        {
                                            if(option.obj['Raven']['Serial No.'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Email' in option.obj['Account'] && option.obj['Account']['Email'])
                                        {
                                            if(option.obj['Account']['Email'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Enclosure Serial No.' in option.obj['Raven'] &&
                                            option.obj['Raven']['Enclosure Serial No.'])
                                        {
                                            if(option.obj['Raven']['Enclosure Serial No.'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Unit Id' in option.obj['Raven'] && option.obj['Raven']['Unit Id'])
                                        {
                                            if(option.obj['Raven']['Unit Id'].toString().includes(filter))
                                                return true;
                                        }
                                        if('Raven UUID' in option.obj['Raven'] && option.obj['Raven']['Raven UUID'])
                                        {
                                            if(option.obj['Raven']['Raven UUID'].toString().includes(filter))
                                                return true;
                                        }

                                        return false;
                                    }}
                                    onChange={this.onSelectRavenSearch} 
                                    clearable={false} />
                            </div>
                        </div>
                        <div className="row">
                            {content}
                        </div>
                    </div>
            </Dialog>
        );
    }
}
