import React, { Component } from 'react';

import CognitoUtil from '../../aws/cognito/cognitoUtil';
import Events from '../../common/events';

class NewPasswordModal extends Component {

    constructor(props) {

        super(props);

        console.log(this.props);

        this.state = {
            errorMessage: '',
            passwordValid: true,
            password: '',
            confirmPassword: '',
        };
    }

    handlePasswordChange = (e) => {
        e.preventDefault();

        var inpass = e.target.value;
        var err = '';

        if(this.state.confirmPassword !== inpass)
        {
            err = "Passwords don't match";
        }

        this.setState({
            password: inpass,
            errorMessage: err
        });
    }

    handleConfirmPasswordChange = (e) => {
        e.preventDefault();

        var inpass = e.target.value;
        var err = '';

        if(this.state.password !== inpass)
        {
            err = "Passwords don't match";
        }

        this.setState({
            confirmPassword: inpass,
            errorMessage: err
        });
    }

    onSubmit = (e) => {
        e.preventDefault();

        if(this.state.password !== this.state.confirmPassword)
        {
            this.setState({errorMessage: "Passwords don't match"});
            return false;
        }

        console.log("cognito change password");

        CognitoUtil.finalizeNewPassword(this.state.password, (error) => {
            if(error)
            {
                this.setState({errorMessage: error});
            }
            else
            {
                // We have logged in, announce as such
                document.dispatchEvent(new CustomEvent(Events.LOGIN_SUCCESS));
            }
        });

    }

    render() {

        return (
            <form id="newPasswordForm">
                <h1>New password required</h1>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="password">New Password</label>
                        <input ref={(pass) => { this.pass = pass; }} id="password" type="password" name="password" onChange={this.handlePasswordChange} onBlur={(e) => {this.handlePasswordChange(e, true)}} />
                    </div>

                    <div className="col-12">
                        <label htmlFor="confirmPassword">Password</label>
                        <input ref={(password) => { this.password = password; }} id="confirmPassword" type="password" name="confirmPassword" className={this.state.passwordValid ? '' : 'invalid'} onChange={this.handleConfirmPasswordChange} onBlur={(e) => {this.handleConfirmPasswordChange(e, true)}} />
                        {this.state.passwordValid ? <p className="error-message">&nbsp;</p> : <p className="error-message">Invalid Password</p>}
                    </div>
                    <div className="col-12">
                        <input className="btn" type="submit" onClick={this.onSubmit} value="Set Password" />
                    </div>
                    {(this.state.errorMessage) ? (<div className="col-12"><p className="error-message"><i className="material-icons">error</i><span>{this.state.errorMessage}</span></p></div>) : null}
                </div>
            </form>
        );
    }
}

export default NewPasswordModal;
