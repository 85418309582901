import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dialog from '../dialog';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';
import { SingleDatePicker, OPEN_UP } from 'react-dates';
import Events from '../../../../common/events';
import globalconfig from '../../../../common/config';

export default class UpdateWarrantyModal extends Component {

    constructor(props) {
        super(props);

        // Store the raven to use in the state.  If it doesn't exist, then offer the
        // raven selector
        this.state = {
            end: null,
        }

        this.localState = {};

        this.componentWillReceiveProps(props);

    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.ravenData.geojson && nextProps.ravenData.geojson.objects)
        {

            this.ravenList = nextProps.ravenData.geojson.objects.reduce((filtered, obj) => {
                if(obj['Account']['Account Id'])
                {
                    filtered.push( { value : '' + obj['Id']
                            + ' ' + obj['Account']['Email']
                            + ' (' + obj['Raven']['Unit Id'] + ')',
                        label: '' + obj['Raven']['Unit Id']
                            + ' ' + obj['Account']['Email'],
                        id: obj['Id'],
                        obj: obj
                    });
                }
                return filtered;
            }, []);

        }
        else
        {
            this.ravenList = [];
        }

    }

    componentDidUpdate() {

        if(this.props.raven && this.props.raven.Id)
        {
            if(this.props.raven.Id !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.raven.Id;

                this.props.dataStore.queryRaven(this.props.raven.Id);
            }

            if(this.state.end === null && this.props.raven["Plan"] && this.props.raven["Plan"]["Warranty End Date"])
            {
                this.setState({end: moment.unix(this.props.raven["Plan"]["Warranty End Date"])});
            }
        }
    }

    onDateSelect = (date) => {
        this.setState({end: date, curState: "set"});
    }


    onMakeChange = () => {
        if(this.state.end)
        {
            this.updateWarrantyEnd();
        }
        else
        {
            console.log("Go clicked with nothing set");
        }
    }

    updateWarrantyEnd = () => {
    
        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE,
                { detail: { loadingMessage: 'Changing Raven Warranty End Date' } }));

        var prefix = this.props.dataStore.prefix2;

        var url = prefix + "updatewarrantyenddate"
            + "?stage=" + this.props.stage
            + "&ravenunit=" + this.props.raven['Raven']['Raven UUID']
            + "&end=" + this.state.end

        this.setState({message: null, messageType: 'error'});

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
          .then((response) => {
            return response.json();
          }).then((json) => {
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));

              this.setState({message: "Success", messageType: 'success'});

              this.props.dataStore.queryRaven(this.props.raven.Id);

          }).catch((error) => {
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
              this.setState({message: "Error: " + error, messageType: 'error'});
              console.error("Warranty Change Error", error);
          });

    }


    onSelectRavenSearch = (e) => {

        var uuid = e.obj['Raven']['Raven UUID'];

        this.props.routeProps.history.push('/tool/' + this.props.stage + '/update-warranty/' + uuid);

    }

    render() {

        var content = "Select Raven";

        var errorMessage;

        if(this.state.messageType === 'success')
        {
            errorMessage = this.state.message ?
                <p className="success-message"><i className="material-icons">check_circle_outline</i>{this.state.message}</p> :
                null;
        } 
        else
        {
            errorMessage = this.state.message ?
                <p className="error-message"><i className="material-icons">error_outline</i>{this.state.message}</p> :
                null;
        } 

        var title;

        if(this.props.raven)
        {
            title="Warranty Adjustment For "+this.props.raven['Raven']['Enclosure Serial No.'];

            var lastseen =  this.props.raven['Last Update']?
                moment.unix(this.props.raven['Last Update']).format(globalconfig.display.timeFormat) + " (" + moment.unix(this.props.raven['Last Update']).fromNow() + ")" :
                "Never";


            var warrantystart = "Loading...";
            var warrantyend = "Loading...";

            if(this.props.raven["Plan"])
            {
                warrantystart = <div className="col-6">{moment.unix(this.props.raven["Plan"]["Warranty Start Date"]).format(globalconfig.display.timeFormat)} ({moment.unix(this.props.raven["Plan"]["Warranty Start Date"]).fromNow()})</div>
                warrantyend = <div className="col-6">{moment.unix(this.props.raven["Plan"]["Warranty End Date"]).format(globalconfig.display.timeFormat)} ({moment.unix(this.props.raven["Plan"]["Warranty End Date"]).fromNow()})</div>
            }

            content = (
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">Raven ID:</div>
                        <div className="col-6">{this.props.raven['Raven']['Enclosure Serial No.']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Email:</div>
                        <div className="col-6">{this.props.raven['Account']['Email']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Owner Name:</div>
                        <div className="col-6">{this.props.raven['Account']['Owner Name']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Seen:</div>
                        <div className="col-6">{lastseen}</div>

                    </div>
                    <div className="row">
                        <div className="col-6">Account Type:</div>
                        <div className="col-6">{this.props.raven['Account']['Account Type']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Warranty Start:</div>
                        {warrantystart}
                    </div>
                    <div className="row">
                        <div className="col-6">Currenty Warranty End:</div>
                        {warrantyend}
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Change Warranty End to: 
                        </div>
                        <div className="col-6">
{ /*this.props.raven["Raven"]["Warranty End Date"] */}
                                <SingleDatePicker
                                  date={this.state.end} 
                                  onDateChange={this.onDateSelect} // PropTypes.func.isRequired,
                                  focused={this.state.dateFocusedInput} 
                                  numberOfMonths={1}
                                  daySize={25}
                                  openDirection='up'
                                  anchorDirection='right'
                                  onFocusChange={ ({focused}) => this.setState({ dateFocusedInput: focused })} // PropTypes.func.isRequired,
                                  id="sdp"
                                />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {this.state.curState ?
                                <button onClick={this.onMakeChange}>Commit</button> :
                                <button onClick={this.onMakeChange} disabled>Set new Warranty Date</button>
                            }
                            {errorMessage}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Dialog
                size="large"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-2">Raven:</div>
                            <div className="col-10">
                                <Select id="ravenSearch" 
                                    placeholder="Search..."
                                    searchable={true} 
                                    options={this.ravenList}
                                    noResultsText="Loading..."
                                    name="ravenSearch" 
                                    filterOption={(option, filter) => {

                                        // Search: ravenid, raven unit id, email, raven enclosure id 
                                        if(option.obj['Id'].toString().includes(filter))
                                            return true;
                                        if('Serial No.' in option.obj['Raven'] && option.obj['Raven']['Serial No.'])
                                        {
                                            if(option.obj['Raven']['Serial No.'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Email' in option.obj['Account'] && option.obj['Account']['Email'])
                                        {
                                            if(option.obj['Account']['Email'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Enclosure Serial No.' in option.obj['Raven'] &&
                                            option.obj['Raven']['Enclosure Serial No.'])
                                        {
                                            if(option.obj['Raven']['Enclosure Serial No.'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Unit Id' in option.obj['Raven'] && option.obj['Raven']['Unit Id'])
                                        {
                                            if(option.obj['Raven']['Unit Id'].toString().includes(filter))
                                                return true;
                                        }
                                        if('Raven UUID' in option.obj['Raven'] && option.obj['Raven']['Raven UUID'])
                                        {
                                            if(option.obj['Raven']['Raven UUID'].toString().includes(filter))
                                                return true;
                                        }

                                        return false;
                                    }}
                                    onChange={this.onSelectRavenSearch} 
                                    clearable={false} />
                            </div>
                        </div>
                        <div className="row">
                            {content}
                        </div>
                    </div>
            </Dialog>
        );
    }
}
