import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dialog from '../dialog';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';
import Events from '../../../../common/events';
import globalconfig from '../../../../common/config';

export default class RavenReprovisionModal extends Component {

    constructor(props) {
        super(props);

        // Store the raven to use in the state.  If it doesn't exist, then offer the
        // raven selector
        this.state = {
            historyModalIsOpen: false,
            curState: null,
            suspend_activity: true,
            serialno: '',
            changed: false,
        }

        this.ravenReprovisionStates = [
          { value: 'No', label: '(No) No Reprovisioning' },
          { value: 'Yes', label: '(Yes) Will Automatically Reprovision' },
        ];

        this.componentWillReceiveProps(props);

    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.ravenData.geojson && nextProps.ravenData.geojson.objects)
        {
            this.ravenList = nextProps.ravenData.geojson.objects.map(obj => {
                return { value : '' + obj['Id']
                        + ' ' + obj['Account']['Email']
                        + ' (' + obj['Raven']['Unit Id'] + ')',
                    label: '' + obj['Raven']['Unit Id']
                        + ' ' + obj['Account']['Email'],
                    id: obj['Id'],
                    obj: obj
                };

            });

        }
        else
        {
            this.ravenList = [];
        }

    }

    onRavenReprovisionChange= (val) => {
        this.setState({curState: val });
    }

    onMakeChange = (e) => {

        e.preventDefault();

        if(this.state.curState)
        {
            this.performReprovisionChange();
        }
        else
        {
            console.log("Go clicked with nothing set");
        }
    }

    performReprovisionChange = () => {

        console.log("Perform Reprovisioning");
    
        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE,
                { detail: { loadingMessage: 'Changing Raven Reprovisioning Status' } }));

        var prefix = this.props.dataStore.prefix2;

        /* FIXME */
        var url = prefix + "setautoreprovisionflag"
            + "?stage=" + this.props.stage
            + "&ravenunit=" + this.props.raven['Raven']['Raven UUID']
            + "&flag=" + (this.state.curState.value === 'Yes' ? 1 : 0);

        this.setState({message: null, messageType: 'error', changed: true});

        console.log("Calling into", url);

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
          .then((response) => {
            return response.json();
          }).then((json) => {

              console.log("Received", json);
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));

              if(json['status'] === 'SUCCESS')
              {
                  this.setState({message: "Success", messageType: 'success'});
              }
              else
              {
                  this.setState({message: "Error: Unknown Error:" + json['status'], messageType: 'error'});
              }

          }).catch((error) => {
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
              this.setState({message: "Error: " + error, messageType: 'error'});
              console.error("Status Change Error", error);
          });

    }


    onSelectRavenSearch = (e) => {

        var enclosureid = e.obj['Raven']['Enclosure Serial No.'];

        this.props.routeProps.history.push('/tool/' + this.props.stage + '/change-reprovision/' + enclosureid);

    }


    render() {
        var content = "Select Raven";

        var errorMessage;

        if(this.state.messageType === 'success')
        {
            errorMessage = this.state.message ?
                <p className="success-message"><i className="material-icons">check_circle_outline</i>{this.state.message}</p> :
                null;
        } 
        else
        {
            errorMessage = this.state.message ?
                <p className="error-message"><i className="material-icons">error_outline</i>{this.state.message}</p> :
                null;
        } 

        var title;

        if(this.props.raven)
        {
            title="Reprovisioning For "+this.props.raven['Raven']['Enclosure Serial No.'];

            var lastseen =  this.props.raven['Last Update']?
                moment.unix(this.props.raven['Last Update']).format(globalconfig.display.timeFormat) + " (" + moment.unix(this.props.raven['Last Update']).fromNow() + ")" :
                "Never";


            content = (
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">Raven ID:</div>
                        <div className="col-6">{this.props.raven['Raven']['Enclosure Serial No.']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Email:</div>
                        <div className="col-6">{this.props.raven['Account']['Email']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Owner Name:</div>
                        <div className="col-6">{this.props.raven['Raven']['Owner Name']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Seen:</div>
                        <div className="col-6">{lastseen}</div>

                    </div>
                    <div className="row">
                        <div className="col-6">Account Type:</div>
                        <div className="col-6">{this.props.raven['Account']['Account Type']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Current Reprovisioning is:</div>
                        <div className="col-6">{this.state.changed ? (<i>Reload to see update</i>) : this.props.raven["Account"]["Auto Reprovision"]} </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Change Status to: 
                        </div>
                        <div className="col-6">
                            <Select id="ravenSearch" 
                            placeholder="Change..."
                            searchable={true} 
                            value={this.state.curState}
                            options={this.ravenReprovisionStates}  
                            name="ravenReprovisionChange" 
                            onChange={this.onRavenReprovisionChange} 
                            clearable={false} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {this.state.curState ?
                                <button onClick={this.onMakeChange}>Commit</button> :
                                <button onClick={this.onMakeChange} disabled>Select an Option</button>
                            }
                        </div>
                        <div className="col-6">
                            {errorMessage}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Dialog
                size="small"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-2">Raven:</div>
                            <div className="col-10">
                                <Select id="ravenSearch" 
                                    placeholder="Search..."
                                    searchable={true} 
                                    options={this.ravenList}
                                    noResultsText="Loading..."
                                    name="ravenSearch" 
                                    filterOption={(option, filter) => {

                                        // Search: ravenid, raven unit id, email, raven enclosure id 
                                        if(option.obj['Id'].toString().includes(filter))
                                            return true;
                                        if('Serial No.' in option.obj['Raven'] && option.obj['Raven']['Serial No.'])
                                        {
                                            if(option.obj['Raven']['Serial No.'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Email' in option.obj['Account'] && option.obj['Account']['Email'])
                                        {
                                            if(option.obj['Account']['Email'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Enclosure Serial No.' in option.obj['Raven'] &&
                                            option.obj['Raven']['Enclosure Serial No.'])
                                        {
                                            if(option.obj['Raven']['Enclosure Serial No.'].toLowerCase().includes(filter))
                                                return true;
                                        }
                                        if('Unit Id' in option.obj['Raven'] && option.obj['Raven']['Unit Id'])
                                        {
                                            if(option.obj['Raven']['Unit Id'].toString().includes(filter))
                                                return true;
                                        }
                                        if('Raven UUID' in option.obj['Raven'] && option.obj['Raven']['Raven UUID'])
                                        {
                                            if(option.obj['Raven']['Raven UUID'].toString().includes(filter))
                                                return true;
                                        }

                                        return false;
                                    }}
                                    onChange={this.onSelectRavenSearch} 
                                    clearable={false} />
                            </div>
                        </div>
                        <div className="row">
                            {content}
                        </div>
                    </div>
            </Dialog>
        );
    }
}
