// stateless react component that renders a Raven side panel
// Takes in the object in the props

import React, { Component } from 'react';

import { Route, Link } from 'react-router-dom';

import { tsvParse } from  "d3-dsv";
import { timeParse, timeFormat } from "d3-time-format";
import moment from 'moment';
import Clipboard from 'clipboard';

import JSONView from '../../../widgets/jsonView';
import PrettySize from '../../../widgets/prettySize';

import { ConfirmationDialog, MessageDialog } from '../../../modals/Modal2';
import Diagnostics from './Diagnostics/diagnostics';
import Actions from './Actions/actions';
import OTABuilds from '../modals/ota/otaBuilds';
import RavenHistoryModal from '../modals/history/ravenHistory';
import DTCHistoryModal from '../modals/dtc/dtcHistory';
import EventHistoryModal from '../modals/event/eventHistory';
import BillingHistoryModal from '../modals/billing/billingHistory';
import TombstoneHistoryModal from '../modals/logs/tombstoneHistory';
import SupportedPidsModal from '../modals/dtc/supportedPids';
import UserViewModal from '../modals/user/userView';
import RavenViewStatusModal from '../modals/status/ravenStatusView';
import NotesModal from '../modals/notes/notesHistory';
import {DataBins, DataBinsModal} from '../modals/datausage/dataBins';

import globalconfig from '../../../common/config';

import ProgressOverlay from '../../../common/ProgressOverlay'; 

const km2mi = (1/1.609);

function hexToAscii(hex) {
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}

export default class FeatureSheet extends Component {

    constructor()
    {
        super();

        this.accountsManagementEnabled = false;
        if (globalconfig.features) {
            if (globalconfig.features.accounts) {
                this.accountsManagementEnabled = globalconfig.features.accounts.managementEnabled || false;
            }
        }


        this.state = {
            otabuilds: {},
            databins: {},
            sensoroverview: {},
            mintime: null,
            maxtime: null,
            detailsSectionIsExpanded: false,
            fullview: false,
            lastupdaterequest: null,
            confirmDialog: null,
            messageDialog: null,
            inProgressField: null
        };

        this.localState = {
            ravenid: null,
        };

        this.modals = [
            {
                'url': 'history',
                'title': 'Raven History',
                'component': RavenHistoryModal,
                'icon': '/images/ic_history_white_24dp_2x.png',
                'tier': 1,
            },
            {
                'url': 'dtc',
                'title': 'DTC History',
                'component': DTCHistoryModal,
                'icon': '/images/car-engine-icon.png',
                'tier': 1, // TODO, tier 2 has not be fully implementd for these modal icons (globalconfig.features.ravens.vendorDetailsPreferred must be false for tier 2 modals to be rendered)
            },
            {
                'url': 'logs',
                'title': 'Log History',
                'component': TombstoneHistoryModal,
                'icon': '/images/tombstone-icon.png',
                'tier': 2, // TODO, tier 2 has not be fully implementd for these modal icons (globalconfig.features.ravens.vendorDetailsPreferred must be false for tier 2 modals to be rendered)
            },
            {
                'url': 'notes',
                'title': 'Notes',
                'component': NotesModal,
                'icon': '/images/baseline_sticky_note_2_white_24dp_2x.png',
                'tier': 2, // TODO, tier 2 has not be fully implementd for these modal icons (globalconfig.features.ravens.vendorDetailsPreferred must be false for tier 2 modals to be rendered)
            },
            {
                'url': 'events',
                'title': 'Event History',
                'component': EventHistoryModal,
                'icon': '/images/ic_event_note_white_24dp_2x.png',
                'tier': 1,
            },
            {
                'url': 'pids',
                'title': 'Supported Pids',
                'component': SupportedPidsModal,
                'icon': '/images/supported-pids.png',
                'tier': 1, // TODO, tier 2 has not be fully implementd for these modal icons (globalconfig.features.ravens.vendorDetailsPreferred must be false for tier 2 modals to be rendered)
            },
            {
                'url': 'billing',
                'title': 'Billing',
                'component': BillingHistoryModal,
                'icon': '/images/ic_assignment_white_24dp_2x.png',
                'tier': 1,
            },
            {
                'url': 'user/:userid',
                'title': 'User View',
                'component': UserViewModal,
                'icon': null,
                'tier': 1,
            },
            {
                'url': 'statushistory',
                'title': 'Status History',
                'component': RavenViewStatusModal,
                'icon': null,
                'tier': 1,
            },
            {
                'url': 'databins',
                'title': 'Data Usage',
                'component': DataBinsModal,
                'icon': '/images/baseline_show_chart_white_24dp_2x.png',
                'tier': 1,
            }
        ];

        if (globalconfig.features.ravens.vendorDetailsPreferred) {
            this.modals = this.modals.filter((modal) => {
                switch (modal.title) {
                    case 'Billing': return false;
                    default: return true;
                }
            });
        }

        this.cardtypes = [
            { 'code': '01', 'vendor': 'Panasonic' },
            { 'code': '02', 'vendor': 'Toshiba' },
            { 'code': '03', 'vendor': 'SanDisk' },
            { 'code': '1b', 'vendor': 'Samsung' },
            { 'code': '1d', 'vendor': 'AData' },
            { 'code': '27', 'vendor': 'Phison, AfgaPhoto, Lexar, PNY, etc.' },
            { 'code': '28', 'vendor': 'Lexar, PNY, ProGrade, etc' },
            { 'code': '31', 'vendor': 'Silicon Power' },
            { 'code': '41', 'vendor': 'Kingston' },
            { 'code': '74', 'vendor': 'Transcend' },
            { 'code': '76', 'vendor': 'Patriot' },
            { 'code': '82', 'vendor': 'Gobe, Sony' },
            { 'code': '9c', 'vendor': 'Angelbird' },
            { 'code': '9f', 'vendor': 'Kingston' },
        ];

    }

    initializeRenderers() {

        var stage = this.props.stage;

        var dateRenderer = (name, value, units) => { 
            return (
                <div className="row json-view-item" key={name}>
                    <span className='col-3 label'><b>{name}: </b></span>
                    <span title={value} className='col-9 label json-view-item-value'>
                        {moment.unix(value).format(globalconfig.display.timeFormat)}
                        ({moment.unix(value).fromNow()})
                    </span>
                </div>
            );
        }

        var sizeRenderer = (name, value, units) => {
            return (
                <div className="row json-view-item" key={name}>
                    <span className='col-3 label'><b>{name}: </b></span>
                    <span className='col-9 label json-view-item-value'>
                        <PrettySize value={value} />
                    </span>
                </div>
            );
        }

        var copyRenderer = (name, value, units) => {

            var btnid = 'copy' + name + 'Btn';

            btnid = btnid.replace(/\s+/g, '');
            btnid = btnid.replace(/\./g, '');

            var cb = new Clipboard('#' + btnid);

            var text = value;
            if(units)
            {
                text += ' ' + units;
            }
            return (
                <div className="row json-view-item" key={name}>
                    <span className='col-3 label'><b>{name}: </b></span>
                    <span className='col-9 label json-view-item-value'>
                        {text}
                        <a href="#" id={btnid} data-clipboard-text={value} onClick={(e) => e.preventDefault() } ><img src="/images/icons8-copy-to-clipboard-24.png" alt="Copy" /></a>
                    </span>
                </div>
            );
        }

        this.sdCardDecoder = (value) => {
            if(value.startsWith("SD card inserted") || value.startsWith("SD card mounted"))
            {
                var result = {};

                var metadata = value.split('-');
                var otherinfo = metadata[0] ? metadata[0].split() : '';
                var cid = metadata[1] ? metadata[1].trim() : '';
                result['vendorcode'] = cid.substring(0,2);
                result['vendor'] = 'Unknown';
                result['oemid'] = cid.substring(2,6);
                result['name'] = hexToAscii(cid.substring(6,16));
                result['size'] = otherinfo;
                result['month'] = parseInt(cid.substring(29,30), 16);
                result['year'] = 2000 + parseInt(cid.substring(27,29), 16);

                for(var i = 0; i < this.cardtypes.length; i++)
                {
                    if(this.cardtypes[i]['code'] === result['vendorcode'])
                    {
                        result['vendor'] = this.cardtypes[i]['vendor'];
                        break;
                    }
                }
                return result;
            }
            return null;
        }

        this.renderers = [
            { 
                key: ['Last Update'],
                func: dateRenderer
            },
            { 
                key: ['Vehicle', 'DTC Last Code Update'],
                func: dateRenderer
            },
            { 
                key: ['Vehicle', 'Last GPS Update'],
                func: dateRenderer
            },
            { 
                key: ['Plan', 'Warranty Start Date'],
                func: dateRenderer
            },
            {
                key: ['Raven', 'Oldest Cabin Video'],
                func: dateRenderer
            },
            {
                key: ['Raven', 'Oldest Road Video'],
                func: dateRenderer
            },
            {
                key: ['Plan','Warranty End Date'],
                func: (name, value, units) => { 

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span title={value} className='col-9 label json-view-item-value'>
                                {moment.unix(value).format(globalconfig.display.timeFormat)}
                                ({moment.unix(value).fromNow()})
                                <Link to={"/tool/" + stage + "/update-warranty/" + this.props.feature.item['Raven']['Raven UUID']} ><img src="/images/ic_edit_white_18dp_1x.png" alt="" />Change</Link>
                            </span>
                        </div>
                    );
                }
            },
            { 
                key: ['Current Billing Period', 'Billing Status'],
                func: (name, value, units) => { 

                    var modalInfo = this.modals.find((elem) => { return elem.url === "billing" });

                    if (!modalInfo) {
                        if (!globalconfig.features.ravens.vendorDetailsPreferred) {
                            console.warn("billing modal not found.");
                        }
                        return null;
                    }

                    var billing = this.renderModalIcon(modalInfo);

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                {value} 
                                {billing}
                            </span>
                        </div>
                    );
                }
            },
            { 
                key: ['Current Billing Period', 'Start Date'],
                func: dateRenderer
            },
            { 
                key: ['Current Billing Period', 'End Date'],
                func: dateRenderer
            },
            { 
                key: ['Current Billing Period', 'Upload Data Usage'],
                func: sizeRenderer
            },
            { 
                key: ['Current Billing Period', 'Download Data Usage'],
                func: sizeRenderer
            },
            { 
                key: ['Current Billing Period', 'Total Data Usage'],
                func: sizeRenderer
            },
            { 
                key: ['Plan', 'Warranty Start Date'],
                func: dateRenderer
            },
            { 
                key: ['Plan', 'Warranty End Date'],
                func: dateRenderer
            },
            { 
                key: ['Plan', 'Data Limit'],
                func: sizeRenderer
            },
            {
                key: ['Raven', 'Last Provisioned Date'],
                func: dateRenderer
            },
            {
                key: ['Raven', 'EOL Timestamp'],
                func: dateRenderer
            },
            {
                key: ['Raven', 'Enclosure Serial No.'],
                func: copyRenderer
            },
            {
                key: ['Raven', 'Unit Id'],
                func: copyRenderer
            },

            {
                key: ['Raven','Uptime'],
                func: (name, value, units) => { 
                    var bootTime = moment.duration(value, 'seconds');

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                {value} seconds
                                ({bootTime.humanize()})
                            </span>
                        </div>
                    );
                }
            },
            { 
                key: ['Account', 'Email'],
                func: (name, value, units) => {
                    // console.log("Rendering", name, value, units);
                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            {this.accountsManagementEnabled ? 
                                <Link to={"/account/" + stage + "/" + value}>{value}</Link>
                            :
                                <span className='col-9 label json-view-item-value'>{value}</span>
                            }
                        </div>
                    );
                }
            },

            {
                key: ['Raven','Viewers'],
                func: (name, value, units) => { 

                    if (globalconfig.features.ravens.vendorDetailsPreferred) {
                        return null;
                    }

                    var viewers = value.split(', ');

                    var txt = viewers.map((obj) => {
                        var tokens = obj.split(';');
                        // return "Viewer: " + tokens[1] + " id: " + tokens[0];
                        // <a href="#!" onClick={(e) => { this.onItemClick(e, item)}}>{item}</a>
                        return (
                            <div key={tokens[0]} >
                                {this.accountsManagementEnabled ? 
                                    <Link key={tokens[0]} to={"/raven/" + stage + "/" + this.props.feature.item['Raven']['Raven UUID'] + "/user/" + tokens[0]} >{tokens[1]}</Link>
                                :
                                    <>{tokens[1]}</>
                                }
                            </div>
                        );
                    });

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                {txt}
                            </span>
                        </div>
                    );
                }
            },
            {
                key: ['Raven','State'],
                func: (name, value, units) => { 

                    if (globalconfig.features.ravens.vendorDetailsPreferred) {
                        return null; // partner-friendly Raven Actions component is already available
                    }

                    if (!globalconfig.features.tools.enabled) {
                        return null; // /tool/ area is disabled
                    }

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                <div className="tombstone d-inline-block">
                                    {value}
                                    <Link to={"/tool/" + stage + "/perform-raven-action/" + this.props.feature.item['Raven']['Raven UUID']} >Perform Action</Link>
                                </div>
                            </span>
                        </div>
                    );
                }
            },
            {
                key: ['Raven','Raven Unit Status'],
                func: (name, value, units) => { 

                    var modalInfo = this.modals.find((elem) => { return elem.url === "statushistory" });

                    if (!modalInfo) {
                        console.warn("statushistory modal not found.");
                        return null;
                    }

                    var modal = this.renderModalIcon(modalInfo);

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                <div className="tombstone d-inline-block">
                                    {value}
                                    { this.props.feature.item['Raven']['Raven UUID'] ?
                                        <Link to={"/raven/" + stage + "/" + this.props.feature.item['Raven']['Raven UUID'] + "/statushistory"} ><img src="/images/ic_history_white_18dp_1x.png" alt="" />History</Link> :
                                        <Link to={"/raven/" + stage + "/" + this.props.feature.item['Raven']['Enclosure Serial No.'] + "/statushistory"} ><img src="/images/ic_history_white_18dp_1x.png" alt="" />History</Link>
                                    }
                                    { globalconfig.features.tools.enabled ? 
                                        <Link to={"/tool/" + stage + "/change-status/" + this.props.feature.item['Raven']['Enclosure Serial No.']} ><img src="/images/ic_edit_white_18dp_1x.png" alt="" />Change</Link>
                                    :
                                        null
                                    }
                                    {modal}
                                </div>
                            </span>
                        </div>
                    );
                }
            },
            {
                key: ['Account','Auto Reprovision'],
                func: (name, value, units) => {

                    if (!globalconfig.features.tools.enabled) {
                        return null;
                    }

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                <div className="tombstone d-inline-block">
                                    {value}
                                    <Link to={"/tool/" + stage + "/change-reprovision/" + this.props.feature.item['Raven']['Enclosure Serial No.']} ><img src="/images/ic_edit_white_18dp_1x.png" alt="" />Change</Link>
                                </div>
                            </span>
                        </div>
                    );
                }
            },
            {
                key: ['SIM', 'SIM Provider'],
                func: (name, value, units) => {
                    var iccid = this.props.feature.item['Raven']['ICCID'];
                    var url = `https://cc7.jasperwireless.com/provision/ui/terminals/sims/sims.html?simsGrid-search=[{%22name%22%3A%22oneBox%22%2C%22display%22%3A%22${iccid}%22%2C%22value%22%3A%22${iccid}%22%2C%22type%22%3A%22CONTAINS%22}]`
                    if ((value == "Telus") && iccid) {
                        return (
                            <div className="row json-view-item" key={name}>
                                <span className='col-3 label'><b>{name}:</b></span>
                                <span className='col-9 label json-view-item-value'>
                                    {value} &nbsp;
                                    <a href={url} target='_blank'>Jasper Control Center Link</a>
                                    <a href="#" id="copyJasperCCLinkBtn" data-clipboard-text={url} onClick={(e) => e.preventDefault() }>
                                        <img src="/images/icons8-copy-to-clipboard-24.png" alt="Copy" />
                                    </a>
                                </span>
                            </div>
                        );
                    } else {
                        return (
                            <div className="row json-view-item" key={name}>
                                <span className='col-3 label'><b>{name}:</b></span>
                                <span className='col-9 label json-view-item-value'>
                                    {value}
                                </span>
                            </div>
                        );
                    }
                }
            },
            {
                key: ['SIM', 'SIM SID'],
                func: (name, value, units) => {

                    let url = "https://www.twilio.com/console/wireless/sims/" + value;

                    var cb = new Clipboard('#copySIMBtn');

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                <a href={url} target='_blank'>{value}</a>
                                <a href="#" id="copySIMBtn" data-clipboard-text={value} onClick={(e) => e.preventDefault() } ><img src="/images/icons8-copy-to-clipboard-24.png" alt="Copy" /></a>
                            </span>
                        </div>
                    );
                }
            },
            {
                key: ['SIM', 'Data Usage this Cycle'],
                func: (name, value, units) => {
                        return (
                            <div className="row json-view-item" key={name}>
                                <span className='col-3 label'><b>{name}:</b></span>
                                <span className='col-9 label json-view-item-value'>
                                    {value} &nbsp;
                                </span>
                            </div>
                        );
                    }
            },
            {
                key: ['SIM', 'In Session'],
                func: (name, value, units) => {
                        return (
                            <div className="row json-view-item" key={name}>
                                <span className='col-3 label'><b>{name}:</b></span>
                                <span className='col-9 label json-view-item-value'>
                                    {value} &nbsp;
                                </span>
                            </div>
                        );
                    }
            },
            {
                key: ['Raven', 'Raven UUID'],
                func: (name, value, units) => {

                    let url = `https://console.aws.amazon.com/iot/home?region=${globalconfig.aws.region}#/thing/${value}`

                    var cb = new Clipboard('#copyUUIDBtn');

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                {value}
                                <a href="#" id="copyUUIDBtn" data-clipboard-text={value} onClick={(e) => e.preventDefault() } ><img src="/images/icons8-copy-to-clipboard-24.png" alt="Copy" /></a>
                                {!globalconfig.features.ravens.vendorDetailsPreferred ?
                                    <a href={url} target='_blank'>Shadow</a>
                                :
                                    null
                                }
                            </span>
                        </div>
                    );
                }
            },
            {
                key: ['Raven', 'SD Card Status'],
                func: (name, value, units) => {

                    var extra = null;

                    var info = this.sdCardDecoder(value);
                    if(info)
                    {
                        extra = <p> {info['vendor'] + '(' + info['vendorcode'] + ') ' + info['name'] + ' ' + info['month'] +'/' + info['year'] } </p>;
                    }

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                {value} 
                                {extra} 
                            </span>
                        </div>
                    );
                }
            },
            {
                key: ['Vehicle', 'Odometer'],
                func: (name, value, units) => {

                    if(value != '-')
                    {
                        var odometer_km = value;
                        var odometer_mi = Math.floor(value * km2mi);

                        value = <div>{odometer_km} km<br/>{odometer_mi} mi</div>
                    }

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                {value} 
                            </span>
                        </div>
                    );
                }
            },
            {
                key: ['Raven','Raven Status'],
                func: (name, value, units) => {
                    
                    const title = "Seasonal Suspend";
                    const cancelButtonTitle = "Cancel";

                    let submitButtonTitle = 'Suspend';
                    let newStatus = 'SUSPENDED';                      
                    let message = "Are you sure you want to suspend this Raven?";

                    if(value === 'SUSPENDED') {
                        submitButtonTitle = "Unsuspend";
                        newStatus = 'UNSUSPEND';
                        message = "Are you sure you want to unsuspend this Raven?";
                    }

                    const toggleSuspendingOrder = () => {
                        const ravenSerialNo = this.props.feature.item['Raven']['Enclosure Serial No.'];
                        const ravenUUID = this.props.feature.item['Raven']['Raven UUID'];

                        this.setState({confirmDialog: null, inProgressField: name});

                        this.props.dataStore.changeRavenStatus(this.props.stage, ravenUUID, newStatus)
                        .then(() => {                            
                            this.renderMessageDialog("success", "Success", `Raven ${ravenSerialNo} has been successfully ${newStatus.toLowerCase()}` , "Okay");                            
                            this.refreshRaven();
                        })
                        .catch((error) => {
                            console.error("Error changing Raven status", ravenSerialNo, error);
                            this.renderMessageDialog("error", "Error", `An error occured trying to to ${submitButtonTitle.toLowerCase()} Raven ${ravenSerialNo}` , "Close");
                        })
                        .finally(() => {
                            this.setState({inProgressField: null});
                        });
                    };

                    return (
                        <div className="row json-view-item" key={name}>
                            <span className='col-3 label'><b>{name}: </b></span>
                            <span className='col-9 label json-view-item-value'>
                                <div className="tombstone d-inline-block">
                                    {value}&nbsp;
                                    <a href="#" onClick={(e) => {e.preventDefault(); this.renderConfirmDialog(title, message, toggleSuspendingOrder, cancelButtonTitle, submitButtonTitle)}} ><img src="/images/ic_edit_white_18dp_1x.png" alt="" />{submitButtonTitle}</a>
                                </div>
                            </span>
                            {this.state.inProgressField === name && <ProgressOverlay />}
                        </div>
                    );
                }
            },

        ];

        // also override certain default renderers based on environment
        if (globalconfig.features.ravens.vendorDetailsPreferred) {
            this.renderers.push(
                {
                    key: ['Vehicle', 'Name'],
                    func: (name, value, units) => {
                        return null; // display nothing
                    }
                },

                {
                    key: ['Account', 'Channel Name'],
                    func: (name, value, units) => {
                        return null; // display nothing
                    }
                },
                {
                    key: ['Account', 'Owner Name'],
                    func: (name, value, units) => {
                        return null; // display nothing
                    }
                },
                {
                    key: ['Account', 'Channel Code'],
                    func: (name, value, units) => {
                        return null; // display nothing
                    }
                },

                {
                    key: ['Raven', 'Fan Type'],
                    func: (name, value, units) => {
                        return null; // display nothing
                    }
                }
            )
        }


    }

    renderModal(modalInfo, props)
    {
        // console.log("Render Modal:", modalInfo, props, this.props);

        var title = "" + modalInfo.title + " for Raven Unit Id " + this.props.feature.item['Raven']['Unit Id'] + " (" + this.props.feature.item.Account.Email + ")";
        if (globalconfig.features.ravens.vendorDetailsPreferred) {
            const ravenEnclosureSN = this.props.feature.item.Raven['Enclosure Serial No.'];
            const accountExternalId = this.props.feature.item.Account['Account Name'];
            if (ravenEnclosureSN && accountExternalId) {
                title = "" + modalInfo.title + " for Raven " + ravenEnclosureSN + " (" + accountExternalId + ")";
            }
        }

        var ModalComponent = modalInfo.component;

        var backlink = "/raven/" + this.props.stage + "/" + this.props.feature.item['Raven']['Raven UUID'];

        return (
            <ModalComponent title={title} isOpen={true}
                    routeProps={props}
                    raven={this.props.feature}
                    dataStore={this.props.dataStore}
                    stage={this.props.stage}
                    mintime={this.state.mintime}
                    maxtime={this.state.maxtime}
                    onClose={() => {props.history.push(backlink);}}

            />
        );

    }

    renderModalIcon(modalInfo)
    {
        var linkto = this.props.feature.item['Raven']['Raven UUID'] ?
                "/raven/" + this.props.stage + "/" + this.props.feature.item['Raven']['Raven UUID'] + "/" + modalInfo.url :
                "/raven/" + this.props.stage + "/" + this.props.feature.item['Raven']['Enclosure Serial No.'] + "/" + modalInfo.url;

        var link;
        if(modalInfo.icon) link = <Link to={linkto} > <img src={modalInfo.icon} alt={modalInfo.title}/> </Link>;

        return (
            <div key={modalInfo.url} className="tombstone modal-icon d-inline-block">
                {  link }
                <Route path={"/raven/:stage/:ravenuuid/" + modalInfo.url } render={(props) => { return this.renderModal(modalInfo, props)} } />
                <div className="tooltip-large">
                    <span>
                        {modalInfo.title}
                    </span>
                </div>

            </div>
        );
    }

    renderConfirmDialog(title, message, confirmAction, cancelButtonTitle, confirmButtonTitle) {
        this.setState({confirmDialog: <ConfirmationDialog
            title={title}
            message={message}
            cancelButtonAction={() => {this.setState({confirmDialog: null})}}
            cancelButtonTitle={cancelButtonTitle}
            hideHeaderCloseButton={true}
            submitButtonAction={confirmAction}
            submitButtonTitle={confirmButtonTitle}
        />});
    }

    renderMessageDialog(messageType, title, message, closeButtonTitle) {
        this.setState({messageDialog: <MessageDialog
            title={title}
            message={message}
            closeButtonAction={() => {this.setState({messageDialog: null})}}
            closeButtonTitle={closeButtonTitle}
            messageType={messageType}
        />});
    }

    componentDidMount() {


        // console.log("Mount Feature Sheet: ", this.props);

        this.initializeRenderers();


        // Set the initial raven id
        if(this.props.feature && this.props.feature.item)
        {
            this.localState.ravenid = this.props.feature.item.Id;

            this.props.dataStore.getRavenEvents(this.props.feature); // for Diagnostics component 'Last reboot time' and also related to Actions event polling
            this.props.dataStore.queryRaven(this.props.feature.item.Id);
        }
        else if(this.props.dataStore.getStage() && this.props.featureId)
        {
            this.localState.ravenid = this.props.featureId;

            if (this.props.feature) { this.props.dataStore.getRavenEvents(this.props.feature); }
            this.props.dataStore.queryRaven(this.props.featureId);
        }
    }

    componentDidUpdate() {

        // console.log("Update Feature Sheet: ", this.props);


        this.initializeRenderers();

        if(this.props.feature && this.props.feature.item.Id)
        {
            if(this.props.feature.item.Id !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.feature.item.Id;

                this.props.dataStore.getRavenEvents(this.props.feature); // for Diagnostics component 'Last reboot time' and also related to Actions event polling
                this.props.dataStore.queryRaven(this.props.feature.item.Id);
            }
        }
        else if(this.props.dataStore.getStage() && this.props.featureId)
        {
            if(this.props.featureId !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.featureId;

                if (this.props.feature) { this.props.dataStore.getRavenEvents(this.props.feature); }
                this.props.dataStore.queryRaven(this.props.featureId);
            }
        }

    }

    refreshRaven(){
        this.setState({lastupdaterequest: this.props.feature.lastupdate});
        this.props.dataStore.getRavenEvents(this.props.feature);
        this.props.dataStore.queryRaven(this.props.featureId); 
    } 

    render() { 
        let oldestLoadedEventMoment;
        let mostRecentLoadedRavenBootedEventMoment;

        if (this.props.feature && this.props.feature.events && this.props.feature.events.length > 0) {
            const oldestEventTimestamp = this.props.feature.events[0].ts;
            if (oldestEventTimestamp) {
                oldestLoadedEventMoment = moment(oldestEventTimestamp.endsWith("Z") ? oldestEventTimestamp : oldestEventTimestamp + "Z", "ddd MMM DD HH:mm:ss YYYYZ"); // Sun Feb 27 00:42:11 2022
            }
            // search in order of most recent events first, oldest events last
            for (let i = this.props.feature.events.length - 1; i > 0; i -= 1) {
                const event = this.props.feature.events[i];
                if (event.name === "EVENT_RAVEN_BOOTED") {
                    const mostRecentLoadedRavenBootedEventTimestamp = this.props.feature.events[i].ts;
                    if (mostRecentLoadedRavenBootedEventTimestamp) {
                        mostRecentLoadedRavenBootedEventMoment = moment(mostRecentLoadedRavenBootedEventTimestamp.endsWith("Z") ? mostRecentLoadedRavenBootedEventTimestamp : mostRecentLoadedRavenBootedEventTimestamp + "Z", "ddd MMM DD HH:mm:ss YYYYZ");
                    }
                    break;
                }
            }
        }

        if(this.props.feature && this.props.feature.item)
        {

            var me = this;

            // console.log("Feature Sheet", this.props);
            var url_prefix;

            var modalsIcons;

            if(this.props.feature.item['Raven']['Raven UUID'])
            {
                url_prefix = "/raven/" + this.props.stage + "/" + this.props.feature.item['Raven']['Raven UUID'] + "/";

                if (globalconfig.features.ravens.vendorDetailsPreferred) {
                    modalsIcons = this.modals.map( (elem) => {
                        if (elem.tier && elem.tier == 1) {
                            return me.renderModalIcon(elem);
                        }
                        return null;
                    });
                } else {
                    modalsIcons = this.modals.map( (elem) => {
                        return me.renderModalIcon(elem);
                    });
                }
            }
            else
            {
                url_prefix = "/raven/" + this.props.stage + "/" + this.props.feature.item['Raven']['Enclosure Serial No.'] + "/";
            }

            return (
                <div className="feature-sheet container-fluid">
                    <Diagnostics
                        item={this.props.feature.item}
                        oldestLoadedEventMoment={oldestLoadedEventMoment}
                        mostRecentLoadedRavenBootedEventMoment={mostRecentLoadedRavenBootedEventMoment}
                        dataStore={this.props.dataStore}
                        otabuilds={this.props.feature.otabuilds}
                        onSupportTicketClick={this.props.onSupportTicketClick}
                    />
                    <Actions
                        feature={this.props.feature}
                        item={this.props.feature.item}
                        oldestLoadedEventMoment={oldestLoadedEventMoment}
                        mostRecentLoadedRavenBootedEventMoment={mostRecentLoadedRavenBootedEventMoment}
                        dataStore={this.props.dataStore}
                        stage={this.props.stage}
                        ravenId={this.props.feature.item['Id']}
                    />
                    <section className="details" style={{marginBottom: '15%'}}>
                        <header >
                            Details
                        </header >
                    
                        <div className="refresh">
                            {this.state.fullview ?
                                <button className="btn" onClick={()=>{this.setState({fullview: false}) } }>Less</button> :
                                <button className="btn" onClick={()=>{this.setState({fullview: true}) } }>More</button> 
                            }
                            <button className="btn" onClick={this.refreshRaven}>Refresh</button> 
                        </div>
                        { this.state.lastupdaterequest === this.props.feature.lastupdate ? <div>Refreshing...</div> : null }
                        <JSONView json={this.state.fullview || !this.props.feature.minitem ? this.props.feature.item : this.props.feature.minitem} renderers={this.renderers} />

                        <OTABuilds stage={this.props.stage}
                            dataStore={this.props.dataStore}
                            raven={this.props.feature.item} 
                            data={ this.props.feature.otabuilds } 
                        />
                        <DataBins 
                            stage={this.props.stage}
                            dataStore={this.props.dataStore}
                            data={ this.props.feature.databins } 
                            raven={this.props.feature.item} 
                        />
                    </section>
                    <div style={{position: 'fixed', bottom: '0', background:'#333333', margin:'0 -5px 10px -15px'}}>
                            { modalsIcons }
                    </div>
                    {this.state.confirmDialog}
                    {this.state.messageDialog}
                </div>
            );
        }
        else if(this.props.feature && this.props.feature.alt)
        {
            return (
                <div className="feature-sheet container-fluid">
                    <JSONView json={this.props.feature.alt} />
                    {this.state.confirmDialog}
                    {this.state.messageDialog}
                </div>
            );
        }
        else
        {
            return (
                <div className="feature-sheet container-fluid">
                    <JSONView json={this.props.feature} />
                    {this.state.confirmDialog}
                    {this.state.messageDialog}
                </div>
            );
        }
    }
                
}

