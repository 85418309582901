import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import globalconfig from '../../../../common/config';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';


const ACTION_STEP_STATUSES = {
    QUEUED: "QUEUED",           // ☐
    IN_PROGRESS: "IN_PROGRESS", // ◌
    COMPLETED: "COMPLETED",     // ☑
    ERROR: "ERROR",             // !
    TIMEOUT: "TIMEOUT",         // ◌ + error message
    SKIPPED: "SKIPPED"          // ☐ + error message
}

const ACTION_STEP_STATUS_ICON_IDS = { // https://fonts.google.com/icons
    QUEUED: "check_box_outline_blank",
    //IN_PROGRESS: "custom_activity_indicator",
    COMPLETED: "check_box",
    ERROR: "error",
    //TIMEOUT: "custom_activity_indicator",
    SKIPPED: "error"
}

const ACTION_STEP_IDS = {
    REQUEST_LOGS: "REQUEST_LOGS",
    POLLING_FOR_RECENT_LOGS: "POLLING_FOR_RECENT_LOGS",
    REQUEST_ACTION: "REQUEST_ACTION",
    POLLING_FOR_RECENT_ACTION: "POLLING_FOR_RECENT_ACTION"
}

//Steps specific to certain actions, requesting logs is always first
const REBOOT_STEPS = {
    REQUEST_ACTION: { // ACTION_STEP_IDS.REQUEST_ACTION
        description: "Proceed with reboot",
        supplementaryDetail: null,
        status: ACTION_STEP_STATUSES.QUEUED,
        // Date.getTime() millisecond timestamp, tested against EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS to set status TIMEOUT during POLLING_FOR_RECENT_REBOOT
        requestDatetime: null
    },
    POLLING_FOR_RECENT_ACTION: { // ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION
        description: "Raven reboot successful",
        supplementaryDetail: "(Checking every 30 seconds)",
        status: ACTION_STEP_STATUSES.QUEUED
    }
}

const FACTORYRESET_STEPS = {
    REQUEST_ACTION: { // ACTION_STEP_IDS.REQUEST_ACTION
        description: "Proceed with factory reset",
        supplementaryDetail: null,
        status: ACTION_STEP_STATUSES.QUEUED,
        // Date.getTime() millisecond timestamp, tested against EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS to set status TIMEOUT during POLLING_FOR_RECENT_REBOOT
        requestDatetime: null
    },
    POLLING_FOR_RECENT_ACTION: { // ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION
        description: "Raven factory reset successful",
        supplementaryDetail: "(Checking every 30 seconds)",
        status: ACTION_STEP_STATUSES.QUEUED
    }
}

const TOMBSTONE_POLLING_INTERVAL_MILLISECONDS = 30000; // 30 seconds
const TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS = 420000 // 7 minutes
const EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS = 900000; // 15 minutes

//For resetting the state when the component unmounts
const DEFAULT_ACTION_STEPS = {
    REQUEST_LOGS: { // ACTION_STEP_IDS.REQUEST_LOGS
        description: "Requesting logs",
        supplementaryDetail: null,
        status: ACTION_STEP_STATUSES.QUEUED,
        requestDatetime: null // Date.getTime() millisecond timestamp, tested against TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS to set status TIMEOUT during POLLING_FOR_RECENT_LOGS
    },
    POLLING_FOR_RECENT_LOGS: { // ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS
        description: "Logs uploaded",
        supplementaryDetail: "(Checking every 30 seconds)",
        status: ACTION_STEP_STATUSES.QUEUED
    }
}


export default class ActionsRebootRavenOverlay extends React.PureComponent {

    static propTypes = {
        dataStore: PropTypes.object.isRequired, // for requesting refresh for missing details on app header sort displayVariable changes
        raven: PropTypes.object.isRequired,
        feature: PropTypes.object.isRequired,
        oldestLoadedEventMoment: PropTypes.object, // moment.js
        mostRecentLoadedRavenBootedEventMoment: PropTypes.object, // moment.js
        stage: PropTypes.string.isRequired,
        reason: PropTypes.string.isRequired,
        onActionClose: PropTypes.func.isRequired,
        persistStateInLocalStorage: PropTypes.func.isRequired,
        restoredActionsOverlayState: PropTypes.object,
        selectedAction: PropTypes.object.isRequired
    };

    initialState = { // used for resetting state
        cancelButtonIsDisabled: false,
        actionStartTime: new Date().getTime(),
        actionCompleted: false, // corresponds to POLLING_FOR_RECENT_REBOOT.status === COMPLETED
        actionErrorOccurred: false,
        actionStatusMessage: null, // displayed red if actionErrorOccurred
        actionSteps: { // Requesting logs is always first, additional steps added in constructor based on props
            ...JSON.parse(JSON.stringify(DEFAULT_ACTION_STEPS)),
        },
        overridableTimeoutOccurred: false,
        actionCanceled: false,
        selectedAction: null,
        ravenId: null
    };

    constructor(props) {
        super(props);

        this.controller = new AbortController();

        // If it's not a log request, adjust steps based on the action requested
        if (props.selectedAction.id !== "LOGREQUEST") {
            if (props.selectedAction.id === "REBOOT") {
                this.initialState.actionSteps = {
                    ...JSON.parse(JSON.stringify(this.initialState.actionSteps)),
                    ...JSON.parse(JSON.stringify(REBOOT_STEPS))
                };
            } else if (props.selectedAction.id === "FACTORYRESET") {
                this.initialState.actionSteps = {
                    ...JSON.parse(JSON.stringify(this.initialState.actionSteps)),
                    ...JSON.parse(JSON.stringify(FACTORYRESET_STEPS))
                };
            }
        }

        this.state = this.initialState;

        this.queryLogDataInterval = undefined;
        this.queryRavenEventsInterval = undefined;
    }

    componentDidMount() {
        if (this.props.ravenId) {
            if (this.props.restoredActionsOverlayState && this.props.restoredActionsOverlayState.ravenId === this.props.ravenId) {
                this.setRestoredActionsOverlayState();
            } else if (this.props.restoredActionsOverlayState && this.props.restoredActionsOverlayState.ravenId !== this.props.ravenId) {
                // A different Raven was loaded with the wrong restored state, wait for a new restoredState
                return;
            } else {
                // start action from the beginning:
                this.setState({ selectedAction: this.props.selectedAction, ravenId: this.props.ravenId }, () => {
                    this.requestActionAndStartPolling("LOGREQUEST");
            });
            }
        }
    }

    componentWillUnmount() {
        this.controller.abort(); //Stop any pending fetch requests
        clearInterval(this.queryLogDataInterval);
        clearInterval(this.queryRavenEventsInterval);
        this.initialState.actionSteps = { ...JSON.parse(JSON.stringify(DEFAULT_ACTION_STEPS)) }; // reset the steps to default
    }

    componentDidUpdate() {
        //I removed a check here earlier, but I think it's necessary to check for the Raven ID change
        // Components are destroyed but they sometimes get rendered with the previous component's restored state before the paremt component updates
        if (this.props.ravenId && this.state.ravenId !== this.props.ravenId) {
            console.debug("placeholder");
        }

        //Used for checking that the boot actually happened
        if (this.state.actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION] 
            && this.state.actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.IN_PROGRESS
        ){
            this.checkMostRecentLoadedRavenBootedEventMoment();
        }
    }

    onClose = () => {
        this.setState({actionCanceled: true}, () => { this.props.onActionClose(); });
    }

    /**
     * Store the current state in local storage for later recovery
     * In the case the selected Raven changes, we store the state of the previous Raven before moving on
     * @param {*} ravenId - The ravenId to store the state for
     * @param {*} prevState - The state of the previous Raven, if required
     */
    persistStateInLocalStorage = (ravenId=null, prevState=null) => {
        if (prevState) 
            this.props.persistStateInLocalStorage(prevState, ravenId);
        else
            this.props.persistStateInLocalStorage(this.state);
    }

    updateState({actionSteps, message, error=false, 
            timeout=false, canceled=false, completed=false, callback=null}) {
        const newState = {
            actionStatusMessage: message,
            actionErrorOccurred: error,
            overridableTimeoutOccurred: timeout,
            actionCanceled: canceled,
            actionCompleted: completed
        };

        if (actionSteps) newState.actionSteps = { ...actionSteps };

        this.setState(newState, callback);
    }

    /**
     * Restores the state of the actions overlay based on the state stored in localstorage.
     */
    setRestoredActionsOverlayState = () => {

        if (!this.props.restoredActionsOverlayState) return; //This is redundant

        this.setState(this.props.restoredActionsOverlayState, () => {

            // Some cleanup for particular states to help resume gracefully, based on when requests were sent
            if (this.state.actionCompleted || this.state.actionCanceled) return;// do nothing; (state does not need to be resumed)

            const now = new Date();
            const { actionSteps } = this.state;

            // Check if the previous request timed out
            const actionStartTime = this.state.actionStartTime;
            if (now.getTime() - actionStartTime > TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS
                && actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.IN_PROGRESS) {

                const revertedState = this.initialState;
                revertedState.actionErrorOccurred = true;
                revertedState.actionStatusMessage = "A previous request stalled waiting for logs. Click 'Cancel' and retry, or click below to try rebooting raven now.";
                revertedState.overridableTimeoutOccurred = true;
                this.setState({ ...revertedState }, this.persistStateInLocalStorage);

                return;
            }


            // RESUME ACTIVITES BASED ON RESTORED STATE
            /** TODO: Check for error states?
             * Possible states to resume from:
             * - REQUEST_LOGS: QUEUED - Logs were not requested yet
             * - REQUEST_LOGS: IN_PROGRESS - Logs were requested, but the callback to check for logs was not called
             * - POLLING_FOR_RECENT_LOGS: QUEUED - Logs were requested, but we did not start polling for logs yet
             * - POLLING_FOR_RECENT_LOGS: IN_PROGRESS - Logs were requested, and we started polling for logs but did not complete
             * - POLLING_FOR_RECENT_LOGS: COMPLETED - Logs were requested, and we started polling for logs and completed (Check if this was all that was needed)
             * - REQUEST_ACTION: QUEUED - Action was not requested yet
             * - REQUEST_ACTION: IN_PROGRESS - Action was requested, but the callback to check for the action was not called
             * - POLLING_FOR_RECENT_ACTION: QUEUED - Action was requested, but we did not start polling for the action yet
             * - POLLING_FOR_RECENT_ACTION: IN_PROGRESS - Action was requested, and we started polling for the action but did not complete
             * - POLLING_FOR_RECENT_ACTION: COMPLETED - Action was requested, and we started polling for the action and completed ( we are done )
             */

            if (actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.QUEUED) {
                this.requestActionAndStartPolling("LOGREQUEST"); //FIX ME: this will send a new request, should have a way to check to checek if the request was already sent
            } else if (actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                //This and REQUEST_ACTION IN_PROGRESS are edge cases where the user closes the overlay before the callback is called
                //Avoiding the risk of redriving the request automatically, force the user to decide
                actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.ERROR;
                let message = "Request for logs stalled. Click 'Cancel' and retry, or click below to try rebooting raven now.";
                this.updateState({actionSteps: actionSteps, message: message, error: true, 
                        timeout: true, callback: this.persistStateInLocalStorage});
            } else if (actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status === ACTION_STEP_STATUSES.QUEUED 
                    || actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status === ACTION_STEP_STATUSES.IN_PROGRESS) {

                if (this.queryLogDataInterval) clearInterval(this.queryLogDataInterval);
                this.queryLogDataInterval = setInterval(this.queryLogData, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
            } else if (actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status === ACTION_STEP_STATUSES.COMPLETED
                    && this.state.selectedAction.id === "LOGREQUEST") {
                return; //we are done
            } else if (actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].status === ACTION_STEP_STATUSES.QUEUED) {
                this.requestActionAndStartPolling(this.state.selectedAction.id);
            } else if (actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].status = ACTION_STEP_STATUSES.ERROR;
                let message = "Request for action stalled. Click 'Cancel' and retry, or click below to try rebooting raven now.";
                this.updateState({actionSteps: actionSteps, message: message, error: true, 
                        timeout: true, callback: this.persistStateInLocalStorage});
            } else if (actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.QUEUED 
                || actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                clearInterval(this.queryRavenEventsInterval); // prevent zombie intervals
                this.queryRavenEventsInterval = setInterval(this.refreshRavenEvents, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
            } else if (actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.COMPLETED) {
                // We are done, this is likely redundant but final safety net
                return;
            }
            this.persistStateInLocalStorage();
        });

    }

    /**
     * Makes an http call to AWS using the provided URL and executes the onSuccess callback on success, and onError on error.
     * @param {str} url - URL to fetch from AWS, requires all necessary parameters for given request. We don't check for missing parameters here. 
     * @param {func} onSuccess - Callback function to execute on successful fetch
     * @param {func} onError - Callback function to execute on error
     * @returns 
     */
    fetchAWS = (url, onSuccess, onError) => {
        const signal = this.controller.signal;

        return fetch(url, {
            method: 'POST',
            headers: { Authorization: CognitoUtil.getCurrentUserToken() },
            signal: signal
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return [];
                }
            })
            .then((data) => { //Can be just a success message, a list of logs (can be empty), 
                //length is a bit of hack here, need a better way to check for success between different endpoints
                if (data.status === "SUCCESS" || data.length > 0) {
                    onSuccess(data);
                } else if (data.message) {
                    onSuccess(data, data.message); //Doesn't make sense but keeping for legacy
                }else {
                    //no message or data. We only get logs as a list in here for now, so this must be an empty list of logs
                    onSuccess(data, "No logs found.");
                }
            })
            .catch((error) => {
                onError(error);
            });
    }

    /**
     * Sends a request to AWS to perform an action on a Raven unit and starts polling for the action to complete.
     * 
     * @param {*} actionToTake Action to send to AWS, taken from state.selectedAction.id
     *  - LOGREQUEST: Request logs from Raven
     *  - REBOOT: Request Raven to reboot
     *  - FACTORYRESET: Request Raven to factory reset
     */
    requestActionAndStartPolling = (actionToTake) => {
        let actionSteps = this.state.actionSteps;
        let currentStep = (actionToTake === "LOGREQUEST") ? ACTION_STEP_IDS.REQUEST_LOGS : ACTION_STEP_IDS.REQUEST_ACTION;

        /**
         * Updates the action steps state and triggers polling based on the action to take.
         *      Currently only logs, reboot, and factoryreset actions are supported.
         * @param {any} _ - Placeholder parameter, not used in the function.
         * @param {string|null} errorMessage - Optional error message.
         */
        const updateActionStepsThenPoll = (_, errorMessage = null) => {
            actionSteps = this.state.actionSteps;

            // update log polling step's status icon and supplementary detail
            if (errorMessage) {
                actionSteps[currentStep].status = ACTION_STEP_STATUSES.ERROR;
            } else {
                actionSteps[currentStep].status = ACTION_STEP_STATUSES.COMPLETED;
            }

            this.updateState({actionSteps: actionSteps, message: errorMessage ? errorMessage : '', error: !!errorMessage,
                    callback: this.persistStateInLocalStorage});

            if (actionToTake === "LOGREQUEST") {
                // proceed with log polling regardless of error (timeout for new logs will provide an option to proceed anyway)
                clearInterval(this.queryLogDataInterval); // Make sure to clear any existing intervals before starting a new one, otherwise we'll have some zombies hanging around

                actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.COMPLETED;
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.IN_PROGRESS;

                this.updateState({actionSteps: actionSteps, message: "Logs requested. Waiting for new logs to be uploaded.", 
                    callback: this.persistStateInLocalStorage});

                // start polling for logs. FIXME: seems a bit wasteful to repeatedly pull all logs, something to look into later
                this.queryLogDataInterval = setInterval(this.queryLogData, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);

            } else if (!this.state.actionErrorOccurred) { //Don't start polling if an error occurred
                // proceed with raven action polling
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.IN_PROGRESS;
                this.updateState({actionSteps: actionSteps, message: "Action request sent. Waiting for raven to report a new event.",
                    callback: this.persistStateInLocalStorage});
                
                clearInterval(this.queryRavenEventsInterval); // probably not necessary, but doing it anyway for due diligence
                this.queryRavenEventsInterval = setInterval(this.refreshRavenEvents, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
            }

        };

        actionSteps[currentStep].status = ACTION_STEP_STATUSES.IN_PROGRESS;
        actionSteps[currentStep].requestDatetime = new Date().getTime();
        
        this.updateState({actionSteps: actionSteps, message: "Sending a " + actionToTake + " request to Raven.",
            callback: this.persistStateInLocalStorage});

        const url = this.props.dataStore.prefix2 + "performravenaction"
            + "?stage=" + this.props.stage
            + "&ravenunit=" + this.props.raven['Raven']['Raven UUID']
            + "&action=" + actionToTake
            + "&reason=" + encodeURIComponent(this.props.reason);

        this.fetchAWS(url, updateActionStepsThenPoll, updateActionStepsThenPoll);
    }

    /**
     * Requests the latest logs from the Raven, and checks if the logs are recent enough 
     * TODO: match the logs to the specific action event, not just any recent logs
     * @returns {void}
     */
    queryLogData = () => {
        const { raven } = this.props;
        let actionSteps = this.state.actionSteps;
        if (actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status !== ACTION_STEP_STATUSES.IN_PROGRESS) return;

        if (!raven) {
            this.props.onActionClose();
            return;
        }

        const ravenId = raven.Id;
        const ravenUuid = raven.Raven["Raven UUID"];
        const ravenOSVersion = raven.Build['OS Version'];

        // perform ajax calls to get it the next state
        const url = process.env.REACT_APP_KLOUD_API_BASE_URL + "query/logs"
            + "?stage=" + this.props.stage
            + "&type=tombstones"
            + "&raven=" + ravenId
            + "&uuid=" + ravenUuid
            + "&version=" + ravenOSVersion;

        this.fetchAWS(url,
            this.checkLogData.bind(this),
            (error) => { // onError callback
                actionSteps = this.state.actionSteps;
                actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.ERROR;
                this.updateState({actionSteps: actionSteps, message: "Error querying logs: " + error.message, error: true,
                    callback: this.persistStateInLocalStorage});
            }
        );
    }

    checkForTimeout = () => {
        const now = new Date();
        const { actionSteps } = this.state;
        const logRequestDatetime = this.state.actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].requestDatetime;

        // If the logs timed out, set the status to timeout
        if (now.getTime() - logRequestDatetime > TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS) {
            if (this.state.selectedAction.id === "LOGREQUEST") {
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.ERROR;

                //clean up the interval and abort the fetch
                clearInterval(this.queryLogDataInterval);
                this.controller.abort();

                let message = "Log request stalled. Click 'Cancel' and retry, or click below to try rebooting raven now.";
                this.updateState({actionSteps: actionSteps, message: message, error: true, 
                    timeout: true, callback: this.persistStateInLocalStorage});
                return;
            }
            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.TIMEOUT;
            let message = "Log polling stalled. Click 'Cancel' and retry, or click below to try rebooting raven now.";
            this.updateState({actionSteps: actionSteps, message: message, error: true,
                timeout: true, callback: this.persistStateInLocalStorage});
        }
    }

    /**
     * Scan the logs from the Raven to check for recent tombstone logs.
     * @param {*} tombstones List of the logs from the Raven
     * @returns {void}
     */
    checkLogData(tombstones, errorMessage = null) {
        if (!tombstones || !tombstones.length) {
            this.checkForTimeout(); //Even if we get nothing back, we should check for a timeout
            return; // no logs yet 
        }
        let message = "Logs found. Checking for recent logs from raven.";
        this.updateState({actionSteps: this.state.actionSteps, 
            message: errorMessage ? errorMessage : message,
            error: !!errorMessage,
            callback: this.persistStateInLocalStorage});

        const updateActionStepsForLogStatus = (pollingForRecentLogsStatus, supplementaryDetail = null) => {

            const { actionSteps } = this.state;

            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = pollingForRecentLogsStatus;
            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].supplementaryDetail = supplementaryDetail;

            if (pollingForRecentLogsStatus === ACTION_STEP_STATUSES.IN_PROGRESS) { // if no recent logs are found:

                if (actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.QUEUED) { // if logs not requested yet:
                    this.requestActionAndStartPolling("LOGREQUEST");

                } else  if (this.state.actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].requestDatetime) {
                    this.checkForTimeout();
                }
            } else if (pollingForRecentLogsStatus === ACTION_STEP_STATUSES.COMPLETED) { // if recent logs found:
                // set previous step to request logs as completed (useful if returning to the overlay while global in-progress flag still set)
                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.COMPLETED;
                clearInterval(this.queryLogDataInterval);

                if (this.state.selectedAction.id === "LOGREQUEST") {
                    let message = "Logs uploaded successfully. Log timestamp: " + supplementaryDetail;
                    this.updateState({actionSteps: actionSteps, message: message, 
                        completed: true, callback: this.persistStateInLocalStorage});
                    return;
                } else {
                    this.requestActionAndStartPolling(this.state.selectedAction.id);
                }
            }
        };

        let mostRecentLogsTimestampDescription;

        for (let i = 0; i < tombstones.length; i += 1) {

            if (!tombstones[i].name) { continue; }

            if (tombstones[i].name.includes("logd")) {

                if (!tombstones[i].LastModified) { continue; }

                const tombstoneLastModified = moment.utc(tombstones[i].LastModified);

                if (tombstoneLastModified.isAfter(this.state.actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].requestDatetime)) {

                    mostRecentLogsTimestampDescription = tombstoneLastModified.local().format(globalconfig.display.timeFormat) + " (" + tombstoneLastModified.fromNow() + ")";
                    updateActionStepsForLogStatus(ACTION_STEP_STATUSES.COMPLETED, mostRecentLogsTimestampDescription);
                    return;

                } else {

                    mostRecentLogsTimestampDescription = tombstoneLastModified.local().format(globalconfig.display.timeFormat) + " (" + tombstoneLastModified.fromNow() + ")";
                }
            }
        }

        if (!mostRecentLogsTimestampDescription) {
            this.setState({
                actionStatusMessage: "No recent logs. Please wait for raven to upload logs.  This may take several minutes."
            }, this.persistStateInLocalStorage);
        } else {
            this.setState({
                actionStatusMessage: "Most recent logs are " + mostRecentLogsTimestampDescription + ". Waiting for newer logs."
            }, this.persistStateInLocalStorage);
        }

        updateActionStepsForLogStatus(ACTION_STEP_STATUSES.IN_PROGRESS); // redundant status update (already in progress), used to check if log request necessary and check for timeout
    }

    /**
     * Checks the most recent events for a recent reboot event and updates the action steps accordingly.
     * TODO: Something is causing this to repeatedly check here. Need to figure out why.
     * @returns {void}
     */
    checkMostRecentLoadedRavenBootedEventMoment() {
        const { actionSteps } = this.state;
        let mostRecentRebootTimestampDescription;
        const mostRecentTimeRavenBootedEvent = this.props.mostRecentLoadedRavenBootedEventMoment ? this.props.mostRecentLoadedRavenBootedEventMoment : null;

        if (!this.props.mostRecentLoadedRavenBootedEventMoment) {
            // There are no recent reboot events yet:

            if (this.state.actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                this.setState({
                    actionStatusMessage: "Waiting for raven to report a new reboot event."
                }, this.persistStateInLocalStorage);
            }
            // no return (will check for timeout below)

        } else if (mostRecentTimeRavenBootedEvent.isAfter(actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].requestDatetime)) {
            // Recent reboot event detected and it is recent enough:

            clearInterval(this.queryRavenEventsInterval);

            mostRecentRebootTimestampDescription = this.props.mostRecentLoadedRavenBootedEventMoment.local().format(globalconfig.display.timeFormat) + " (" + this.props.mostRecentLoadedRavenBootedEventMoment.fromNow() + ")";

            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.COMPLETED;
            let message = "Raven " + this.state.selectedAction.label + " successful: " + mostRecentRebootTimestampDescription;
            this.updateState({actionSteps: actionSteps, message: message, 
                completed: true, callback: this.persistStateInLocalStorage});
            return; // reboot was successful

        } else {
            // Recent reboot event detected but it is not recent enough:

            mostRecentRebootTimestampDescription = this.props.mostRecentLoadedRavenBootedEventMoment.local().format(globalconfig.display.timeFormat) + " (" + this.props.mostRecentLoadedRavenBootedEventMoment.fromNow() + ")";

            this.setState({
                actionStatusMessage: "Most recent reboot is " + mostRecentRebootTimestampDescription + ". Waiting for raven to report a newer reboot event."
            }, this.persistStateInLocalStorage);
            // no return (will check for timeout below)
        }

        // at this point, reboot event is still pending (either no event yet, or event is not recent enough):
        const rebootRequestDatetime = this.state.actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].requestDatetime;

        if (rebootRequestDatetime) {

            const now = new Date();

            if (now.getTime() - rebootRequestDatetime > EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS) {

                actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.TIMEOUT;
                let message = "Raven reboot appears to be delayed. The raven may not be connected. Click below to retry.";
                this.updateState({actionSteps: actionSteps, message: message, error: true,
                    timeout: true, callback: this.persistStateInLocalStorage});
            }
        }
    }

    /**
     * Requests events from the Raven to check for a recent reboot event.
     */
    refreshRavenEvents = () => {

        if (this.props.feature) {
            // for Diagnostics component 'Last reboot time' and also related to Actions event polling
            this.props.dataStore.getRavenEvents(this.props.feature);
        }

        let actionSteps = this.state.actionSteps;
        actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.IN_PROGRESS;
        this.updateState({actionSteps: actionSteps, message: "Checking for recent reboot event from raven.",
            callback: this.persistStateInLocalStorage});
    }

    iconElementForStatus = (status) => {

        let statusIconElement;
        if (status === ACTION_STEP_STATUSES.IN_PROGRESS || status === ACTION_STEP_STATUSES.TIMEOUT) {
            statusIconElement = <span className="action-step-status-icon"><ActivityIndicator /></span>;
        } else {
            statusIconElement = <span className="material-icons-outlined action-step-status-icon">{ACTION_STEP_STATUS_ICON_IDS[status]}</span>;
        }
        return statusIconElement;
    }

    /**
     * Handles the user clicking the "Proceed Anyway" button after a timeout or error occurred.
     * This can happen from a log query or poll, or from a reboot query or poll.
     * @returns {void}
     */
    handleProceedAnyway = () => {
        let actionSteps = { ...this.state.actionSteps};
        // If the log request was interrupted before a response was received, proceed with polling for logs. Do not redrive the log request.
        if (actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.ERROR) {
            clearInterval(this.queryLogDataInterval);
            actionSteps[ACTION_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.COMPLETED;
            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.IN_PROGRESS;
            let message = "Log polling skipped at user request.";
            this.updateState({actionSteps: actionSteps, message: message, error: false,
                callback: () => {
                    this.queryLogDataInterval = setInterval(this.queryLogData, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
                }});
        // If the log polling was interrupted before completion, proceed with the action requested
        }else if (actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status === ACTION_STEP_STATUSES.TIMEOUT) {
            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.SKIPPED;
            let message = "Log polling skipped at user request.";
            this.updateState({actionSteps: actionSteps, message: message, error: false,
                callback: this.requestActionAndStartPolling(this.state.selectedAction.id)});
        // If the action request was interrupted before a response was received, proceed with polling for the action. Do not redrive the action request.
        }else if (actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].status === ACTION_STEP_STATUSES.ERROR) {
            actionSteps[ACTION_STEP_IDS.REQUEST_ACTION].status = ACTION_STEP_STATUSES.COMPLETED;
            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.IN_PROGRESS;
            let message = "Action polling skipped at user request.";
            this.updateState({actionSteps: actionSteps, message: message, error: false,
                callback: () => {
                    clearInterval(this.queryRavenEventsInterval);
                    this.queryRavenEventsInterval = setInterval(this.refreshRavenEvents, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
                }});
        }else if (actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status === ACTION_STEP_STATUSES.TIMEOUT) {
            actionSteps[ACTION_STEP_IDS.POLLING_FOR_RECENT_ACTION].status = ACTION_STEP_STATUSES.SKIPPED;
            clearInterval(this.queryRavenEventsInterval);
            let message = "Action polling skipped at user request.";
            this.updateState({actionSteps: actionSteps, message: message, error: false, completed: true,
                callback: this.persistStateInLocalStorage});
        }
    }

    render() {

        let actionMessageClassName = "action-message";
        if (this.state.actionErrorOccurred) {
            actionMessageClassName += " error";
        }

        let proceedAnywayButton = null;
        if (this.state.overridableTimeoutOccurred) {
            proceedAnywayButton = <button className="message-button" onClick={this.handleProceedAnyway}>Proceed Anyway</button>
        }
        
        return (
            <div className="raven-actions-pending-overlay">
                { this.state.actionCompleted ?
                    <header>
                        Action completed
                        <button className="success-button" disabled={this.state.cancelButtonIsDisabled} onClick={this.onClose}>Dismiss</button>
                    </header>
                    :
                    <header>
                        Action in progress
                        <button className="close-button" disabled={this.state.cancelButtonIsDisabled} onClick={this.onClose}>Cancel</button>
                    </header>
                }

                {/** Loop through the action steps and display them */}
                {
                    Object.keys(this.state.actionSteps).map((stepId) => (
                        <div className="action-step" key={stepId}>
                            {this.iconElementForStatus(this.state.actionSteps[stepId].status)}
                            {this.state.actionSteps[stepId].description}
                        </div>
                    ))
                }

                <div className={actionMessageClassName}>
                    <div>{this.state.actionStatusMessage}</div>
                    {proceedAnywayButton}
                </div>
            </div>
        );
    }
}

class ActivityIndicator extends React.PureComponent {
    render() {
        return <div className="activity-indicator"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    }
}
