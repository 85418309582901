import React, { Component } from 'react';
import Modal from 'react-modal';
import Events from '../../../../common/events';
import globalconfig from '../../../../common/config';

import recurlyOptions from './recurlyOptions';

export default class EditBillingModal extends Component {

    constructor(props) {

        super(props);
        this.state = {
            setBillingMessage: null
        };
    }

    afterEditBillingModalOpened = (e) => {

        console.log("Configuring Recurly");

        document.documentElement.classList.add("no-scroll");
        if(window.recurly)
        {
            recurlyOptions.publicKey = globalconfig.recurly.key;
            window.recurly.configure(recurlyOptions);
        }
    }

    onEditBillingSubmit = (e) => {

        //modify billing info
        e.preventDefault();
        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE, { detail: { loadingPageMessage: "Updating Billing Information" } }));
        var form = e.target;
        if(window.recurly)
        {
            window.recurly.token(form, (err, token) => {
                if (err) {
                    console.error(err);
                    this.onEditBillingFail(err);
                } else {
                    console.log(token);
                    this.props.onSuccess("billingtoken", token.id);
                    this.props.closeEditBillingModal();
                }
            });
        }
        else
        {
            this.onEditBillingFail("Unable to loading billing information.  Javascript disabled?" );
        }
    }

    onEditBillingSuccess = (data) => {

        console.log(data);
        var payload = JSON.parse(data.Payload);
        if (payload && payload.status && payload.status === "ERROR") {
            var message = payload && payload.message ? //TODO LOC BILLING
                payload.message :
                "An unknown error occured while trying to save billing information. Please contact support to resolve this issue.";
            this.setState({ setBillingMessage: message }, () => { document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE)); });
        } else {
            document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE, { detail: { loadingPageMessage: "Successfully updated your Billing Information", style: 'success', override: "true" } }));
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }

    onEditBillingFail = (err) => {

        console.error(err);
        document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
        var message = err && err.message ? err.message : "An unknown error occured while trying to save billing information. Please contact support to resolve this issue."; //TODO LOC BILLING
        this.setState({ setBillingMessage: message });
    }

    render() {

        var errorMessage = this.state.setBillingMessage ?
            <p className="error-message"><i className="material-icons">error_outline</i>{this.state.setBillingMessage}</p> :
            null
        // var noBillingMessage = null;
        // if (!this.props.accountDetails || !this.props.accountDetails.billingInfo) {
        //     noBillingMessage = <p className="error-message">There's no billing information associated with this account. <br></br> Please enter it now to continue using your Raven.</p>;
        // }

        return (
            <Modal isOpen={this.props.isOpen}
                contentLabel="edit-billing-modal"
                className="react-modal-content react-modal-sm"
                overlayClassName="react-modal-overlay"
                onRequestClose={this.props.closeEditBillingModal}
                onAfterOpen={this.afterEditBillingModalOpened}>
                <div className="container-fluid form-card">

                    <div className="row">

                        <div className="col-12 content-group">
                            <form className="row" onSubmit={this.onEditBillingSubmit}>

                                <div className="col-12 form-section">

                                    <h2><i className="material-icons">payment</i> Edit Payment Information</h2>

                                    {/*noBillingMessage*/}

                                    <h3>Credit Card Information</h3>

                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="recurly-number">Credit Card Number</label>
                                            <div data-recurly="number" id="recurly-number"></div>
                                        </div>

                                        <div className="col-4 col-sm-3">
                                            <label htmlFor="recurly-cvv">CVV</label>
                                            <div data-recurly="cvv" id="recurly-cvv"></div>
                                        </div>

                                        <div className="col-12 d-md-none d-lg-none d-xl-none"></div>

                                        <div className="col-4 col-md-3">
                                            <label htmlFor="recurly-month">Expiry Month</label>
                                            <div data-recurly="month" id="recurly-month"></div>
                                        </div>

                                        <div className="col-12 d-sm-none d-md-none d-lg-none d-xl-none"></div>

                                        <div className="col-8 col-md-3">
                                            <label htmlFor="recurly-number">Expiry Year</label>
                                            <div data-recurly="year" id="recurly-year"></div>
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <label htmlFor="first-name">First Name</label>
                                            <input id="first-name" type="text" data-recurly="first_name" placeholder="Jane" />
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <label htmlFor="last-name">Last Name</label>
                                            <input type="text" data-recurly="last_name" placeholder="Doe" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 form-section">

                                    <h3>Billing Address</h3>

                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="address1">Street Address Line 1</label>
                                            <input id="address1" type="text" data-recurly="address1" placeholder="123 Raven rd." />
                                            <label htmlFor="address2">Street Address Line 2</label>
                                            <input id="address2" type="text" data-recurly="address2" />
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <label htmlFor="city" >City</label>
                                            <input id="city" type="text" data-recurly="city" placeholder="Ottawa" />
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <label htmlFor="postal_code">ZIP/Postal Code</label>
                                            <input id="postal_code" type="text" data-recurly="postal_code" placeholder="12345/A1A1A1" />
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <label htmlFor="country">Country</label>
                                            <input id="country" type="text" data-recurly="country" placeholder="Canada" />
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <label htmlFor="state">State/Province</label>
                                            <input id="state" type="text" data-recurly="state" placeholder="Ontario" />
                                        </div>

                                        <input type="hidden" name="recurly-token" data-recurly="token" />
                                    </div>
                                </div>

                                <div className="col-12 action-btns">
                                    <button className="btn btn-outline-blue" onClick={this.props.closeEditBillingModal}><i className="material-icons">close</i>Cancel</button>
                                    <button className="btn"><i className="material-icons">check</i>Save</button>
                                    {errorMessage}
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        );
    }
}
