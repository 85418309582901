import React, { Component } from 'react';

import Dialog from '../dialog';
import SupportedPidView from './supportedPidView';

import CognitoUtil from '../../../../aws/cognito/cognitoUtil';

export default class SupportedPidsModal extends Component {

    constructor() {
    
        super();

        this.state = {
            isOpen: false,
            pids: []
        };

        this.localState = {};

    }

    queryPIDData()
    {
        this.props.dataStore.getSupportedPids(this.props.raven);
    }

    componentDidMount() {
        // Set the initial raven id
        this.localState.ravenid = this.props.raven.item.Id;

        this.queryPIDData();
    }

    componentDidUpdate() {

        if(this.props.raven && this.props.raven.item.Id)
        {
            if(this.props.raven.item.Id !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.raven.item.Id;

                this.queryPIDData();
            }
        }
    }

    render() {

        return (
            <Dialog
                size="medium"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.props.raven.pids ?
                                        this.props.raven.pids.length !== 0 ?  
                                            <SupportedPidView 
                                                data={this.props.raven.pids} 
                                                /> : 
                                            <p>No PID Data...</p> :
                                        <p>Loading...</p>
                                }
                            </div>
                        </div>
                    
                    </div>
            </Dialog>
        );
    }

}
