// stateless react component that renders an object
// Takes in the object in the props

import React, { Component } from 'react';
import globalconfig from '../../components/common/config'

export default class JSONView extends Component {

    ravenExternalId = undefined; // workaround added due to difficulties accessing BOTH "SIM" and "Raven" details inside recursive renderPropertyClass calls

    renderPropertyClass = (hierarchy, obj) => {
        var renderedJSXBase = [];
        var renderedJSX = [];

        if(!obj)
        {
            return;
        }
        if(typeof(obj) === 'string')
        {
            return (
                <div>
                    <span className='label'>{obj}</span>
                </div>
            );
        }

        Object.keys(obj).forEach((key) => {

            if (globalconfig.features.ravens.vendorDetailsPreferred) {
                switch (key) {
                    case "Plan":
                    case "Current Billing Period":
                        return;
                    default:
                        break;
                }
            }

            var newhierarchy = hierarchy ? [hierarchy, key] : [key];

            if(typeof(obj[key]) === 'function')
            {
                if(this.props.makeLinks)
                {
                    renderedJSX.push(
                        <div key={key}>
                            <a href="#!" onClick={obj[key]}><span className='label'>{key} </span></a>
                        </div>
                    );
                }
                else
                {
                    renderedJSX.push(this.renderPropertyText(newhierarchy, key, obj[key]()));
                }
            }
            else if(typeof(obj[key]) === 'object')
            {
                if (globalconfig.features.ravens.vendorDetailsPreferred) {
                    if (key === 'SIM') {
                        if (this.ravenExternalId) { // patch to avoid adding more complexity to middleman query(s)
                            const ravenIdName = globalconfig.features.ravens.externalIdOptionalTitle || 'External Id';
                            obj[key][ravenIdName] = this.ravenExternalId;
                        }
                    }
                }
                var content = this.renderPropertyClass(newhierarchy, obj[key]);
                if(content)
                {
                    renderedJSX.push(
                        <div className="row" key={key}><h3>{key}</h3>
                        { content }
                        </div>
                    );
                }
            }
            else
            {
                renderedJSXBase.push(this.renderPropertyText(newhierarchy, key, obj[key]));
            }
        });

        return ( 
            <div className="col-12"> 
                { renderedJSXBase } 
                { renderedJSX } 
            </div>
        );
    }

    compareArrays = (a1, a2) => {
        if(!a1 || !a2)
        {
            return false;
        }

        if(a1.length !== a2.length)
        {
            return false;
        }

        for(var i = 0; i < a1.length; i++)
        {

            /*jslint eqeq: true*/
            if(a1[i] != a2[i])
            {
                return false;
            }
        }

        return true;
    }

    renderPropertyText = (hierarchy, name, value, units) => {

        var renderid;

        if(this.props.renderers)
        {
            for(renderid = 0; renderid < this.props.renderers.length; renderid++)
            {
                var renderer = this.props.renderers[renderid];

                if(this.compareArrays(renderer.key, hierarchy))
                {
                    if(renderer.text)
                    {
                        return (
                            <div className="row json-view-item" key={name}>
                                <span className='col-3 label'><b>{name}: </b></span>
                                <span className='col-9 label json-view-item-value'>{renderer.text(value)}</span>
                            </div>
                        );
                    }
                    else if(renderer.func)
                    {
                        return renderer.func(name, value, units);
                    }
                }
            }
        }

        // Didn't find an existing renderer, default to basic mode

        var text = value;
        if(units)
        {
            text += ' ' + units;
        }
        return (
            <div className="row json-view-item" key={name}>
                <span className='col-3 label'><b>{name}: </b></span>
                <span className='col-9 label json-view-item-value'>{text}</span>
            </div>
        );
    }

    componentWillReceiveProps(nextProps) {
        //if (!this.ravenExternalId) { // workaround added due to difficulties accessing BOTH SIM details and Raven details inside recursive renderPropertyClass calls
            if (nextProps.json && nextProps.json.hasOwnProperty('Raven')) {
                if (nextProps.json.Raven.hasOwnProperty('Raven External Id')) {
                    this.ravenExternalId = nextProps.json.Raven['Raven External Id'];
                }
            }
        //}
        this.renderedProperties = this.renderPropertyClass(null, nextProps.json);
    }

    render() {

        if(!this.props.json)
        {
            return ( <div className="json-view row"></div>);
        }

        return (
            <div className="json-view row">
            { this.renderedProperties }
            </div>
        );
    }
                
}
