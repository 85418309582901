
var theme = 'dark';

var recurlyOptions = {
    fields: {
        all: {
            style: {
                // fontFamily: 'Arial',
                fontSize: '1.2rem',
                fontColor: theme !== 'light' ? '#898989' : 'black',
                fontSmoothing: 'antialiased',
                fontWeight: theme !== 'light' ? '600' : '100',
                // fontVariant: 'small-caps',
                // fontStyle: 'italic',
                lineHeight: '1em',
                height: '1rem',
                placeholder: {
                    opacity: '0.4'
                }
            }
        },
        number: {
            selector: '#recurly-number',
            style: {
                placeholder: {
                    content: '4111 1111 1111 1111'
                }
            }
        },
        month: {
            selector: '#recurly-month',
            style: {
                placeholder: {
                    content: '12'
                }
            }
        },
        year: {
            selector: '#recurly-year',
            style: {
                placeholder: {
                    content: '2018'
                }
            }
        },
        cvv: {
            selector: '#recurly-cvv',
            style: {
                placeholder: {
                    content: '123'
                }
            }
        }
    }
};

export default recurlyOptions = recurlyOptions;
