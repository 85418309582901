import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import "react-table/react-table.css";
import moment from 'moment';

import Dialog from '../dialog';
import globalconfig from '../../../../common/config';
import JSONView from '../../../../widgets/jsonView';

import CognitoUtil from '../../../../aws/cognito/cognitoUtil';

export default class UserViewModal extends Component {

    constructor() {
    
        super();

        this.localState = {
            userid: null,
        };

    }

    queryUserData(userid)
    {
        if(this.props.raven)
            this.props.dataStore.getUserInfo(this.props.raven, userid);

    }

    componentDidMount() {
        this.localState.userid = this.props.routeProps.match.params.userid;

        this.queryUserData(this.localState.userid);
    }

    componentDidUpdate() {

        if(this.props.routeProps.match.params.userid)
        {
            if(this.props.routeProps.match.params.userid !== this.localState.userid)
            {
                this.localState.userid = this.props.routeProps.match.params.userid;

                this.queryUserData(this.localState.userid);
            }
        }
    }

    render() {

        var userdata;

        var stage = this.props.routeProps.match.params.stage;

        if(this.props.raven.users)
            userdata = this.props.raven.users[this.localState.userid];

        if(!userdata)
            userdata = { ravens: [], devices: [] };

        var columns = [
            {
                Header: 'Last Seen',
                accessor: 'ts_heartbeat',
                Cell: props => <span title={props.value}>{ moment(props.value * 1000).format(globalconfig.display.timeFormat) } ( { moment(props.value * 1000).fromNow() } ) </span> ,
                defaultSortDesc: true,
            },
            {
                Header: 'Id',
                accessor: 'ravenuuid',
                maxWidth: 100,
                Cell: props => <Link to= { "/raven/" + stage + "/" + props.value}>{props.original.ravenunitid }</Link>
            },
            {
                Header: 'Name',
                accessor: 'carname',
            },
            {
                Header: 'Type',
                accessor: 'make',
                Cell: props => <span>{ props.original.make } { props.original.model } {props.original.year}</span>,
                maxWidth: 200,
            },
            {
                Header: 'Status',
                accessor: 'ravenstatus',
                Cell: props => <span>{ props.original.ravenstatus } { props.original.ravenunitstatus }</span>,
                maxWidth: 200,
            },
            {
                Header: 'Owner',
                accessor: 'ravenemail',
            },
        ];

        var datasize = userdata.ravens ? userdata.ravens.length : 0;
        var paginate = userdata.ravens ? datasize > this.props.pageSize : false;
        var pagesize = paginate ? this.props.pageSize : datasize;

        /*

            last_seen   1541736562
            locale  en_US
            make    samsung
            model   SM-G965U
            model_version   null
            platform    Android
            platform_version    8.0.0
            raven_app_version   1.3.2
        */

        var columns2 = [
            {
                Header: 'Last Seen',
                accessor: 'last_seen',
                Cell: props => <span>{ moment(props.value * 1000).format("LLLL") } ( { moment(props.value * 1000).fromNow() } ) </span> ,
                defaultSortDesc: true,
            },
            {
                Header: 'Locale',
                accessor: 'locale',
                maxWidth: 100,
            },
            {
                Header: 'Phone',
                accessor: 'make',
                Cell: props => <span>{ props.original.make } { props.original.model } {props.original.model_version} </span> ,
            },
            {
                Header: 'Platform',
                accessor: 'platform',
                Cell: props => <span>{ props.original.platform } {props.original.platform_version} </span> ,
            },
            {
                Header: 'App',
                accessor: 'raven_app_version',
            },
        ];

        var datasize2 = userdata.devices ? userdata.devices.length : 0;
        var paginate2 = userdata.devices ? datasize2 > this.props.pageSize : false;
        var pagesize2 = paginate2 ? this.props.pageSize : datasize2;

        return (
            <Dialog
                size="medium"
                className="userView"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-12">
                            { userdata && userdata.user ? ( 
                                <div id="tombstone-listing" className="tombstone-list">
                                        <h4>Mobile Devices ({userdata.devices ? userdata.devices.length: 0})</h4>
                                        <ReactTable className="-striped" data={userdata.devices}
                                            showPagination={paginate2}
                                            showPageSizeOptions={false}
                                            showPageJump={false}
                                            pageSize={pagesize2}
                                            style={{ height: "20vh" }}
                                            noDataText="No Devices"
                                            defaultSort={[{'id': 'last_seen', 'desc': true }]}
                                            columns={columns2} />
                                        <p></p>
                                        <h4>Ravens on the account ({userdata.ravens ? userdata.ravens.length: 0})</h4>
                                        <ReactTable className="-striped" data={userdata.ravens}
                                            showPagination={paginate}
                                            showPageSizeOptions={false}
                                            showPageJump={false}
                                            pageSize={pagesize}
                                            style={{ height: "35vh" }}
                                            noDataText="No Ravens"
                                            defaultSort={[{'id': 'ts_heartbeat', 'desc': true }]}
                                            columns={columns} />
                                </div>
                                ) : (<p>Loading...</p>) }

                            </div>
                        </div>
                    
                    </div>
            </Dialog>
        );
    }

}

UserViewModal.defaultProps = {
    pageSize: 100,
};

UserViewModal.propTypes = {
    pageSize: PropTypes.number,
}
