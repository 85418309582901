var OBDPids = {
        // Mode 1
        "1":  { 
            // PIDS under Mode 1
            "0": "List of PIDs supported (range 01 to 32)",
            "1": "Status since the last clearing of fault codes",
            "2": "Fault code that caused the recording of 'freeze frame' data",
            "3": "Fuel system status",
            "4": "Engine load calculated in %",
            "5": "Temperature of the engine coolant in °C",
            "6": "Short-term fuel % trim bank 1",
            "7": "Long-term fuel % trim bank 1",
            "8": "Short-term fuel % trim bank 2",
            "9": "Long-term fuel % trim bank 2",
            "10": "Fuel pressure in kPa",
            "11": "Intake manifold absolute pressure in kPa",
            "12": "Engine speed in rpm",
            "13": "Vehicle speed in kph",
            "14": "Timing advance on cylinder 1 in degrees",
            "15": "Intake air temperature in °C",
            "16": "Air flow measured by the flowmeter in g/s",
            "17": "Throttle position in %",
            "18": "Status of the secondary intake circuit",
            "19": "O2 sensor positions bank/sensor",
            "20": "Oxygen sensor volts bank 1 sensor 1",
            "21": "Oxygen sensor volts bank 1 sensor 2",
            "22": "Oxygen sensor volts bank 1 sensor 3",
            "23": "Oxygen sensor volts bank 1 sensor 4",
            "24": "Oxygen sensor volts bank 2 sensor 1",
            "25": "Oxygen sensor volts bank 2 sensor 2",
            "26": "Oxygen sensor volts bank 2 sensor 3",
            "27": "Oxygen sensor volts bank 2 sensor 4",
            "28": "OBD computer specification",
            "29": "O2 sensor positions bank/sensor",
            "30": "Auxiliary input status",
            "31": "Run time since engine start",
            "32": "List of PIDs supported (range 33 to 64)",
            "33": "Distance travelled with MIL on in kms",
            "34": "Relative fuel rail pressure in kPa",
            "35": "Fuel rail pressure in kPa",
            "36": "O2 sensor (extended range) bank 1, sensor 1 (lambda and volts)",
            "37": "O2 sensor (extended range) bank 1, sensor 2 (lambda and volts)",
            "38": "O2 sensor (extended range) bank 1, sensor 3 (lambda and volts)",
            "39": "O2 sensor (extended range) bank 1, sensor 4 (lambda and volts)",
            "40": "O2 sensor (extended range) bank 2, sensor 1 (lambda and volts)",
            "41": "O2 sensor (extended range) bank 2, sensor 2 (lambda and volts)",
            "42": "O2 sensor (extended range) bank 2, sensor 3 (lambda and volts)",
            "43": "O2 sensor (extended range) bank 2, sensor 4 (lambda and volts)",
            "44": "EGR in %",
            "45": "EGR error in %",
            "46": "Evaporation purge in %",
            "47": "Fuel level in %",
            "48": "Number of warning(s) since faults (DTC) were cleared",
            "49": "Distance since faults (DTC) were cleared.",
            "50": "Evaporation system vapour pressure in Pa",
            "51": "Barometic pressure in kPa",
            "52": "O2 sensor (extended range) bank 1, sensor 1 (lambda and volts)",
            "53": "O2 sensor (extended range) bank 1, sensor 2 (lambda and volts)",
            "54": "O2 sensor (extended range) bank 1, sensor 3 (lambda and volts)",
            "55": "O2 sensor (extended range) bank 1, sensor 4 (lambda and volts)",
            "56": "O2 sensor (extended range) bank 2, sensor 1 (lambda and volts)",
            "57": "O2 sensor (extended range) bank 2, sensor 2 (lambda and volts)",
            "58": "O2 sensor (extended range) bank 2, sensor 3 (lambda and volts)",
            "59": "O2 sensor (extended range) bank 2, sensor 4 (lambda and volts)",
            "60": "Catalyst temperature in °C bank 1, sensor 1",
            "61": "Catalyst temperature in °C bank 2, sensor 1",
            "62": "Catalyst temperature in °C bank 1, sensor 2",
            "63": "Catalyst temperature in °C bank 2, sensor 1",
            "64": "List of PIDs supported (range 65 to 96)",
            "65": "Monitor status this drive cycle",
            "66": "Control module voltage in V",
            "67": "Absolute engine load",
            "68": "Equivalent fuel/air mixture request",
            "69": "Relative throttle position in %",
            "70": "Ambient air temperature in °C",
            "71": "Absolute throttle position B in %",
            "72": "Absolute throttle position C in %",
            "73": "Accelerator pedal position D in %",
            "74": "Accelerator pedal position E in %",
            "75": "Accelerator pedal position F in %",
            "76": "Commanded throttle actuator in %",
            "77": "Engine run time since MIL on in min",
            "78": "Engine run time since faults cleared in min",
            "79": "Exteral test equipment no. 1 configuration information",
            "80": "Exteral test equipment no. 2 configuration information",
            "81": "Fuel type used by the vehicle",
            "82": "Ethanol fuel %",
            "83": "Absolute evaporation system vapour pressure in kPa",
            "84": "Evaporation system vapour pressure in Pa",
            "85": "Short-term O2 sensor trim bank 1 and 3",
            "86": "Long-term O2 sensor trim bank 1 and 3",
            "87": "Short-term O2 sensor trim bank 2 and 4",
            "88": "Long-term O2 sensor trim bank 2 and 4",
            "89": "Absolute fuel rail pressure in kPa",
            "90": "Relative accelerator pedal position in %",
            "91": "Battery unit remaining life (hybrid) in %",
            "92": "Engine oil temperature in °C",
            "93": "Fuel injection timing in °",
            "94": "Fuel consumption in litre/hr",
            "95": "Fuel consumption in litre/hr",
            "96": "List of PIDs supported (range 97 to 128)",
            "97": "Driver demand: torque percentage (%)",
            "98": "Final engine torque percentage (%)",
            "99": "Engine torque reference in Nm",
            "100":"Engine torque data in %",
            "101":"Auxiliary inputs / outputs",
            "102":"Flowmeter sensor",
            "103":"Engine water temperature in °C",
            "104":"Air temperature sensor in °C",
            "105":"Commanded EGR and EGR error",
            "106":"Commanded Diesel intake air flow control and relative intake air flow position",
            "107":"Recirculation gas temperature in °C",
            "108":"Commanded throttle actuator control and relative throttle position",
            "109":"Fuel pressure control system",
            "110":"Injection pressure control system",
            "111":"Turbocharger compressor inlet pressure in kPa",
            "112":"Boost pressure control in kPa",
            "113":"Variable Geometry turbo (VGT) control",
            "114":"Wastegate control",
            "115":"Exhaust pressure in kPa",
            "116":"Turbocharger RPM",
            "117":"Turbocharger A temperature in °C",
            "118":"Turbocharger B temperature in °C",
            "119":"Charge air cooler temperature in °C",
            "120":"Exhaust Gas temperature (EGT) Bank 1",
            "121":"Exhaust Gas temperature (EGT) Bank 2",
            "122":"Diesel particulate filter (DPF) bank 1",
            "123":"Diesel particulate filter (DPF) bank 2",
            "124":"Diesel Particulate filter (DPF) temperature",
            "125":"NOx NTE control area status",
            "126":"PM NTE control area status",
            "127":"Engine run time",
            "128":"List of PIDs supported (range 129 to 160)"
        },
        // Mode 5
        "5": {
            // PIDS under Mode 5

            "0": "List of PIDs supported (range 01 to 32)",
            "1": "Rich to lean sensor threshold voltage",
            "2": "Lean to rich sensor threshold voltage",
            "3": "Low voltage used to calculated passage time",
            "4": "High voltage used to calculated passage time",
            "5": "Rich to lean calculated passage time",
            "6": "Lean to rich calculated passage time",
            "7": "Minimum sensor voltage during test cycle",
            "8": "Maximum sensor voltage during test cycle",
            "9": "Time between sensor transitions",
            "10": "Sensor period",
            "11": "Reserved for future use"
        },

        // Mode 6
        "6": {
            // PIDS under Mode 6

            "0":  "List of PIDs supported (range 01h to 20h)",
            "1":  "Exhaust gas sensor bank 1 - sensor 1",
            "2":  "Exhaust gas sensor bank 1 - sensor 2",
            "3":  "Exhaust gas sensor bank 1 - sensor 3",
            "4":  "Exhaust gas sensor bank 1 - sensor 4",
            "5":  "Exhaust gas sensor bank 2 - sensor 1",
            "6":  "Exhaust gas sensor bank 2 - sensor 2",
            "7":  "Exhaust gas sensor bank 2 - sensor 3",
            "8":  "Exhaust gas sensor bank 2 - sensor 4",
            "9":  "Exhaust gas sensor bank 3 - sensor 1",
            "10": "Exhaust gas sensor bank 3 - sensor 2",
            "11": "Exhaust gas sensor bank 3 - sensor 3",
            "12": "Exhaust gas sensor bank 3 - sensor 4",
            "13": "Exhaust gas sensor bank 4 - sensor 1",
            "14": "Exhaust gas sensor bank 4 - sensor 2",
            "15": "Exhaust gas sensor bank 4 - sensor 3",
            "16": "Exhaust gas sensor bank 4 - sensor 4",
            "32": "List of PIDs supported (range 21h to 40h)",
            "33": "Catalytic bank 1",
            "34": "Catalytic bank 2",
            "35": "Catalytic bank 3",
            "36": "Catalytic bank 4",
            "49": "EGR bank 1",
            "50": "EGR bank 2",
            "51": "EGR bank 3",
            "52": "EGR bank 4",
            "53": "VVT bank 1",
            "54": "VVT bank 2",
            "55": "VVT bank 3",
            "56": "VVT bank 4",
            "57": "EVAP (Cap Off / 0.150\")",
            "58": "EVAP (0.090\")",
            "59": "EVAP (0.040\")",
            "60": "EVAP (0.020\")",
            "61": "Flux de purge",
            "64": "List of PIDs supported (range 41h to 60h)",
            "65": "Heated exhaust gas sensor bank 1 - sensor 1",
            "66": "Heated exhaust gas sensor bank 1 - sensor 2",
            "67": "Heated exhaust gas sensor bank 1 - sensor 3",
            "68": "Heated exhaust gas sensor bank 1 - sensor 4",
            "69": "Heated exhaust gas sensor bank 2 - sensor 1",
            "70": "Heated exhaust gas sensor bank 2 - sensor 2",
            "71": "Heated exhaust gas sensor bank 2 - sensor 3",
            "72": "Heated exhaust gas sensor bank 2 - sensor 4",
            "73": "Heated exhaust gas sensor bank 3 - sensor 1",
            "74": "Heated exhaust gas sensor bank 3 - sensor 2",
            "75": "Heated exhaust gas sensor bank 3 - sensor 3",
            "76": "Heated exhaust gas sensor bank 3 - sensor 4",
            "77": "Heated exhaust gas sensor bank 4 - sensor 1",
            "78": "Heated exhaust gas sensor bank 4 - sensor 2",
            "79": "Heated exhaust gas sensor bank 4 - sensor 3",
            "80": "Heated exhaust gas sensor bank 4 - sensor 4",
            "96": "List of PIDs supported (range 61h to 80h)",
            "97": "Heated catalyst bank 1",
            "98": "Heated catalyst bank 2",
            "99": "Heated catalyst bank 3",
            "100":"Heated catalyst bank 4",
            "113":"Secondary air 1",
            "114":"Secondary air 2",
            "115":"Secondary air 3",
            "116":"Secondary air 4",
            "128":"List of PIDs supported (range 81h to A0h)",
            "129":"Fuel system bank 1",
            "130":"Fuel system bank 2",
            "131":"Fuel system bank 3",
            "132":"Fuel system bank 4",
            "133":"Turbo pressure bank 1",
            "134":"Turbo pressure bank 2",
            "144":"Nox Absorber bank 1",
            "145":"Nox Absorber bank 2",
            "152":"Catalytic NOx bank 1",
            "153":"Catalytic NOx bank 2",
            "160":"List of PIDs supported (range A1h to C0h)",
            "161":"General misfire monitoring data",
            "162":"Misfire data cylinder 1",
            "163":"Misfire data cylinder 2",
            "164":"Misfire data cylinder 3",
            "165":"Misfire data cylinder 4",
            "166":"Misfire data cylinder 5",
            "167":"Misfire data cylinder 6",
            "168":"Misfire data cylinder 7",
            "169":"Misfire data cylinder 8",
            "170":"Misfire data cylinder 9",
            "171":"Misfire data cylinder 10",
            "172":"Misfire data cylinder 11",
            "173":"Misfire data cylinder 12",
            "174":"Misfire data cylinder 13",
            "175":"Misfire data cylinder 14",
            "176":"Misfire data cylinder 15",
            "177":"Misfire data cylinder 16",
            "178":"PM filter bank 1",
            "179":"PM filter bank 2",
            "192":"List of PIDs supported (range C1h to E0h)",
            "224":"List of PIDs supported (range E1h to FFh)"
        },

        // Mode 9
        "9": {
            // PIDS under Mode 9
            "0": "List of PIDs supported (range 01h to 20h)",
            "1": "VIN message count",
            "2": "VIN (vehicle identification number)",
            "3": "Calibration ID message count",
            "4": "Calibration IDs",
            "5": "CALIB verification numbers message count",
            "6": "Calibration verification number",
            "7": "IPT message count",
            "8": "In-use performance tracking (IPT)",
            "9": "ECU name message count",
            "10": "ECU name",
            "11": "In-use performance tracking"
        }
    }

export default OBDPids;
