import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import StageSelector from '../../../widgets/stageSelector';

import 'react-select/dist/react-select.css';

export default class Header extends Component {

    componentWillReceiveProps(nextProps) {
        this.ravenList = nextProps.ravenList.map(elem => {
                var obj = elem['account'];
                if(!obj)
                    return null;
                return { value : '' + obj['email'],
                    label: '' + obj['email'],
                    id: obj['accountId'],
                    obj: obj
                };

            });
    }

    onSelectRavenSearch = (val) => {
        this.props.onSelectRavenSearch(val.id);

    }

    render() {

        return (
            <div className="row" id="controls">
                <div className="branding col-2 raven-logo">
                    <Link to="/"></Link>
                </div>
                <div className="viewOptions col-10">
                    <div className="row">
                        <div className="col-3" >
                            <StageSelector {...this.props} />
                        </div>
                        <div className="col-3" >
                            <Select id="ravenSearch" 
                                placeholder="Search..."
                                searchable={true} 
                                options={this.ravenList}  
                                name="ravenSearch" 
                                filterOption={(option, filter) => {
                                    if(!option)
                                        return false;
                                    // Search: ravenid, raven unit id, email, raven enclosure id 
                                    else if(option.obj['accountId'] && option.obj['accountId'].toString().includes(filter))
                                        return true;
                                    else if(option.obj['email'] && option.obj['email'].toString().includes(filter))
                                        return true;
                                    return false;
                                }}
                                onChange={this.onSelectRavenSearch} 
                                clearable={false} />
                        </div>
                        <div className="col-3" >
                            <Link to={"/tool/" + this.props.stage + "/new-fleet-account"} className="Select-Link">New Account</Link>
                        </div>

                    </div>

                </div>
            </div>

        );
    }
}
