
import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import "react-table/react-table.css";

import PIDs from '../../../../common/OBDPids';


export default class SupportedPidView extends Component {

    render() {

        var columns = [
            {
                Header: 'Mode',
                accessor: 'mode',
                maxWidth: 50,
            },
            {
                Header: 'PID',
                accessor: 'pid',
                maxWidth: 50,
            },
            {
                Header: 'Description',
                id: 'description',
                accessor: d => PIDs[d.mode][d.pid]
            },
        ];

        var datasize = this.props.data ? this.props.data.length : 0;
        var paginate = this.props.data ? datasize > this.props.pageSize : false;

        var pagesize = paginate ? this.props.pageSize : datasize;



        return (
            <div id="dtc-listing" className="dtc-list">
                    <ReactTable className="-striped" data={this.props.data}
                        filterable={true}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value) }

                        showPagination={paginate}
                        showPageSizeOptions={false}
                        showPageJump={false}
                        style = {{ height: "65vh"}}
                        pageSize={pagesize}
                        noDataText="No Supported Pids"
                        defaultSorted={[{id: 'mode'}]}
                        columns={columns} />
            </div>
        );
    }
}

SupportedPidView.defaultProps = {
    pageSize: 100,
};

SupportedPidView.propTypes = { 
    pageSize: PropTypes.number,
}

