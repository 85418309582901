import React from 'react';
import PropTypes from 'prop-types';
import RavenDataStore from '../../common/ravenDataStore';
import Header from './panels/Header';
import { ModalContainer, ConfirmationDialog } from '../../modals/Modal2';
import ActiveOrdersTable, { ORDER_STATUS } from './panels/ActiveOrdersTable/ActiveOrdersTable';
import OrderForm, { PRIMARY_SECTION_FOCUS } from './OrderForm';
import supporttoolIconAddCircle from './panels/ActiveOrdersTable/images/supporttool-icon-add-circle.png';
import NewCustomInventoryTable from './../orders/panels/NewCustomInventoryTable/NewCustomInventoryTable';
import globalconfig from '../../common/config';

export const MAIN_FILTER_TYPES = {
    SHOW_ALL_UNFULFILLED: "SHOW_ALL_UNFULFILLED",
    SHOW_ONLY_PENDING_RAVENS: "SHOW_ONLY_PENDING_RAVENS",
    SHOW_ONLY_PENDING_PROVIDER_IDS: "SHOW_ONLY_PENDING_PROVIDER_IDS",
    SHOW_ONLY_PENDING_SHIPMENT: "SHOW_ONLY_PENDING_SHIPMENT",
    SHOW_ONLY_COMPLETED: "SHOW_ONLY_COMPLETED"
}

export const MAIN_FILTER_TYPES_STATUSES = {
    //SHOW_ALL_UNFULFILLED: ["PENDING_RAVENS", "PENDING_SIM_DETAILS", "PENDING_SHIPMENT"], // inefficient, check for !COMPLETED
    SHOW_ONLY_PENDING_RAVENS: "PENDING_RAVENS",
    SHOW_ONLY_PENDING_PROVIDER_IDS: "PENDING_SIM_DETAILS",
    SHOW_ONLY_PENDING_SHIPMENT: "PENDING_SHIPMENT",
    SHOW_ONLY_COMPLETED: "COMPLETED",
}

const GET_ACTIVE_ORDERS_TIMEOUT = 15000;

const PAGE_SIZE = 20;

const INFINITE_SCROLLER_SENSITIVITY_PADDING = 20; // number of pixels before actually reaching the bottom of the page

export default class OrdersPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            stage: undefined,
            mainFilter: MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED,
            urlParamOrderNumber: undefined,
            orders: null,
            checkForMoreOrdersInProgress: false,
            allOrdersLoadedForMainFilter: false,
            ratePlans: undefined,
            itemTypes: undefined,
            getActiveOrdersError: null,
            confirmDialog: null,
            addOrderModal: null,
            addNewRavenIntentoryModal: null
        };

        this.ravenDataStore = new RavenDataStore(() => {});
        this.getActiveOrdersTimeoutId = undefined; // checkForMoreOrdersInProgress set resets correspond to this
        this.ordersTableWrapperRef = React.createRef();
    }

    timeoutGetActiveOrders = () => {
        this.setState({
            getActiveOrdersError: "It is taking too long to load the active orders."
        });
    }

    getUrlParamOrderNumber = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get("orderNumber");
    }

    getOrders = (stage, limit = null, offset = 0, refreshFilterOverride = undefined) => { // 

        if (this.getActiveOrdersTimeoutId !== undefined) return;
        if (this.allOrdersLoadedForMainFilter === true) return;

        this.getActiveOrdersTimeoutId = setTimeout(() => {this.timeoutgetOrders()}, GET_ACTIVE_ORDERS_TIMEOUT);

        this.setState({checkForMoreOrdersInProgress: true});

        let activeOrdersFilterString = "";
        const filter = refreshFilterOverride ? refreshFilterOverride : this.state.mainFilter;
        switch (this.state.mainFilter) {
            case MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED:
                activeOrdersFilterString = "status=PENDING_RAVENS&status=PENDING_SIM_DETAILS&status=PENDING_SHIPMENT";
                break;
            default:
                activeOrdersFilterString = "status=" + MAIN_FILTER_TYPES_STATUSES[this.state.mainFilter];
                break;
        }
        if (limit) {
            activeOrdersFilterString += "&limit=" + limit;
            activeOrdersFilterString += "&skip=" + offset; // is it called "skip" b/c MongoDB?
        }

        Promise.all([

            this.ravenDataStore.getRatePlans(stage)
            .then(
                (ratePlans) => {
                    this.setState({
                        ratePlans: ratePlans // [{"id":"1","name":"3 year"},{"id":"2","name":"MTM"}]
                    })
                    return Promise.resolve();
                },
                (error) => {                
                    this.setState({
                        getActiveOrdersError: error && error.message ? error.message : "An error occurred"
                    });
                }
            ),

            this.ravenDataStore.getItemTypes(stage)
            .then(
                (itemTypes) => {
                    this.setState({
                        itemTypes: itemTypes // [{"id":"RAVEN","name":"string","category":"DEVICE"}]
                    })
                    return Promise.resolve();
                },
                (error) => {                
                    this.setState({
                        getActiveOrdersError: error && error.message ? error.message : "An error occurred"
                    });
                }
            )
        ])// Promoise.all
        .then(
            () => {
                return this.ravenDataStore.listOrders(stage, activeOrdersFilterString)
            },
            (error) => {
                console.warn("ordersPage unknown error getting rate plans and/or items types");
                console.error(error);
            }
        )
        .then(
            (orders) => {
                const urlParamOrderNumber = this.getUrlParamOrderNumber();
                let urlParamOrderCorrespondingMainFilter = null;
                if (urlParamOrderNumber) {
                    for (var i in orders) {
                        if (orders[i].orderNumber === urlParamOrderNumber) {
                            switch (orders[i].status) {
                                case ORDER_STATUS.NEW:
                                    urlParamOrderCorrespondingMainFilter = MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED;
                                    break;
                                case ORDER_STATUS.PENDING_RAVENS:
                                    urlParamOrderCorrespondingMainFilter = MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_RAVENS;
                                    break;
                                case ORDER_STATUS.PENDING_SIM_DETAILS:
                                    urlParamOrderCorrespondingMainFilter = MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_PROVIDER_IDS;
                                    break;
                                case ORDER_STATUS.PENDING_SHIPMENT:
                                    urlParamOrderCorrespondingMainFilter = MAIN_FILTER_TYPES.SHOW_ONLY_PENDING_SHIPMENT;
                                    break;
                                case ORDER_STATUS.COMPLETED:
                                    urlParamOrderCorrespondingMainFilter = MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED;
                                    break;
                                default:
                                    console.error("orderPage getActiveOrders ravenDataStore.listOrders order has invalid status, " + orders[i].status);
                                    break;
                            }
                            break; // break for loop
                        }
                    }
                }

                if (urlParamOrderNumber) {
                    this.setState({
                        urlParamOrderNumber: urlParamOrderNumber
                    })
                }
                if (urlParamOrderCorrespondingMainFilter) {
                    this.setState({
                        mainFilter: urlParamOrderCorrespondingMainFilter
                    })
                }
                if (orders.length === 0) {
                    this.setState({allOrdersLoadedForMainFilter: true});
                }
                const currentOrders = this.state.orders ? this.state.orders : [];
                this.setState({
                    orders: currentOrders.concat(orders),
                    getActiveOrdersError: null
                });
            },
            (error) => {
                this.setState({
                    getActiveOrdersError: error && error.message ? error.message : "An error occurred"
                })
            }
        )
        .then(
            () => {
                clearTimeout(this.getActiveOrdersTimeoutId);
                this.getActiveOrdersTimeoutId = undefined;
                this.setState({checkForMoreOrdersInProgress: false});
            }
        )
    }

    updateOrderStatusNext = (orderNumber) => {
        if (!orderNumber) {
            console.error("OrdersPage updateOrderStatusNext invalid or missing orderNumber", orderNumber);
            return;
        }

        let orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

        let localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            console.error("OrdersPage updateOrderStatusNext order not found in state.");
            return;
        }

        let order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

        order.pending = true;
        orders[localOrderIndex] = order;
        this.setState({
            orders: orders
        });

        this.ravenDataStore.updateOrderStatusNext(this.state.stage, orderNumber)
        .then(
            (updatedOrder) => {
                // refresh all known details
                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    console.error("OrdersPage updateOrderStatusNext ravenDataStore order no longer found in state.");
                    return;
                }
                orders[localOrderIndex] = updatedOrder;
                this.setState({
                    orders: orders
                })
            },
            (error) => {
                console.log("OrdersPage updateOrderStatusNext error occurred");
            }
        );
    }

    updateOrderStatusBack = (orderNumber) => {
        if (!orderNumber) {
            console.error("OrdersPage updateOrderStatusBack invalid or missing orderNumber", orderNumber);
            return;
        }

        let orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

        let localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            console.error("OrdersPage updateOrderStatusBack order not found in state.");
            return;
        }

        let order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

        order.pending = true;
        orders[localOrderIndex] = order;
        this.setState({
            orders: orders
        });

        this.ravenDataStore.updateOrderStatusBack(this.state.stage, orderNumber)
        .then(
            (updatedOrder) => {
                // refresh all known details
                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    console.error("OrdersPage updateOrderStatusBack ravenDataStore order no longer found in state.");
                    return;
                }
                orders[localOrderIndex] = updatedOrder;
                this.setState({
                    orders: orders
                })
            },
            (error) => {
                console.log("OrdersPage updateOrderStatusBack error occurred");
            }
        );
    }

    updateOrderItem = (orderItemUpdateDetails) => {
        /* {
            orderId: this.props.item.orderId,
            orderItemId: orderItemId,
            externalId: externalId
        } */

        const orderId = orderItemUpdateDetails.orderId;
        if (!orderId) {
            console.error("OrdersPage updateOrderItem orderItemUpdateDetails invalid or missing orderId", orderItemUpdateDetails);
            return;
        }

        const orderItemId = orderItemUpdateDetails.orderItemId;
        if (!orderItemId) {
            console.error("OrdersPage updateOrderItem orderItemUpdateDetails invalid or missing orderItemId", orderItemUpdateDetails);
            return;
        }

        const ravenUnit = orderItemUpdateDetails.ravenUnit? orderItemUpdateDetails.ravenUnit : {};
        let unitSerial = ravenUnit.serial;
        if (ravenUnit) {
            unitSerial = ravenUnit.serial;
        }

        const externalId = orderItemUpdateDetails.externalId;

        const ratePlanId = orderItemUpdateDetails.hasOwnProperty("ratePlanId") ? orderItemUpdateDetails.ratePlanId : undefined;

        if (!unitSerial && !externalId && !ratePlanId) {
            console.error("OrdersPage updateOrderItem orderItemUpdateDetails no valid updates provided", orderItemUpdateDetails);
            return;
        }

        const updateDelta = {};
        if (unitSerial) {
            updateDelta.ravenUnit = {serial: unitSerial};
        }
        if (externalId) {
            updateDelta.externalId = externalId;
        }
        if (ratePlanId !== undefined) {
            let ratePlanIds = ratePlanId.split("."); // "<ratePlanId>.<termId>" // RAV-2762 rush job
            if (ratePlanIds.length === 2) {
                updateDelta.ratePlanId = ratePlanIds[0];
                updateDelta.rateTermId = ratePlanIds[1];
            } else {
                // backwards compatibility
                updateDelta.ratePlanId = ratePlanId;
            }
        }

        let orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

        let localOrderIndex = orders.findIndex((order) => order.orderId === orderId);
        if (localOrderIndex === -1) {
            console.error("OrdersPage updateOrderItem order not found in state.");
            return;
        }

        let order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

        let localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
        if (localOrderItemIndex === -1) {
            console.error("OrdersPage updateOrderItem order's item not found in state's order.", order);
            return;
        }

        order.items[localOrderItemIndex].pending = true;
        orders[localOrderIndex] = order;

        this.setState({
            orders: orders
        });
        this.ravenDataStore.updateOrderItem(this.state.stage, orderId, orderItemId, updateDelta)
        .then(
            (updatedOrderItem) => {
                // refresh all known details
                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                localOrderIndex = orders.findIndex((order) => order.orderId === orderId);
                if (localOrderIndex === -1) {
                    console.error("OrdersPage updateOrderItem ravenDataStore order no longer found in state.");
                    return;
                }

                order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

                localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                if (localOrderItemIndex === -1) {
                    console.error("OrdersPage updateOrderItem order's item no longer found in state's order.", order);
                    return;
                }
        
                //updatedOrderItem.pending = false;
                order.items[localOrderItemIndex] = updatedOrderItem;
                orders[localOrderIndex] = order;
                this.setState({
                    orders: orders
                });
            },
            (error) => {
                console.log("OrdersPage updateOrderItem error occurred:");
                console.error(error);

                // refresh all known details
                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                localOrderIndex = orders.findIndex((order) => order.orderId === orderId);
                if (localOrderIndex === -1) {
                    console.error("OrdersPage updateOrderItem ravenDataStore order no longer found in state.");
                    return;
                }

                order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

                localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                if (localOrderItemIndex === -1) {
                    console.error("OrdersPage updateOrderItem order's item no longer found in state's order.", order);
                    return;
                }

                order.items[localOrderItemIndex].pending = false;

                if (!order.items[localOrderItemIndex].errors) {
                    order.items[localOrderItemIndex].errors = {};
                }
                switch (error.code) {
                    case 404:
                        if (updateDelta.ravenUnit) {
                            if (updateDelta.ravenUnit.serial) {
                                order.items[localOrderItemIndex].errors.unitSerial = "Unknown serial number (404)";
                                orders[localOrderIndex] = order;
                                this.setState({orders: orders});
                                return;
                            }
                        }
                        break;
                    case 422:
                        if (updateDelta.ravenUnit) {
                            if (updateDelta.ravenUnit.serial) {
                                order.items[localOrderItemIndex].errors.unitSerial = "Invalid serial number";
                                orders[localOrderIndex] = order;
                                this.setState({orders: orders});
                                return;
                            }
                        }
                        if (updateDelta.externalId) {
                            order.items[localOrderItemIndex].errors.externalId = "Invalid id";
                            orders[localOrderIndex] = order;
                            this.setState({orders: orders});
                            return;
                        }
                    default:
                        break;
                }
                if (updateDelta.ravenUnit) {
                    if (updateDelta.ravenUnit.serial) {
                        order.items[localOrderItemIndex].errors.unitSerial = "An error occurred";
                    }
                    if (updateDelta.ravenUnit.iccid) {
                        order.items[localOrderItemIndex].errors.iccid = "An error occurred";
                    }
                    if (updateDelta.ravenUnit.imei) {
                        order.items[localOrderItemIndex].errors.imei = "An error occurred";
                    }
                }
                if (updateDelta.externalId) {
                    order.items[localOrderItemIndex].errors.externalId = "An error occurred";
                }
                orders[localOrderIndex] = order;
                this.setState({orders: orders});
            }
        );
    }

    setOrderItem = (orderId, orderItemId, orderItem) => {

        if (!orderId) {
            console.error("OrdersPage setOrderItem orderId invalid or missing", orderId);
            return;
        }

        if (!orderItemId) {
            console.error("OrdersPage setOrderItem orderItemId invalid or missing", orderItemId);
            return;
        }

        let orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

        let localOrderIndex = orders.findIndex((order) => order.orderId === orderId);
        if (localOrderIndex === -1) {
            console.error("OrdersPage setOrderItem order not found in state.");
            return;
        }

        let order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

        let localOrderItemIndex = order.items.findIndex((orderItemFromState) => orderItemFromState.orderItemId === orderItemId);
        if (localOrderItemIndex === -1) {
            console.error("OrdersPage setOrderItem order's item not found in state's order.", order);
            return;
        }

        order.items[localOrderItemIndex].pending = true;
        orders[localOrderIndex] = order;

        this.setState({
            orders: orders
        });
        this.ravenDataStore.setOrderItem(this.state.stage, orderId, orderItemId, orderItem)
        .then(
            (updatedOrderItem) => {
                // refresh all known details
                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                localOrderIndex = orders.findIndex((order) => order.orderId === orderId);
                if (localOrderIndex === -1) {
                    console.error("OrdersPage setOrderItem ravenDataStore order no longer found in state.");
                    return;
                }

                order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

                localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                if (localOrderItemIndex === -1) {
                    console.error("OrdersPage setOrderItem order's item no longer found in state's order.", order);
                    return;
                }
        
                //updatedOrderItem.pending = false;
                order.items[localOrderItemIndex] = updatedOrderItem;
                orders[localOrderIndex] = order;
                this.setState({
                    orders: orders
                });
            },
            (error) => {
                console.log("OrdersPage setOrderItem error occurred:");
                console.error(error);

                // refresh all known details
                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                localOrderIndex = orders.findIndex((order) => order.orderId === orderId);
                if (localOrderIndex === -1) {
                    console.error("OrdersPage setOrderItem ravenDataStore order no longer found in state.");
                    return;
                }

                order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

                localOrderItemIndex = order.items.findIndex((orderItemFromState) => orderItemFromState.orderItemId === orderItemId);
                if (localOrderItemIndex === -1) {
                    console.error("OrdersPage setOrderItem order's item no longer found in state's order.", order);
                    return;
                }

                order.items[localOrderItemIndex].pending = false;

                if (!order.items[localOrderItemIndex].errors) {
                    order.items[localOrderItemIndex].errors = {};
                }
                // Currently, there is no way to display a general error for the entire order item row
                // Since only unitSerial and externalIds use setOrderItem at the moment, using their error items for now
                switch (error.code) {
                    case 404:
                        order.items[localOrderItemIndex].errors.unitSerial = "Unknown item (404)";
                        order.items[localOrderItemIndex].errors.externalId = "Unknown item (404)";
                        orders[localOrderIndex] = order;
                        this.setState({orders: orders});
                        return;
                    case 422:
                        order.items[localOrderItemIndex].errors.unitSerial = "Invalid item (404)";
                        order.items[localOrderItemIndex].errors.externalId = "Invalid item (404)";
                        orders[localOrderIndex] = order;
                        this.setState({orders: orders});
                        return;
                    default:
                        break;
                }
                order.items[localOrderItemIndex].errors.unitSerial = "An error occurred";
                order.items[localOrderItemIndex].errors.externalId = "An error occurred";
                orders[localOrderIndex] = order;
                this.setState({orders: orders});
            }
        );
    }

    componentDidMount() {

        const stageUrlParam = this.props.match.params.stage;
        this.setState({
            stage: stageUrlParam
        });
        this.getOrders(stageUrlParam);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const stageUrlParam = this.props.match.params.stage;
        if (stageUrlParam !== this.state.stage) {
            this.setState({
                stage: stageUrlParam,
                orders: [],
                mainFilter: this.state.mainFilter === MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED ? MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED : this.state.mainFilter,
                checkForMoreOrdersInProgress: false,
                allOrdersLoadedForMainFilter: false
            });
            this.getOrders(stageUrlParam, null, 0, MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED); // basic getOrders(stage) with refreshFilterOverride
        } else {
            if (this.state.mainFilter !== prevState.mainFilter) {
                this.setState({allOrdersLoadedForMainFilter: false});
                this.checkIfOrdersTableWrapperBottomVisible();
            }
        }
    }

    onRavenStageChanged = (stage) => {
        this.setState({
            stage: stage,
            orders: [],
            mainFilter: this.state.mainFilter === MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED ? MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED : this.state.mainFilter,
            checkForMoreOrdersInProgress: false,
            allOrdersLoadedForMainFilter: false
        });
        this.props.history.push("/orders/" + stage);
        this.getOrders(stage, null, 0, MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED); // basic getOrders(stage) with refreshFilterOverride
    }

    onMainFilterClick = (filter) => {
        if (!MAIN_FILTER_TYPES.hasOwnProperty(filter)) {
            console.log("OrdersPage onMainFilterClick invalid main filter name: " + filter);
            return;
        }
        this.setState({mainFilter: filter});
    }

    onClickAddOrder = () => {

        const dismissModal = () => {
            this.setState({addOrderModal: null});    
        };

        const cancelAndClearForm = () => {

            const onClickConfirm = () => {
                this.setState({
                    addOrderModal: null,
                    confirmDialog: null
                })
            }
            const onClickBack = () => {
                this.setState({
                    confirmDialog: null
                })            
            }
    
            this.setState({
                confirmDialog: <ConfirmationDialog
                        title="Clear Order Form?"
                        message="Any changes in this new order form will be lost."
                        cancelButtonAction={onClickBack}
                        cancelButtonTitle="Go Back"
                        submitButtonAction={onClickConfirm}
                        submitButtonTitle="Discard Any Unsaved Changes"
                    />
            });
    
        }
    
        this.setState({
            addOrderModal: <AddOrderForm
                stage={this.state.stage}
                ratePlans={this.state.ratePlans}
                itemTypes={this.state.itemTypes}
                onCancel={cancelAndClearForm}
                onDismiss={dismissModal}
                onSuccess={() => {
                    this.setState({
                        orders: null,
                        checkForMoreOrdersInProgress: false,
                        allOrdersLoadedForMainFilter: false
                    });            
                    this.getOrders(this.state.stage, null, 0, MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED); // basic getOrders(stage) with refreshFilterOverride
                    dismissModal();
                }}
            />
        });
    }

    showEditOrderForm = (order, primarySectionFocus) => {

        const dismissModal = () => {
            this.setState({addOrderModal: null});    
        };

        const cancelAndClearForm = () => {

            const onClickConfirm = () => {
                this.setState({
                    addOrderModal: null,
                    confirmDialog: null
                })
            }
            const onClickBack = () => {
                this.setState({
                    confirmDialog: null
                })            
            }
    
            this.setState({
                confirmDialog: <ConfirmationDialog
                        title="Dismiss Order Changes?"
                        message="Any changes in this order will be lost."
                        cancelButtonAction={onClickBack}
                        cancelButtonTitle="Go Back"
                        submitButtonAction={onClickConfirm}
                        submitButtonTitle="Dismiss Changes"
                    />
            });
    
        }
    
        this.setState({
            addOrderModal: <EditOrderForm
                stage={this.props.match.params.stage}
                orderForEditMode={order}
                primarySectionFocus={primarySectionFocus}
                ratePlans={this.state.ratePlans}
                itemTypes={this.state.itemTypes}
                onCancel={cancelAndClearForm}
                onDismiss={dismissModal}
                onSuccess={() => {
                    this.setState({
                        orders: null,
                        checkForMoreOrdersInProgress: false,
                        allOrdersLoadedForMainFilter: false
                    });            
                    this.getOrders(this.state.stage, null, 0, MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED); // getOrders(stage) with refreshFilterOverride
                    dismissModal();
                }}
            />
        });
    }

    onEditAccountClick = (order) => {
        this.showEditOrderForm(order, PRIMARY_SECTION_FOCUS.RAVEN_ACCOUNT);
    }

    onEditOrderClick = (order) => {
        this.showEditOrderForm(order, PRIMARY_SECTION_FOCUS.ORDER);
    }

    onEditShippingAddressClick = (order) => {
        this.showEditOrderForm(order, PRIMARY_SECTION_FOCUS.SHIPPING);
    }

    onAddOrderItemClick = (orderNumber, addItemType = "RAVEN_PLUS") => {

        if (!orderNumber) {
            console.error("OrdersPage onAddOrderItemClick invalid or missing orderNumber", orderNumber);
            return;
        }

        const onClickConfirm = () => {

            let orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

            let localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
            if (localOrderIndex === -1) {
                console.error("OrdersPage onAddOrderItemClick order not found in state.");
                return;
            }
    
            let order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)
    
            order.pending = true;
            orders[localOrderIndex] = order;
            this.setState({
                confirmDialog: null,
                orders: orders
            });

            this.ravenDataStore.addOrderItem(this.state.stage, orderNumber, addItemType)
            .then(
                (newOrderItem) => {
                    // refresh all known details
                    orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))
    
                    localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
                    if (localOrderIndex === -1) {
                        console.error("OrdersPage onAddOrderItemClick ravenDataStore order no longer found in state.");
                        return;
                    }
    
                    order = JSON.parse(JSON.stringify(orders[localOrderIndex]));
    
                    order.items.push(newOrderItem);
    
                    order.pending = false;
    
                    const quantity = orders[localOrderIndex].quantity ? orders[localOrderIndex].quantity : 0;
                    order.quantity = quantity + 1;
    
                    orders[localOrderIndex] = order;
    
                    this.setState({
                        orders: orders
                    })
                },
                (error) => {
                    console.log("OrdersPage onAddOrderItemClick error occurred");
                }
            );
        }

        const onClickCancel = () => {
            this.setState({
                confirmDialog: null
            });
        }

        const localOrderIndex = this.state.orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            console.error("OrdersPage onAddOrderItemClick order not found in state.");
            return;
        }

        const order = this.state.orders[localOrderIndex];
        const totalDevices = this.totalItemsForCategory(order.items, "DEVICE");

        this.setState({
            confirmDialog: <ConfirmationDialog
                    title="Add a New Unit to This Order?"
                    message={"Order #" + order.orderNumber + " for account #" + order.account.externalId + " currently has " + totalDevices + " item(s).  Click 'Add Item' below to add another item."}
                    cancelButtonAction={onClickCancel}
                    cancelButtonTitle="Cancel"
                    submitButtonAction={onClickConfirm}
                    submitButtonTitle="Add Item"
                />
        });
    }

    addOrderItem = (orderNumber, addItemType = "RAVEN_PLUS", updateOrderPendingState = true) => { // updateOrderPendingState false is useful for batch requests for a single order
        let orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

        let localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            console.error("OrdersPage addOrderItem order not found in state.");
            const error = new Error("Order not found in this table. Please reload page.");
            error.orderNumber = orderNumber;
            return Promise.reject(error);
        }

        let order = orders[localOrderIndex]; // not a deep copy

        if (updateOrderPendingState) {
            order.pending = true;

            orders[localOrderIndex] = order;
            this.setState({
                confirmDialog: null,
                orders: orders
            });    
        }

        this.setState({
            confirmDialog: null
        });

        return this.ravenDataStore.addOrderItem(this.state.stage, orderNumber, addItemType)
        .then(
            (newOrderItem) => {
                // refresh all known details
                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    console.error("OrdersPage addOrderItem ravenDataStore order no longer found in state.");
                    const error = new Error("Order no longer found in this table. Please reload page.");
                    error.orderNumber = orderNumber;
                    return Promise.reject(error);
                }

                order = orders[localOrderIndex]; // not a deep copy

                order.items.push(newOrderItem);

                if (updateOrderPendingState) {
                    order.pending = false;
                }

                const quantity = orders[localOrderIndex].quantity ? orders[localOrderIndex].quantity : 0;
                order.quantity = quantity + 1;

                orders[localOrderIndex] = order;

                this.setState({
                    orders: orders
                })
                return Promise.resolve(newOrderItem.orderItemId);
            },
            (error) => {
                const returnError = error ? error : new Error("An error occurred");
                returnError.orderNumber = orderNumber;
                return Promise.reject(returnError);
            }
        );
    }

    onRemoveOrderItemClick = (orderNumber, orderItemId) => {

        if (!orderNumber) {
            console.error("OrdersPage onRemoveOrderItemClick invalid or missing orderNumber", orderNumber);
            return;
        }

        const onClickConfirm = () => {

            let orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

            let localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
            if (localOrderIndex === -1) {
                console.error("OrdersPage onRemoveOrderItemClick order not found in state.");
                return;
            }

            let order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

            let localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
            if (localOrderItemIndex === -1) {
                console.error("OrdersPage onRemoveOrderItemClick order's item not found in state's order.", order, orderItemId);
                return;
            }

            order.items[localOrderItemIndex].pending = true;
            orders[localOrderIndex] = order;

            this.setState({
                confirmDialog: null,
                orders: orders
            });

            this.ravenDataStore.removeOrderItem(this.state.stage, orderNumber, orderItemId)
            .then(
                () => {
                    // refresh all known details
                    orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                    localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
                    if (localOrderIndex === -1) {
                        console.error("OrdersPage onRemoveOrderItemClick ravenDataStore order no longer found in state.");
                        return;
                    }

                    order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

                    localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                    if (localOrderItemIndex === -1) {
                        console.error("OrdersPage onRemoveOrderItemClick order's item no longer found in state's order.", order);
                        return;
                    }
            
                    //updatedOrderItem.pending = false;
                    order.items.splice(localOrderItemIndex, 1); // remove the deleted item from the local state

                    const quantity = orders[localOrderIndex].quantity ? orders[localOrderIndex].quantity : 0;
                    order.quantity = quantity? quantity - 1 : 0;

                    orders[localOrderIndex] = order;
                    this.setState({
                        orders: orders
                    });
                },
                (error) => {
                    console.log("OrdersPage onRemoveOrderItemClick error occurred:");
                    console.error(error);

                    // refresh all known details
                    orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                    localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
                    if (localOrderIndex === -1) {
                        console.error("OrdersPage onRemoveOrderItemClick ravenDataStore order no longer found in state.");
                        return;
                    }

                    order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

                    localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                    if (localOrderItemIndex === -1) {
                        console.error("OrdersPage onRemoveOrderItemClick order's item no longer found in state's order.", order);
                        return;
                    }

                    order.items[localOrderItemIndex].pending = false;

                    if (!order.items[localOrderItemIndex].errors) {
                        order.items[localOrderItemIndex].errors = {};
                    }

                    order.items[localOrderItemIndex].errors.delete = "An error occurred";

                    orders[localOrderIndex] = order;
                    this.setState({orders: orders});
                }
            );
        }

        const onClickCancel = () => {
            this.setState({
                confirmDialog: null
            });
        }

        const localOrderIndex = this.state.orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            console.error("OrdersPage onRemoveOrderItemClick order not found in state.");
            return;
        }

        const order = this.state.orders[localOrderIndex];

        const localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
        if (localOrderItemIndex === -1) {
            console.error("OrdersPage onRemoveOrderItemClick order's item not found in state's order.", order, orderItemId);
            return;
        }

        const totalDevices = this.totalItemsForCategory(order.items, "DEVICE");

        this.setState({
            confirmDialog: <ConfirmationDialog
                    title="Remove From This Order?"
                    message={"Do you want to delete row #" + (localOrderItemIndex + 1) + " in order #" + order.orderNumber + " for account #" + order.account.externalId + "?  (Order #" + order.orderNumber + " currently has " + totalDevices + " item(s).)  This action cannot be undone.  Click 'Remove Item' below to remove it from the order."}
                    cancelButtonAction={onClickCancel}
                    cancelButtonTitle="Cancel"
                    submitButtonAction={onClickConfirm}
                    submitButtonTitle="Remove Item"
                />
        });
    }

    removeOrderItem = (orderNumber, orderItemId,  updateOrderItemPendingState = true, setParentOrderPending = false) => {

        let orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

        let localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            console.error("OrdersPage removeOrderItemClick order not found in state.");
            const error = new Error("Order not found in this table. Please reload page.");
            error.orderNumber = orderNumber;
            error.orderItemId = orderItemId;
            return Promise.reject(error);
        }

        let order = orders[localOrderIndex]; // not a deep copy

        let localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
        if (localOrderItemIndex === -1) {
            console.error("OrdersPage removeOrderItemClick order's item not found in state's order.", order, orderItemId);
            const error = new Error("Order's item not found in this table. Please reload page.");
            error.orderNumber = orderNumber;
            error.orderItemId = orderItemId;
            return Promise.reject(error);
        }


        if (updateOrderItemPendingState) {
            order.items[localOrderItemIndex].pending = true;
            if (setParentOrderPending) {
                order.pending = true;
            }
            orders[localOrderIndex] = order;

            this.setState({
                orders: orders
            });    
        }

        return this.ravenDataStore.removeOrderItem(this.state.stage, orderNumber, orderItemId)
        .then(
            () => {
                // refresh all known details
                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    console.error("OrdersPage removeOrderItemClick ravenDataStore order no longer found in state.");
                    const error = new Error("Order no longer found in this table. Please reload page.");
                    error.orderNumber = orderNumber;
                    error.orderItemId = orderItemId;
                    return Promise.reject(error);
                }

                order = orders[localOrderIndex]; // not a deep copy

                localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                if (localOrderItemIndex === -1) {
                    const error = new Error("Order's item no longer found in this table. Please reload page.");
                    error.orderNumber = orderNumber;
                    error.orderItemId = orderItemId;
                    return Promise.reject(error);
                }
        
                //updatedOrderItem.pending = false;
                order.items.splice(localOrderItemIndex, 1); // remove the deleted item from the local state

                const quantity = orders[localOrderIndex].quantity ? orders[localOrderIndex].quantity : 0;
                order.quantity = quantity? quantity - 1 : 0;

                if (updateOrderItemPendingState) {
                    if (setParentOrderPending) {
                        order.pending = false;
                    }
                }

                orders[localOrderIndex] = order;
                this.setState({
                    orders: orders
                });
                return Promise.resolve(orderItemId);
            },
            (error) => {
                console.log("OrdersPage removeOrderItemClick error occurred:");
                console.error(error);

                // refresh all known details
                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    console.error("OrdersPage removeOrderItemClick ravenDataStore order no longer found in state.");
                    const returnError = error ? error : new Error("Error occurred and order no longer found in this table. Please reload page.");
                    returnError.orderNumber = orderNumber;
                    returnError.orderItemId = orderItemId;
                    return Promise.reject(returnError);
                }

                order = orders[localOrderIndex]; // not a deep copy

                localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                if (localOrderItemIndex === -1) {
                    const returnError = error ? error : new Error("Error occurred and order's item no longer found in this table. Please reload page.");
                    returnError.orderNumber = orderNumber;
                    returnError.orderItemId = orderItemId;
                    return Promise.reject(returnError);
                }

                order.items[localOrderItemIndex].pending = false;

                if (!order.items[localOrderItemIndex].errors) {
                    order.items[localOrderItemIndex].errors = {};
                }

                order.items[localOrderItemIndex].errors.delete = "An error occurred";

                orders[localOrderIndex] = order;
                this.setState({orders: orders});

                const returnError = error ? error : new Error("Error occurred.");
                returnError.orderNumber = orderNumber;
                returnError.orderItemId = orderItemId;
                return Promise.reject(returnError);
        }
        );

    }

    totalItemsForCategory = (orderItems, itemTypesCategory) => {

        if (!orderItems) {
            return 0;
        }

        if (!this.state.itemTypes) {
            console.warn("ordersPage totalItemsForCategory itemTypes not found in state yet.");
            return 0;
        }

        let totalItems = 0;
        const itemCategoriesByType = {};

        orderItems.forEach( (item) => {

            let itemCategory = undefined;

            if (itemCategoriesByType.hasOwnProperty(item.itemType)) {
                itemCategory = itemCategoriesByType[item.itemType];
            } else {
                this.state.itemTypes.forEach( (itemType) => {
                    if (itemType.id === item.itemType) {
                        itemCategoriesByType[item.itemType] = itemType.category;
                        itemCategory = itemCategoriesByType[item.itemType];
                    }
                });
            }

            if (itemCategory === undefined) {
                console.error("ordersPage totalItemsForCategory order item category not found in itemTypes", item, this.state.itemTypes);
                return;
            }

            if (itemCategory === itemTypesCategory) {
                totalItems += 1;
            }
        });

        return totalItems;
    }

    orderItemIdsForType = (orderItems, itemTypeId) => {
        if (!orderItems) {
            console.warn("ordersPage orderItemIdsForType requested with no orderItems");
            return [];
        }

        const orderItemIds = [];

        orderItems.forEach( (orderItem) => {
            if (orderItem.itemType === itemTypeId) {
                orderItemIds.push(orderItem.orderItemId);
            }
        });

        return orderItemIds;
    }

    onUpdateOrderAccessoriesClick = (orderNumber, itemTypesWithQuantities) => {

        if (!orderNumber) {
            console.error("OrdersPage onUpdateOrderAccessoriesClick invalid or missing orderNumber", orderNumber);
            return;
        }

        const onClickConfirm = () => {

            let orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

            let localOrderIndex = orders.findIndex((order) => order.orderNumber === orderNumber);
            if (localOrderIndex === -1) {
                console.error("OrdersPage onUpdateOrderAccessoriesClick order not found in state.");
                return;
            }

            let order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

            order.pending = true;

            orders[localOrderIndex] = order;
            this.setState({
                confirmDialog: null,
                orders: orders
            });
            
            const concurrentAPIRequests = [];

            const requestShouldUpdatePendingState = false;
            
            itemTypesWithQuantities.forEach( (itemType) => {

                if (itemType.quantity === itemType.quantityFromProps) {
                    return;
                }

                if (itemType.quantity > itemType.quantityFromProps) {

                    // Add more items

                    let totalAddOrderItemRequests = itemType.quantity - itemType.quantityFromProps;

                    while (totalAddOrderItemRequests > 0) {
                        concurrentAPIRequests.push(this.addOrderItem(orderNumber, itemType.id, requestShouldUpdatePendingState));
                        totalAddOrderItemRequests -= 1;
                    }

                    return;
                }

                // Remove items by orderItemId
                let totalRemoveOrderItemRequests = itemType.quantityFromProps - itemType.quantity;
                const orderItemIdsForType = this.orderItemIdsForType(order.items, itemType.id);

                if (orderItemIdsForType.length < totalRemoveOrderItemRequests) {
                    console.error("OrdersPage onUpdateOrderAccessoriesClick not enough orderItemIdsForType");
                    return;
                }

                while (totalRemoveOrderItemRequests > 0) {
                    totalRemoveOrderItemRequests -= 1;
                    const orderItemId = orderItemIdsForType.pop();
                    concurrentAPIRequests.push(this.removeOrderItem(orderNumber, orderItemId, requestShouldUpdatePendingState));
                    
                }
                
            });

            if (concurrentAPIRequests.length === 0) {

                console.warn("OrdersPage onUpdateOrderAccessoriesClick no changes queued.");

                orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)

                order.pending = false;
    
                orders[localOrderIndex] = order;
                this.setState({
                    orders: orders
                });
    
                return;
            }
            Promise.all(concurrentAPIRequests)
            .then((orderItemIds) => {
                setTimeout(() => {
                    orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                    order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)
    
                    order.pending = false;
        
                    orders[localOrderIndex] = order;
                    this.setState({
                        orders: orders
                    });
                }, 1000);

            })
            .catch((error) => {
                setTimeout(() => {
                    orders = [...this.state.orders]; // shallow copy (enough to trigger a state update in setState({orders}))

                    order = JSON.parse(JSON.stringify(orders[localOrderIndex])); // quick deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)
    
                    order.pending = false;
        
                    orders[localOrderIndex] = order;
                    this.setState({
                        orders: orders
                    });
                }, 1000);
            });
        }

        const onClickCancel = () => {
            this.setState({
                confirmDialog: null
            });
        }

        const localOrderIndex = this.state.orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            console.error("OrdersPage onUpdateOrderAccessoriesClick order not found in state.");
            return;
        }

        const order = this.state.orders[localOrderIndex];

        const totalAccessories = this.totalItemsForCategory(order.items, "ACCESSORY");

        let message = "Order #" + order.orderNumber + " for account #" + order.account.externalId + " currently has ";
        if (totalAccessories > 1) {
            message = message + totalAccessories + " accessories.";
        } else {
            message = message + totalAccessories + " accessory.";
        }
        message = message + "  Click 'Update Accessories' below to apply the changes."



        this.setState({
            confirmDialog: <ConfirmationDialog
                    title="Update Order Accessories?"
                    message={message}
                    cancelButtonAction={onClickCancel}
                    cancelButtonTitle="Cancel"
                    submitButtonAction={onClickConfirm}
                    submitButtonTitle="Update Accessories"
                />
        });
    }

    onClickAddInventory = () => {

        const title = "New Custom Inventory";

        const dismissModal = () => {
            this.setState({addNewRavenIntentoryModal: null});    
        };

        const cancelAndClearForm = () => {

            const onClickConfirm = () => {
                this.setState({
                    addNewRavenIntentoryModal: null,
                    confirmDialog: null
                })
            }
            const onClickBack = () => {
                this.setState({
                    confirmDialog: null
                })            
            }
    
            this.setState({
                confirmDialog: <ConfirmationDialog
                        title="Clear Inventory Form?"
                        message="Any changes in this new inventory form will be lost."
                        cancelButtonAction={onClickBack}
                        cancelButtonTitle="Go Back"
                        submitButtonAction={onClickConfirm}
                        submitButtonTitle="Clear Form"
                    />
            });
        };

        this.setState({
            addNewRavenIntentoryModal: (
                <ModalContainer title={title} onClickClose={cancelAndClearForm}>
                    <NewCustomInventoryTable
                        stage={this.props.match.params.stage}
                        onCancel={cancelAndClearForm}
                        onFormSubmit={dismissModal}
                    />
                </ModalContainer>
            )
        })
    }

    checkForMoreOrders = () => {
        let offset = 0;
        if (this.state.orders) {
            switch (this.state.mainFilter) {
                case MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED:
                    offset = this.state.orders.filter(order => order.status !== MAIN_FILTER_TYPES_STATUSES[MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED]).length;
                    break;
                case MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED:
                    offset = this.state.orders.filter(order => order.status === MAIN_FILTER_TYPES_STATUSES[this.state.mainFilter]).length;
                    break;
                default:
                    offset = this.state.orders.filter(order => order.status === MAIN_FILTER_TYPES_STATUSES[this.state.mainFilter]).length;
                    break;
            }
        }

        this.getOrders(this.state.stage, PAGE_SIZE, offset);
    }

    checkIfOrdersTableWrapperBottomVisible = () => {
        const ordersTableWrapperElement = this.ordersTableWrapperRef.current;

        if (!ordersTableWrapperElement) return; // ordersTableWrapper is not

        const ordersTableWrapperBottomYCoordinate = ordersTableWrapperElement.getBoundingClientRect()

        if (ordersTableWrapperBottomYCoordinate.bottom - INFINITE_SCROLLER_SENSITIVITY_PADDING <= window.innerHeight) {
            this.checkForMoreOrders();
        }
    }

    onOrdersTableScroll = (event) => {
        if (!this.state.allOrdersLoadedForMainFilter) {
            this.checkIfOrdersTableWrapperBottomVisible();
        }
    }

    render () {

        return (

            <div className="orders-page" id="orders-page-overflow-scrolling-div-id" onScroll={this.onOrdersTableScroll}>
                <Header
                    ravenStages={ this.props.stages }
                    stage={this.props.match.params.stage}
                    onRavenStageChange={this.onRavenStageChanged}
                    mainFilter={this.state.mainFilter}
                    onMainFilterClick={this.onMainFilterClick}
                    onClickAddInventory={this.onClickAddInventory}
                />

                <section className="main-content" ref={this.ordersTableWrapperRef} >
                    <h1>
                        Orders
                    </h1>
                    <div className="options">
                        <OrdersPageMainFilters mainFilter={this.state.mainFilter} onMainFilterClick={this.onMainFilterClick} />
                        {/* <button className="add-order-icon-only" onClick={this.onClickAddOrder}><img src={supporttoolIconAddCircle} /></button> */}
                        <button className="add-order" onClick={this.onClickAddOrder}>Add New Order</button>
                    </div>
                    <ul className="error-messages">
                    {this.state.getActiveOrdersError ?
                        (<li className="error-message">{this.state.getActiveOrdersError}</li>)
                    :
                        null
                    }
                    </ul>
                    <ActiveOrdersTable
                        stage={this.props.match.params.stage}
                        orders={this.state.orders}
                        ratePlans={this.state.ratePlans}
                        itemTypes={this.state.itemTypes}
                        mainFilter={this.state.mainFilter}
                        totalItemsForCategory={this.totalItemsForCategory}
                        urlParamOrderNumber={this.state.urlParamOrderNumber}
                        onEditOrderClick={this.onEditOrderClick}
                        onEditAccountClick={this.onEditAccountClick}
                        onEditShippingAddressClick={this.onEditShippingAddressClick}
                        onAddOrderItemClick={this.onAddOrderItemClick}
                        onRemoveOrderItemClick={this.onRemoveOrderItemClick}
                        onUpdateOrderAccessoriesClick={this.onUpdateOrderAccessoriesClick}
                        updateOrderItem={this.updateOrderItem}
                        setOrderItem={this.setOrderItem}
                        updateOrderStatusNext={this.updateOrderStatusNext}
                        updateOrderStatusBack={this.updateOrderStatusBack}
                        checkForMoreOrdersInProgress={this.state.checkForMoreOrdersInProgress}
                        allOrdersLoadedForMainFilter={this.state.allOrdersLoadedForMainFilter}
                    />
                </section>
                {this.state.addOrderModal}
                {this.state.addNewRavenIntentoryModal}
                {this.state.confirmDialog}
            </div>
        );

    }
}
class OrdersPageMainFilters extends React.PureComponent {

    static propTypes = {
        mainFilter: PropTypes.string.isRequired,
        onMainFilterClick:  PropTypes.func.isRequired
    };

    render() {
        return (
            <div className="main-filters">

                {Object.keys(MAIN_FILTER_TYPES).map((MAIN_FILTER_TYPE) => {

                    let buttonLabel = "Unknown";
                    switch (MAIN_FILTER_TYPE) { // TODO localize using 
                        case "SHOW_ALL_UNFULFILLED": buttonLabel = "Active"; break;
                        case "SHOW_ONLY_PENDING_RAVENS": buttonLabel = "Pending Assigned Ravens"; break;
                        case "SHOW_ONLY_PENDING_PROVIDER_IDS":
                            buttonLabel = "Pending Provider Ids";
                            if (globalconfig.features.ravens.externalIdOptionalTitle) {
                                buttonLabel = "Pending " + globalconfig.features.ravens.externalIdOptionalTitle;
                            }
                            break;
                        case "SHOW_ONLY_PENDING_SHIPMENT": buttonLabel = "Pending Shipment"; break;
                        case "SHOW_ONLY_COMPLETED": buttonLabel = "Completed"; break;
                    }

                    return (
                        <button name={MAIN_FILTER_TYPE} className={MAIN_FILTER_TYPE === this.props.mainFilter? "active" : null} key={MAIN_FILTER_TYPE} onClick={(event) => {this.props.onMainFilterClick(event.target.name)}}>
                            {buttonLabel}
                        </button>
                    )
                })}

            </div>
        )
    }
}

class AddOrderForm extends React.PureComponent {

    static propTypes = {
        stage: PropTypes.string.isRequired,
        ratePlans: PropTypes.array,
        itemTypes: PropTypes.array,
        onCancel: PropTypes.func.isRequired,
        onDismiss: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);
        this.unsavedEditsFound = false; // prompt on dismiss
        this.reloadOnDismiss = false;
    }

    updateUnsavedEditsFound = (unsavedEditsFound = true) => { // RAV-1843 (adding basic support for ModalContainer's onClickClose to know when edits are present)
        this.unsavedEditsFound = unsavedEditsFound;
    }

    setReloadOnDismiss = (reloadOnDismiss = true) => { // RAV-1843 (adding basic support for ModalContainer's onClickClose to know when a reload is required)
        this.reloadOnDismiss = reloadOnDismiss;
    }

    onCancel = () => {
        if (this.reloadOnDismiss) {
            // ignoring this.unsavedEditsFound because confirm prompt only dismisses (upgrade needed) and unsavedEditsFound usage is currently not granular enough (upgrade needed)
            this.props.onSuccess(); // dismiss and parent reloads
        } else if (this.unsavedEditsFound) {
            this.props.onCancel(); // confirmation prompt
        } else {
            this.props.onDismiss(); // basic dismiss
        }        
    }

    render () {
        const title = "Add New Order";

        return (
            <ModalContainer title={title} onClickClose={this.onCancel} >
                <OrderForm
                    stage={this.props.stage}
                    ratePlans={this.props.ratePlans}
                    itemTypes={this.props.itemTypes}
                    onCancel={this.onCancel}
                    onSuccess={this.props.onSuccess}
                    setReloadOnDismiss={this.setReloadOnDismiss}
                    updateUnsavedEditsFound={this.updateUnsavedEditsFound}
                />
            </ModalContainer>
        )
    }
}

class EditOrderForm extends React.PureComponent {

    static propTypes = {
        stage: PropTypes.string.isRequired,
        orderForEditMode: PropTypes.object.isRequired,
        primarySectionFocus: PropTypes.string.isRequired,
        ratePlans: PropTypes.array,
        itemTypes: PropTypes.array,
        onCancel: PropTypes.func.isRequired,
        onDismiss: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);
        this.unsavedEditsFound = false; // prompt on dismiss
        this.reloadOnDismiss = false;
    }

    updateUnsavedEditsFound = (unsavedEditsFound = true) => { // RAV-1843 (adding basic support for ModalContainer's onClickClose to know when edits are present)
        this.unsavedEditsFound = unsavedEditsFound;
    }

    setReloadOnDismiss = (reloadOnDismiss = true) => { // RAV-1843 (adding basic support for ModalContainer's onClickClose to know when a reload is required)
        this.reloadOnDismiss = reloadOnDismiss;
    }

    onCancel = () => {
        if (this.reloadOnDismiss) {
            // ignoring this.unsavedEditsFound because confirm prompt only dismisses (upgrade needed) and unsavedEditsFound usage is currently not granular enough (upgrade needed)
            this.props.onSuccess(); // dismiss and parent reloads
        } else if (this.unsavedEditsFound) {
            this.props.onCancel(); // confirmation prompt
        } else {
            this.props.onDismiss(); // basic dismiss
        }        
    }

    render () {
        let title = "";
        switch (this.props.primarySectionFocus) {
            case PRIMARY_SECTION_FOCUS.RAVEN_ACCOUNT:
                title = "Account and Order Details"; 
                break;
            case PRIMARY_SECTION_FOCUS.ORDER:
            default:
                title = "Order and Account Details";
                break;
        }

        return (
            <ModalContainer title={title} >
                <OrderForm
                    stage={this.props.stage}
                    orderForEditMode={this.props.orderForEditMode}
                    primarySectionFocus={this.props.primarySectionFocus || PRIMARY_SECTION_FOCUS.NONE}
                    ratePlans={this.props.ratePlans}
                    itemTypes={this.props.itemTypes}
                    onCancel={this.onCancel}
                    onSuccess={this.props.onSuccess}
                    setReloadOnDismiss={this.setReloadOnDismiss}
                    updateUnsavedEditsFound={this.updateUnsavedEditsFound}
                />
            </ModalContainer>
        )
    }
}