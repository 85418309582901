
import React, { Component } from 'react';

import PropTypes from "prop-types";

import { timeFormat } from "d3-time-format";
import { format } from "d3-format";
import { scaleTime } from  "d3-scale";
import { timeSecond, timeMinute, timeHour, timeDay, timeWeek, timeMonth, timeYear } from  "d3-time";
import moment from 'moment';

import { ChartCanvas, Chart } from 'react-stockcharts';

import { Annotate, LabelAnnotation } from "react-stockcharts/lib/annotation";

import { BarSeries } from "react-stockcharts/lib/series";

import { EdgeIndicator, CrossHairCursor, MouseCoordinateX, MouseCoordinateY } from "react-stockcharts/lib/coordinates";

import { HoverTooltip } from "react-stockcharts/lib/tooltip";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { fitWidth } from "react-stockcharts/lib/helper";

import BoxPlotSeries from "./BoxPlotSeries";
import EventAnnotate from "./EventAnnotate";
import SegmentSeries from "./SegmentSeries";

import { StackedBarSeries, LineSeries } from "react-stockcharts/lib/series";
import { Label } from "react-stockcharts/lib/annotation";
import { set } from "d3-collection";
import { scaleOrdinal } from  "d3-scale";
import { schemeCategory10 } from  "d3-scale-chromatic";

import globalconfig from '../../../../common/config';

var dateFormat = timeFormat("%a %Y-%m-%d %H:%M:%S");
var numberFormat = format(".2f");

var formatMillisecond = timeFormat(".%L"),
    formatSecond = timeFormat(":%S"),
    formatMinute = timeFormat("%H:%M"),
    formatHour = timeFormat("%H:%M"),
    formatDay = timeFormat("%a %d"),
    formatWeek = timeFormat("%b %d"),
    formatMonth = timeFormat("%B"),
    formatYear = timeFormat("%Y");

function multiFormat(date) {

  return (timeSecond(date) < date ? formatMillisecond
      : timeMinute(date) < date ? formatSecond
      : timeHour(date) < date ? formatMinute
      : timeDay(date) < date ? formatHour
      : timeMonth(date) < date ? (timeWeek(date) < date ? formatDay : formatWeek)
      : timeYear(date) < date ? formatMonth
      : formatYear)(date);
}


function tooltipContent(finelevel, segments, segmentlookup) {
    return ({ currentItem, xAccessor }) => {

        if(!finelevel)
        {
            if(isNaN(currentItem.min) || isNaN(currentItem.max))
            {
                return {
                    x: dateFormat(xAccessor(currentItem)),
                    y: [ { label: "data", value: 'no data' }, ]
                };
            }
        }

        var segmentindex = segmentlookup[currentItem.segid];

        var vehicle = "Unknown";

        if(segmentindex >= 0 && segments[segmentindex])
        {
            vehicle = "" + segments[segmentindex].vehicle_name + " (" + segments[segmentindex].vehicle_type + ")";
        }

        var retval = {
            x: dateFormat(xAccessor(currentItem)),
            y: [
                { label: "cardid", value: currentItem.card },
                { label: "segmentid", value: currentItem.segid },
                { label: "vehicle", value: vehicle },
                { label: "time", value: moment(currentItem.date).format(globalconfig.display.timeFormat) },
                { label: "mean", value: currentItem && currentItem.mean !== null ? numberFormat(currentItem.mean) : null },
                { label: "min", value: currentItem && currentItem.min !== null ? numberFormat(currentItem.min) : null },
                { label: "max", value: currentItem && currentItem.max !== null ? numberFormat(currentItem.max) : null },
                { label: "count", value: currentItem ? currentItem.count && numberFormat(currentItem.count) : null },
                { label: "latency", value: currentItem ? currentItem.latency && numberFormat(currentItem.latency) : null },
            ]
            .filter(line => line.value)
        };

        if (currentItem.events)
        {
            currentItem.events.forEach(x => {
                retval.y.push({
                    label: "event", 
                    value: x.name,
                })
                retval.y.push({
                    label: "", 
                    value: "" + dateFormat(x.date * 1000),
                })
            });
        }

        return retval;
    };
}

function getSymbolForEvent(e)
{
    var icons = [];

    var unknown = false;

    switch(e.name) {
        case 'EVENT_MEDIA_UPLOADED_WIFI':
        case 'EVENT_MEDIA_UPLOADED_USB':
        case 'EVENT_MEDIA_UPLOADED_CELLULAR':
            icons.push("\ue142"); // paperclip
            break;

        case 'EVENT_MEDIA_SHORT_CREATED':
            icons.push("\ue009"); // film
            break;

        case 'EVENT_ENGINE_ON':
            icons.push("\ue024"); // engine on
            break;
        case 'EVENT_ENGINE_OFF':
        case 'EVENT_ENGINE_UNKNOWN':
            icons.push("\ue062"); // pin
            break;
        case 'EVENT_MEDIA_TIME_LAPSE_INITIATED':
            icons.push("\ue023"); // timelapse
            break;
        case 'EVENT_RAVEN_BT_BUTTON_SHORT_PRESS':
        case 'EVENT_RAVEN_BT_BUTTON_LONG_PRESS':
            icons.push("\ue129"); // gesture
            break;
        case 'EVENT_RAVEN_BOOTED':
            icons.push("\ue017"); // power on
            break;
        case 'EVENT_RAVEN_PLUGGED_IN':
            icons.push("\ue017"); // power on
            break;
        case 'EVENT_RAVEN_UNPLUGGED':
            icons.push("\ue162"); // power plug
            break;
        case 'EVENT_CAR_BATTERY_LOW':
            icons.push("\ue082"); // battery low
            break;
        case 'EVENT_RAVEN_SHUTDOWN':
            icons.push("\ue083");  // X
            break;
        case 'EVENT_WEBRTC_STREAMING_STARTED':
            icons.push("\ue105"); // eye open
            break;
        case 'EVENT_WEBRTC_STREAMING_STOPPED':
            icons.push("\ue106"); // eye close
            break;
        case 'EVENT_UPDATE_REQUEST_RECEIVED':
            icons.push("\ue197"); // cloud download
            break;
        case 'EVENT_UPDATE_PACKAGE_RECEIVED':
            icons.push("\ue197"); // cloud download
            break;
        case 'EVENT_UPDATE_REBOOT':
            icons.push("\ue031"); // refresh
            break;
        case 'EVENT_UPDATE_COMPLETE':
            icons.push("\ue197"); // cloud download
            break;
        case 'EVENT_HARD_BRAKING':
        case 'EVENT_AGGRESSIVE_ACCELERATION':
            icons.push("\ue107"); // warning sign
            break;

        case 'EVENT_NAVIGATION_STARTED':
            icons.push("\ue087"); // screenshot
            break;
        case 'EVENT_NAVIGATION_STOPPED':
            icons.push("\ue090"); // ban circle
            break;

        /* FIXME */
        case 'EVENT_TOMBSTONE':
            icons.push("\ue023"); // fire
            break;

        case 'EVENT_MEDIA_TIME_LAPSE_CREATED':
        case 'EVENT_MEDIA_SHORT_INITIATED':
        case '':
            break;
        default:
            unknown = true;
            break;
    } 

    if(unknown)
    {
        icons.push("\ue085"); // unknown
    }

    if(icons.length > 0)
        return icons.join('');
    return '';
}

function daysBetween( date1, date2 ) {

    var day_in_ms=1000*60*60*24;

    // Convert to time since epoch
    var epoch1 = date1.getTime();
    var epoch2 = date2.getTime();

    // Calculate the difference in milliseconds
    var diff = epoch2 - epoch1;

    // Convert back to days and return
    return Math.round(diff/day_in_ms); 
}

function secondsBetween( date1, date2 ) {

    // Convert to time since epoch
    var epoch1 = date1.getTime();
    var epoch2 = date2.getTime();

    // Calculate the difference in milliseconds
    var diff = epoch2 - epoch1;

    // Convert back to days and return
    return Math.round(diff/1000); 
}

function dataMinMax(arr, extents) {
  let min = null, max = null;

  let datemin = null, datemax = null;

  if(extents)
  {
      datemin = extents[0];
      datemax = extents[1];
      if(datemin > datemax)
      {
        datemin = extents[1];
        datemax = extents[0];
      }
  }

  for (let i = 0, len=arr.length; i < len; i++) {

    if(datemin && datemax)
    {
        if(!(arr[i].date && arr[i].date >= datemin && arr[i].date <= datemax))
        {
            continue;
        }
    }

    let v = arr[i].min;
    if(v)
    {
        if(min === null) min = v;
        else min = (v < min) ? v : min;
    }
    let w = arr[i].max;
    if(w)
    {
        if(max === null) max = v;
        else max = (w > max) ? w : max;
    }
  }

  return [min, max];
}

class CandleStickChartForDiscontinuousIntraDay extends React.Component {

    constructor () {

        super();

        // console.log("Constructor called, setting dataLevel to fine");

        this.state = {
            dataLevel: 'fine',
        }

        this.lastScaleChanges = [];
        this.numLastScaleChanges = 4;

        this.xScale = scaleTime();

        this.locationBarUpdater = null;

    }

    handleScaleChange = (me, zoom, domain, range) => {

        // Report days between
        var numdays = daysBetween(domain[0], domain[1]);

        var numseconds = secondsBetween(domain[0], domain[1]);

        var pixelwidth = range[1] - range[0];

        var secondsperwidth = numseconds/pixelwidth;

        if(numseconds === 0)
            return;

        console.log("Handle Scale Change to", zoom, numseconds, pixelwidth, pixelwidth/numseconds, numseconds/pixelwidth);

        me.lastScaleChanges.push({
            zoom: zoom,
            numseconds: numseconds,
            pixelwidth: pixelwidth
        });
        if(me.lastScaleChanges.length > me.numLastScaleChanges)
            me.lastScaleChanges.shift();

        var dataLevel = me.state.dataLevel;

        if(zoom > 0)
        {
            // Zoom in
            if(secondsperwidth > 10000)
            {
                // weeks of data
                // Don't switch to week if we are zooming in
            }
            else if(secondsperwidth > 1500 && me.state.dataLevel === 'week')
            {
                // days of data
                if(me.state.dataLevel !== 'day')
                {
                    console.log("Switching to day dataLevel");
                    dataLevel = 'day';
                }
            }
            else if(secondsperwidth > 100 && me.state.dataLevel === 'day')
            {
                // hours of data
                if(me.state.dataLevel !== 'minute')
                {
                    console.log("Switching to minute dataLevel");
                    dataLevel = 'minute';
                }
            }
            else
            {
                if(me.state.dataLevel !== 'fine')
                {
                    console.log("Switching to fine dataLevel");
                    dataLevel = 'fine';
                }
            }
        }
        else if(zoom < 0)
        {
            // zoom out
            if(secondsperwidth > 10000)
            {
                // weeks of data
                if(me.state.dataLevel !== 'week')
                {
                    console.log("Switching to week dataLevel");
                    dataLevel = 'week';
                }
            }
            else if(secondsperwidth > 500)
            {
                // days of data
                if(me.state.dataLevel !== 'day')
                {
                    console.log("Switching to day dataLevel");
                    dataLevel = 'day';
                }
            }
            else if(secondsperwidth > 50)
            {
                // hours of data
                if(me.state.dataLevel !== 'minute')
                {
                    console.log("Switching to minute dataLevel");
                    dataLevel = 'minute';
                }
            }
            else
            {
                // Don't switch to fine if we are zooming out
            }
        }

        if(this.props.displayLevel !== 'auto')
        {
            if(this.props.displayLevel != this.state.dataLevel)
                me.setState({dataLevel: this.props.displayLevel, xExtents: domain});
        }
        else if(dataLevel !== me.state.dataLevel)
        {
            me.setState({dataLevel: dataLevel, xExtents: domain});
        }
        else if(zoom !== 0)
        {
            // It's the same, see if we had the same zoom request n times in a row
            let numlevels = me.lastScaleChanges.length;
            let forcechange = true;
            if(numlevels >= me.numLastScaleChanges)
            {
                for(let i = 1; i < numlevels; i++)
                {
                    if(me.lastScaleChanges[i].zoom !== me.lastScaleChanges[0].zoom ||
                        me.lastScaleChanges[i].numseconds !== me.lastScaleChanges[0].numseconds ||
                        me.lastScaleChanges[i].pixelwidth !== me.lastScaleChanges[0].pixelwidth)
                    {
                        forcechange = false;
                        break;
                    }
                }
            }
            else
            {
                forcechange = false;
            }

            if(forcechange)
            {
                // It's the same for the last n times, change the scale by one the 
                // direction

                if(zoom > 0)
                {
                    // zoom in
                    if(dataLevel === "week")
                        dataLevel = "day";
                    else if(dataLevel === "day")
                        dataLevel = "minute";
                    else if(dataLevel === "minute")
                        dataLevel = "fine";
                }
                else
                {
                    // zoom out
                    if(dataLevel === "fine")
                        dataLevel = "minute";
                    else if(dataLevel === "minute")
                        dataLevel = "day";
                    else if(dataLevel === "day")
                        dataLevel = "week";
                }

                if(this.state.dataLevel != dataLevel)
                {
                    console.log("Force Scale Change to", dataLevel);
                    me.setState({dataLevel: dataLevel, xExtents: domain});
                }
            }
        }
        else
        {
            me.setState({xExtents: domain});
        }

        if(me.locationBarUpdater)
        {
            clearTimeout(me.locationBarUpdater);
        }

        me.locationBarUpdater = setTimeout(() => { 
            me.props.onSettingChanged( {'level': dataLevel, 'min': moment(domain[0]).unix(), 'max': moment(domain[1]).unix() });
        }, 500);

    }


    componentWillMount() {
        var me = this;

        // Pull domain and data level out of the search

        var newDataLevel = this.props.settings['level'] ? this.props.settings['level'] : this.state.dataLevel;
        var newDomain;
        if(this.props.settings['min'] && this.props.settings['max'])
        {
            newDomain = [new Date(this.props.settings['min'] * 1000), new Date(this.props.settings['max'] * 1000)];
        }

        me.setState({dataLevel: newDataLevel, xExtents: newDomain});
    }

    componentDidMount() {
        var me = this;

        // Handle scale change whenever a zoom happens
        var handleEvents = (type, moreProps, inState, e) => {

            // if(type !== "mousemove")
                // console.log("Handling event", type, moreProps, inState, e);

            if(type === "zoom")
            {
                var oldDomain = inState.xScale.domain();
                var newDomain = moreProps.xScale.domain();

                var zoom = 1;

                if((oldDomain[1] - oldDomain[0]) < (newDomain[1] - newDomain[0]))
                {
                    zoom = -1;
                }

                // console.log("Zoom:", moreProps, inState, moreProps.xScale.domain(), inState.xScale.domain());
                me.handleScaleChange(me, zoom, moreProps.xScale.domain(), moreProps.xScale.range());
            }
            if(type === "panend")
            {
                var oldDomain = inState.xScale.domain();
                var newDomain = moreProps.xScale.domain();

                // console.log("Pan Ended, new scale", inState.xScale.domain());
                // console.log("Pan End:", moreProps, inState, moreProps.xScale.domain(), inState.xScale.domain());
                me.handleScaleChange(me, 0, moreProps.xScale.domain(), moreProps.xScale.range());
            }
            if(type === "pan")
            {
                // console.log("Pan, new scale", inState.xScale.domain());
            }
        }

        this.chart.subscribe("zoomHandler", { listener: handleEvents });

        // Handle scale change now
        // me.handleScaleChange(me, this.chart.state.xScale.domain(), this.chart.state.xScale.range());

    }

    componentDidUpdate(prevProps) {
        if (this.props.displayLevel !== prevProps.displayLevel) {
            this.handleScaleChange(this, 0, this.chart.state.xScale.domain(), this.chart.state.xScale.range());
        }
    }

    componentWillUnmount() {
        if(this.chart)
            this.chart.unsubscribe("zoomHandler")
    }

    render() {
        return this.renderOriginal();
    }


	renderDataUsage() {
		var { data, type, width, height, ratio } = this.props;

        if(!data || data.length === 0)
        {
            return (
                <div>No Data</div>
            );
        }
/*
        var annotationProps = {
            fontFamily: "Glyphicons Halflings",
            fontSize: 20,
            fill: "#060F8F",
            opacity: 0.8,
            // text: "\ue182",
            text: "\ue094",
            y: ({ yScale, datum }) => yScale(datum.high) - 20,
            // y: ({ yScale }) => yScale.range()[0],
            onClick: console.log.bind(console),
            tooltip: d => dateFormat(d.date) + " " + d.label,
            // onMouseOver: console.log.bind(console),
        };
*/

        const xAccessor = d => d ? d.date : null;

        const f = scaleOrdinal(schemeCategory10)
            .domain(set(data[this.state.dataLevel].map(d => d.region)));

        const fill = (d, i) => f(i);
        const fill2 = (d, i) => f(i + 5);

        var margin = { left: 80, right: 80, top: 10, bottom: 10 };

        // var [yAxisLabelX, yAxisLabelY] = [width -margin.left - 40, margin.top + (height - margin.top - margin.bottom) / 2]
        var [yAxisLabelX, yAxisLabelY] = [ -((margin.left/2) + 10), margin.top + (height - margin.top - margin.bottom) / 2]
        var [yAxisLabelXRight, yAxisLabelYRight] = [ width - margin.left - 10, margin.top + (height - margin.top - margin.bottom) / 2]

		return (
			<ChartCanvas ratio={ratio} width={width} height={height}
					margin={margin} type={type}
					seriesName="Property"
					data={data[this.state.dataLevel]} 
                    onLoadMore={this.props.onLoadMore}
                    pointsPerPxThreshold={1}
                    minPointsPerPxThreshold={1/10000}
					xAccessor={xAccessor} 
					displayXAccessor={xAccessor} 
                    xScale={scaleTime()}>
                <Chart id={1} height={height - 50}
                        padding={{ top: 10, bottom: 60}}
                        yExtents={[0, d => d.total_bytes]}>

					<XAxis axisAt="bottom" orient="bottom"
                        stroke="#ffffff" tickStroke="#ffffff"
                    />
					<YAxis axisAt="right" orient="right" ticks={5}
                        stroke="#ffffff" tickStroke="#ffffff"
                    />

					<MouseCoordinateX
						rectWidth={60}
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(",.0f")} />

{ /*
                    <StackedBarSeries yAccessor={[
                            d => d.protobuf_other_bytes, 
                            d => d.media_preview_bytes, 
                            d => d.media_snapshot_bytes 
                                + d.media_timelapse_bytes 
                                + d.media_short_bytes 
                                + d.media_video_bytes,
                            d => d.tx_other,
                            d => d.rx,
                        ]}
                        fill={fill} />

					<EdgeIndicator itemType="last" orient="right" edgeAt="right"
						yAccessor={d => d.total_bytes} 
                        fill="#6BA583" displayFormat={format(",.0f")} />

                    <Label x={yAxisLabelXRight} y={yAxisLabelYRight}
                        rotate={-90} fontSize={12} text="Bytes"
                        fill="#ffffff"
                    />

                    <HoverTooltip
                        chartId={1}
                        tooltipContent={tooltipContent([])}
                        fontSize={15} />
				</Chart>
                <Chart id={3} height={(height - 50)/2}
                        origin={(w, h) => [0, (height - 50)/2]}
                        padding={{ top: 10, bottom: 60}}
                        yExtents={[0, d => d.protobuf_count]}>
					<YAxis axisAt="left" orient="left" ticks={5} 
                        stroke="#ffffff" tickStroke="#ffffff"
                    />

                    <Label x={yAxisLabelX} y={yAxisLabelY}
                        rotate={-90} fontSize="12" text="Number of Packets"
                        fill="#ffffff"
                    />

                    <LineSeries yAccessor={d => d.protobuf_count} stroke="#ff5555" />
				</Chart>
                <Chart id={2} height={50}
                        padding={{ top:10, bottom: 10}}
                        origin={(w, h) => [0, h - 80]}
                        yExtents={[0, 1]}>

                    <YAxis axisAt="left" orient="left" ticks={3}
                        stroke="#ffffff" tickStroke="#ffffff"
                    />

                    <MouseCoordinateX
                        rectWidth={60}
                        at="bottom"
                        orient="bottom"
                        displayFormat={timeFormat("%Y-%m-%d")} />
                    <MouseCoordinateY
                        at="right"
                        orient="right"
                        displayFormat={format(",.2f")} />

                    <StackedBarSeries yAccessor={[d => d.driving_fraction, d => d.parked_fraction, d => d.offline_fraction]}
                            fill={fill2} />

                    <Label x={yAxisLabelX} y={yAxisLabelY + 150}
                        rotate={-90} fontSize="12" text="Mode Fraction"
                        fill="#ffffff"
                    />
*/ }

                </Chart>

				<CrossHairCursor />
			</ChartCanvas>
		);
	}

    onChartLoadMore = (start, end) => {
        return this.props.onLoadMore(start, end, this.chart.state.xScale.domain());
    } 

    setChartRef = (chart) => {
        this.chart = chart;
    }

	renderOriginal() {
		var { type, width, ratio } = this.props;

		var { data } = this.props;

        var me = this;

        const xAccessor = d => d ? d.date : null;


        var annotationProps = {
            fontFamily: "Glyphicons Halflings",
            fontSize: 20,
            // fill: "#060F8F",
            fill: "#a6aF8F",
            opacity: 0.8,
            // text: "\ue182",
            text: x => { return getSymbolForEvent(x); },
            x: ({ xScale, xAccessor, datum }) => { 
                return xScale(datum.date);
            },
            y: ({ yScale }) => yScale.range()[0],
            onClick: console.log.bind(console),
            tooltip: e => { 
                return dateFormat(e.date) + " " + e.name;
            }
        };

        // var curScale = scaleTime();

        // For some reason react-stockcharts doesn't like me dynamically adding
        // charts.  Hardcode left only and a left/right graphs
        if( this.props.dataRight  && this.props.dataRight[this.state.dataLevel])
        {
            var rightExtents = dataMinMax(this.props.dataRight[this.state.dataLevel], me.chart ? me.chart.state.xScale.domain() : null);

            return (
                <ChartCanvas ratio={ratio} width={width} height={400}
                        ref={this.setChartRef}
                        margin={{ left: 80, right: 100, top: 10, bottom: 30 }} 
                                    type={type}
                        seriesName="Property"
                        data={data[this.state.dataLevel]} 
                        onLoadMore={this.onChartLoadMore}
                        zoomMultiplier={1.25}
                        pointsPerPxThreshold={1}
                        minPointsPerPxThreshold={1/10000}
                        xScale={this.xScale} 
                        xAccessor={xAccessor}
                        displayXAccessor={xAccessor}
                >
                    <Chart id={2}
                            yExtents={[d => [1.0,0.0]]}
                            >
                        <YAxis axisAt="left" orient="left" ticks={0} />

                        <SegmentSeries plotData={this.state.dataLevel === "fine" ? data['segments'] : []} yAccessor={d => { return (d.segid %2 == 1) ? 1 : 0} }  fill="#6BA583" />
                        <EventAnnotate data={this.state.dataLevel === "fine" ? data['events'] : [] } with={LabelAnnotation}
                            when={d => d.date }
                            rotate={-90}
                            xAccessor={d => d ? d.date : null} 
                            displayXAccessor={d => d ? d.date : null}
                            usingProps={annotationProps} />
                    </Chart>
                    <Chart id={1}
                            yExtents={[d => d ? [d.max, d.min] : [null, null] ]}
                            padding={{ top: 40, bottom: 20 }}>
                        <XAxis axisAt="bottom" orient="bottom"
                            stroke="#ffffff" tickStroke="#ffffff"
                            tickFormat={multiFormat}
                        />
                        <YAxis axisAt="left" orient="left" ticks={5} 
                            stroke="#ffffff" tickStroke="#ffffff"
                        />

                        <MouseCoordinateX
                            rectWidth={60}
                            at="bottom"
                            orient="bottom"
                            displayFormat={timeFormat("%H:%M:%S")} />
                        <MouseCoordinateY
                            at="left"
                            orient="left"
                            displayFormat={format(".2f")} />

                        <BoxPlotSeries 
                                clip={false}
                                yAccessor={ d => {
                                    if(d)
                                    {
                                        return ({ mean: d.mean, max: d.max, min: d.min, stdev: d.stdev });
                                    }
                                    else
                                    {
                                        return { min: null, max: null, mean: null, stdev: null };
                                    }
                                }}
                                wickStroke={ "#ffff88" }
                                candleStrokeWidth={1}
                                opacity={1}
                                fill={ "#ffff88" }
                                stroke={ "#aaaaaa" }
                        />

                        <EdgeIndicator itemType="first" orient="left" edgeAt="left"
                            yAccessor={d => d ? d.mean: null} 
                            fill={d => d ? ( d.max > d.min ? "#6BA583" : "#FF0000" ) : "#FFFFFF" }
                        />

                        <HoverTooltip
                            chartId={1}
                            tooltipContent={tooltipContent(this.state.dataLevel === "fine", data['segments'], this.props.segmentlookup)}
                            fontSize={15} />
                    </Chart>

                    <Chart id={4}
                            yExtents={rightExtents}
                            padding={{ top: 40, bottom: 20 }}>
                        <YAxis axisAt="right" orient="right" ticks={5} 
                            stroke="#ffffff" tickStroke="#ffffff"
                        />

                        <MouseCoordinateY
                            at="right"
                            orient="right"
                            displayFormat={format(".2f")} />

                        <BoxPlotSeries 
                                plotData={this.props.dataRight[this.state.dataLevel]}
                                yAccessor={ d => {
                                    if(d)
                                    {
                                        return ({ mean: d.mean, max: d.max, min: d.min, stdev: d.stdev });
                                    }
                                    else
                                    {
                                        return { min: null, max: null, mean: null, stdev: null };
                                    }
                                }}
                                wickStroke={ "#88ffff" }
                                candleStrokeWidth={1}
                                opacity={1}
                                fill={ "#88ffff" }
                                stroke={ "#aaaaaa" }
                                clip={false}
                        />

                        {/*
                        <EdgeIndicator itemType="last" orient="right" edgeAt="right"
                            yAccessor={ d => d ? d.mean : null } 
                            fill={d => d ? ( d.max > d.min ? "#6BA583" : "#FF0000" ) : "#FFFFFF" }
                            
                        />
                        */}
                    </Chart>
                    <Chart id={3} height={50}
                            yExtents={[d => d.latency]}
                            origin={(w, h) => [0, h - 50]}>
                        <YAxis axisAt="left" orient="left" ticks={5} 
                            tickFormat={format(".2s")}
                            stroke="#ffffff" tickStroke="#ffffff"
                        />

                        <MouseCoordinateY at="left" orient="left"
                                displayFormat={format(".4s")} />

                        <BarSeries yAccessor={d => d.latency} fill={"#6B65E3"} 
                                clip={false}
                        />
                    </Chart>
                    <CrossHairCursor />
                </ChartCanvas>
            );
        }
        else
        {

            return (
                <ChartCanvas ratio={ratio} width={width} height={400}
                        ref={this.setChartRef}
                        margin={{ left: 80, right: 100, top: 10, bottom: 30 }} 
                                    type={type}
                        seriesName="Property"
                        data={data[this.state.dataLevel]} 
                        onLoadMore={this.onChartLoadMore}
                        zoomMultiplier={1.25}
                        pointsPerPxThreshold={1}
                        minPointsPerPxThreshold={1/10000}
                        xScale={this.xScale} 
                        xAccessor={xAccessor}
                        displayXAccessor={xAccessor}
                >

                    <Chart id={2}
                            yExtents={[d => [1.0,0.0]]}
                            >
                        <YAxis axisAt="left" orient="left" ticks={0} />


                        <SegmentSeries plotData={this.state.dataLevel === "fine" ? data['segments'] : []} yAccessor={d => { return (d.segid %2 == 1) ? 1 : 0} }  
                            fill="#6BA583" 
                            clip={false}
                        />
                        <EventAnnotate data={this.state.dataLevel === "fine" ? data['events'] : [] } with={LabelAnnotation}
                            when={d => d.date }
                            rotate={-90}
                            xAccessor={d => d ? d.date : null} 
                            displayXAccessor={d=> d ? d.date : null}
                            usingProps={annotationProps} />
                    </Chart>

                    <Chart id={1}
                            yExtents={[d => d ? [d.max, d.min] : [null, null] ]}
                            padding={{ top: 40, bottom: 20 }}>

                        <XAxis axisAt="bottom" orient="bottom"
                            stroke="#ffffff" tickStroke="#ffffff"
                            tickFormat={multiFormat}
                        />
                        <YAxis axisAt="left" orient="left" ticks={5} 
                            stroke="#ffffff" tickStroke="#ffffff"
                        />

                        <MouseCoordinateX
                            rectWidth={60}
                            at="bottom"
                            orient="bottom"
                            displayFormat={timeFormat("%H:%M:%S")} />
                        <MouseCoordinateY
                            at="left"
                            orient="left"
                            displayFormat={format(".2f")} />

                        <BoxPlotSeries 
                                yAccessor={ d => {
                                    if(d)
                                    {
                                        return ({ mean: d.mean, max: d.max, min: d.min, stdev: d.stdev });
                                    }
                                    else
                                    {
                                        return { min: null, max: null, mean: null, stdev: null };
                                    }
                                }}
                                wickStroke={ "#ffffff" }
                                candleStrokeWidth={1}
                                opacity={1}
                                fill={ "#ffffff" }
                                stroke={ "#aaaaaa" }
                                clip={false}
                        />

                        <EdgeIndicator itemType="first" orient="left" edgeAt="left"
                            yAccessor={d => d ? d.mean: null} 
                            fill={d => d ? ( d.max > d.min ? "#6BA583" : "#FF0000" ) : "#FFFFFF" }
                        />
                        <HoverTooltip
                            chartId={1}
                            tooltipContent={tooltipContent(this.state.dataLevel === "fine", data['segments'], this.props.segmentlookup)}
                            fontSize={15} />
                </Chart>

                    <Chart id={3} height={50}
                            yExtents={[d => d.latency]}
                            origin={(w, h) => [0, h - 50]}>
                        <YAxis axisAt="left" orient="left" ticks={5} 
                            tickFormat={format(".2s")}
                            stroke="#ffffff" tickStroke="#ffffff"
                        />

                        <MouseCoordinateY at="left" orient="left"
                                displayFormat={format(".4s")} />

                        <BarSeries yAccessor={d => d.latency} 
                            fill={"#6B65E3"} 
                            clip={false}
                        />
                    </Chart>
                    <CrossHairCursor />
                </ChartCanvas>
            );
        }
	}
}

CandleStickChartForDiscontinuousIntraDay.propTypes = {
	data: PropTypes.object.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

CandleStickChartForDiscontinuousIntraDay.defaultProps = {
	type: "hybrid",
};
CandleStickChartForDiscontinuousIntraDay = fitWidth(CandleStickChartForDiscontinuousIntraDay);

class ChartErrorBoundary extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            errorFound: false,
        };
    }

    componentDidCatch(err, info) {
        console.log("Component Did Catch Called", err);
        this.setState({errorFound: true});
    }

    static getDerivedStateFromError() {
        console.log("Get Derived State From Error Called");
        return {errorFound: true};
    }

    render()
    {
        if(this.state.errorFound)
        {
            return (
                <label>Unable to display this date range.  Select another date range.</label>
            );
        }
        return this.props.children;
    }

}


export default class SensorView extends Component {

    render()
    {
        if(!this.props.data || Object.keys(this.props.data).length === 0)
        {
            return (
                <label>No Data to display</label>
            );
        }
        if(this.props.data && Object.keys(this.props.data).length && this.props.data['fine'] && this.props.data['fine'].length === 1 && !this.props.data['fine'][0]['count']) 
        {
            return (
                <label>No Data to display</label>
            );
        }

        return ( 
            <ChartErrorBoundary>
                <CandleStickChartForDiscontinuousIntraDay
                    data={this.props.data} 
                    dataRight={this.props.dataRight} 
                    start={this.props.start}
                    onSettingChanged={this.props.onSettingChanged}
                    settings={this.props.settings}
                    onLoadMore={this.props.onLoadMore}
                    segmentlookup={this.props.segmentlookup}
                    // width={this.chart_width}
                    // width={800}
                    height={450}
                    ratio={1}
                    type="hybrid"
                    seriesName="Usage" 
                    displayLevel={this.props.displayLevel}
                />
            </ChartErrorBoundary>
        );

    }
}
