import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StageSelector from '../widgets/StageSelector';

import { MAIN_FILTER_TYPES } from './../ordersPage';

export default class Header extends React.PureComponent {

    componentDidMount() {
        console.log(window.location.pathname);
    }

    render() {
        const activeOrdersClass = this.props.mainFilter !== MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED ? "active" : null;        
        const orderHistoryClass = this.props.mainFilter === MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED ? "active" : null;

        return (
            <div className="row" id="controls">
                <div className="branding col-2 raven-logo">
                    <Link to="/"/>
                </div>
                <div className="viewOptions col-10">
                    <div className="row spacedRow">
                        <div className="col-3" >
                            <StageSelector
                                ravenStages = {this.props.ravenStages}
                                stage = {this.props.stage}
                                onRavenStageChange = {this.props.onRavenStageChange}
                            />
                        </div>
                        <div className="col-9" >
                            <div className="orders-nav">
                            <button onClick={() => {this.props.onMainFilterClick(MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED)}} className={activeOrdersClass}>Active Orders</button>
                            <button onClick={() => {this.props.onMainFilterClick(MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED)}} className={orderHistoryClass}>History</button>
                            {/*<button onClick={this.props.onClickAddInventory}>New Inventory</button>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


Header.propTypes = {
    ravenStages: PropTypes.array.isRequired,
    stage: PropTypes.string.isRequired,
    onRavenStageChange: PropTypes.func.isRequired,
    mainFilter: PropTypes.string,
    onMainFilterClick: PropTypes.func.isRequired,
    onClickAddInventory: PropTypes.func.isRequired
};