import React from 'react';

import ProgressSpinnerSmall from './ProgressSpinnerSmall';

const ProgressOverlay = () => (
    <div className='progress-overlay'>
        <ProgressSpinnerSmall/>  
    </div>
);

export default ProgressOverlay;
