import { CognitoUser, CognitoUserPool, AuthenticationDetails } from "amazon-cognito-identity-js";
import globalconfig from '../../common/config';
import Events from '../../common/events';

export default class CognitoUtil {

    static refreshTimeoutHandle = null;

    static userPool = new CognitoUserPool({
        UserPoolId: globalconfig.aws.UserPoolId,
        ClientId: globalconfig.aws.ClientId
    });

    static parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    };

    static checkUserRole(session) {
        var token = session.getIdToken().getJwtToken();
        var jwt = this.parseJwt(token);
        if(jwt['cognito:roles'] && jwt['cognito:roles'].indexOf(globalconfig.aws.RoleId) >= 0)
        {
            return true;
        }

        // console.log(jwt['cognito:roles']);
        // alert("User not authorized");
        return false;

    }

    static getCurrentUserToken () {
        return window.cognitoSession.getIdToken().getJwtToken();
    }

    static loginUser(email, password, history) {

        var selfObj = this;

        try {
            const authenticationDetails = new AuthenticationDetails({
                Username: email,
                Password: password
            });
            
            this.cognitoUser = new CognitoUser({Username:email, Pool: CognitoUtil.userPool});
            this.cognitoUser.email = email;
            this.cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {

                    var nevent;

                    if(!selfObj.checkUserRole(result))
                    {
                        console.error("User Authenticated, but role doesn't match");
                        nevent = new CustomEvent(Events.LOGIN_FAILED, { detail: { message: "No permission for this service"}});
                        document.dispatchEvent(nevent);

                        return;
                    }
                    
                    window.cognitoSession = result;

                    nevent = new CustomEvent(Events.LOGIN_SUCCESS);
                    document.dispatchEvent(nevent);
                },

                onFailure: function(err) {
                    var nevent;

                    if(err.code === "PasswordResetRequiredException")
                    {
                        nevent = new CustomEvent(Events.LOGIN_PASSWORD_RESET_REQUIRED);
                        document.dispatchEvent(nevent);
                        return;
                    }

                    nevent = new CustomEvent(Events.LOGIN_FAILED, {detail:err});
                    document.dispatchEvent(nevent);
                },

                newPasswordRequired: function(data) {
                    var nevent = new CustomEvent(Events.LOGIN_NEW_PASSWORD_REQUIRED);
                    document.dispatchEvent(nevent);
                },

                mfaRequired: function(data) {
                    var nevent = new CustomEvent(Events.LOGIN_MFA_REQUIRED);
                    document.dispatchEvent(nevent);
                }
            });
        } catch (err) {
            console.error("Login Error", err);
            return false;
        }
    }

    static setRefreshHandler(session)
    {

        var me = this;

        if(this.refreshTimeoutHandle)
        {
            window.clearTimeout(this.refreshTimeoutHandle);
            this.refreshTimeoutHandle = null;
        }

        var periodicRefreshFunc = function() {

            var refreshToken = window.cognitoSession.getRefreshToken();

            me.cognitoUser.refreshSession(refreshToken, (err) => {
                // Success or fail, call refresh Login
                me.refreshLogin();
            });
        }

        if(!session)
        {
            return false;
        }

        var token = session.getIdToken().getJwtToken();
        var jwt = this.parseJwt(token);

        if(!('exp' in jwt))
        {
            return false;
        }

        var curTime = Date.now() / 1000;
        var delta = jwt['exp'] - curTime;

        var delta5min = 5 * 60;
        var delta1min = 1 * 60;

        // Refresh the token 5 minutes before it expires

        var delay;

        if(delta <= delta5min)
        {
            // Almost or already expired, set it to 1 minute from now
            delay = delta1min * 1000;
        }
        else
        {
            delay = (delta - delta5min) * 1000;
        }

        // var expireTime = new Date(jwt['exp'] * 1000);
        // var newTime = new Date((curTime * 1000) + delay);
        // console.log("Token set to expire at", expireTime.toLocaleString());
        // console.log("Delay set to", delay/1000, "seconds", delay/1000/60, "minutes");
        // console.log("Setting token refresh to run at", newTime.toLocaleString());

        // Set the refresh time to be 10 minutes before the token expires
        this.refreshTimeoutHandle = window.setTimeout(periodicRefreshFunc, delay);


        return true;
    }

    //
    // Check to see if there are credentials in localstorage and load them
    // verify and refresh creds with cognito refresh flow
    //
    static refreshLogin() {

        var me = this;

        var fail = function(err) {
            console.error('failed refresh login. no cognitouser in userpool.', err);
            var nevent = new CustomEvent(Events.LOGIN_FAILED);
            document.dispatchEvent(nevent);
        }

        this.cognitoUser = CognitoUtil.userPool.getCurrentUser();
        window.user = this.cognitoUser;
        if(!this.cognitoUser) {
            fail();
            return;
        }
        
        this.cognitoUser.getSession((err, session) => {
            if(err || !session) {
                // Failed to get the session, set the timeout again
                me.setRefreshHandler(session);
                fail(err);
                return;
            }

            window.cognitoSession = session;

            var nevent = new CustomEvent(Events.LOGIN_SUCCESS);
            document.dispatchEvent(nevent);

            me.setRefreshHandler(session);

        });
    }

    static getUser() {

        return CognitoUtil.userPool.getCurrentUser();
    }

    static changePassword(oldPassword, newPassword) {

        try {
            this.cognitoUser.changePassword(oldPassword, newPassword, function(err, result) {
                if (err) {
                    document.dispatchEvent(new CustomEvent(Events.CHANGE_PASSWORD_FAIL, {detail:{err:err}}));
                    return;
                }
                document.dispatchEvent(new CustomEvent(Events.CHANGE_PASSWORD_SUCCESS));
            });
        } catch (err) {
            console.error(err);
            document.dispatchEvent(new CustomEvent(Events.CHANGE_PASSWORD_FAIL, {detail:{err:err}}));
        }
        
    }

    static finalizeNewPassword(newpass, callback) {
        this.cognitoUser.completeNewPasswordChallenge(newpass, {}, {
          onSuccess: function(result) {
                window.cognitoSession = result;
                callback();
            },
          onFailure: function(error) {
                callback(error.message);
            }
        });
    }

    static resetPassword(mfainput, pass, callback) {
        this.cognitoUser.confirmPassword(mfainput, pass, {
            onSuccess: function() {
                // We don't get the cognito session for some reason
                callback();
            },
            onFailure: function(err) {
                callback(err.message);
            }
        });
    }

    static logout() {
        this.cognitoUser.signOut();
        localStorage.clear();
    }
}
