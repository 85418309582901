import React, { Component } from 'react';
import Select from 'react-select';
import "react-table/react-table.css";
import moment from 'moment';

import Dialog from '../dialog';

export class ThirdpartyStatusModal extends Component {

    constructor() {
    
        super();

        this.state = {
            isOpen: false,
            stats: [ 
            { 
                name: 'Twilio',
                page: 'gpkpyklzq55q',
                url: 'http://status.twilio.com/',
                type: 'statuspage'
            },
            {
                name: 'TokBox',
                page: '9z6ybxjmnqyz',
                url: 'http://status.tokbox.com/',
                type: 'statuspage'
            },
            {
                name: 'Recurly',
                page: 'rq8zvx7w9n0c',
                url: 'http://status.recurly.com/',
                type: 'statuspage'
            },
            {
                name: 'Mapbox',
                page: 'l363gv8nm9gc',
                url: 'http://status.mapbox.com/',
                type: 'statuspage'
            },
            {
                name: 'AWS',
                url: 'http://status.aws.amazon.com/',
                type: 'direct',
                data: {
                    status: {
                        description: "Unknown"
                    }
                }
            },
            ]
        };


    }

    query3rdparty()
    {

        var me = this;

        // Query all of the 3rdparty providers
        this.state.stats.forEach( (item, index, statusArray) => {
            if(item.type == 'statuspage')
            {
                var sp;
                if(window.StatusPage)
                {
                    sp = new window.StatusPage.page({ page : item.page });
                    sp.summary({
                        success : function(data) {
                            statusArray[index].data = data;
                            me.setState({stats: statusArray});
                        }
                    });
                }
                else
                {
                    statusArray[index].data = { status: {
                            description: "Enable Javascript"
                        }
                    };
                    me.setState({stats: statusArray});
                }
            }
            if(item.type == 'direct')
            {
                statusArray[index].data = { status: {
                        description: "Unknown"
                    }
                };
                me.setState({stats: statusArray});
            }
        });


    }

    openModal = (e) => {
        this.setState({isOpen: true});
    }

    closeModal = (e) => {
        this.setState({isOpen: false});
    }

    componentDidMount() {
        this.query3rdparty();
    }

    refresh = (e) => {
        e.preventDefault();

        var i = 0;

        var stats = this.state.stats;

        for(i = 0; i < stats.length; i++)
        {
            stats[i].data = null;
        }

        this.setState({stats: stats});

        this.query3rdparty();
    }


    render() {

        var rows = this.state.stats.map(item => {
            return (
                <div key={item.name} className="row">
                    <div className="col-3">{item.name}</div>
                    <div className="col-9">
                        { item.data ? <a href={item.url}>{item.data['status'].description}</a> : "Fetching..."} 
                    </div>
                </div>
            );
        });

        return (
            <Dialog
                size="small"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="container-fluid">
                            <div className="col-12">
                                { rows }
                            </div>
                        </div>
                    </div>
                    <div align="right">
                        <button onClick={this.refresh}>Refresh</button>
                    </div>

            </Dialog>
        );
    }

}

export default class ThirdpartyStatus extends Component {

    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
        }
    }

    openModal = (e) => {
        e.preventDefault();

        this.setState({modalIsOpen: true});
    }

    closeModal = (e) => {
        this.setState({modalIsOpen: false});
    }

    render()
    {
        var title = "3rdparty Status";


        return ( 
            <div className="3rdparty d-inline-block">
                <a href="#!" onClick={this.openModal}>
                    <img src="/images/3rdparty-status-icon.png" alt="3rdparty Status"/>
                </a>
                <ThirdpartyStatusModal title={title} isOpen={this.state.modalIsOpen} 
                        stage={this.props.stage}
                        onClose={() => {this.setState({modalIsOpen: false})}} 
                />
            </div>
        );

    }
}

