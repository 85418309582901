import React from 'react';
import PropTypes from 'prop-types';


export default class NewCustomInventoryTable extends React.PureComponent {

    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onFormSubmit: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            pendingNewInventoryItems:  [
                {
                    sn: "",
                    iccid: "",
                    imei: "",
                    pending: false,
                    saved: false
                }/*
                {
                    sn: "1RVN11122233",
                    iccid: "8991112233334444556",
                    imei: "9911111122222233",
                    pending: false,
                    saved: true
                },
                {
                    sn: "1RVN11122234",
                    iccid: "8991112233334444557",
                    imei: "9911111122222234",
                    pending: false,
                    saved: true
                },
                {
                    sn: "1RVN11122235",
                    iccid: "8991112233334444558",
                    imei: "9911111122222235",
                    pending: false,
                    saved: true
                },
                {
                    sn: "1RVN11122236",
                    iccid: "8991112233334444559",
                    imei: "9911111122222236",
                    pending: false,
                    saved: false
                }*/
            ],
            confirmDialog: null
        }
    }

    submitButtonAction = () => {
        this.props.onFormSubmit();
    };

    render () {

        const cancelButtonTitle = "Cancel";
        const submitButtonTitle = "Done";
    
        return (
            <>
            <section className="new-inventory-table orders-table inventory">
                <NewCustomInventoryTableHeader />
                {
                    this.state.pendingNewInventoryItems.map( (item) => {
                        return <NewCustomInventoryItem item={item} />
                    })
                }
            </section>
            <div className="actions">
                <button className="destructive" onClick={this.props.onCancel} >
                    {cancelButtonTitle}
                </button>
                <button onClick={this.submitButtonAction} >
                    {submitButtonTitle}
                </button>
            </div>
            {this.state.confirmDialog}
            </>
        )
    }
}

class NewCustomInventoryTableHeader extends React.PureComponent {
    render() {
        return (
            <header className="row">
                <div className="serial-number">Raven S/N</div>
                <div className="iccid">ICCID</div>
                <div className="imei">IMEI</div>
                <div className="item-status">Status</div>
            </header>
        )
    }
}


class NewCustomInventoryItem extends React.PureComponent {

    static propTypes = {
        item: PropTypes.object  // optional
    }

    render() {

        const inputSerialNumber = <input
                type="text"
                placeholder="Raven Unit"
                value={this.props.item? this.props.item.sn : undefined}
            />;

        const inputIccid = <input
                type="text"
                value={this.props.item? this.props.item.iccid : undefined}
            />;

        const inputImei = <input
                type="text"
                value={this.props.item? this.props.item.imei : undefined}
            />;
    

        return (
            <div className="row item">
                <div className="serial-number" >
                    {inputSerialNumber}
                </div>
                <div className="iccid">
                    {inputIccid}
                </div>
                <div className="imei">
                    {inputImei}
                </div>
                <div className="item-status">
                    <button>Save</button>
                    <button>Clear</button>
                </div>
            </div>
        )
    }
}