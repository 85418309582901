
import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import "react-table/react-table.css";
import moment from 'moment';
import globalconfig from '../../../../common/config';

export default class NotesView extends Component {

    render() {

        var columns = [
            {
                Header: 'Time',
                accessor: d => moment.utc(d.ts).unix() * 1000,
                id: 'ts',
                maxWidth: 200,
                Cell: props => <span title={moment.utc(props.original.ts).unix()} >{ moment(props.value).format(globalconfig.display.timeFormat) } ( { moment(props.value).fromNow() } ) </span> ,

            },
            {
                Header: 'Raven',
                accessor: 'ravenid',
                maxWidth: 100,
            },
            {
                Header: 'Serial No',
                accessor: 'enclosure_serial_no',
                maxWidth: 150,
            },
            {
                Header: 'Notes',
                accessor: 'notes'
            },
        ];

        var datasize = this.props.data ? this.props.data.length : 0;
        var paginate = this.props.data ? datasize > this.props.pageSize : false;

        var pagesize = paginate ? this.props.pageSize : datasize;

        return (
            <div id="dtc-listing" className="dtc-list">
                    <ReactTable className="-striped" data={this.props.data}
                        filterable={true}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value) }

                        showPagination={paginate}
                        showPageSizeOptions={false}
                        showPageJump={false}
                        style = {{ height: "65vh"}}
                        pageSize={pagesize}
                        noDataText="No Notes"
                        defaultSorted={[{id: 'ts', desc: true}]}
                        columns={columns} />
            </div>
        );
    }
}

NotesView.defaultProps = {
    pageSize: 100,
};

NotesView.propTypes = {
    pageSize: PropTypes.number,
}

