import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import Clipboard from 'clipboard';

import Dialog from '../dialog';
import Events from '../../../../common/events';
import recurlyOptions from './recurlyOptions';
import globalconfig from '../../../../common/config';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';


export class NewAccountCreateModal extends Component {

    constructor() {
    
        super();

        this.state = {
            channel: '',
            channelid: -1,
            email: '',
            notes: '',
            plancode: null,
            passwd: null,
            planprice: '',
            discount: '',
            name: '',
            isOpen: false,
            hasBilling: false
        };

        this.billingInitialized = false;

    }

    componentDidUpdate() {

        var cb = new Clipboard('#copyPasswdBtn');
    }


    openModal = (e) => {
        this.setState({isOpen: true});
    }

    closeModal = (e) => {
        this.setState({isOpen: false});
    }

    reinitializeRecurly = (e) => {
        if(this.billingInitialized)
        {
            // If the billing stuff is closed, initialization is lost,
            // rereset to reinit later
            if(!this.state.hasBilling)
                this.billingInitialized = false;
        }
        if(this.state.hasBilling && window.recurly)
        {
            // Grab the appropriate key for recurly
            recurlyOptions.publicKey = globalconfig.recurly.key;
            window.recurly.configure(recurlyOptions);
            this.billingInitialized = true;
        }
    }

    onNotesChange = (val) => {
        this.setState({notes: val });
    }

    onEmailChange = (val) => {
        this.setState({email: val });
    }

    onNameChange = (val) => {
        this.setState({name: val });
    }

    onChannelChange = (val) => {
        if(val.obj['channelId'] === 0)
        {
            // Don't touch hasBilling
            this.setState({channel: val.obj['channelName'], channelid: val.obj['channelId'] });
        }
        else
        {
            // force billing off, force plan code to default
            this.setState({
                channel: val.obj['channelName'], 
                channelid: val.obj['channelId'], 
                hasBilling: false ,
                plancode: val.obj['planCode'],
                planprice: 0,
            });
        }

    }

    onPlanChange = (val) => {
        this.setState({plancode: val.value, planprice: val.obj['planPrice'] });
    }

    onPriceChange = (val) => {
        this.setState({planprice: val });
    }

    onDiscountChange = (val) => {
        // FIXME - validate input for discount
        this.setState({discount: val });
    }

    onEditBillingFail = (err) => {

        console.error(err);
        document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
        var message = err && err.message ? err.message : "An unknown error occured while trying to save billing information. Please contact support to resolve this issue."; //TODO LOC BILLING
        this.setState({ message: message, messageType: 'error' });
    }


    onCreateAccount = (e) => {
        //modify billing info
        e.preventDefault();
        var form = e.target;
        if(!this.state.email)
        {
            return this.onEditBillingFail({message: "Email not set", messageType: 'error'}); 
        }

        if(this.state.hasBilling)
        {
            if(!window.recurly)
            {
                return this.onEditBillingFail({message: "JavaScript Not Enabled for Recurly", messageType: 'error'}); 
            }

            document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE, { detail: { loadingPageMessage: "Pushing Data to Recurly" } }));

            window.recurly.token(form, (err, token) => {
                document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
                if (err) {
                    console.error(err);
                    this.onEditBillingFail(err);
                } else {
                    console.log(token);

                    this.performAccountCreate(token);
                }
            });            
        }
        else
        {
            this.performAccountCreate(null);
        }
    }

    performAccountCreate = (recurlyToken) => {
    
        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE,
                { detail: { loadingMessage: 'Creating Fleet Account' } }));


        /* FIXME - add token to URL*/

        this.props.dataStore.createFleetAccount(this.props.stage, 
                this.state.channelid, this.state.email.toLowerCase(), 
                this.state.name, recurlyToken, this.state.notes, 
                this.state.plancode, this.state.planprice, (json) => {
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));

              if(!json.payload)
                {
                  document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
                  this.setState({message: "Error: " + json.errorMessage ? json.errorMessage : "Unknown" , messageType: 'error'});
                  console.error("Account Create Error", json);
                }

              this.setState({
                    created: true, 
                    passwd: json.payload.temporaryPassword,
                    message: json.status + ": Temporary Password: " + json.payload.temporaryPassword,
                    messageType: 'success'
                });
            
        }, (error) => {
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
              this.setState({message: "Error: " + error, messageType: 'error'});
              console.error("Account Create Error", error);
        });

    }

    doNothing = (e) => {
        e.preventDefault();
    }

    handleBillingCheckboxChange = (e) => {

        var me = this;

        this.setState({"hasBilling": e.target.checked}, () => {
            me.reinitializeRecurly();
        });
    }

    renderBillingSection() {
        var content;
        if( this.state.channelid === -1 )
        {
            content =
                <h3>
                    Select a channel
                </h3>;
        }
        else if(this.state.channelid !== 0)
        {
            content =
                <h3>
                    No Billing Info required for Channel { this.state.channel}
                </h3>;
        }
        else
        {

            var checkboxblock = <h3>
                        <input type="checkbox" 
                            name="billing-checkbox" 
                            checked={this.state.hasBilling}
                            onChange={this.handleBillingCheckboxChange} /> :
                        <i className="material-icons">payment</i>Credit Card Information
                    </h3>;

            if(this.state.hasBilling)
            {
                content = <Fragment >
                        { checkboxblock }
                        <div className="row billing-block">
                            <div className="col-12">
                                <label htmlFor="recurly-number">Credit Card Number</label>
                                <div data-recurly="number" id="recurly-number"></div>
                            </div>

                            <div className="col-4 col-sm-3">
                                <label htmlFor="recurly-cvv">CVV</label>
                                <div data-recurly="cvv" id="recurly-cvv"></div>
                            </div>

                            <div className="col-12 d-md-none d-lg-none d-xl-none"></div>

                            <div className="col-4 col-md-3">
                                <label htmlFor="recurly-month">Expiry Month</label>
                                <div data-recurly="month" id="recurly-month"></div>
                            </div>

                            <div className="col-12 d-sm-none d-md-none d-lg-none d-xl-none"></div>

                            <div className="col-8 col-md-3">
                                <label htmlFor="recurly-number">Expiry Year</label>
                                <div data-recurly="year" id="recurly-year"></div>
                            </div>

                            <div className="col-12 col-sm-6">
                                <label htmlFor="first-name">First Name</label>
                                <input id="first-name" type="text" data-recurly="first_name" placeholder="Jane" />
                            </div>

                            <div className="col-12 col-sm-6">
                                <label htmlFor="last-name">Last Name</label>
                                <input type="text" data-recurly="last_name" placeholder="Doe" />
                            </div>
                            <h4>Billing Address</h4>

                            <div className="col-12">
                                <label htmlFor="address1">Street Address Line 1</label>
                                <input id="address1" type="text" data-recurly="address1" placeholder="123 Raven rd." />
                                <label htmlFor="address2">Street Address Line 2</label>
                                <input id="address2" type="text" data-recurly="address2" />
                            </div>

                            <div className="col-12 col-sm-6">
                                <label htmlFor="city" >City</label>
                                <input id="city" type="text" data-recurly="city" placeholder="Ottawa" />
                            </div>

                            <div className="col-12 col-sm-6">
                                <label htmlFor="postal_code">ZIP/Postal Code</label>
                                <input id="postal_code" type="text" data-recurly="postal_code" placeholder="12345/A1A1A1" />
                            </div>

                            <div className="col-12 col-sm-6">
                                <label htmlFor="country">Country</label>
                                <input id="country" type="text" data-recurly="country" placeholder="Canada" />
                            </div>

                            <div className="col-12 col-sm-6">
                                <label htmlFor="state">State/Province</label>
                                <input id="state" type="text" data-recurly="state" placeholder="Ontario" />
                            </div>

                            <input type="hidden" name="recurly-token" data-recurly="token" />

                        </div>
                        </Fragment >;

            }
            else
            {
                content = <Fragment >
                        { checkboxblock }
                        <div>Click checkbox to add billing info</div>
                        </Fragment >;
            }
        }

        return (
            <div className="col-12 form-section">
            { content }
            </div>
        );

    }

    render() {

        // FIXME - probably not the most efficient.
        this.channels = this.props.channels.map(elem => {
                var obj = elem;
                return { value : '' + obj['channelId'],
                    label: '' + obj['channelName'] + " (" + obj['channelCode'] + ")",
                    obj: obj
                };

            });

        // FIXME - probably not the most efficient.
        this.plans = this.props.plans.map(elem => {
                var obj = elem;
                return { value : '' + obj['planCode'],
                    label: '' + obj['planName'],
                    obj: obj
                };

            });

        var errorMessage = null;

        if(this.state.messageType === 'success')
        {
            errorMessage = this.state.message ?
                <p className="success-message"><i className="material-icons">check_circle_outline</i>{this.state.message}</p> :
                null;
        }
        else
        {
            errorMessage = this.state.message ?
                <p className="error-message"><i className="material-icons">error_outline</i>{this.state.message}</p> :
                null;
        }

        return (
            <Dialog
                size="medium"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                onAfterOpen={this.reinitializeRecurly}
                >

                <div className="container-fluid form-card">

                    <div className="row">

                        <div className="col-12 content-group">

                            <h2>New Account Details</h2>

                            <div className="row">
                                <div className="col-2">
                                    <label htmlFor="stage">Stage</label>
                                </div>
                                <div className="col-4">{this.props.stage} </div>
                                <div className="col-2">
                                    <label htmlFor="channel">Channel</label>
                                </div>
                                <div className="col-2">
                                    <Select id="channel"
                                    placeholder="Channel..."
                                    searchable={true}
                                    value={this.state.channelid}
                                    options={this.channels}
                                    name="channel"
                                    onChange={this.onChannelChange}
                                    clearable={false} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <label htmlFor="plancode">Plan Code</label>
                                </div>
                                <div className="col-4">
                                    { this.state.channelid === 0 ?
                                        <Select id="plancode"
                                        placeholder="Plan..."
                                        searchable={true}
                                        value={this.state.plancode}
                                        options={this.plans}
                                        name="plancode"
                                        onChange={this.onPlanChange}
                                        clearable={false} /> :
                                        "Default Plan"
                                    }
                                </div>
                                <div className="col-2">
                                    <label htmlFor="planprice">Price</label>
                                </div>
                                <div className="col-3">
                                    { this.state.channelid === 0 ?
                                        <input type="text" 
                                            name="price"
                                            onChange={(e) => { this.onPriceChange(e.target.value) }} 
                                            value={this.state.planprice}
                                        /> :
                                        "Default Price"
                                    }
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-2">
                                    <label htmlFor="email">Email Address</label>
                                </div>
                                <div className="col-10">
                                    <input type="text" 
                                        name="email"
                                        onChange={(e) => { this.onEmailChange(e.target.value) }} 
                                        value={this.state.email}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <label htmlFor="notes">Notes</label>
                                </div>
                                <div className="col-10">
                                    <input type="text" 
                                        name="notes"
                                        onChange={(e) => { this.onNotesChange(e.target.value) }} 
                                        value={this.state.notes}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-2">
                                    <label htmlFor="name">Account Name</label>
                                </div>
                                <div className="col-10">
                                    <input type="text" 
                                        name="name"
                                        onChange={(e) => { this.onNameChange(e.target.value) }} 
                                        value={this.state.name}
                                    />
                                </div>
                            </div>


{/*
                            <div className="row">
                                <div className="col-12">
                                    <h3>Discounts</h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-4">
                                </div>
                                <div className="col-2">
                                    <label htmlFor="trial-length">Account Discount</label>
                                </div>
                                <div className="col-4">
                                    <input type="text" 
                                        name="discount"
                                        onChange={(e) => { this.onDiscountChange(e.target.value) }} 
                                        value={this.state.discount}
                                    />
                                </div>
                            </div>
*/}

                            <form className="row" onSubmit={this.onCreateAccount}>

                                { this.renderBillingSection()}

                                <div className="col-12">
                                    {this.state.email ?
                                        <button>Create Account</button> :
                                        <button disabled>Create Account</button>
                                    }
                                    {this.state.created ?
                                        <div>
                                            Temporary Password: {this.state.passwd} <button className="btn" id="copyPasswdBtn" data-clipboard-text={this.state.passwd} onClick={(e) => e.preventDefault() } >Copy to clipboard</button>
                                            <Link to={"/account/" + this.props.stage + "/" + this.state.email.toLowerCase()}>Go to Account</Link> 
                                        </div> :
                                        null
                                    }
                                    {errorMessage}
                                </div>
                            </form>


                        </div>
                    </div>
                    
                    </div>
            </Dialog>
        );
    }

}

export default class NewAccountCreate extends Component {

    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
        }
    }

    openModal = (e) => {
        e.preventDefault();

        this.setState({modalIsOpen: true});
    }

    closeModal = (e) => {
        this.setState({modalIsOpen: false});
    }

    render()
    {
        var title = "Create New Fleet Account (" + this.props.stage + ")"; 


        return ( 
            <div className="3rdparty d-inline-block">
                <a href="#!" onClick={this.openModal}>
                    <img src="/images/baseline_person_add_white_24dp.png" alt="New Fleet Account"/>
                </a>
                <NewAccountCreateModal title={title} isOpen={this.state.modalIsOpen} 
                        stage={this.props.stage}
                        onClose={() => {this.setState({modalIsOpen: false})}} 
                />
            </div>
        );

    }
}

