import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import "react-table/react-table.css";
import moment from 'moment';
import globalconfig from '../../../../common/config';

class TombstonePreview extends Component {
    constructor()
    {

        super();
    
        this.state = {
            content: "Loading..."
        };
    }

    componentDidMount()
    {
        this.fetchTombstonePreview();
    }

    fetchTombstonePreview()
    {
        var me = this;

        if(this.props.tombstone.endsWith(".gz"))
        {
            me.setState({content: "No Previews for compressed data"});
        }
        else
        {

            return fetch(this.props.url, { 
                    method: "GET",
                    headers: {
                    "Range": "bytes=0-2000"
                  }
                })
              .then((response) => {
                if (response.ok) {
                    return response.text();
                }
                else
                {
                    return "Failed to fetch data";
                }
            }).then((text) => {
                me.setState({content: text});
            });
        }
    }

    render()
    {
        return <div className="tombstone-preview"><pre> {this.state.content} </pre></div>;
    }
}

export default class TombstoneView extends Component {

    render() {

        var columns = [
            {
                Header: 'Time',
                accessor: d => moment.utc(d.LastModified).unix() * 1000,
                id: 'LastModified',
                Cell: props => <span title={moment.utc(props.original.LastModified).unix()}>{ moment(props.value).format(globalconfig.display.timeFormat) } ( { moment(props.value).fromNow() } ) </span> ,
                defaultSortDesc: true,
            },
            {
                Header: 'Raven Id',
                accessor: 'raven_unit_id',
                maxWidth: 100
            },
            {
                Header: 'Build',
                accessor: 'build',
                maxWidth: 100
            },
            {
                Header: 'File',
                accessor: 'name',
                Cell: cellInfo => (
                    <a href={cellInfo.original.url}>{cellInfo.original.name}</a>)
            },
            {
                Header: 'Size',
                accessor: 'Size',
                maxWidth: 100
            },
        ];

        var datasize = this.props.data ? this.props.data.length : 0;
        var paginate = this.props.data ? datasize > this.props.pageSize : false;

        var pagesize = paginate ? this.props.pageSize : datasize;

        return (
            <div id="tombstone-listing" className="tombstone-list">
                    <ReactTable className="-striped" data={this.props.data}
                        filterable={true}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value) }
                        showPagination={paginate}
                        showPageSizeOptions={true}
                        showPageJump={false}
                        style = {{ height: "65vh"}}
                        noDataText="No Tombstones"
                        defaultSorted={[{id: 'LastModified', desc: true}]}
                        defaultPageSize={pagesize}
                        loading={this.props.loading}
                        onPageChange={(pageIndex) => { if(pageIndex >= (this.props.data.length/pagesize) - 2) { this.props.requestMoreData(); } } }

                        SubComponent = { row => {
                            return ( <TombstonePreview tombstone={row.original.name} url={row.original.url} /> );
                        } }
                        columns={columns} />
            </div>
        );
    }
}


TombstoneView.defaultProps = {
    pageSize: 100,
};

TombstoneView.propTypes = {
    pageSize: PropTypes.number,
}
