import React, { Component } from 'react';

import Dialog from '../dialog';
import Events from '../../../../common/events';
import NotesView from './notesView';

import CognitoUtil from '../../../../aws/cognito/cognitoUtil';

export default class NotesHistoryModal extends Component {

    constructor() {
        super();

        this.state = {
            newNote: ''
        };

        this.localState = {};
    }

    queryNotesData(raven)
    {
        this.props.dataStore.getNotes(raven);
    }

    componentDidMount() {
        this.localState.ravenid = this.props.raven.item.Id;
        this.queryNotesData(this.props.raven);
    }

    componentDidUpdate() {

        if(this.props.raven && this.props.raven.item && this.props.raven.item.Id)
        {
            if(this.props.raven.item.Id !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.raven.item.Id;

                this.queryNotesData(this.props.raven);
            }
        }
    }

    onNoteChange = (event) => {
        if(event && this.state.newNote !== event.target.value) {
            this.setState({newNote: event.target.value });
        }
    }

    onAddNote = (val) => {
        if(this.state.newNote === null || this.state.newNote === '') {
            this.setState({message: "No Notes to Send" });
            return

        }

        var me = this;

        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE,
                { detail: { loadingMessage: 'Adding Note' } }));

        var prefix = this.props.dataStore.prefix2;

        var url = prefix + "updatenotes"
            + "?stage=" + this.props.stage
            + "&type=" + "raven_unit"
            + "&ravenid=" + this.props.raven.item.Id
            + "&ravenUuid=" + this.props.raven.item['Raven']['Raven UUID']
            + "&ravenUnitId=" + this.props.raven.item['Raven']['Unit Id']
            + "&notes=" + this.state.newNote;

        this.setState({message: null});

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
          .then((response) => {
            return response.json();
          }).then((json) => {
              // We grabbed the json.  Put it in the state
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));

              this.setState({message: json});

              // Requery the notes after setting a new one
              setTimeout( () => {this.queryNotesData(this.props.raven) }, 5000);
          }).catch((error) => {

              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
              var message = "Unknown error adding note" + error;

              this.setState({message: message});

              console.error("Add Note Error", error);
          });



    }

    render() {

        return (
            <Dialog
                size="medium"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.props.raven.notes ? 
                                        this.props.raven.notes.Error ? 
                                            <p>{this.props.raven.notes.Error}</p> :
                                        this.props.raven.notes.length !== 0 ?  
                                            <NotesView 
                                                data={this.props.raven.notes} 
                                                /> : 
                                            <p>No Notes Data...</p> :
                                        <p>Loading...</p>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <label>New Note</label>
                            </div>
                            <div className="col-10">
                                <input autoComplete="off" type="text" value={this.state.newNote} name="newnote" onChange={this.onNoteChange}></input>
                            </div>
                            <div className="col-1">
                                <div className="actions">
                                    <button onClick={this.onAddNote} >
                                        Add Note
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div>{this.state.message}</div>
                            </div>
                        </div>
                    </div>
            </Dialog>
        );
    }

}
