import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import Header from './panels/header';
import { AddOrderModal, RemoveOrderModal } from './modals/orders';
import { EditAccountModal } from './modals/editAccount';
import EditBillingModal from '../tool/modals/user/editBillingModal';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import moment from 'moment';
import globalconfig from '../../common/config';

import Events from '../../common/events';
import RavenDataStore from '../../common/ravenDataStore';

function PPDate(indate)
{
    if(!indate)
        return '-';
    return moment.utc(indate).local().format(globalconfig.display.timeFormat);
}

class AccountView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ravenData: [],
            addOrderModalIsOpen: false,
            removeOrderModalIsOpen: false,
            accountNotificationIsOpen: false,
            editOrderModal: null,
            editAccountModal: null,
            accountNotificationText: null
        };

        this.setReportHeaders();

        this.props.dataStore.queryFleetPlans(this.props.match.params.stage);

        this.pageSize = 10;
    }

    //Report headers for both fleet and consumer
    setReportHeaders() {

        let stage = this.props.match.params.stage;

        this.ordersColumns = [
            {
                Header: 'ID',
                accessor: 'orderNumber',
                Cell: props => props.original.canceled ? <div><strike>{props.value}</strike></div> : <div>{props.value}<a href="#" onClick={(e) => { e.preventDefault(); this.openEditOrderModal(e, props.original) } }><img src="/images/ic_edit_white_18dp_1x.png" alt="" /></a> </div>

            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: props => props.original.canceled ? <div>Canceled</div> : <div>{props.value}</div>
            },
            {
                Header: 'Date',
                accessor: 'createDate',
                Cell: props => props.original.canceled ? <div><strike>{PPDate(props.value)}</strike></div> : <div>{PPDate(props.value)}</div>
            },
            {
                Header: 'Date Shipped',
                accessor: 'shippedDate',
                Cell: props => props.original.canceled ? <div><strike>{PPDate(props.value)}</strike></div> : <div>{PPDate(props.value)}</div>

            },
            {
                Header: 'Ordered',
                accessor: 'quantity',
                Cell: props => props.original.canceled ? <div><strike>{props.value}</strike></div> : <div>{props.value}</div>
            },
            {
                Header: 'Actual',
                accessor: 'ravenCount',
                Cell: props => props.original.canceled ? <div><strike>{props.value}</strike></div> : <div>{props.value}</div>
            },
            {
                Header: 'Provisioned',
                accessor: 'provisionedCount',
                Cell: props => props.original.canceled ? <div><strike>{props.value}</strike></div> : <div>{props.value}</div>
            },
        ];

        this.fleetSingleOrderColumns = [
            {
                Header: 'Raven',
                accessor: 'enclosureSerialNo',
                Cell: props => <Link to={"/raven/"+ stage +"/"+ props.value}>{props.value}</Link>
            },
            {
                Header: 'Date Provisioned',
                accessor: 'provisionedDate',
                Cell: props => <div>{PPDate(props.value)}</div>
            },
            {
                Header: 'Last Seen',
                accessor: 'lastSeen',
                Cell: props => <div>{PPDate(props.value)}</div>
            },
        ];

        this.consumerSingleOrderColumns = [
            {
                Header: 'Raven',
                accessor: 'enclosureSerialNo',
                Cell: props => <Link to={"/raven/"+ stage +"/"+ props.value}>{props.value}</Link>
            },
            {
                Header: 'Date Provisioned',
                accessor: 'lastProvisioned',
                Cell: props => <div>{PPDate(props.value)}</div>
            },
            {
                Header: 'Last Seen',
                accessor: 'lastSeen',
                Cell: props => <div>{PPDate(props.value)}</div>
            },
            {
                Header: 'Plan',
                accessor: 'name',
                Cell: props => <div>{props.value}</div>
            },
            {
                Header: 'Subscription UUID',
                accessor: 'subscriptionUuid',
                Cell: props => <div>{props.value}</div>
            },
            {
                Header: 'Data Limit',
                accessor: 'dataLimit',
                Cell: props => <div>{props.value}</div>
            }
        ];

    }

    onRemoveOrder = (orderId, comment) => {
        // FIXME - actually send order ID to the kloud, to be removed

        this.props.dataStore.cancelFleetOrder(this.props.accountid, orderId, comment,
                () => {
                    return this.props.dataStore.getAccountInfo(this.props.accountid);
                });

    }

    onAddOrder = (newOrderId, count, comment) => {
        // actually send new order ID to the kloud, and requery

        if (!newOrderId) {
            this.setState({accountNotificationIsOpen: true, accountNotificationText: `Warning: Order # is required`})
            return

        }
        this.setState({accountNotificationIsOpen: true, accountNotificationText: `Adding Order: ${newOrderId}...`})
        this.props.dataStore.addFleetOrder(this.props.accountid,
                newOrderId, count, comment, 
                () => {
                    if (newOrderId) {
                        this.setState({accountNotificationIsOpen: true, accountNotificationText: `Added Order: ${newOrderId}!`})
                    }
                    return this.props.dataStore.getAccountInfo(this.props.accountid);
                });
    }

    onEditOrder = (orderId, count, comment, ravenSerialNumbers, shippedDate) => {

        var nonNullSerialNumbers = ravenSerialNumbers.filter(e => e != null).filter(e => e != "");
        var orderProcessedMsg = `Order ${orderId} processed successfully!`

        if (nonNullSerialNumbers.length != count && nonNullSerialNumbers.length > 0) {
            //return 0 in this case to indicate we should not close the modal
            //and risk losing all entered serials
            return 0
        }

        this.setState({accountNotificationIsOpen: true, accountNotificationText: `Updating Order ${orderId}...`})

        this.props.dataStore.changeFleetOrder(this.props.accountid,
                orderId, count, comment, 
                () => {
                        // only show update notification if not processing order after
                        if (!ravenSerialNumbers.every(element => (element))){
                            this.setState({accountNotificationIsOpen: true, accountNotificationText: 'Order Updated!'})
                            this.props.dataStore.getAccountInfo(this.props.accountid);
                            return
                        }
                    }
                );

        // processShipment if necessary
        if (orderId && shippedDate && (nonNullSerialNumbers.length > 0) && nonNullSerialNumbers.every(ele=>(ele))) {

            this.setState({accountNotificationIsOpen: true, accountNotificationText: `Procesing Order ${orderId}...`})

            this.props.dataStore.processShipments(
                orderId, ravenSerialNumbers, shippedDate,
                (response) => {
                    this.setState({accountNotificationIsOpen: true, accountNotificationText: orderProcessedMsg})
                    this.props.dataStore.getAccountInfo(this.props.accountid);
                    return
                },
                (response) => {
                    this.setState({accountNotificationIsOpen: true, accountNotificationText: `Error processing order: ${response}`})
                    this.props.dataStore.getAccountInfo(this.props.accountid);
                    return
                });
            return
        }
    }

    onEditAccount = (prop, value) => {

        this.setState({accountNotificationIsOpen: true, accountNotificationText: "..."})
        this.props.dataStore.updateFleetAccount(this.props.stage,
                this.props.accountid, prop, value, (response) => {
                    // onSuccess
                    this.setState({accountNotificationIsOpen: true, accountNotificationText: "Account updated succesfully!"})
                    return this.props.dataStore.getAccountInfo(this.props.accountid);
                }, (response) => {
                    // onFail
                    this.setState({accountNotificationIsOpen: true, accountNotificationText: `Error editing account: ${response}`})
                    return this.props.dataStore.getAccountInfo(this.props.accountid);
                });

        // fixme
    }

    openEditAccountModal = (e, property) => {

        e.preventDefault();

        var me = this;

        var modal =
                    <EditAccountModal isOpen={true}
                        onClose={() => {me.setState({editAccountModal: null})}}
                        onSuccess={me.onEditAccount}
                        property={property}
                        plans={this.state.ravenData.plans}
                        data={this.props.account}
                    />

        this.setState({editAccountModal: modal});

    }

    openEditBillingModal = (e, property) => {
        e.preventDefault();

        var me = this;

        var modal =
                    <EditBillingModal isOpen={true}
                        onClose={() => {me.setState({editAccountModal: null})}}
                        closeEditBillingModal={() => {me.setState({editAccountModal: null})}}
                        onSuccess={me.onEditAccount}
                        property={property}
                        plans={this.state.ravenData.plans}
                        data={this.props.account}
                    />

        this.setState({editAccountModal: modal});
    }

    openAddOrderModal = (e) => {
        e.preventDefault();
        this.setState({addOrderModalIsOpen: true});
    }

    openEditOrderModal = (e, orderinfo) => {
        e.preventDefault();

        var me = this;

        var modal =
                    <AddOrderModal
                        isOpen={true}
                        onClose={() => {me.setState({editOrderModal: null})}}
                        onSuccess={me.onEditOrder}
                        data={me.props.account}
                        currentOrder={orderinfo}
                    />

        this.setState({editOrderModal: modal});

    }

    openRemoveOrderModal = (e) => {
        e.preventDefault();

        this.setState({removeOrderModalIsOpen: true});

    }

    renderOrder(orderData) {
        // Generate the data

        var orderid = orderData.original.orderNumber;
        var seenSerials = new Set();

        var data = this.props.account.ravens.filter((el) => {
            if (el.orderNumber === orderid) {
                // do not display the same raven twice on the same order
                // fixes glitch in dev/test/qa when ordering the same raven many times
                if (!seenSerials.has(el.enclosureSerialNo)) {
                    seenSerials.add(el.enclosureSerialNo)
                    return true
                }
            }
            return false
        });

        var enclosureSerialNumbers = data.map((el) => el.enclosureSerialNo);

        var datasize = data ? data.length : 0;

        var tracking;

        if(orderData.original.trackingNumber)
        {
            if(orderData.original.trackingUrl)
            {
                tracking = <a href={orderData.original.trackingUrl} target="_blank">
                        {orderData.original.trackingNumber}</a>;
            }
            else
            {
                tracking = orderData.original.trackingNumber;
            }
        }

        if(tracking)
            tracking = <div className="col-3">{tracking}</div>

        // Generate the table
        return (
            <div>
                <div className="row">
                    <div className="col-1"><strong>Comment</strong></div>
                    <div className="col-9">{orderData.original.comment}</div>
                </div>
                <div className="row">


                    <div className="col-1"></div>
                    <div className="col-3"></div>
                    <div className="col-1"><strong>Last Update</strong></div>
                    <div className="col-3">{orderData.original.lastUpdateDate}</div>
                    <div className="col-1"><strong>Tracking</strong></div>
                    <div className="col-3">{tracking}</div>


                    {/* Q: should this go here or in the report headers for easier access? */}
                    {/* gave the button a bit more space (col-3) since it was getting cut off */}
                    {enclosureSerialNumbers.length > 0 ? <div className="col-3"><button onClick={() => {navigator.clipboard.writeText(enclosureSerialNumbers)}}> Copy Serials</button></div> : null}

                </div>
                { datasize > 0 ?
                    <div className="row reports-page-subdata">
                        <div className="col-10">
                            <ReactTable className="-striped" data={data}
                                showPagination={false}
                                showPageSizeOptions={false}
                                showPageJump={false}
                                pageSize={datasize}
                                noDataText="No Ravens"
                                defaultSorted={[{ id: 'Raven' }]}
                                columns={this.fleetSingleOrderColumns}
                                collapseOnDataChange={false}
                                getTheadProps={d => {return {className: "sub-table"}}}
                            />
                        </div>
                    </div> : null }
            </div>
        );

    }


    // TODO: issue here somewhere i believe (??)
    renderRavenData(accountData) {
        if(!accountData) return "No orders or Ravens yet";

        let datasize;

        //Temporary check for fleet accounts, consumers do not have only a single plan
        if (accountData.plan) {
            datasize = this.props.account && this.props.account.orders ? this.props.account.orders.length : 0;
            let paginate = datasize > 0 ? datasize > this.pageSize : false;
            let pagesize = paginate ? this.pageSize : datasize;

            let numOrdered = 0;
            let numActual = 0;
            let numProvisioned = 0;

            accountData.orders.forEach((elem) => {
                if(!elem.canceled)
                {
                    numOrdered += elem.quantity;
                    numActual += elem.ravenCount;
                    numProvisioned += elem.provisionedCount;
                }
            });       

            // Fleet Orders
            return <div className="row">
                        <h3>Orders (Ravens {numProvisioned}/{numActual}/{numOrdered})</h3>
                        <div className="col-12">
                            <ReactTable
                                className="-striped" data={this.props.account.orders}
                                showPagination={paginate}
                                showPageSizeOptions={false}
                                showPageJump={false}
                                style = {{height: "50vh"}}
                                pageSize={pagesize}
                                noDataText="No orders"
                                defaultSorted={[{ id: 'ID'  }]}
                                columns={this.ordersColumns}
                                collapseOnDataChange={false}
                                SubComponent = { row => {
                                    return ( this.renderOrder(row) );
                                } }
                            />
                        </div>
                    </div>;
        }
        
        if (!accountData.ravenPlans) return "No Ravens yet";

        datasize = accountData && accountData.ravenPlans ? accountData.ravenPlans.length : 0;
        let paginate = datasize > 0 ? datasize > this.pageSize : false;
        let pagesize = paginate ? this.pageSize : datasize;

        let numActual = accountData.ravenPlans ? accountData.ravenPlans.length : 0;
        let numProvisioned = accountData.ravenPlans ? accountData.ravenPlans.filter((elem) => { return elem.provisionedDate; }).length : 0;

        // Consumer Ravens + Plans in one table
        return <div className="row">
                    <h3>Ravens</h3>
                    <div className="col-12">
                        <ReactTable
                            className="-striped" data={accountData.ravenPlans}
                            showPagination={paginate}
                            showPageSizeOptions={false}
                            showPageJump={false}
                            style = {{height: "50vh"}}
                            pageSize={pagesize}
                            noDataText="No Ravens"
                            defaultSorted={[{ id: 'Raven' }]}
                            columns={this.consumerSingleOrderColumns}
                            collapseOnDataChange={false}
                        />
                    </div>
                </div>;

    }

    renderFleetPlanDetails(accountData)
    {
        return (
            <>
                <div className="col-1"><strong>Plan</strong></div>
                <div className="col-3">{accountData.plan.name}</div>
                <div className="col-1"><strong>Price</strong> </div>
                <div className="col-3">{accountData.plan.price}</div>
            </>
        )
    }

    //TODO: Add type of account to the account details
    renderAccountDetails(accountData) {

        var discounts = [];
        let fleetPlan;
        
        //Only display fleet specific info for fleet accounts
        if(accountData.plan){
            if(accountData.plan.discount && accountData.plan.discount > 0)
            {
                discounts[0] = <div key='d1' className="col-1"><strong>Discount</strong></div>
                discounts[1] = <div key='d2' className="col-2">{accountData.plan.discount}%</div>
            }
            fleetPlan = this.renderFleetPlanDetails(accountData);
        }
        
        return <div>
                <div className="row">
                    <div className="col-12">
                        <h3>Account</h3>
                    </div>
                    <div className="col-1"><strong>Name</strong>
                        <a href="#" onClick={(e) => { this.openEditAccountModal(e, "name") } }><img src="/images/ic_edit_white_18dp_1x.png" alt="Edit" /></a>
                    </div>
                    <div className="col-3">{accountData.account.name ? accountData.account.name : accountData.account.firstName }</div>
                    <div className="col-1"><strong>Email</strong>
                            <a href="#" onClick={(e) => { this.openEditAccountModal(e, "email") } }><img src="/images/ic_edit_white_18dp_1x.png" alt="Edit" /></a> </div>
                    <div className="col-3">{accountData.account.email}</div>
                    <div className="col-1"><strong>Account Type</strong></div>
                    <div className="col-3">{accountData.account.type}</div>
                    <div className="col-1"><strong>Start Date</strong></div>
                    <div className="col-3">{PPDate(accountData.account.createDate)}</div>
                    <div className="col-1"><strong>Status</strong></div>
                    <div className="col-3">{accountData.account.status}</div>

                    { accountData.plan ? fleetPlan : null }

                    <div className="col-1"><strong>Channel Code</strong></div>
                    <div className="col-3">{accountData.channel ? accountData.channel.code : "UNKNOWN"}</div>
                    <div className="col-1"><strong>Channel Name</strong></div>
                    <div className="col-3">{accountData.channel ? accountData.channel.name : "UNKNOWN"}</div>
                </div>
                <div className="row">
                    <div className="col-1"><strong>Notes</strong>
                            <a href="#" onClick={(e) => { this.openEditAccountModal(e, "notes") } }><img src="/images/ic_edit_white_18dp_1x.png" alt="Edit" /></a> </div>
                    <div className="col-11">{accountData.account.notes}</div>
                </div>
                {discounts}
            </div>
    }

    renderBillingDetails(accountData) {

        var billingEditButton = <a href="#" onClick={(e) => { this.openEditBillingModal(e) } }><img src="/images/ic_edit_white_18dp_1x.png" alt="Edit" /></a>;

        if(accountData.channel && accountData.channel.channelId && accountData.channel.channelId != 0)
        {
            billingEditButton = null;
        }

        if(!accountData.account.billingInfo)
        {
            return <div className="row">
                    <div className="col-12">
                        <h3>No Billing Info {billingEditButton}</h3>
                    </div>
                </div>;
        }

        return <div className="row">
            <div className="col-12">
                <h3>Billing {billingEditButton}</h3>
            </div>
            <div className="col-1"><strong>Name</strong></div>
            <div className="col-3">{accountData.account.billingInfo.firstName} {accountData.account.billingInfo.lastName}</div>
            <div className="col-1"><strong>Company</strong></div>
            <div className="col-3">{accountData.account.billingInfo.company}</div>
            <div className="col-1"><strong></strong></div>
            <div className="col-3"></div>
            <div className="col-1"><strong>Address</strong></div>
            <div className="col-11">{accountData.account.billingInfo.address1},
                                { accountData.account.billingInfo.address2 ? accountData.account.billingInfo.address2 + "," : null }
                                {accountData.account.billingInfo.city} {accountData.account.billingInfo.state},
                                {accountData.account.billingInfo.zip},
                                {accountData.account.billingInfo.country}
            </div>
            <div className="col-1"><strong>Card</strong></div>
            <div className="col-11">{accountData.account.billingInfo.cardType} {accountData.account.billingInfo.lastFour} {accountData.account.billingInfo.month}/{accountData.account.billingInfo.year}</div>
        </div>;
    }

    render() {
        return (
            <div>
                { this.state.accountNotificationIsOpen ? <div className="row" id="account-notification"> {this.state.accountNotificationText} </div> : null }
                <div className="row">
                    <div className="col-12">
                        <h2>Account Details for {this.props.match.params.email} </h2>
                    </div>
                </div>
                {this.renderAccountDetails(this.props.account)}
                {this.renderBillingDetails(this.props.account)}
                {this.renderRavenData(this.props.account)} 

                { this.props.account.plan ?
                <div className="row">
                    <div className="col-12"> {/** switch on consumer vs fleet */}
                        <button onClick={this.openAddOrderModal}>Add Order</button>
                        <button onClick={this.openRemoveOrderModal}>Cancel Order</button>
                    </div> 
                    <AddOrderModal 
                        isOpen={this.state.addOrderModalIsOpen}
                        onClose={() => {this.setState({addOrderModalIsOpen: false})}}
                        onSuccess={this.onAddOrder}
                        data={this.props.account}
                    /> 
                     <RemoveOrderModal
                        isOpen={this.state.removeOrderModalIsOpen}
                        onClose={() => {this.setState({removeOrderModalIsOpen: false})}}
                        onSuccess={this.onRemoveOrder}
                        data={this.props.account}
                    /> 
                    { this.props.account.plan ? this.state.editOrderModal : null} {/** switch on consumer vs fleet */}
                </div> : null }
                {this.state.editAccountModal}
            </div>
        );
    }
}

export default class AccountPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ravenData: [],
        };

        this.localState = {}
        this.dataStore = new RavenDataStore(this.dataChangeCallback);
        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE, { detail: { loadingMessage: 'Loading Raven List' } }));
    }

    componentDidMount()
    {
        // Get all accounts
        this.dataStore.queryAccounts(this.props.match.params.stage);
        this.localState.stage = this.props.match.params.stage;
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(this.props.match.params.stage !== this.localState.stage)
        {
            this.localState.stage = this.props.match.params.stage;
            this.dataStore.queryAccounts(this.props.match.params.stage);
            this.dataStore.queryFleetPlans(this.props.match.params.stage);
        }
    }

    dataChangeCallback = (ravenData) => {
        document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
        this.setState({ravenData: ravenData});
    }

    onRavenStageChanged = (stage) => {

        this.props.history.push("/account/" + stage);

    }

    onSelectRavenSearch = (id) => {

        var entry = this.state.ravenData.accounts.find((elem) => {
            if(!elem.account)
                return false;
            return elem.account.accountId === id;
        });

        if(entry)
            this.props.history.push("/account/"+ this.localState.stage + '/' + entry['account']['email']);
    }

    renderAccount = (props) => {

        if(props.match.params.email)
        {
            var entryid;
            if(this.state.ravenData.accounts)
            {
                entryid = this.state.ravenData.accounts.findIndex((elem) => {
                    if(elem && elem['account'])
                        return elem['account']['email'] === props.match.params.email;
                    return false;
                });
            }

            if (typeof entryid === 'undefined' || entryid < 0 ||
                    !this.state.ravenData.accounts[entryid])
            {
                return <div>Unable to find account details...</div>;
            }

            var account = this.state.ravenData.accounts[entryid];

            if(account.error)
            {
                return <div>ERROR: {account.error}</div>;
            }
            else if(account.account && !account.account.status)
            {

                if(!this.dataStore.getAccountInfo(account['account']['accountId']))
                {
                    account.error = "Refusing to query for account that doesn't exist";
                    return <div>ERROR: Account does not exist, data not loaded.</div>;
                }
                return <div className="loading-inline-container">
                    <div className="loading-activity-indicator"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>;
            }

            return (
                <AccountView
                    dataStore={this.dataStore}
                    stage={this.props.match.params.stage}
                    accountid={account['account']['accountId']}
                    account={account}
                    {...this.props} {...props} />
            );
        }
        else
        {
            return <div>Select an account...</div>;
        }
    }

    render () {

        return (
            <div id="account-page" className="account-page">
                <div className="container-fluid">
                    <Header
                        ravenStages={ this.props.stages }
                        stage={this.props.match.params.stage}
                        ravenList={this.state.ravenData.accounts ? this.state.ravenData.accounts : []}
                        ravenStageLoader={this.getRavenStages}
                        onRavenStageChange={this.onRavenStageChanged}
                        onSelectRavenSearch={this.onSelectRavenSearch}
                    />
                </div>

                <div className="main-content container-fluid">
                    <div className="row">
                        <div className="listing col-12" id='feature-listing'>
                            <Route path="/account/:stage/:email?" render={this.renderAccount} />
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}
