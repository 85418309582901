import React, { Component } from 'react';

import Dialog from '../dialog';
import TombstoneView from './tombstoneView';

import CognitoUtil from '../../../../aws/cognito/cognitoUtil';

import moment from 'moment';

export default class TombstoneHistoryModal extends Component {

    constructor() {
        super();

        this.localState = {};
    }

    loadMoreData = () => 
    {

        if(this.props.raven.oldest_tombstone)
        {

            this.props.dataStore.getRavenTombstones(this.props.raven, this.props.raven.oldest_tombstone);
        }
        else
        {
            var mindate = moment(this.props.raven.tombstones[0].ts).unix();
            var maxdate = moment(this.props.raven.tombstones[this.props.raven.tombstones.length-1].ts).unix();

            this.props.dataStore.getRavenTombstones(this.props.raven, mindate < maxdate ? mindate : maxdate);
        }
    }


    queryTombstoneData()
    {

        this.props.dataStore.getRavenTombstones(this.props.raven);

    }

    componentDidMount() {
        this.localState.ravenid = this.props.raven.item.Id;
        this.queryTombstoneData();
    }

    componentDidUpdate() {

        if(this.props.raven && this.props.raven.item && this.props.raven.item.Id)
        {
            if(this.props.raven.item.Id !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.raven.item.Id;

                this.queryTombstoneData();
            }
        }
    }

    render() {

        return (
            <Dialog
                size="medium"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.props.raven.tombstones ?
                                        this.props.raven.tombstones.length !== 0 ?  
                                            <TombstoneView 
                                                data={this.props.raven.tombstones} 
                                                loading={false}
                                                requestMoreData={this.loadMoreData}
                                                /> : 
                                            <p>No Tombstone Data...</p> :
                                        <p>Loading...</p>
                                }
                            </div>
                        </div>
                    </div>
            </Dialog>
        );
    }
}
