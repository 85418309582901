// stateless react component that renders an object
// Takes in the object in the props

import React, { Component } from 'react';
import LatLon from 'geodesy/latlon-spherical.js';

export default class LngLatLink extends Component {

    render() {

        if(!this.props.ll)
        {
            return <div></div>;
        }

        var ll = new LatLon(this.props.ll[0],this.props.ll[1]);

        var snippet = ll.toString('dms').trim();
        snippet = snippet.replace(/\s/g, '');
        var readable = snippet.replace(/,/g, ' ');
        snippet = snippet.replace(/,/g, '+');
        var url = "https://www.google.com/maps/place/" + snippet + "/@"
            + this.props.ll[0] + ","
            + this.props.ll[1] + ",15z/data=!3m1!4b1!!4m5!3m4!1s0x0:0x0!8m2!3d"
            + this.props.ll[0] + "!4d"
            + this.props.ll[1];

        return <a href={url} target="_blank">{readable}</a>;

    }
                
}
