import React, { Component } from 'react';

import Dialog from '../dialog';
import BillingView from './billingView';

import CognitoUtil from '../../../../aws/cognito/cognitoUtil';

export default class BillingHistoryModal extends Component {

    constructor() {
    
        super();

        this.state = {
            isOpen: false,
            data: []
        };

        this.localState = {};

    }

    queryBilling(raven)
    {
        this.props.dataStore.queryBilling(raven);
    }

    componentDidMount() {
        this.localState.ravenid = this.props.raven.item.Id;
        if(this.props.raven && !this.props.raven.billing) {
            this.queryBilling(this.props.raven);
        }
    }

    componentDidUpdate() {

        if(this.props.raven && this.props.raven.item && this.props.raven.item.Id)
        {   
            if(this.props.raven.item.Id !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.raven.item.Id;
                if(this.props.raven && !this.props.raven.billing) {
                    this.queryBilling(this.props.raven);
                }
            }
        }
    }

    render() {

        return (
            <Dialog
                size="medium"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.props.raven.billing ?
                                        typeof this.props.raven.billing === "string" ?  <p>this.props.raven.billing</p> :
                                            this.props.raven.billing.length !== 0 ?  
                                                <BillingView 
                                                    data={this.props.raven.billing} 
                                                    /> : 
                                                <p>No Billing Data...</p> :
                                        <p>Loading...</p>
                                }
                            </div>
                        </div>
                    
                    </div>
            </Dialog>
        );
    }

}

