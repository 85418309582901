"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";

import { area as d3Area } from "d3-shape";

import GenericChartComponent from "react-stockcharts/lib/GenericChartComponent";
import { getAxisCanvas } from "react-stockcharts/lib/GenericComponent";
import { hexToRGBA, isDefined, first, last, functor } from "react-stockcharts/lib/utils";

class SegmentSeries extends Component {
	constructor(props) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
		this.drawOnCanvas = this.drawOnCanvas.bind(this);
	}
	drawOnCanvas(ctx, moreProps) {
		var { yAccessor, defined, base } = this.props;
		var { fill, stroke, opacity, plotData } = this.props;

		var { xScale, chartConfig: { yScale }, xAccessor } = moreProps;

		var newBase = functor(base);

		ctx.fillStyle = hexToRGBA(fill, opacity);
		ctx.strokeStyle = stroke;

		var points0 = [];

        points0.push([0,0]);

		for (let i = 0; i < plotData.length; i++) {
			let d = plotData[i];
			if (defined(yAccessor(d), i)) {
				let [x, y] = [xScale(xAccessor(d)), yScale(yAccessor(d))];

				points0.push([x, y]);
			} else if (points0.length) {
				segment(points0, ctx);
				points0 = [];
			}
		}
		if (points0.length) segment(points0, ctx);
	}
	renderSVG(moreProps) {
		var { yAccessor, defined, base } = this.props;
		var { stroke, fill, className, opacity } = this.props;

		var { xScale, chartConfig: { yScale }, plotData, xAccessor } = moreProps;

		var newBase = functor(base);
		var areaSeries = d3Area()
			.defined(d => defined(yAccessor(d)))
			.x((d) => xScale(xAccessor(d)))
			.y0((d) => yScale(yAccessor(d)))
			.y1((d) => yScale(yAccessor(d)));

		var d = areaSeries(plotData);
		className = className.concat(isDefined(stroke) ? "" : " line-stroke");
		return (
			<path d={d} stroke={stroke} fill={fill} className={className} opacity={opacity} />
		);
	}
	render() {
		return <GenericChartComponent
			canvasToDraw={getAxisCanvas}
			svgDraw={this.renderSVG}
			canvasDraw={this.drawOnCanvas}
			drawOn={["pan"]}
			/>;
	}
}

SegmentSeries.propTypes = {
	className: PropTypes.string,
	yAccessor: PropTypes.func.isRequired,
	stroke: PropTypes.string,
	fill: PropTypes.string,
	opacity: PropTypes.number,
	defined: PropTypes.func,
	base: PropTypes.oneOfType([
		PropTypes.func, PropTypes.number
	]),
};

SegmentSeries.defaultProps = {
	className: "line ",
	fill: "none",
	opacity: 1,
	defined: d => !isNaN(d),
	base: (yScale/* , d*/) => first(yScale.range()),
};


function segment(points0,ctx) {
	ctx.beginPath();
	var [x0, y0] = first(points0);
	ctx.moveTo(x0, y0);

	var i;
	for (i = 1; i < points0.length; i++) {
		let [x1, y1] = points0[i];
		ctx.lineTo(x0, y1);
		ctx.lineTo(x1, y1);
        x0 = x1;
        y0 = y1;
	}

    [x0, y0] = last(points0);

    ctx.lineTo(x0, 0);

	ctx.closePath();
	ctx.fill();
}

export default SegmentSeries;
