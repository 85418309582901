import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import queryString from 'querystring';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize'


import { tsvParse } from  "d3-dsv";

import Dialog from '../dialog';
import SensorView from './sensorView';

import CognitoUtil from '../../../../aws/cognito/cognitoUtil';

function parseSensorData() {
    return function(d) {
        d.date = new Date((+d.date)*1000);
        d.driving = +d.driving;
        d.segid = +d.segid;
        d.mean = d.mean ? +d.mean : null;
        d.min = d.min ? +d.min : null;
        d.max = d.max ? +d.max : null;
        d.count = d.count ? +d.count : null;
        d.latency = d.latency ? +d.latency : null;

        if(d.event)
        {
            var entries = d.event.split(';');
            d.events = entries.map(x => { var vals = x.split(':'); return { name: vals[1], date: vals[0] }; } );
        }
        if(d.eventdate)
        {
            d.eventdate = new Date((+d.eventdate)*1000);
        }

        return d;
    };
}

function parseEventData() {
    return function(d) {
        d.date = new Date((+d.date)*1000);
        d.segid = +d.segid;
        d.type = d.type ? +d.type : null;
        d.name = d.name ? d.name : null;
        d.level = d.level ? +d.level : null;

        return d;
    };
}

function minNotNull(a, b)
{
    if(a)
    {
        if(b)
        {
            if(a < b) return a;
            return b;
        }
        return a;
    }
    return b;
}

function maxNotNull(a, b)
{
    if(a)
    {
        if(b)
        {
            if(a > b) return a;
            return b;
        }
        return a;
    }
    return b;
}

class RavenHistoryDateSelectorModal extends Component {
    constructor(props) {
    
        super(props);
        this.state = {
            dateFocusedInput: null,
            startDate: moment.unix(props.startTime),
            endDate: moment.unix(props.endTime),

        };

    }

    onDateSelect = (dates) => {
        this.setState({startDate: dates.startDate, endDate: dates.endDate, chartError: false});
    }

    checkDate = (day) => {
        return (day < moment(this.props.dateRange[0])) || (day > moment(this.props.dateRange[1]));
    }

    render() {
        return (
            <Dialog
                size="small"
                className="dateselector"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <h4>Select Date Range</h4>
                            </div>
                            <div className="col-12">
                                <DateRangePicker
                                  startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                  endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                  onDatesChange={this.onDateSelect} // PropTypes.func.isRequired,
                                  focusedInput={this.state.dateFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                  onFocusChange={focusedInput => this.setState({ dateFocusedInput: focusedInput })} // PropTypes.func.isRequired,
                                  enableOutsideDays={false}
                                  minimumNights={0}
                                  isDayBlocked={() => { return false } }
                                  isOutsideRange={this.checkDate}
                                />

                            </div>
                            <div className="col-12">
                                <input 
                                    type="submit" 
                                    onClick={() => { return this.props.onTimeChange(this.state.startDate.startOf('day').unix(), this.state.endDate.endOf('day').unix()); }} 
                                    className="btn" />
                            </div>
                        </div>
                    
                    </div>
            </Dialog>
        );
    }
}

export default class RavenHistoryModal extends Component {

    constructor() {
    
        super();

        this.state = {
            sensordata: {},
            dateSelectModalOpen: false,
            loading: false,
            scaleType: 'auto',
        };

        this.searchCriteria = {};

        this.resetLocalState();

        this.scaleTypes = [
          { value: "auto", label: 'Auto' },
          { value: "fine", label: 'Original' },
          { value: "minute", label: 'Minute' },
          { value: "day", label: 'Day' },
          { value: "week", label: 'Week' },
        ];

        this.propertyTypes = [
          { value: "sensor1", label: 'Raven Battery Voltage (internal)' },
          // { value: "sensor3", label: 'Air Pressure' },
          // { value: "sensor5", label: 'OBD RPM' },
          // { value: "sensor6", label: 'OBD Speed' },
          // { value: "sensor7", label: 'Air Intake Temperature' },
          { value: "sensor8", label: 'Battery Voltage (vehicle)' },
          { value: "sensor9", label: 'Fuel Level' },
          { value: "sensor10", label: 'Raven Current Usage' },
          { value: "sensor17", label: 'Raven Temperature' },
          { value: "sensor25", label: 'Raven Battery Current Usage' },
          { value: "sensor26", label: 'Raven Battery Percent' },
          { value: "sensor16", label: 'Raven Battery Temperature' },
          { value: "sensor34", label: 'Raven Uptime' },
          { value: "sensor40", label: 'Raven Accelerometer X' },
          { value: "sensor41", label: 'Raven Accelerometer Y' },
          { value: "sensor42", label: 'Raven Accelerometer Z' },
          { value: "sensor43", label: 'Fan Goodness' },
          { value: "sensor44", label: 'Internal MMC Debug' },
          { value: "sensor45", label: 'External MMC Debug' },
          { value: "sensor29", label: 'WNC Cell Temperature' },
          { value: "gpsspeed", label: 'GPS Speed' },
          { value: "gpscompass", label: 'GPS Direction' },
          { value: "gpsaltitude", label: 'GPS Altitude' },
          { value: "gpsaccuracy", label: 'GPS accuracy' },

          { value: "sensor46", label: 'Raven Data Free Space' },
          { value: "sensor47", label: 'Vision Core FPS' },
          { value: "sensor48", label: 'Vision Segmentation FPS' },

          { value: "sensor260", label: 'OBD Calculated Engine Load'},
          { value: "sensor261", label: 'OBD Engine Coolant Temperature'},
          { value: "sensor268", label: 'OBD Vehicle RPM'},
          { value: "sensor269", label: 'OBD Vehicle Speed'},
          { value: "sensor271", label: 'OBD Intake Air Temperature'},
          { value: "sensor272", label: 'OBD Engine MAF Rate'},
          { value: "sensor273", label: 'OBD Throttle Position'},
          { value: "sensor287", label: 'OBD Run Time Since Engine Start'},
          { value: "sensor303", label: 'OBD Fuel Tank Level Input'},
          { value: "sensor307", label: 'OBD Absolute Barometric Pressure'},
          { value: "sensor326", label: 'OBD Ambient Air Temperature'},
          { value: "sensor348", label: 'OBD Engine Oil Temperature'},
          { value: "sensor350", label: 'OBD Engine Fuel Rate'},
          { value: "sensor65537", label: 'OBD Derived Fuel Rate'}, // 0x10001
          { value: "sensor65538", label: 'OBD FIFO0 Receive Message Rate'}, // 0x10002
          { value: "sensor65539", label: 'OBD FIFO1 Receive Message Rate'}, // 0x10003
          { value: "sensor65540", label: 'Odometer From Vehicle'}, // 0x10004

          { value: "sensor35", label: 'Cell RSCP (Received Signal Code Power)' },
          { value: "sensor36", label: 'Cell ECIO (Chip Energy over Noise)' },
          { value: "sensor37", label: 'Cell Pathloss' },
          { value: "sensor38", label: 'Cell SIR (Signal Interference Ratio)' },
          { value: "sensor39", label: 'Cell RSSI (Received Signal Stength Indicator)' },

// 256 | OBD_PIDS_SUPPORTED_01_20         | 2017-12-06 20:54:51 |
// 257 | OBD_MONITOR_STATUS               | 2017-12-06 20:54:52 |
// 284 | OBD_FORMAT_STANDARD              | 2017-12-06 20:54:52 |
// 287 | OBD_RUN_TIME_SINCE_ENGINE_START  | 2017-12-06 20:54:52 |
// 288 | OBD_PIDS_SUPPORTED_21_40         | 2017-12-06 20:54:52 |
// 320 | OBD_PIDS_SUPPORTED_41_60         | 2017-12-06 20:54:52 |
// 337 | OBD_FUEL_TYPE                    | 2017-12-06 20:54:52 |
// 352 | OBD_PIDS_SUPPORTED_61_80         | 2017-12-06 20:54:52 |
// 768 | OBD_STORED_DTC                   | 2017-12-06 20:54:52 |
// 1792 | OBD_PENDING_DTC                  | 2017-12-06 20:54:52 |
// 2304 | OBD_PIDS_SUPPORTED_MODE_09       | 2017-12-06 20:54:53 |
// 2306 | OBD_VIN                          | 2017-12-06 20:54:53 |
// 2314 | OBD_ECU_NAME                     | 2018-05-10 14:04:55 |
// 2560 | OBD_PERMANENT_DTC                | 2017-12-06 20:54:53 |
        ];

    }

    componentDidMount()
    {

        var data = this.parseQueryString(this.props.routeProps.location.search);

        this.searchCriteria = { ...data };

        // Request some initial data
        if(this.searchCriteria.min && this.searchCriteria.max)
        {

            this.localState.ravenid = this.props.raven.item.Id;
            this.localState.curPropertyType = this.searchCriteria.left;
            this.localState.curPropertyTypeRight = this.searchCriteria.right;
            this.localState.sensorlimits = { global: { start: this.searchCriteria.min, end: this.searchCriteria.max } };

            this.requestMoreSensorData(this.searchCriteria.left, this.searchCriteria.min, this.searchCriteria.max);
            this.requestMoreSensorData(this.searchCriteria.right, this.searchCriteria.min, this.searchCriteria.max);
            
        }
        
    }

    resetLocalState()
    {

        var curDateTime = new Date();
        var curDate = new Date( curDateTime.getFullYear(), curDateTime.getMonth(), curDateTime.getDate());

        this.localState = {
            ravenid: null,
            curPropertyType: null,
            curPropertyTypeRight: null,
            sensorlimits: { 
                global: {
                    start: moment().unix() - 12 * 3600, // 12 hours ago
                    end: null, // null means now
                }
            },
        };
    }

    requestMoreSensorData(sensorid, start, end, queryCallback)
    {
        if(!sensorid) return;

        // Determine what to actually query based on what we have and what is requested
        var endstr = end;

        if(!start)
        {
            start = this.localState.sensorlimits.global.start;
        }
        if(!end)
        {
            if(this.localState.sensorlimits.global.end)
            {
                end = this.localState.sensorlimits.global.end;
                endstr = end;
            }
            else
            {
                end = moment().unix(); // now
                endstr = "now";
            }
        }

        // Don't allow querying for data prior to July 2017
        if(start < 1500000000)
            start = 1500000000;
        if(end < 1500000000)
            end = 1500000000;

        if(!this.localState.sensorlimits[sensorid])
        {
            // Pass in the start string 'now' if start isn't defined, and this is our first call
            this.localState.sensorlimits[sensorid] = { start: start, end: end };
            this.querySensorAjax(sensorid, start, endstr, queryCallback);

        }

        else
        {
            var existingStart = this.localState.sensorlimits[sensorid].start;
            var existingEnd = this.localState.sensorlimits[sensorid].end;

            // Do the before part
            if(start < existingStart)
            {
                this.localState.sensorlimits[sensorid].start = start;
                this.querySensorAjax(sensorid, start, existingStart, queryCallback);
            }

            // Do the after part
            if(end > existingEnd)
            {
                this.localState.sensorlimits[sensorid].end = end;
                this.querySensorAjax(sensorid, existingEnd, end, queryCallback);
            }

        }

        this.localState.sensorlimits['global'].start = minNotNull(this.localState.sensorlimits['global'].start, start);
        this.localState.sensorlimits['global'].end = maxNotNull(this.localState.sensorlimits['global'].end, end);

    }

    sortData(newsensordata)
    {
        newsensordata.sort( function (a,b) { return new Date(a.date) - new Date(b.date); } );
    }

    generateSegmentLookup(segments)
    {

        var myresults = {};

        var numsegs = segments.length;
        for(var seg = 0; seg < numsegs; seg++)
        {
            myresults[segments[seg].segid] = seg;
        }

        return myresults;
    }

    querySensorAjax(sensorid, start, end, queryCallback = null)
    {
        if(this.localState.sensorlimits[sensorid].querying)
        {
            // Already querying this sensor, queue up next query behind this one
            var nextQuery = this.localState.sensorlimits[sensorid].nextQuery;
            if(nextQuery)
            {
                if(nextQuery.start > start) nextQuery.start = start;
                if(nextQuery.end < end) nextQuery.end = end;
            }
            else
            {
                nextQuery = {
                    start: start,
                    end: end,
                };
            }
            this.localState.sensorlimits[sensorid].nextQuery = nextQuery;
            return;
        }

        this.localState.sensorlimits[sensorid].querying = true;

        var delta;

        if(end === 'now')
        {
            delta = "now";
        }
        else
        {
            delta = (end - start);
            if(delta <= 0)
            {
                return;
            }
        }

        var me = this;

        var ravenid = this.props.raven.item['Id'];

        // CognitoUtil.refreshLogin();

        var prefix = this.props.dataStore.prefix1;

        // perform ajax calls to get it the next state
        var url = prefix + "ajaxbig"
                + "?stage=" + this.props.stage
                + "&type=" + sensorid
                + "&start=" + start
                + "&end=" + end
                + "&raven=" + ravenid;

        // Report that it is loading data.
        this.setState({loading: true});

        return this.props.dataStore.queryLoop(url, (json) => {

            this.localState.sensorlimits[sensorid].querying = false;

            var mysensordata = me.state.sensordata;

            var mysegmentlookup = {};

            if(!mysensordata)
                mysensordata = {}

            if(!(sensorid in mysensordata))
                mysensordata[sensorid] = {};

            var fields = ['segments', 'events','fine','minute','day','week'];

            for (var id in fields)
            {
                var name = fields[id];

                var tsv = json[name];
                var parsed = tsvParse(tsv, (id === 'events' || id === 'segments') ? parseEventData() : parseSensorData());

                if(mysensordata[sensorid][name])
                    mysensordata[sensorid][name].push(...parsed);
                else
                    mysensordata[sensorid][name] = parsed;

                this.sortData(mysensordata[sensorid][name]);
            }

            if(mysensordata[sensorid]['segments'])
            {
                mysegmentlookup = this.generateSegmentLookup(mysensordata[sensorid]['segments']);
            }

            me.setState({
                    loading: false,
                    sensordata: mysensordata, 
                    segmentlookup: mysegmentlookup, 
                    chartError: false}, 
                () => { if(queryCallback) queryCallback(); }
            );

            // If there is a queued query, run that one now
            var nextQuery = this.localState.sensorlimits[sensorid].nextQuery;
            if(nextQuery)
            {
                this.localState.sensorlimits[sensorid].nextQuery = null;
                this.querySensorAjax(sensorid, nextQuery.start, nextQuery.end, queryCallback);
            }

        });

    }

    handleOnLoadMore = (start, end, domain, queryCallback) => {

        if(start === end)
            return;

        var startTime = moment(start).unix();
        var endTime = moment(end).unix();

        this.localState.curDomain = domain;

        const parsedSearch = this.parseQueryString(this.props.routeProps.location.search);

        // FIXME - iterate over the currently selected property types
        this.requestMoreSensorData(parsedSearch.left, startTime, endTime, queryCallback);
        this.requestMoreSensorData(parsedSearch.right, startTime, endTime, queryCallback);
        
    }

    onSelectPropertyType = (val) => {

        this.searchCriteria['left'] = val.value;

        this.props.routeProps.history.push(this.props.routeProps.location.pathname +
            '?' + queryString.stringify(this.searchCriteria ));
    }

    onSelectPropertyTypeRight = (val) => {

        if(val)
            this.searchCriteria['right'] = val.value;
        else
            delete this.searchCriteria.right;

        this.props.routeProps.history.push(this.props.routeProps.location.pathname + 
            '?' + queryString.stringify(this.searchCriteria ));

    }

    onSelectScale = (val) => {
        this.setState({scaleType: val.value});
    }


    componentDidUpdate() {

        const parsedSearch = this.parseQueryString(this.props.routeProps.location.search);

        if(this.props.raven && this.props.raven.item && this.props.raven.item.Id && parsedSearch.left)
        {

            if(this.props.raven.item.Id !== this.localState.ravenid)
            {
                // Blow away any local state
                this.resetLocalState();

                // Set new raven id
                this.localState.ravenid = this.props.raven.item.Id;

                // Raven ID changed, blow away all the sensor data
                this.setState({sensordata: { }, chartError: false });

            }

            
            if(parsedSearch.left !== this.localState.curPropertyType)
            {
                this.localState.curPropertyType = parsedSearch.left;

                this.requestMoreSensorData(parsedSearch.left);
                
            }

            if(parsedSearch.right !== this.localState.curPropertyTypeRight)
            {
                this.localState.curPropertyTypeRight = parsedSearch.right;

                this.requestMoreSensorData(parsedSearch.right);
                
            }
        }
    }

    changeSetting = (data) => {

        this.searchCriteria = { ...this.searchCriteria, ...data };

        this.props.routeProps.history.push(this.props.routeProps.location.pathname +
            '?' + queryString.stringify(this.searchCriteria ));

    }

    openDateSelectModal = (e) => {
        e.preventDefault();

        this.setState({dateSelectModalOpen: true});


    }

    parseQueryString(mysearch) {

        if(mysearch.charAt(0) === '?')
        {
            mysearch = mysearch.substr(1);
        }

        return queryString.parse(mysearch);
    }

    reportError = () => {
        this.setState({chartError: true});
    }

    getLevelFromDomainWidth(numseconds)
    {
        if(numseconds < 3600*24*1.5)
        {
            return 'fine';
        }
        if(numseconds < 3600*24*6)
        {
            return 'minute';
        }
        if(numseconds < 3600*24*10)
        {
            return 'day';
        }

        return 'week';
    }

    exportDataHref = () => {
        const parsedSearch = this.parseQueryString(this.props.routeProps.location.search);

        if(!parsedSearch.left)
            return "";

        if(!this.state.sensordata[parsedSearch.left])
            return "";

        // var startTime = this.searchCriteria.min ? this.searchCriteria.min : moment().unix();
        // var endTime = this.searchCriteria.max ? this.searchCriteria.max : moment().unix();

        let headers = [ 'date', 'ravenid', 'segid', 'sensorid', 'driving', 'mean', 'min', 'max', 'count', 'latency'];

        let csvContent = "data:text/csv;charset=utf-8,"
            + headers.join(",") + '\n'
            + this.state.sensordata[parsedSearch.left]['fine'].filter(d=> d.segid > 0).map(d => {
                return [
                    moment(d.date).unix(),
                    this.props.raven.item['Id'],
                    d.segid,
                    parsedSearch.left,
                    d.driving,
                    d.mean,
                    d.min,
                    d.max,
                    d.count,
                    d.latency,
                ].join(",");
            }).join("\n");

        return encodeURI(csvContent);
    }

    render() {

        const parsedSearch = this.parseQueryString(this.props.routeProps.location.search);

        let exportDisabled = (!parsedSearch.left || !this.state.sensordata[parsedSearch.left]);

        return (
            <Dialog
                size="medium"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-1">
                                <a href="#!" onClick={this.openDateSelectModal}>
                                    <img src="/images/baseline_date_range_white_18dp.png" alt="Date Select"/>
                                </a>
                                { exportDisabled ?
                                    <a download="data.csv" >
                                        <img src="/images/baseline_cloud_download_white_18dp.png" alt="Export"/>
                                    </a> :
                                   <a href={this.exportDataHref()} download={ "raven" + this.props.raven.item['Id'] + '_' + parsedSearch.left + ".csv" } >
                                        <img src="/images/baseline_cloud_download_white_18dp.png" alt="Export"/>
                                    </a>
                                }
                                { this.state.dateSelectModalOpen? 
                                    <RavenHistoryDateSelectorModal 
                                        title={"Select Date Range"} isOpen={this.state.dateSelectModalOpen} 
                                        onClose={() => {this.setState({dateSelectModalOpen: false})}} 
                                        dateRange={this.props.raven.sensordaterange}
                                        startTime={this.searchCriteria.min ? this.searchCriteria.min : moment().unix()}
                                        endTime={this.searchCriteria.max ? this.searchCriteria.max : moment().unix()}
                                        onTimeChange={ (start, end) => { 
                                                var newdomain = this.state.scaleType === 'auto' ? this.getLevelFromDomainWidth(end - start) : this.state.scaleType;

                                                this.localState.sensorlimits = {
                                                            global: {
                                                                start: start, // 12 hours ago
                                                                end: end, // now
                                                            }
                                                        };

                                                this.setState({dateSelectModalOpen: false, 
                                                        sensordata: {}, 
                                                        chartError: false }, () => {
                                                    this.handleOnLoadMore(
                                                        moment.unix(start), moment.unix(end), newdomain, 
                                                        () => {
                                                            this.changeSetting({'level': newdomain, 'min': start, 'max': end } );

                                                            this.setState({dateSelectModalOpen: false});
                                                        }
                                                    );
                                                }); 
                                        } }
                                    /> : null
                                }
                            </div>
                            <div className="col-5">
                                <label>
                                    { parsedSearch.right ? 
                                        <div className="color-box" style={{backgroundColor: '#ffff88'}}></div> : 
                                        <div className="color-box" style={{backgroundColor: '#ffffff'}}></div>
                                    }
                                    Left
                                </label>
                                <Select id="historyType"
                                    placeholder="Property..."
                                    options={this.propertyTypes}
                                    value={parsedSearch.left}
                                    name="historyType"
                                    onChange={this.onSelectPropertyType}
                                    clearable={false} searchable={false} />
                            </div>
                            <div className="col-4">
                                <label>
                                    { parsedSearch.right ? 
                                        <div className="color-box" style={{backgroundColor: '#88ffff'}}></div>
                                        : null
                                    }
                                    Right
                                </label>
                                <Select id="historyType"
                                    placeholder="Property..."
                                    options={this.propertyTypes}
                                    value={parsedSearch.right}
                                    name="historyType"
                                    onChange={this.onSelectPropertyTypeRight}
                                    clearable={true} searchable={false} />
                            </div>
                            <div className="col-2">
                                <label>
                                    Scale
                                </label>
                                <Select id="scaleType"
                                    placeholder="Scale..."
                                    options={this.scaleTypes}
                                    value={this.state.scaleType}
                                    name="scaleType"
                                    onChange={this.onSelectScale}
                                    clearable={false} searchable={false} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {

                                    this.state.sensordata && this.state.sensordata[parsedSearch.left] ?  
                                        <SensorView 
                                            data={this.state.sensordata[parsedSearch.left]} 
                                            segmentlookup={this.state.segmentlookup}
                                            dataRight={this.state.sensordata[parsedSearch.right]} 
                                            onSettingChanged={this.changeSetting}
                                            settings={this.searchCriteria}
                                            onLoadMore={this.handleOnLoadMore} 
                                            error={this.state.chartError}
                                            reportError={this.reportError}
                                            displayLevel={this.state.scaleType}
                                        /> : 

                                        this.state.loading ?
                                        <p>Loading...</p> :
                                        <p>Select a property on the left...</p>
                                }
                            </div>
                        </div>
                    
                    </div>
            </Dialog>
        );
    }

}
