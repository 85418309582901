import React, { Component } from 'react';
import Select from 'react-select';

import 'react-select/dist/react-select.css';

export default class StageSelector extends Component {

    constructor () {
        super();

    }

    setRavenStages(nextProps) {
        this.ravenStages = nextProps.ravenStages.map(obj => {
                return { value : obj,
                    label: obj,
                    id: obj,
                };
            });
    }

    componentDidMount() {

        this.setRavenStages(this.props);

        // After component mounted, fire the active display type
        // this.props.onRavenStageChange(this.ravenStages[0].value);
    }

    componentWillReceiveProps(nextProps) {
        this.setRavenStages(nextProps);
    }

    onSelectRavenStage = (val) => {
      console.log('Selected Raven Stage: ', val);

      if(!val) return;

      this.props.onRavenStageChange(val.value);

    }

    render() {

        return (
            <Select id="ravenStage" 
                placeholder="Stage..."
                options={this.ravenStages} 
                value={this.props.stage} 
                name="ravenStage" 
                onChange={this.onSelectRavenStage} 
                clearable={false} searchable={false} />
        );
    }
}
