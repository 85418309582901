import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import globalconfig from '../../../../common/config';
import supporttoolIconProblem from './images/supporttool-icon-problem.png';
import supporttoolIconCheckmark from './images/supporttool-icon-checkmark.png';
import supporttoolIconInfoWhite from './images/supporttool-icon-info-white.png';

const PARKED_UPDATE_MAX_WAIT_TIME_SECONDS = 900; // 15 mins
const DRIVING_UPDATE_MAX_WAIT_TIME_SECONDS = 300; // 5 mins

export class ActivityIndicator extends React.PureComponent {
    render () {
        return <div className="activity-indicator"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    }
}

export default class Diagnostics extends React.PureComponent {

    constructor(props) {
        super(props);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const previousPropsItem = prevProps.item;
        const currentPropsItem = this.props.item;

        if (previousPropsItem.Id && currentPropsItem.Id) {
            if (previousPropsItem.Id === currentPropsItem.Id) {
                if (Object.keys(previousPropsItem).length > Object.keys(currentPropsItem).length) {
                    // the required data has somehow been reset, request it again
                    this.props.dataStore.queryRaven(currentPropsItem.Id);
                }
            }
        }

    }

    render () {

        const {item, otabuilds} = this.props;
        const raven = item["Raven"];
        const build = item["Build"];

        const isDriving = raven["State"] === "Parked" ? false : true;
        const sdCardStatus = raven["SD Card Status"] ? raven["SD Card Status"] : undefined; // 'undefined' to be treated as 'pending'
        const nowSeconds = Math.floor(Date.now() / 1000);
        const lastUpdateSeconds = item.hasOwnProperty("Last Update") && !isNaN(item["Last Update"]) ? parseInt(item["Last Update"]) : undefined; // 'undefined' to be treated as 'pending'
        const lastUpdateAgeSeconds = nowSeconds - lastUpdateSeconds;
        const updateIsTimely = isDriving ? lastUpdateAgeSeconds <= DRIVING_UPDATE_MAX_WAIT_TIME_SECONDS : lastUpdateAgeSeconds <= PARKED_UPDATE_MAX_WAIT_TIME_SECONDS;
        const lastUpdateStatusLoadingClass = lastUpdateSeconds !== undefined ? "ready" : "pending";
        const lastUpdateStatusIconSrc = updateIsTimely ? supporttoolIconCheckmark : supporttoolIconProblem;
        const lastUpdateStatusTooptip = updateIsTimely ? (
            <div className="tooltip-large">
                <span>
                    Raven is properly sending data to the cloud (last update: {lastUpdateSeconds ? moment.unix(lastUpdateSeconds).format(globalconfig.display.timeFormat) : "N/A"})
                </span>
            </div>
        ) : ( // const lastUpdateStatusTooptip = updateIsTimely
            <div className="tooltip-large">
                <span>
                    Cloud is not receiving data from Raven in the expected time frame.
                </span>
            </div>
        );
        
        const sdCardStatusIsGood = sdCardStatus !== undefined ? sdCardStatus.toLowerCase().includes("sd card mounted") : "Unknown";
        const sdCardStatusLoadingClass = sdCardStatus !== undefined ? "ready" : "pending";
        const sdCardStatusIconSrc = sdCardStatusIsGood ? supporttoolIconCheckmark : supporttoolIconProblem;
        const sdCardStatusTooptip = (<div className="tooltip-large"><span>{sdCardStatus}</span></div>);

        let lastEventName = raven.hasOwnProperty("Last Event") ? String(raven["Last Event"]) : undefined;

        if (lastEventName) {
            if (lastEventName.includes(",")) { // middleman is returning a CSV for last "event", grab the first one
                lastEventName = lastEventName.split(",")[0];
            }
            if (lastEventName.startsWith("EVENT_")) {
                lastEventName = lastEventName.replace("EVENT_", "");
            }
            lastEventName = lastEventName.replace("_", " ");
            lastEventName = lastEventName.toLowerCase();
        }
        const lastEventNameLoadingClass = lastEventName && lastEventName != "" ? "ready" : "pending";
        const lastEventTooptip = undefined;//(<div className="tooltip-large"><span>WIP - Just a placeholder</span></div>);

        // otabuilds looks like: {"history":[],"otabuilds":[]}
        const newOTABuildsAvailable = otabuilds && otabuilds.otabuilds ? otabuilds.otabuilds.length > 0 : undefined;
        const firmwareIsTheLatest = !newOTABuildsAvailable;
        let firmwareVersion = build["OS Version"] ? build["OS Version"] : undefined; // 'undefined' to be treated as 'pending'
        const firmwareStatusLoadingClass = firmwareVersion ? "ready" : "pending";
        if (firmwareVersion && firmwareIsTheLatest) {
            firmwareVersion += " (latest)";
        }

        let simManagementExternalUrl = undefined;
        let simManagementExternalUrlTitle = "SIM Tool";
        if (globalconfig.features && globalconfig.features.ravens) {
            simManagementExternalUrl = globalconfig.features.ravens.simManagementExternalUrl;
            if (globalconfig.features.ravens.simManagementExternalUrlOptionalTitle) {
                simManagementExternalUrlTitle = globalconfig.features.ravens.simManagementExternalUrlOptionalTitle;
            }
        }

        let lastRavenBootedEventLoadingClass = "pending";
        let lastRavenRebootTime = "";
        let lastRavenRebootDate = "";

        if (this.props.oldestLoadedEventMoment) { // using presence of oldest event date as a flag for data loaded

            lastRavenBootedEventLoadingClass = "ready";

            if (this.props.mostRecentLoadedRavenBootedEventMoment) {

                lastRavenRebootDate = this.props.mostRecentLoadedRavenBootedEventMoment.format("ddd, MMM Do, h:mmA");
                lastRavenRebootTime = this.props.mostRecentLoadedRavenBootedEventMoment.fromNow();

            } else {
                lastRavenRebootTime = "No recent reboot events found."; //Greater than " + this.props.oldestLoadedEventMoment.fromNow();
            }
        }

        return (
            <section className="diagnostics">
                <header>
                    Diagnostics
                    <button className="support-button" onClick={() => {this.props.onSupportTicketClick(this.props.item, this.props.otabuilds); }}>+ Raven Ticket</button>
                </header>
                <div className={lastUpdateStatusLoadingClass}>
                    <div>Last update time: </div>
                    <div className="status-icon"><ActivityIndicator /><img src={lastUpdateStatusIconSrc} /></div>
                    <div className="info-icon">
                        <img src={supporttoolIconInfoWhite} />
                        {lastUpdateStatusTooptip}
                    </div>
                </div>

                { simManagementExternalUrl ?
                    <div>
                        <div>SIM connection status: </div>
                        <div className="status-icon"><a href={simManagementExternalUrl} target="_blank">{simManagementExternalUrlTitle} <i className="material-icons">launch</i></a></div>
                    </div>
                :
                    null
                }
                <div className={lastEventNameLoadingClass}>
                    <div>Last Event: </div>
                    <div className="event-name">{lastEventName}</div>
                    <div className="status-icon"><ActivityIndicator /></div>
                </div>

                <div className={sdCardStatusLoadingClass}>
                    <div>SD card status: </div>
                    <div className="status-icon"><ActivityIndicator /><img src={sdCardStatusIconSrc} /></div>
                    <div className="info-icon">
                        <img src={supporttoolIconInfoWhite} />
                        {sdCardStatusTooptip}
                    </div>
                </div>

                <div className={firmwareStatusLoadingClass}>
                    <div>Raven Firmware: </div>
                    <div className="firmware-version"><ActivityIndicator />{firmwareVersion ? firmwareVersion : null}</div>
                </div>

                <div className={lastRavenBootedEventLoadingClass}>
                    <div>Last Reboot Time:</div>
                    <div className="firmware-version"><ActivityIndicator /></div>
                </div>
                {lastRavenRebootTime ?
                    <div className={lastRavenBootedEventLoadingClass}>
                        {lastRavenRebootDate}
                        {" (" + lastRavenRebootTime + ")"}
                    </div>
                :
                    null
                }

            </section>
        )
    }

}

Diagnostics.propTypes = {
    dataStore: PropTypes.object.isRequired, // for requesting refresh for missing details on app header sort displayVariable changes
    item: PropTypes.object.isRequired,
    oldestLoadedEventMoment: PropTypes.object, // moment.js
    mostRecentLoadedRavenBootedEventMoment: PropTypes.object, // moment.js
    onSupportTicketClick: PropTypes.func.isRequired,
    otabuilds: PropTypes.object
};
