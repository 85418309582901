
import React, { Component } from 'react';
import Select from 'react-select';
import Dialog from '../dialog';

import JSONView from '../../../../widgets/jsonView';
import Events from '../../../../common/events';

import CognitoUtil from '../../../../aws/cognito/cognitoUtil';

import OTAHistoryModal from './otaBuildHistory';


/*
    Class to poll the OTA status as it is progressing
*/
class OTAUpdatePoller extends Component {
    constructor() {
    
        super();

        this.state = {
            message: null
        };

    }

    pollStatus = () => {

        var me = this;

        var url = this.props.prefix + "ajax"
            + "?stage=" + this.props.data.stage
            + "&type=ota"
            + "&raven=" + this.props.data.ravenid;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
          .then((response) => {
            return response.json();
          }).then((json) => {
              // We grabbed the json.  Put it in the state

              var message = "Error finding status";

              json.forEach( x => {
                if(x.operation_id === this.props.id)
                {
                    if(x.type === "SUCCESS" || x.type === "FAILED" || x.type === "CANCELED")
                    {
                        this.keepPolling = false;
                    }

                    if(this.keepPolling)
                    {
                        message = <div>Operation: { x.operation_id } Status: {x.type} <span className="ota-query-spinner"><i className="material-icons">refresh</i></span></div>;
                    }
                    else
                    {
                        message = <div>Operation: { x.operation_id } Status: {x.type} </div>;
                    }

                }
            });


            if(this.keepPolling)
            {
                me.setState({message: message});

                setTimeout(this.pollStatus, 2000);
            }
          }).catch((error) => {
              me.setState({message: "Unknown error querying OTA data" + error});
          })


    }

    componentDidMount() {
        this.keepPolling = true;
        this.pollTimeout = setTimeout(this.pollStatus, 2000);
    }

    componentWillUnmount() {
        this.keepPolling = false;
        clearTimeout(this.pollTimeout);
    }

    render() {
        if(this.state.message)
        {
            return (
                <div>{this.state.message}</div>
            );
        }
        else
        {
            return (
                <div>Polling for status...</div>
            );
        }
    }
}


/* Class to ask the user to perform OTA and to display the result from das Kloud
*/
class OTAUpdateModal extends Component {
    constructor() {
    
        super();

        this.priorities = [ 
            { value: 0, label: "Urgent" },
            { value: 1, label: "High" },
            { value: 2, label: "Medium" },
            { value: 3, label: "Low" },
        ];

        this.state = {
            otas : [],
            priority: this.priorities[0]
        };

        // Create our ota structure globally
        if(!document.otas)
        {
            document.otas = [];
        }

    }

    performOTAUpdate = (e) => {
        var me = this;
    
        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE,
                { detail: { loadingMessage: 'Updating Raven' } }));

        var prefix = this.props.dataStore.prefix2;

        var url = prefix + "pushota"
            + "?stage=" + this.props.data.stage
            + "&ravenunit=" + this.props.data.ravenunitid
            + "&build=" + this.props.data.build
            + "&priority=" + this.state.priority.value;

        document.otas.push({
            id: document.otas.length+1,
            stage: this.props.data.stage,
            ravenunitid: this.props.data.ravenunitid,
            build: this.props.data.build,
            ravenid: this.props.data.ravenid,
            message: "Pending..."
        });

        this.setState({message: null});

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
          .then((response) => {
            return response.json();
          }).then((json) => {
              // We grabbed the json.  Put it in the state
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));

              var myindex = document.otas.findIndex((elem) => { return elem.ravenunitid === this.props.data.ravenunitid; });
              document.otas[myindex].message = json;
              
              me.setState({message: json});
          }).catch((error) => {
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
              console.error("OTA Error", error);

              var myindex = document.otas.findIndex((elem) => { return elem.ravenunitid === this.props.data.ravenunitid; });
              var message = "Unknown error sending OTA request" + error;
              document.otas[myindex].message = message;
              me.setState({message: message});
          });

    }

    onChangePriority = (val) => {
        this.setState({priority: val});
    }

    render() {

        var otheroutput = [];
        var currentoutput;
        var buttons;

        var message;
        var poll;

        var curRavenInfo = this.state;

        if((curRavenInfo && curRavenInfo.message) || (document.otas.findIndex((obj) => {return obj.ravenunitid === this.props.data.ravenunitid; }) >= 0))
        {
            // Just an OK button
            buttons = <div align="right">
                            <button onClick={this.props.onClose}>Ok</button>
                        </div>;

            currentoutput = <div><br/>{buttons}</div>;

        }
        else
        {
            message = "Are you sure you want to update Raven "+this.props.data.ravenid+" (Raven Unit Id "+this.props.data.ravenunitid+") to build "+this.props.data.build+" now?";

            buttons = <div align="right">
                            <button onClick={this.props.onClose}>Cancel</button> 
                            <button onClick={this.performOTAUpdate}>Update</button>
                        </div>;

            currentoutput = <div className="row">
                                <div className="col-12">
                                {message} 
                                </div>
                                <div className="col-2">
                                    Priority: 
                                </div>
                                <div className="col-10">
                                    <Select id="priority"
                                    placeholder="Priority..."
                                    options={this.priorities}
                                    value={this.state.priority}
                                    name="priority"
                                    onChange={this.onChangePriority}
                                    clearable={false} searchable={false} />
                                </div>
                                <div className="col-12">
                                    {poll}
                                </div>
                                <div className="col-6">
                                    {buttons}
                                </div>
                            </div>;
        }


        if(document.otas)
        {
            document.otas.forEach((obj) => { 
                if(obj.message)
                {
                    if(obj.message.message)
                    {
                        message = obj.message.message;
                    }
                    else
                    {
                        message = obj.message;
                    }

                    if(obj.message.otaOperationId)
                    {
                        poll = <OTAUpdatePoller data={obj} id={obj.message.otaOperationId} prefix={this.props.dataStore.prefix1} />
                    }
                }

                // Create a taylored delete function for this line
                var me = this;
                var deleteFunc = () => {
                    
                    var index = document.otas.findIndex((a) => {return a.ravenunitid === obj.ravenunitid});
                    if (index > -1) {
                      document.otas.splice(index, 1);
                    }

                    // Force rerender
                    me.setState({otas: document.otas});
                };

                otheroutput.push({
                    id: obj.id,
                    ravenunitid: obj.ravenunitid,
                    deleteFunc: deleteFunc,
                    message: message,
                    poll: poll
                });
            });
        }

        console.log("OTA Other output",document.otas ? document.otas.length : 0, document.otas , otheroutput);

        return (
            <Dialog
                size="small"
                label="OTA Update"
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >


                {otheroutput.map( (obj) => {
                    return <div key={obj.id}><button onClick={obj.deleteFunc}>X</button> {obj.ravenunitid} {obj.message} {obj.poll}<br/></div>;
                })}

                {currentoutput}
                
            </Dialog>
        );
    }

}

export default class OTABuilds extends Component {

    constructor() {
        super();

        this.state = {
            ravenid: '',
            stage: '',
            ravenunitid: '',
            build: '',
            modalIsOpen: false,
            otahistory: null,
            historyModalIsOpen: false,
        }

        this.localState = {
            ravenid: null
        }
    }

    closeModal = (e) => {
        this.setState({modalIsOpen: false});
    }

    closeHistoryModal = (e) => {
        this.setState({historyModalIsOpen: false});
    }

    onViewOtaHistory = (e) => {
        e.preventDefault();
        this.setState({
            historyModalIsOpen: true
        });
    }

    onItemClick = (e, item) => {
        e.preventDefault();

        var ravenid = this.props.raven['Id'];
        var ravenunitid = this.props.raven['Raven']['Unit Id'];
        var stage = this.props.raven['Build']['Stage'];

        this.setState({
            ravenid: ravenid,
            stage: stage,
            ravenunitid: ravenunitid,
            build: item,
            modalIsOpen: true
        });

    }

    componentDidUpdate() {

        if(this.props.raven && this.props.raven.Id)
        {
            if(this.props.raven.Id !== this.localState.ravenid)
            {
                this.localState.ravenid = this.props.raven.Id;

                this.queryOtaHistory();
            }
        }
    }

    queryOtaHistory()
    {
        var me = this;

        var ravenid = this.props.raven['Id'];

        console.log("Querying OTA for " + ravenid);

        // CognitoUtil.refreshLogin();

        var prefix = this.props.dataStore.prefix1;

        // perform ajax calls to get it the next state
        var url = prefix + "ajax"
                + "?stage=" + this.props.stage
                + "&type=ota" 
                + "&raven=" + ravenid;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
          .then((response) => {
            return response.json();
        }).then((json) => {

            me.setState({otahistory: json});
        });

    }

    render()
    {
        if(!this.props.data || Object.keys(this.props.data).length === 0)
            return null;

        var curhistory = this.state.otahistory;

        var historyobj;
        var buildsobj;

        if(curhistory)
        {
            historyobj = <a href="#!" onClick={(e) => {this.onViewOtaHistory(e)}}>View OTA History</a>
        }

        if(this.props.data.otabuilds)
        {
            if(this.props.data.otabuilds.length === 0)
            {

                buildsobj = <JSONView json="No new builds" />
            }
            else
            {
                buildsobj = ( <div>
                    {
                        this.props.data.otabuilds.map((item, value) => (
                            <div key={value}>
                                <a href="#!" onClick={(e) => { this.onItemClick(e, item)}}>{item}</a>
                            </div>

                        ))
                    }
                    </div>
                );
            }
        }
        else
        {
            buildsobj = <JSONView json={this.props.data} />
        }

        return ( <div className="ota-builds">
            <h3>OTA Builds</h3>
            {historyobj}
            {buildsobj}

            <OTAUpdateModal isOpen={this.state.modalIsOpen} 
                onClose={() => {this.setState({modalIsOpen: false})}} 
                data={this.state} 
                dataStore={this.props.dataStore} 
            />
            <OTAHistoryModal isOpen={this.state.historyModalIsOpen} 
                onClose={() => {this.setState({historyModalIsOpen: false})}} 
                data={this.state.otahistory} 
                raven={this.props.raven} 
                dataStore={this.props.dataStore} 
            />
            </div>
        );

    }

}
