
import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import "react-table/react-table.css";
import moment from 'moment';
import globalconfig from '../../../../common/config';

export default class DTCView extends Component {

    render() {

        var columns = [
            {
                Header: 'Time',
                accessor: d => moment.utc(d.ts).unix() * 1000,
                id: 'ts',
                Cell: props => <span title={moment.utc(props.original.ts).unix()} >{ moment(props.value).format(globalconfig.display.timeFormat) } ( { moment(props.value).fromNow() } ) </span> ,

            },
            {
                Header: 'Segment',
                accessor: 'segid',
                maxWidth: 50,
            },
            {
                Header: 'Vehicle',
                accessor: 'vehicle'
            },
            {
                Header: 'Code',
                accessor: 'code',
                maxWidth: 75,
            },
            {
                Header: 'Description',
                accessor: 'description'
            },
            {
                Header: 'Type',
                accessor: 'parameter',
                maxWidth: 100,
                Cell: props => {
                    var txt;
                    switch(props.value)
                    {
                    case 768:
                        txt = "Stored";
                        break;
                    case 1792:
                        txt = "Temporary";
                        break;
                    case 2560:
                        txt = "Permenant";
                        break;
                    default:
                        txt = props.value;
                    };

                    return (<div>{txt}</div>);
                }
            },
        ];

        var datasize = this.props.data ? this.props.data.length : 0;
        var paginate = this.props.data ? datasize > this.props.pageSize : false;

        var pagesize = paginate ? this.props.pageSize : datasize;

        return (
            <div id="dtc-listing" className="dtc-list">
                    <ReactTable className="-striped" data={this.props.data}
                        filterable={true}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value) }

                        showPagination={paginate}
                        showPageSizeOptions={false}
                        showPageJump={false}
                        style = {{ height: "65vh"}}
                        pageSize={pagesize}
                        noDataText="No DTCs"
                        defaultSorted={[{id: 'ts', desc: true}]}
                        columns={columns} />
            </div>
        );
    }
}

DTCView.defaultProps = {
    pageSize: 100,
};

DTCView.propTypes = {
    pageSize: PropTypes.number,
}

