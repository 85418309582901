import React from 'react';
import PropTypes from 'prop-types';
import globalconfig from '../../../common/config';

// auto-shows contact form unless isOpen (optional) prop is false
export default class ZendeskContactWebWidget extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            userName: this.props.user? this.props.user.username : undefined,
            userEmail: this.props.user? "Loading ..." : undefined,
            customerOrderNumber: this.props.order? this.props.order.orderNumber : undefined,
            customerAccountNumber: this.props.order && this.props.order.account? this.props.order.account.externalId : undefined,
            item: this.props.item
        }

    }

    static propTypes = {
        isOpen: PropTypes.bool, // set false to avoid automatically opening widget
        user: PropTypes.object, // used for form field prefills
        order: PropTypes.object, // used for form field prefills
        item: PropTypes.object, // i.e. the feature.item used in pages/raven/panels/featureSheet
        onClose: PropTypes.func.isRequired
    };

    componentDidMount() {

        /* // Zendesk web widget does not permit removing listeners, try to avoid the following:
        if (window.hasOwnProperty('zE')) { // otherwise, this set in zendeskWidgetDidLoad()
            window.zE('webWidget:on', 'close', this.zendeskWidgetDidClose);
        }*/
        if (window.hasOwnProperty('zE')) {
            window.zE('webWidget:on', 'close', this.props.onClose); // attempting to avoid/reduce memory leak risk by passing pointer of parent component
        }

        this.openZendeskWidget();
    }

    componentWillUnmount() {

        /* IMPORTANT: There is no way to remove event listeners or reset/remove web widget using the APIs:
            - https://develop.zendesk.com/hc/en-us/community/posts/360004398468-Removing-the-web-widget-via-the-API
            - https://develop.zendesk.com/hc/en-us/community/posts/360004408007-WebWidget-on-open-on-closed-not-firing-as-expected ("And please provide a way to remove event listener. This is so needed!");
        
           Hacks don't work:
            - window.zE('webWidget:off', 'close', this.zendeskWidgetDidClose); // Uncaught Error: Method webWidget:off.close does not exist
            - window.zE('webWidget:on', 'close', undefined); // doesn't work (does not overwrite/clear original event handler)
           Incomplete, buggy (inadvertently prevents re-launch, prevents subsequent opens):
            - delete window.zE;
            - const zeSnippetScript = document.getElementById('ze-snippet'); zeSnippetScript.remove();
        */
    }

    componentDidUpdate(prevProps, prevState) {

        this.zendeskUpdateFieldsPrefills(prevState);

        if (this.props.isOpen) {
            // since opening is default (isOpen undefined graceful fallback to true), check if isOpen was false
            if (prevProps.isOpen === false) {
                this.openZendeskWidget();
            }
        } else if (this.props.isOpen === false) {
            if (prevProps.isOpen === true || prevProps.isOpen === undefined) {
                this.closeZendeskWidget();
            }
        }
    }

    zendeskCustomFieldsPrefillsDelta = (prevState = undefined) => {
        const fields = [];
        if (!prevState || // initiate
            prevState.userName !== this.state.userName) { // update
            fields.push({
                id: 'name',
                prefill: { '*': this.state.userName } // '*' means all locales (https://developer.zendesk.com/embeddables/docs/widget/settings#fields)
            });
        }
        if (!prevState || prevState.userEmail !== this.state.userEmail) {
            fields.push({
                id: 'email',
                prefill: { '*': this.state.userEmail }
            });
        }
        if (!prevState || prevState.customerOrderNumber !== this.state.customerOrderNumber) {
            fields.push({
                id: '360054276214', // Customer Order Number (https://klashwerks.zendesk.com/agent/admin/ticket_fields)
                prefill: { '*': this.state.customerOrderNumber }
            });
        } else {
            fields.push({
                id: '360054276214', // Customer Order Number (https://klashwerks.zendesk.com/agent/admin/ticket_fields)
                prefill: { '*': "" }
            });
        }
        if (!prevState || prevState.customerAccountNumber !== this.state.customerAccountNumber) {
            fields.push({
                id: '360054276234', // Customer Account Number (https://klashwerks.zendesk.com/agent/admin/ticket_fields)
                prefill: { '*': this.state.customerAccountNumber }
            });
        } else {
            fields.push({
                id: '360054276234', // Customer Account Number (https://klashwerks.zendesk.com/agent/admin/ticket_fields)
                prefill: { '*': "" }
            });
        }

        let subject = "";
        let description = "";

        const prevStateItemRavenSerialNumber = prevState && prevState.item && prevState.item.Raven && prevState.item.Raven.hasOwnProperty("Serial No.") ? prevState.item.Raven["Enclosure Serial No."] : undefined;
        const stateItemRavenSerialNumber = this.state.item && this.state.item.Raven && this.state.item.Raven.hasOwnProperty("Serial No.") ? this.state.item.Raven["Enclosure Serial No."] : undefined;

        if (!prevState || prevStateItemRavenSerialNumber !== stateItemRavenSerialNumber) {

            if (stateItemRavenSerialNumber) {
                subject = "Raven Diagnostic - " + stateItemRavenSerialNumber;
                description = "\n\n\n" +
                    "Pre-filled Raven Details below." + "\n\n" +
                    "Serial Number (Enclosure):\n" + stateItemRavenSerialNumber + "\n\n";
                    if (this.state.item && this.state.item.Raven) {
                        description += "ICCID:\n" + this.state.item.Raven["ICCID"] + "\n\n" +
                            "IMEI:\n" + this.state.item.Raven["IMEI"] + "\n\n" +
                            "UUID:\n" + this.state.item.Raven["Raven UUID"];    
                    }
            }
        }

        const prevStateItemBuild = prevState && prevState.item && prevState.item.Build ? prevState.item.Build : undefined;
        const stateItemBuild = this.state.item && this.state.item.Build ? this.state.item.Build : undefined;

        if (!prevState || prevStateItemBuild !== stateItemBuild) {

            if (stateItemBuild) {
                description += "\n" +
                    "Stage:\n" + stateItemBuild["Stage"] + "\n\n" +
                    "OS Version:\n" + stateItemBuild["OS Version"] + "\n\n" +
                    "MCU Version:\n" + stateItemBuild["MCU Version"] + "\n\n" +
                    "WNC Version:\n" + stateItemBuild["WNC Version"];
            }
        }

        const prevStateItemSIM = prevState && prevState.item && prevState.item.SIM;
        const stateItemSIM = this.state.item && this.state.item.SIM;

        if (!prevState || prevStateItemSIM !== stateItemSIM) {

            if (stateItemSIM) {
                description += "\n" +
                    "SIM SID:\n" + stateItemSIM["SIM SID"] + "\n\n" +
                    "SIM Status:\n" + stateItemSIM["SIM Status"];
            }
        }

        if (globalconfig.features &&
            globalconfig.features.support &&
            globalconfig.features.support.escalationFormVendorName) {
            description += "\n\nSupport Tool Environment:\n" + globalconfig.features.support.escalationFormVendorName;
        }

        if (subject !== "") {
            fields.push({
                id: 'subject',
                prefill: { '*': subject}
            });
        }

        if (description !== "") {
            fields.push({
                id: 'description',
                prefill: { '*': description }
            });
        }
        return fields;
    }

    zendeskUpdateFieldsPrefills = (prevProps = undefined) => {

        if (window.hasOwnProperty('zE')) {

            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    contactForm: {
                        fields: this.zendeskCustomFieldsPrefillsDelta(),
                        title: {
                            '*': 'Contact Raven Connected'
                        },
                        subject: true
                    }
                }
            });    
        }
    }

    zendeskInitializeCustomFieldsPrefills = () => {

        if (this.props.user) {

            this.props.user.getUserAttributes((error, response) => {

                if (error) {
                    console.error(error);
                    return;
                }
                if (!response) {
                    console.error("ZendeskContactWebWidget Cognito getUserAttributes no response");
                    return;
                }

                let userEmail = undefined;

                response.forEach(attribute => {
                    const attributeName = attribute.getName();
                    const attributeValue = attribute.getValue();
                    switch (attributeName) {
                        case "email":
                            userEmail = attributeValue;
                    }
                });
    
                this.setState({
                    userEmail: userEmail
                });
            });
        }

        // while getUserAttributes is completing, initialize fields for current state
        window.zESettings = {
            webWidget: {
                contactForm: {
                    fields: this.zendeskCustomFieldsPrefillsDelta()
                }
            }
        }
    };

    appendZendeskWebWidgetScript = () => {

        // Zendesk web widget does not permit removing listeners
        // (An on.close listener could be used to hide launcher button without resorting to undocumented/private APIs)
        // As an alertnative, ∴ appending CSS to hide launcher button completely based on Zendesk's id string
        // Wors case scenario, if Zendesk changes their launcher id, the standard "Support" will remain visible (due to time constraints, assuming the risk is acceptable)
        const hideZendeskStyle = document.createElement("style");
        hideZendeskStyle.innerText = `
            body > div > iframe#launcher {
                visibility: hidden !important;
            }
            body > div > iframe#webWidget {
                bottom: 50% !important;
                right: 50% !important;
                transform: translate(50%, 50%);
                -ms-transform: translate(50%, 50%);
            }
        `;
        document.head.appendChild(hideZendeskStyle);

        const zendeskScript = document.createElement('script');
        zendeskScript.onload = this.zendeskScriptDidLoad();
        zendeskScript.id = "ze-snippet";
        zendeskScript.src = "https://static.zdassets.com/ekr/snippet.js?key=a1f9954e-8dbc-4c9a-ac95-ea9bc32f20c5";
        document.body.appendChild(zendeskScript);
    }

    openZendeskWidget = () => {
        if (window.hasOwnProperty('zE')) {
            window.zE('webWidget', 'show'); // shows launcher button (must be visible for widget to open)
            window.zE('webWidget', 'open'); // opens widget
            this.zendeskInitializeCustomFieldsPrefills();
        } else {
            this.appendZendeskWebWidgetScript();
        }
    }

    closeZendeskWidget = () => {
        if (window.hasOwnProperty('zE')) {
            window.zE('webWidget', 'close'); // closes widget
            window.zE('webWidget', 'hide'); // hides launcher button in bottom right corner
            //  zendeskWidgetDidClose -> window.zE('webWidget', 'hide'); // hides launcher button
        }
        // else nothing to do
    };

    zendeskWidgetDidLoad = () => {

        this.zendeskInitializeCustomFieldsPrefills();

        /* // Zendesk web widget does not permit removing listeners, try to avoid the following:
        if (window.hasOwnProperty('zE')) {
            window.zE('webWidget:on', 'close', this.zendeskWidgetDidClose);
        }*/
        if (window.hasOwnProperty('zE')) {
            window.zE('webWidget:on', 'close', this.props.onClose); // attempting to avoid/reduce memory leak risk by passing pointer of parent component
        }

        if (this.props.isOpen === undefined || this.props.isOpen === true) {
            window.zE('webWidget', 'open');
        }
    }

    // not used, due to memory leak risk:
    zendeskWidgetDidClose = () => {
        window.zE('webWidget', 'hide'); // hide launcher button (doesn't hide the widget, i.e. contact form)
        this.props.onClose(); // cleanup
    }

    zendeskScriptDidLoad = () => {

        // monitor script launch for zE API to become ready
        const zendeskWidgetLoading = setInterval(() => {
            if (window.hasOwnProperty('zE')) {
                clearInterval(zendeskWidgetLoading);
                this.zendeskWidgetDidLoad();
            }
        }, 100); // 10/s to prep (hide launcher) before zE launcher button auto-shows (zE launcher button fades in over a period of 1-2 seconds, at time of writing)
    }

    render () {
        return null;
    }
}