import React, { Component } from 'react';
import Modal from 'react-modal';
import Events from '../../common/events';
import CognitoUtil from '../../aws/cognito/cognitoUtil';
import NewPasswordModal from './newPasswordModal';
import ResetPasswordModal from './resetPasswordModal';

/*
const customStyles = {
  content : {
    top                   : '20%',
    left                  : '20%',
    right                 : 'auto',
    bottom                : 'auto',
    // marginRight           : '-50%',
    // transform             : 'translate(-50%, -50%)'
  }
};
*/


export default class LoginModal extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isOpen: true,
            email: '',
            password: '',
            errorMessage: '',
            emailValid: true,
            passwordValid: true,
            form: 'login'
        };
    }

    componentDidMount() {
        
        //refresh login
        document.addEventListener(Events.LOGIN_FAILED, this.onLoginFailed);
        document.addEventListener(Events.LOGIN_PASSWORD_RESET_REQUIRED, this.onLoginException);
        document.addEventListener(Events.LOGIN_NEW_PASSWORD_REQUIRED, this.onLoginException);
        document.addEventListener(Events.LOGIN_MFA_REQUIRED, this.onLoginException);

        // this.email.focus();
    }

    componentWillUnmount() {
        
        document.removeEventListener(Events.LOGIN_FAILED, this.onLoginFailed);
        document.removeEventListener(Events.LOGIN_PASSWORD_RESET_REQUIRED, this.onLoginException);
        document.removeEventListener(Events.LOGIN_NEW_PASSWORD_REQUIRED, this.onLoginException);
        document.removeEventListener(Events.LOGIN_MFA_REQUIRED, this.onLoginException);
    }
    
    onLoginFailed = (event) => {
        
        document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
        if(event.detail && event.detail.message)
        {
            this.setState({ isOpen: true, errorMessage: event.detail.message });
        }
        else
        {
            this.setState({ isOpen: true, errorMessage: '' });
        }
    }

    onLoginException = (event) => {

        document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));

        var message = '';
        var form = 'login';

        if(event.type === Events.LOGIN_PASSWORD_RESET_REQUIRED)
        {
            form = 'reset';
        }
        else if(event.type === Events.LOGIN_NEW_PASSWORD_REQUIRED)
        {
            form = 'newpass';

        }
        else if(event.type === Events.LOGIN_MFA_REQUIRED)
        {
            message = "Authentication Verification Required.  Please contact Raven Connected Support.";
        }

        this.setState({ isOpen: true, errorMessage: message, form: form });
    }

    handleEmailChange = (e, force = false) => {

        this.validateEmail(e.target.value, force);
    }

    handlePasswordChange = (e, force = false) => {

        this.validatePassword(e.target.value, force);
    }

    validateEmail(value, force) {

        var emailValid = false;

        if(!this.state.emailValid || force) {
            var longEnough = value.length > 0;
            // var indexOfAt = value.indexOf('@');
            // var charBeforeAt = value.slice(0, indexOfAt).length > 0;
            // var charAfterAt = value.slice(indexOfAt, value.length - 1).length > 0;
    
            emailValid = longEnough ; // && indexOfAt > -1 && charBeforeAt && charAfterAt;
            this.setState({ email: value, emailValid: emailValid });
        }
        
        return emailValid;
    }

    validatePassword(value, force) {

        var passwordValid = value.length > 0;
        this.setState({ password: value, passwordValid: passwordValid });

        return passwordValid;
    }

    onLogin = (e) => {

        e.preventDefault();
        var emailValid = this.validateEmail(this.email.value, true);
        var passwordValid = this.validatePassword(this.password.value, true);
        if ( emailValid && passwordValid ) {
            e.preventDefault();
            const email = this.state.email.trim();
            const password = this.state.password.trim();
            document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE, { detail: { loadingMessage: 'Logging you in' } }));
            CognitoUtil.loginUser(email, password, this.props.history);
        }
    }

    renderLoginForm = () => {
        return (
            <form id="loginForm">
                <h1>Sign In</h1>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="username">Username</label>
                        <input ref={(email) => { this.email = email; }} id="username" type="email" name="email" className={this.state.emailValid ? '' : 'invalid'} onChange={this.handleEmailChange} onBlur={(e) => {this.handleEmailChange(e, true)}} />
                        {this.state.emailValid ? <p className="error-message">&nbsp;</p> : <p className="error-message">Invalid Username</p>}
                    </div>

                    <div className="col-12">
                        <label htmlFor="password">Password</label>
                        <input ref={(password) => { this.password = password; }} id="password" type="password" name="password" className={this.state.passwordValid ? '' : 'invalid'} onChange={this.handlePasswordChange} onBlur={(e) => {this.handlePasswordChange(e, true)}} />
                        {this.state.passwordValid ? <p className="error-message">&nbsp;</p> : <p className="error-message">Invalid Password</p>}
                    </div>
                    <div className="col-12">
                        <input className="btn" type="submit" onClick={this.onLogin} value="Login" />  
                    </div>
                    {(this.state.errorMessage) ? (<div className="col-12"><p className="error-message"><i className="material-icons">error</i><span>{this.state.errorMessage}</span></p></div>) : null}
                </div>
            </form>
        );
    }

    renderForm = () => {
        if(this.state.form === 'newpass')
        {
            return ( <NewPasswordModal /> );
        }
        else if(this.state.form === 'reset')
        {
            return ( <ResetPasswordModal /> );
        }
        return this.renderLoginForm();
    }

    render() {

        return (
            <Modal id="loginModal" className="login-modal"
                isOpen={this.state.isOpen}
                contentLabel="Login"
            >

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-5 login-form">
                            { this.renderForm() }
                        </div>
                        <div className="col-12 col-md-7 welcome-group">
                            <div>
                                <h2>Welcome to Raven Support Tool</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
