import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RootPage from './components/pages/rootPage';
import LoginModal from './components/modals/login/loginModal';
import { LoadingPage } from './components/common/loadingPage';
import CognitoUtil from './components/aws/cognito/cognitoUtil';
import Events from './components/common/events';
import globalconfig from "./components/common/config";

import 'bootstrap/dist/css/bootstrap.min.css';

// Import stylesheet, defaults to ./stylesheets/main.scss
// May be overridden with REACT_APP_MAIN_STYLESHEET env var
const MAIN_STYLESHEET_FIRST_LOAD_WAIT_TIME = 1000;
import(process.env.REACT_APP_MAIN_STYLESHEET || './stylesheets/main.scss')
    .then(module => {
        if (process.env.REACT_APP_MAIN_STYLESHEET) {
            console.info("Applying " + process.env.REACT_APP_MAIN_STYLESHEET);
        }// else { console.info("Applying default stylesheet"); }
    })
    .catch(error => {
        import('./stylesheets/main.scss').then(module => {
            console.error(process.env.REACT_APP_MAIN_STYLESHEET + "failed to load. ", );
        });
    });

class App extends Component {
    constructor() {
        super();

        document.addEventListener(Events.LOGIN_SUCCESS, this.onLoginSuccess);

        this.state = {
            curPage: 'login',
            styleSheetApplied: false // avoid flashing unstyled web app ()
        };

    }

    componentDidMount() {
        CognitoUtil.refreshLogin();

        setTimeout(() => {
            this.setState({styleSheetApplied: true});
        }, MAIN_STYLESHEET_FIRST_LOAD_WAIT_TIME);
    }

    onLoginSuccess = (event) => {

        /* Go get the stages.  Only after we get them can you log in */
          fetch("" + process.env.REACT_APP_KLOUD_API_BASE_URL + 'query/stages',

                { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } }
            )
            .then((response) => {
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
              return response.json();
            }).then((json) => {
                if(json.hasOwnProperty('errorMessage'))
                {
                    // Some error message, probably a permissions issue
                }
                if(Array.isArray(json))
                {
                    globalconfig.ravenStages = json;
                    this.setState({curPage: 'main'});
                }
                else
                {
                    // Some unknown error
                }
            });
    }

	render() {

        var curpage = <LoginModal isOpen="true"/>;

        if (!this.state.styleSheetApplied) {
            return null; // see MAIN_STYLESHEET_FIRST_LOAD_WAIT_TIME timeout in componentDidMount above
        }

        if(this.state.curPage === 'login') 
        {
            curpage = <LoginModal isOpen="true"/>;
        }
        else if(this.state.curPage === 'main')
        {
            curpage = <RootPage />;
        }

        return (
            <Router basename={process.env.PUBLIC_URL} >
                <div>
                    <LoadingPage color="blue" />
                    {curpage}
                </div>
            </Router>
        );
	}
}

export default App;
