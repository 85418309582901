import React, { Component } from 'react';

import Events from '../common/events.js';

class LoadingPage extends Component {

    constructor(props) {

        super(props);

        this.state = {
            show: false
        };

        document.addEventListener(Events.SHOW_LOADING_PAGE, this.showLoadingPage);
        document.addEventListener(Events.HIDE_LOADING_PAGE, this.hideLoadingPage);
    }

    showLoadingPage = (e) => {

        console.log("Show Loading Page from",e);

        this.setState({ show: true, message: (e.detail && e.detail.loadingPageMessage) ? e.detail.loadingPageMessage : '' });

        document.documentElement.classList.add("loading");
    }

    hideLoadingPage = (e) => {

        console.log("Hide Loading Page from",e);

        this.setState({ show: false });
        document.documentElement.classList.remove("loading");
    }

    render() {

        if(this.state.show) {
            return (
                <div id="loadingPage">
                    <div>
                        <div className="loading-activity-indicator"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        <h3>{this.state.message}</h3>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

class ModalLoadingPage extends Component {

    render() {

        var loadingClassName = '';

        loadingClassName = this.props.color === 'blue' ? loadingClassName + 'blue' : '';
        var loadingPageImage = this.props.color === 'blue' ? '/images/raven-letter-white.png' : '/images/raven-letter-blue.png';

        return (
            <div id="modalLoadingPage">
                <div className="loading-activity-indicator"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }
}

export {
    LoadingPage,
    ModalLoadingPage
}
