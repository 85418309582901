import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import StageSelector from '../../../widgets/stageSelector';

export default class Header extends Component {

    render() {

        return (
            <div className="row" id="controls">
                <div className="branding col-2 raven-logo">
                    <Link to="/"></Link>
                </div>
                <div className="viewOptions col-10">
                    <div className="row">
                        <div className="col-3" >
                            <StageSelector {...this.props} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
