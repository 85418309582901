import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import "react-table/react-table.css";
import moment from 'moment';
import globalconfig from '../../../../common/config';
import LngLatLink from '../../../../widgets/lngLatLink';

export default class EventView extends Component {

    render() {

        var columns = [
            {
                Header: 'Time',
                accessor: d => moment.utc(d.ts).unix() * 1000,
                id: 'ts',
                Cell: props => <span title={moment.utc(props.original.ts).unix()}>{ moment(props.value).format(globalconfig.display.timeFormat) } ( { moment(props.value).fromNow() } ) </span> ,
            },
            {
                Header: 'Segment',
                accessor: 'seg_id',
                maxWidth: 100,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Level',
                accessor: 'level',
                maxWidth: 80,
            },
            {
                Header: 'Duration',
                accessor: 'duration',
                maxWidth: 80,
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Location',
                accessor: 'lat',
                Cell: cellInfo => ( <LngLatLink ll={[cellInfo.original.lng,cellInfo.original.lat]} />),
            },
            {
                Header: 'Media',
                accessor: 'media',
                Cell: value => {

                    if(!value.original.media)
                        return (<div></div>);

                    var viewers = value.original.media.split(',');

                    var txt = viewers.map((obj) => {
                        var tokens = obj.split(';');
                        // return "Viewer: " + tokens[1] + " id: " + tokens[0];
                        // <a href="#!" onClick={(e) => { this.onItemClick(e, item)}}>{item}</a>
                        return (
                            <div key={tokens[0]} title={tokens[1] ? tokens[1] : "No URL"}>{tokens[0]}</div>
                        );
                    });

                    return ( <div>{txt}</div> );

                },
            },
        ];

        var datasize = this.props.data ? this.props.data.length : 0;
        var paginate = this.props.data ? datasize > this.props.pageSize : false;

        var pagesize = paginate ? this.props.pageSize : datasize;


        return (
            <div id="tombstone-listing" className="tombstone-list">
                    <ReactTable className="-striped" data={this.props.data}
                        filterable={true}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value) }
                        showPagination={paginate}
                        showPageSizeOptions={true}
                        showPageJump={true}
                        style = {{ height: "65vh"}}
                        noDataText="No Events"
                        defaultSorted={[{ id: 'ts', desc: true }]}
                        defaultPageSize={pagesize}
                        columns={columns} 
                        loading={this.props.loading}
                        onPageChange={(pageIndex) => { if(pageIndex >= (this.props.data.length/pagesize) - 2) { this.props.requestMoreData(); } } }
                    />
            </div>
        );
    }
}


EventView.defaultProps = {
    pageSize: 100,
};

EventView.propTypes = {
    pageSize: PropTypes.number,
}
