import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dialog from '../dialog';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';
import Events from '../../../../common/events';

export default class CouponLookupModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            couponData: [],
            curCoupon: null
        }

        this.getCouponData();

    }


    getCouponData = () => {

        var prefix = this.props.dataStore.prefix1;

        return fetch(prefix + "ajax" +
            "?stage=" + this.props.stage +
            "&type=coupon",
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
        .then((response) => {
          return response.json();
        }).then((json) => {
            this.setState({couponData: json});

            this.updateCouponData(json);

        });
    }

    
    updateCouponData = (couponData) => {
        if(couponData)
        {
            this.couponList = couponData.map(obj => {
                return { value : '' + obj['coupon']
                        + ' ' + obj['email'],
                    label: '' + obj['coupon'],
                    id: obj['coupon'],
                    obj: obj
                };

            });
        }
        else
        {
            this.couponList = [];
        }

    }

    onSelectCoupon(item) {
        this.setState({curCoupon: item.obj});
    }

    render() {
        var content = "Search Coupon";

        var errorMessage = this.state.message ?
            <p className="error-message"><i className="material-icons">error_outline</i>{this.state.message}</p> :
            null;

        var title;

        if(this.state.curCoupon)
        {
            content ="Information For "+this.state.curCoupon;

/*
            content = (
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">Raven ID:</div>
                        <div className="col-6">{this.state.raven.item['Raven']['Enclosure Serial No.']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Last Email:</div>
                        <div className="col-6">{this.state.raven.item['Account']['Email']}</div>
                    </div>
                    <div className="row">
                        <div className="col-6">Current Status is:</div>
                        <div className="col-6">{this.state.raven.item["Raven"]["Raven Unit Status"]} </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Change Status to: 
                        </div>
                        <div className="col-6">
                            <Select id="ravenSearch" 
                            placeholder="Change..."
                            searchable={true} 
                            value={this.state.curState}
                            options={this.ravenStates}  
                            name="ravenStatusChange" 
                            onChange={this.onRavenStatusChange} 
                            clearable={false} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Suspend Activity:<br />
                            <small>(Raven at Klashwerks)</small>
                        </div>
                        <div className="col-6">
                            <input type="checkbox" 
                                defaultChecked={this.state.suspend_activity} 
                                onChange={this.onRavenSuspendChange} 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Replacement Raven Serial No:
                        </div>
                        <div className="col-6">
                            <input type="text" 
                                name="serialno"
                                onChange={(e) => { this.onRavenSerialChange(e.target.value) }} 
                                value={this.state.serialno}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {this.state.curState ?
                                <button onClick={this.onMakeChange}>Commit</button> :
                                <button onClick={this.onMakeChange} disabled>Select an Option</button>
                            }
                        </div>
                        <div className="col-6">
                            <button onClick={this.openHistoryModal}>View History</button>
                            {errorMessage}
                        </div>
                    </div>
                    <RavenViewStatusModal title={title} isOpen={this.state.historyModalIsOpen} 
                            onClose={() => {this.setState({historyModalIsOpen: false})}} 
                            raven={this.state.raven} 
                            stage={this.props.stage} 
                    />
                </div>
            );
*/
        }

        return (
            <Dialog
                size="small"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >
                    <div>
                        <div className="row">
                            <div className="col-2">Coupon:</div>
                            <div className="col-10">
                                <Select id="couponSearch" 
                                    placeholder="Search..."
                                    searchable={true} 
                                    options={this.couponList}
                                    name="couponSearch" 
                                    filterOption={(option, filter) => {

                                        // Search: ravenid, raven unit id, email, raven enclosure id 
                                        if(option.obj['coupon'].toString().includes(filter))
                                            return true;

                                        return false;
                                    }}
                                    onChange={this.onSelectCoupon} 
                                    clearable={false} />
                            </div>
                        </div>
                        <div className="row">
                            {content}
                        </div>
                    </div>
            </Dialog>
        );
    }
}
