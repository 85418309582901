"use strict";

import { nest } from "d3-collection";

import React, { Component } from "react";
import PropTypes from "prop-types";

import GenericChartComponent from "react-stockcharts/lib/GenericChartComponent";
import { getAxisCanvas } from "react-stockcharts/lib/GenericComponent";

import { first, last, hexToRGBA, isDefined, functor } from "react-stockcharts/lib/utils";

class BoxPlotSeries extends Component {
	constructor(props) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
		this.drawOnCanvas = this.drawOnCanvas.bind(this);
	}
	drawOnCanvas(ctx, moreProps) {
		drawOnCanvas(ctx, this.props, moreProps);
	}
	renderSVG(moreProps) {
		var { className, wickClassName, boxClassName } = this.props;

		return <g className={className}>
			<g className={wickClassName} key="wicks">
				{getWicksSVG(this.props, moreProps)}
			</g>
			<g className={boxClassName} key="candles">
				{getBoxSVG(this.props, moreProps)}
			</g>
			<g className={wickClassName} key="means">
				{getMeanSVG(this.props, moreProps)}
			</g>
		</g>;
	}

	render() {
		var { clip } = this.props;
		return <GenericChartComponent
			canvasToDraw={getAxisCanvas}
			svgDraw={this.renderSVG}
			canvasDraw={this.drawOnCanvas}
			clip={clip}
			drawOn={["pan"]}
			/>;
	}
}

BoxPlotSeries.propTypes = {
	className: PropTypes.string,
	wickClassName: PropTypes.string,
	boxClassName: PropTypes.string,
	widthRatio: PropTypes.number.isRequired,
	classNames: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string
	]).isRequired,
	fill: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string
	]).isRequired,
	stroke: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string
	]).isRequired,
	wickStroke: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string
	]).isRequired,
	yAccessor: PropTypes.func.isRequired,
	clip: PropTypes.bool.isRequired,
};

BoxPlotSeries.defaultProps = {
	className: "react-stockcharts-candlestick",
	wickClassName: "react-stockcharts-candlestick-wick",
	boxClassName: "react-stockcharts-candlestick-candle",
	yAccessor: d => ({ min: d.min, max: d.max, mean: d.mean, stdev: d.stdev }),
	classNames: "up",
	widthRatio: 0.8,
	wickStroke: "#000000",
	fill: "#6BA583",
	stroke: "#000000",
	boxStrokeWidth: 0.5,
	opacity: 0.5,
	clip: true,
};

function getWicksSVG(props, moreProps) {

    var plotData = props.plotData;
    if(!plotData)
        plotData = moreProps.plotData;

	/* eslint-disable react/prop-types */
	var { xScale, chartConfig: { yScale }, xAccessor } = moreProps;
	/* eslint-enable react/prop-types */

	var wickData = getWickData(props, xAccessor, xScale, yScale, plotData);
	var wicks = wickData
		.map((d, idx) => <path key={idx}
			className={d.className} stroke={d.stroke} style={{ shapeRendering: "crispEdges" }}
			d={`M${d.x},${d.y1} L${d.x},${d.y2} M${d.x},${d.y3} L${d.x},${d.y4}`} />
		);
	return wicks;
}

function getBoxSVG(props, moreProps) {

    var plotData = props.plotData;
    if(!plotData)
        plotData = moreProps.plotData;

	/* eslint-disable react/prop-types */
	var { opacity, boxStrokeWidth } = props;
	var { xScale, chartConfig: { yScale }, xAccessor } = moreProps;
	/* eslint-enable react/prop-types */

	var boxData = getBoxData(props, xAccessor, xScale, yScale, plotData);
	var boxes = boxData.map((d, idx) => {
		if (d.width < 0)
			return (
				<line className={d.className} key={idx}
					x1={d.x} y1={d.y} x2={d.x} y2={d.y + d.height}
					stroke={d.fill} />
			);
		else if (d.height === 0)
			return (
				<line key={idx}
					x1={d.x} y1={d.y} x2={d.x + d.width} y2={d.y + d.height}
					stroke={d.fill} />
			);
		return (
			<rect key={idx} className={d.className}
				fillOpacity={opacity}
				x={d.x} y={d.y} width={d.width} height={d.height}
				fill={d.fill} stroke={d.stroke} strokeWidth={boxStrokeWidth} />
		);
	});
	return boxes;
}

function getMeanSVG(props, moreProps) {

    var plotData = props.plotData;
    if(!plotData)
        plotData = moreProps.plotData;

	/* eslint-disable react/prop-types */
	var { xScale, chartConfig: { yScale }, xAccessor } = moreProps;
	/* eslint-enable react/prop-types */

	var meanData = getMeanData(props, xAccessor, xScale, yScale, plotData);
	var means = meanData
		.map((d, idx) => <path key={idx}
			className={d.className} stroke={d.stroke} stroke-width={2} style={{ shapeRendering: "crispEdges" }}
			d={`M${d.x1},${d.y} L${d.x2},${d.y}`} />
		);
	return means;
}

function drawOnCanvas(ctx, props, moreProps) {

    var plotData = props.plotData;
    if(!plotData)
        plotData = moreProps.plotData;

	var { opacity, boxStrokeWidth } = props;
	var { xScale, chartConfig: { yScale }, xAccessor } = moreProps;

	var wickData = getWickData(props, xAccessor, xScale, yScale, plotData);

	var wickNest = nest()
		.key(d => d.stroke)
		.entries(wickData);

	wickNest.forEach(outer => {
		var { key, values } = outer;
		ctx.strokeStyle = key;
		values.forEach(d => {
			ctx.beginPath();
			ctx.moveTo(d.x, d.y1);
			ctx.lineTo(d.x, d.y2);

			ctx.moveTo(d.x, d.y3);
			ctx.lineTo(d.x, d.y4);
			ctx.stroke();
		});
	});

	var boxData = getBoxData(props, xAccessor, xScale, yScale, plotData);

	var boxNest = nest()
		.key(d => d.stroke)
		.key(d => d.fill)
		.entries(boxData);

	boxNest.forEach(outer => {
		var { key: strokeKey, values: strokeValues } = outer;
		if (strokeKey !== "none") {
			ctx.strokeStyle = strokeKey;
			ctx.lineWidth = boxStrokeWidth;
		}
		strokeValues.forEach(inner => {
			var { key, values } = inner;
			ctx.fillStyle = hexToRGBA(key, opacity);

			values.forEach(d => {
				if (d.width < 0) {
					// <line className={d.className} key={idx} x1={d.x} y1={d.y} x2={d.x} y2={d.y + d.height}/>
					ctx.beginPath();
					ctx.moveTo(d.x, d.y);
					ctx.lineTo(d.x, d.y + d.height);
					ctx.stroke();
				} else if (d.height === 0) {
					// <line key={idx} x1={d.x} y1={d.y} x2={d.x + d.width} y2={d.y + d.height} />
					ctx.beginPath();
					ctx.moveTo(d.x, d.y);
					ctx.lineTo(d.x + d.width, d.y + d.height);
					ctx.stroke();
				} else {
					ctx.beginPath();
					ctx.rect(d.x, d.y, d.width, d.height);
					ctx.closePath();
					ctx.fill();
					if (strokeKey !== "none") ctx.stroke();
				}
			});
		});
	});

	var meanData = getMeanData(props, xAccessor, xScale, yScale, plotData);

	var meanNest = nest()
		.key(d => d.stroke)
		.entries(meanData);

	meanNest.forEach(outer => {
		var { key, values } = outer;
		ctx.strokeStyle = key;
		ctx.lineWidth = 2;
		values.forEach(d => {
			ctx.beginPath();
			ctx.moveTo(d.x1, d.y);
			ctx.lineTo(d.x2, d.y);
			ctx.stroke();
		});
	});
}

function getWickData(props, xAccessor, xScale, yScale, plotData) {

	var { classNames: classNameProp, wickStroke: wickStrokeProp, yAccessor } = props;
	var wickStroke = functor(wickStrokeProp);
	var className = functor(classNameProp);
	var wickData = plotData
			.filter(d => isDefined(yAccessor(d).mean))
			.map(d => {
				// console.log(yAccessor);
				var mmmd = yAccessor(d);

                if(!mmmd.stdev)
                {
                    mmmd.stdev = 0;
                }

				var x = Math.round(xScale(xAccessor(d))),
					y1 = yScale(mmmd.max),
					y2 = yScale(mmmd.mean + mmmd.stdev),
					y3 = yScale(mmmd.mean - mmmd.stdev),
					y4 = yScale(mmmd.min);

				return {
					x,
					y1,
					y2,
					y3,
					y4,
					className: className(mmmd),
					direction: 1,
					stroke: wickStroke(mmmd),
				};
			});
	return wickData;
}

function getBoxData(props, xAccessor, xScale, yScale, plotData) {
	var { classNames, fill: fillProp, stroke: strokeProp, widthRatio, yAccessor } = props;
	var fill = functor(fillProp);
	var stroke = functor(strokeProp);
	// console.log(plotData);
	var width = xScale(xAccessor(last(plotData)))
		- xScale(xAccessor(first(plotData)));
	var cw = (width / (plotData.length - 1) * widthRatio);
	var boxWidth = Math.round(cw); // Math.floor(cw) % 2 === 0 ? Math.floor(cw) : Math.round(cw);

	var offset = (boxWidth === 1 ? 0 : 0.5 * cw);
	var boxes = plotData
			.filter(d => isDefined(yAccessor(d).stdev)) // only draw box if there is a stdev value
			.map(d => {
				var mmmd = yAccessor(d);
				var x = Math.round(xScale(xAccessor(d)) - offset),
					y = yScale(mmmd.mean + mmmd.stdev),
					height = Math.abs(yScale(mmmd.stdev * 2)),
					className = classNames.up;
				return {
					// type: "line"
					x: x,
					y: y,
					height: height,
					width: boxWidth,
					className: className,
					fill: fill(mmmd),
					stroke: stroke(mmmd),
					direction: 1,
				};
			});
	return boxes;
}

function getMeanData(props, xAccessor, xScale, yScale, plotData) {

	var { classNames: classNameProp, wickStroke: wickStrokeProp, widthRatio, yAccessor } = props;
	var wickStroke = functor(wickStrokeProp);
	var className = functor(classNameProp);

	var width = xScale(xAccessor(last(plotData)))
		- xScale(xAccessor(first(plotData)));
	var cw = (width / (plotData.length - 1) * widthRatio);
	var offset = (boxWidth === 1 ? 0 : 0.5 * cw);
	var boxWidth = Math.round(cw); // Math.floor(cw) % 2 === 0 ? Math.floor(cw) : Math.round(cw);

	var meanData = plotData
			.filter(d => isDefined(yAccessor(d).mean))
			.map(d => {
				// console.log(yAccessor);
				var mmmd = yAccessor(d);

				var x1 = Math.round(xScale(xAccessor(d)) - offset),
                    x2 = x1 + boxWidth,
					y = yScale(mmmd.mean);

				return {
					x1,
					x2,
					y,
					className: className(mmmd),
					direction: 1,
					stroke: wickStroke(mmmd),
				};
			});

    // console.log("Mean Data",meanData.length, "First", meanData[0].x1, "-", meanData[0].x2, "at", meanData[0].y);
	return meanData;
}

export default BoxPlotSeries;
