import React, { Component } from 'react';
import Select from 'react-select';

import Dialog from './dialog';

import CognitoUtil from '../../../aws/cognito/cognitoUtil';

/* Class to ask the user to perform OTA and to display the result from das Kloud
*/
export class EditAccountModal extends Component {
    constructor (props) {
        super(props);

        this.state = {
            name: null,
            val: null,
        };
    }

    getValueByPath(obj, path) {
        return path.split('.').reduce((o, k) => (o || {})[k], obj);
    }

    componentDidMount() {
        const propertyMap = {
            notes: { name: "Notes", valPath: "account.notes" },
            note: { name: "Note", valPath: "account.notes" },
            email: { name: "Email", valPath: "account.email" },
            name: { name: "Name", valPath: "account.name" },
            plancode: { name: "Plan", valPath: "plan.name" },
            planprice: { name: "Plan Price", valPath: "plan.price" },
        };
        const property = this.props.property;
        const propertyData = propertyMap[property];

        if (propertyData) {
            const val = propertyData.valPath.split('.').reduce((o, k) => (o || {})[k], this.props.data);
            this.setState({
                name: propertyData.name,
                val: val,
            });
        }else {
            console.error("Unknown property", property);
        }
    }

    onValChange = (val) => {
        this.setState({val: val});
    }

    onEditClicked = () => {

        console.log("Sending",this.state);

        this.props.onSuccess(this.props.property, this.state.val);

        this.props.onClose();
    }

    render() {

        var title = "Edit Account";
        var buttonText = "Edit";

        if(this.props.data && this.props.data.account && this.props.data.account.email)
        {
            title += " for ";
            title += this.props.data.account.email;
        }

        return (
            <Dialog
                size="small"
                label={title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >

                <div className="row">
                    <div className="col-3">
                        {this.state.name}
                    </div>
                    <div className="col-9">
                        <input name="orderno" 
                            type="text" 
                            onChange={(e) => { this.onValChange(e.target.value) }}
                            value={this.state.val}
                        />
                    </div>
                    <div className="col-12">
                        <div align="right">
                            <button onClick={this.props.onClose}>Cancel</button> 
                            <button onClick={this.onEditClicked}>{buttonText}</button>
                        </div>
                    </div>

                </div>


            </Dialog>
        );
    }

}
